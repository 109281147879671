import * as React from 'react';
import styled from 'styled-components';
import {useTheme} from '@anywhere-expert/colors';
import {CoreIcon} from '@anywhere-expert/base-ui';

import {EnvelopeIcon} from '@anywhere-expert/icons';

const Dot = styled.div`
    background-color: ${({theme}) => theme.colors.contrast.caption};
    align-self: center;
    height: 2px;
    width: 2px;
    margin: 0px 4px;
`;

const EmailContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const EmailIndication = () => {
    const theme = useTheme();
    return (
        <EmailContainer>
            <CoreIcon size="xs" icon={EnvelopeIcon} color={theme.colors.contrast.caption} />
            <Dot />
        </EmailContainer>
    );
};

export default EmailIndication;
