import React, {useEffect, useMemo} from 'react';
import styled from 'styled-components';
import {AnalyticsProvider, useAnalytics} from 'react-shisell';
import {CoreText} from '@anywhere-expert/base-ui';
import {SnoozeMenu} from '@anywhere-expert/session-actions';
import UnsnoozeButton from './UnsnoozeButton';
import ConversationOverlayBarContainer from './conversation-unavailable-bar/ConversationOverlayBarContainer';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
`;

const SpacedCoreText = styled(CoreText)`
    margin-right: 16px;
`;

type Props = {
    sessionId: string;
};

const margin = {marginRight: '8px'};

const SnoozedFooter = ({sessionId}: Props) => {
    const analytics = useAnalytics();
    const enhancedAnalytics = useMemo(
        () =>
            analytics.dispatcher.createScoped('SnoozedConversationBar').withExtras({
                ConversationStatus: 'Snoozed',
            }),
        [analytics]
    );

    useEffect(() => {
        enhancedAnalytics.dispatch('View');
    }, [enhancedAnalytics]);

    return (
        <AnalyticsProvider dispatcher={enhancedAnalytics}>
            <ConversationOverlayBarContainer>
                <Container>
                    <SpacedCoreText size="m">This conversation is snoozed</SpacedCoreText>
                    <UnsnoozeButton sessionId={sessionId} style={margin} text="Activate conversation" />
                    <SnoozeMenu sessionId={sessionId} position="top" withText />
                </Container>
            </ConversationOverlayBarContainer>
        </AnalyticsProvider>
    );
};

export default SnoozedFooter;
