import {useState, useMemo} from 'react';
import {useTweekValue} from 'react-tweek';
import camelCase from 'camelcase';

type TransferByReasonOptions = {
    addedExpertise?: string;
    displayText: string;
    isEnabled: boolean;
};

type TweekConfig = {
    optionsOrder: string;
} & Record<string, TransferByReasonOptions>;

export type Option = {
    value: string[] | undefined;
    label: string;
};

const useTransferOptions = () => {
    const [selectedOption, setSelectedOption] = useState<Option | undefined>();
    const {optionsOrder = '', ...options} = useTweekValue<TweekConfig>(
        'support/session/add_an_expert/transfer_by_reason/_',
        {optionsOrder: ''} as TweekConfig
    );
    const filteredOptions = useMemo(
        () =>
            optionsOrder
                .split(',')
                .map(o => {
                    const option = options[camelCase(o)];
                    if (!option) return null;

                    const {addedExpertise, displayText, isEnabled} = option;
                    if (!isEnabled) return null;

                    return {
                        value: addedExpertise ? [addedExpertise] : undefined,
                        label: displayText,
                    };
                })
                .filter((x): x is Option => x !== null),
        [optionsOrder, options]
    );

    return {selectedOption, setSelectedOption, filteredOptions};
};

export default useTransferOptions;
