import * as React from 'react';
import {compose, withProps, branch, renderComponent} from 'recompose';
import {StyleSheet, css} from 'aphrodite';
import {SpeedTestResult} from '../types';
import {useTheme} from '@anywhere-expert/colors';

const downArrow = require('./resources/ic-arrow-down.svg');
const upArrow = require('./resources/ic-arrow-up.svg');

const styles = StyleSheet.create({
    resultsContainer: {
        marginTop: '20px',
        textAlign: 'center',
    },
    resultsGradeText: {
        fontSize: '40px',
        lineHeight: '40px',
        fontWeight: 'bold',
    },
    resultsErrorText: {
        fontSize: '20px',
        lineHeight: '20px',
    },
    resultsValuesContainer: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px',
    },
    resultsMargin: {
        marginLeft: '40px',
    },
    resultsValue: {
        flexDirection: 'column',
        display: 'flex',
        marginLeft: '5px',
    },
    resultsValueText: {
        fontSize: '24px',
        lineHeight: '24px',
    },
    resultsUnitsText: {
        fontSize: '12px',
        lineHeight: '12px',
        opacity: 0.6,
        textAlign: 'left',
    },
    arrowIcon: {
        width: '12px',
        height: '12px',
    },
});

const calculateTestGrade = downloadSpeed => {
    if (0 < downloadSpeed && downloadSpeed <= 0.1) {
        return 'Poor';
    }
    if (0.1 < downloadSpeed && downloadSpeed <= 1) {
        return 'OK';
    }
    if (1 < downloadSpeed && downloadSpeed <= 5) {
        return 'Great';
    }
    if (5 < downloadSpeed && downloadSpeed <= 15) {
        return 'Excellent';
    }
    if (15 < downloadSpeed) {
        return 'Amazing';
    }
    return null;
};

const ErrorResult: React.SFC<EnhancedSpeedTestResultProps> = () => {
    const theme = useTheme();

    return (
        <div className={css(styles.resultsContainer)}>
            <span className={css(styles.resultsErrorText)} style={{color: theme.colors.neutral.grey.deepest}}>
                Something went wrong
            </span>
        </div>
    );
};

const Results: React.SFC<EnhancedSpeedTestResultProps> = ({testGrade, downloadSpeed, uploadSpeed}) => {
    const theme = useTheme();

    return (
        <div className={css(styles.resultsContainer)}>
            <span className={css(styles.resultsGradeText)} style={{color: theme.colors.brand.blue}}>
                {testGrade}
            </span>
            <div className={css(styles.resultsValuesContainer)}>
                <img src={downArrow} className={css(styles.arrowIcon)} alt="" />
                <div className={css(styles.resultsValue)}>
                    <span className={css(styles.resultsValueText)} style={{color: theme.colors.neutral.grey.deepest}}>
                        {downloadSpeed}
                    </span>
                    <span className={css(styles.resultsUnitsText)} style={{color: theme.colors.neutral.grey.deepest}}>
                        Mbps
                    </span>
                </div>
                <div className={css(styles.resultsMargin)} />
                <img src={upArrow} className={css(styles.arrowIcon)} alt="" />
                <div className={css(styles.resultsValue)}>
                    <span className={css(styles.resultsValueText)} style={{color: theme.colors.neutral.grey.deepest}}>
                        {uploadSpeed}
                    </span>
                    <span className={css(styles.resultsUnitsText)} style={{color: theme.colors.neutral.grey.deepest}}>
                        Mbps
                    </span>
                </div>
            </div>
        </div>
    );
};

type EnhancedSpeedTestResultProps = SpeedTestResult & {testGrade: string | null};

const withResultsBehavior = compose<EnhancedSpeedTestResultProps, SpeedTestResult>(
    branch(({success}) => success == false, renderComponent(ErrorResult)),
    withProps(({downloadSpeed}) => ({
        testGrade: calculateTestGrade(downloadSpeed),
    })),
    branch(({testGrade}) => testGrade == null, renderComponent(ErrorResult))
);

export default withResultsBehavior(Results);
