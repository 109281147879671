import {CommandResultItem, CommandPrePermissionComponent} from '@anywhere-expert/slash-commands';
import {withTechTemplate, TechListItemActionItem} from '@anywhere-expert/timeline-item';
import {LottieTimelineItem} from '@anywhere-expert/lottie-timeline-item';
import {VideoItem} from '@anywhere-expert/voice-over-video';
import {NameInputItem} from '@anywhere-expert/timeline-item';
import {SelfHelpItem} from '@anywhere-expert/self-help-item';

export default ({url, commandType}: {url: string; commandType: string}) => {
    if (commandType != null) return CommandResultItem;

    switch (url) {
        case 'CommandPrePermission':
            return withTechTemplate(CommandPrePermissionComponent);
        case 'LottieTimelineItem':
            return LottieTimelineItem;
        case 'VideoItem':
            return VideoItem;
        case 'NameInput':
            return NameInputItem;
        case 'ProactiveEmail': // Backwards compatibility
        case 'TechListItemActionItem':
            return TechListItemActionItem;
        case 'SelfHelp':
            return SelfHelpItem;
        default:
            return null;
    }
};
