import React from 'react';
import {StyleSheet, css} from 'aphrodite';
import {palette} from '@anywhere-expert/colors';

const SecureWifiPasswordTypingIcon = require('../../assets/ic_password_box.svg');
import SecureWifiConnectResult from './SecureWifiConnectResult';

const styles = StyleSheet.create({
    mainContainer: {
        marginTop: '24px',
        marginBottom: '24px',
        alignSelf: 'center',
        display: 'flex',
        flexShrink: 0,
        alignItems: 'center',
        flexDirection: 'column',
    },
    message: {
        textAlign: 'center',
        fontSize: '14px',
        color: palette.neutral.grey.deepest,
        fontFamily: 'Roboto',
        fontStyle: 'italic',
        // fontWeight: 'medium',
        lineHeight: 1.54,
    },
    icon: {
        width: '183px',
        height: '59px',
        marginBottom: '12px',
        justifyContent: 'center',
    },
});

export default ({commandResult, dataTestId}) => {
    if ('success' in commandResult) {
        return (
            <SecureWifiConnectResult
                isSuccess={commandResult.success}
                ssid={commandResult.ssid}
                dataTestId={dataTestId}
            />
        );
    }

    return (
        <div className={css(styles.mainContainer)} data-test-id={dataTestId}>
            <img src={SecureWifiPasswordTypingIcon} className={css(styles.icon)} />
            <div className={css(styles.message)}>Customer is typing password..</div>
        </div>
    );
};
