import React, {SFC} from 'react';
import styled from 'styled-components';
import SystemMessage from '@system-messages/system-message';
import {SystemMessageItemProps} from '../types';

const InfoIcon = require('./ic-info.svg');

const Container = styled.div`
    margin-top: 28px;
    margin-bottom: 24px;
    display: flex;
    align-self: center;
    align-items: center;
    flex-direction: column;
    flex-shrink: 0;
`;

const SubMessage = styled.span`
    margin-top: 2px;
    align-self: center;
    text-align: center;
    font-size: 12px;
    font-family: Roboto;
    line-height: 1.54;
    color: ${({theme}) => theme.colors.neutral.grey.deep};
    font-style: italic;
`;

const SystemMessageItem: SFC<SystemMessageItemProps> = ({
    subMessage,
    dataTestId,
    customStyles = {},
    icon,
    subIcon,
    subIconProps = {},
    ...other
}: SystemMessageItemProps) => (
    <Container style={customStyles.mainContainer} data-test-id={dataTestId}>
        <SystemMessage customStyles={customStyles} icon={icon === undefined ? InfoIcon : icon} {...other} />
        <SubMessage>{subMessage}</SubMessage>
        {subIcon && <img src={subIcon} {...subIconProps} />}
    </Container>
);

export default SystemMessageItem;
