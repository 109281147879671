import * as React from 'react';
import {CoreText} from '@anywhere-expert/base-ui';
import styled from 'styled-components';

const Container = styled.div`
    position: absolute;
    bottom: 8px;
`;

const IsTypingBar = () => (
    <Container>
        <CoreText size="s" style={{fontStyle: 'italic'}} textType="tertiary">
            Customer is typing...
        </CoreText>
    </Container>
);

export default IsTypingBar;
