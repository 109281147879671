import {BehaviorSubject} from 'rxjs';
import {useObservable} from 'rxjs-hooks';

const isShowingEscalate = new BehaviorSubject(false);

export const isShowingEscalate$ = isShowingEscalate.asObservable();

export const useIsShowingEscalate = () => !!useObservable(() => isShowingEscalate$);

const setShowEscalate = show => isShowingEscalate.next(show);

export const showEscalate = () => setShowEscalate(true);

export const disableEscalate = () => setShowEscalate(false);
