import {compose, mapProps, setDisplayName} from 'recompose';
import {
    SystemMessageItem,
    withVisibilityIndicator,
    withIconFromUri,
    SystemMessageItemProps,
} from '@anywhere-expert/system-messages';
import {enrichAnalytics, withAnalyticOnView} from 'react-shisell';

const withAnalytics = compose(
    enrichAnalytics((dispatcher, {message}) => {
        return dispatcher.createScoped('SystemMessage').withExtra('Message', message);
    }),
    withAnalyticOnView({
        analyticName: 'View',
    })
);

const enhance = compose<SystemMessageItemProps, {content: SystemMessageItemProps & {icon?: string}}>(
    setDisplayName('SystemMessageForExpert'),
    mapProps(({content}) => (typeof content === 'string' ? {message: content} : content)),
    withVisibilityIndicator,
    withIconFromUri,
    withAnalytics
);

export default enhance(SystemMessageItem);
