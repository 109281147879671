import React from 'react';
import DropToBottomStore from './DropToBottomStore';
import {observer} from 'mobx-react';
import styled, {keyframes, css} from 'styled-components';
import Transition from 'react-transition-group/Transition';
import {ChevronDownIcon} from '@anywhere-expert/icons';
import {CoreButton, CoreIconButton} from '@anywhere-expert/base-ui';
import {palette} from '@anywhere-expert/colors';

const fadeIn = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
`;

const iconStyle: any = {side: 'right'};

const indicationStyle = (state: string) => css`
    align-self: center;
    position: absolute;
    top: -36px;
    transition: opacity 0.2s ease-in-out;
    animation: ${fadeIn} 0.2s ease-in;
    will-change: opacity;
    opacity: ${state === 'entering' || state === 'entered' ? 1 : 0};
`;

const IndicationButtonWithText = styled(CoreButton)<{state: string}>`
    ${({state}) => indicationStyle(state)}
`;

const IndicationButton = styled(CoreIconButton)<{state: string}>`
    ${({state}) => indicationStyle(state)}
`;

type Props = {
    onClick: () => void;
};

const DropToBottomButton = observer(({onClick}: Props) => {
    const {isShowing, numberOfMessages} = DropToBottomStore;

    const onButtonClick = React.useCallback(() => {
        DropToBottomStore.hide();
        onClick();
    }, [onClick]);

    const text = React.useMemo(() => (numberOfMessages > 1 ? `${numberOfMessages} New Messages` : 'New Message'), [
        numberOfMessages,
    ]);

    if (!isShowing) return null;

    return (
        <Transition in={isShowing} mountOnEnter unmountOnExit timeout={100}>
            {state =>
                numberOfMessages > 0 ? (
                    <IndicationButtonWithText
                        state={state}
                        icon={ChevronDownIcon}
                        variant="contained"
                        color="tertiary"
                        text={text}
                        iconProps={iconStyle}
                        onClick={onButtonClick}
                        size="s"
                    />
                ) : (
                    <IndicationButton
                        state={state}
                        icon={ChevronDownIcon}
                        variant="contained"
                        color="default"
                        onClick={onButtonClick}
                        size="s"
                        colorOverride={palette.brand.blue}
                    />
                )
            }
        </Transition>
    );
});

export default DropToBottomButton;
