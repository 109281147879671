import React from 'react';
import {SystemMessageItem, IconType} from '@anywhere-expert/system-messages';
import {VisualComponentType} from '@slash-commands/command-visual-component';
import {FlightIcon} from '@anywhere-expert/icons';
import getFormattedMessage from '../getFormattedMessage';
const AirplaneIcon = require('./ic-airplane.svg');

export {description} from '../shared';

export const icon: IconType = AirplaneIcon;
export const iconOutline = FlightIcon;

export const Component: VisualComponentType = ({commandPayload}) => {
    return <SystemMessageItem message={getFormattedMessage(commandPayload)} icon={icon} />;
};
