import React from 'react';

export const Cancel: React.ComponentType<{color?: string}> = React.memo(({color = 'black', ...rest}) => {
    return (
        <svg width="105" height="65" viewBox="0 0 64 64" fill={color} xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                d="M27.7642 10.4888C31.8697 10.0906 36.2003 10.4604 39.8318 12.5748C44.7147 15.149 48.1494 20.0367 48.9624 25.5028C49.1046 28.1197 49.8892 30.9238 48.8486 33.4293C48.7681 33.6237 48.6021 33.7706 48.3959 33.8204C47.6326 34.0124 46.7295 34.093 46.5494 33.1401C45.0798 30.9072 41.4958 31.7179 39.8246 31.917C35.1337 33.1804 31.6089 38.7413 33.5835 43.4512C34.6952 45.229 37.0442 44.4444 38.2958 43.1928C38.374 43.1146 38.5043 43.1763 38.4972 43.2853C38.4451 44.1149 38.4712 44.9445 38.5423 45.7718C38.547 45.8192 38.5233 45.8643 38.4806 45.888C34.8706 47.9051 30.5541 48.4598 26.5198 47.6894C23.9432 47.2201 21.2836 45.997 19.1029 44.4017C14.1156 40.7561 11.5414 34.8562 11.5129 28.9137C11.7381 23.1845 14.4261 17.5312 19.0649 14.106C21.7932 12.5368 24.614 10.9795 27.7642 10.4888ZM31.0969 13.1034C25.6925 13.1271 20.4279 15.6728 17.0809 19.9182C15.7417 21.9306 14.4309 24.0568 14.4072 26.5552C16.1683 26.8918 17.9011 25.9934 19.3826 25.5217C20.5796 25.0998 21.44 24.5546 22.5091 24.1493C26.7995 22.2127 31.891 21.6722 36.2715 23.6728C39.3553 24.768 42.214 26.8325 45.6344 26.624C46.1322 26.3656 46.08 25.8228 45.9663 25.3605C45.926 22.1677 43.6789 19.6124 41.7138 17.3108C38.6774 14.9949 35.0507 12.8853 31.0969 13.1034ZM29.6913 25.6805C28.646 25.8702 27.6741 26.4651 27.0626 27.3114C25.7162 29.466 27.475 32.8651 30.1014 32.7205C32.5358 32.9102 35.0412 30.0894 33.8608 27.7428C33.2279 26.0574 31.3126 25.5834 29.6913 25.6805ZM16.8676 31.3552C16.1518 31.5045 15.4288 31.7179 14.8054 32.109C14.3384 33.1899 15.078 34.2921 15.4098 35.309C16.5405 37.6462 17.939 40.0189 20.1885 41.4435C21.4543 42.1499 22.3787 43.2995 23.8365 43.7499C24.7609 44.0343 26.0315 44.2334 26.4392 43.0696C27.2072 40.8699 26.9915 38.8148 26.4249 36.5179C25.062 32.7537 20.6839 30.8266 16.8676 31.3552Z"
                fill={color}
            />
            <path
                d="M46.7912 34.7377C51.0128 33.8583 55.6635 36.1813 57.1758 40.282C59.1716 43.949 57.9319 49.0548 54.4759 51.4038C50.9156 54.0799 45.5396 53.8642 42.1547 50.9937C38.8718 48.0497 38.4143 42.8302 40.5761 39.1087C41.9864 36.8805 44.3521 35.5555 46.7912 34.7377ZM47.2392 36.3567C43.6528 36.7004 41.0976 40.237 40.7515 43.6361C40.5524 48.2962 45.094 53.0156 49.8727 52.0485C55.2131 51.4441 58.2733 44.6625 55.6493 40.1161C53.9213 37.3404 50.4842 35.6669 47.2392 36.3567Z"
                fill={color}
            />
            <path
                d="M45.3238 40.1469C46.8504 40.3175 47.6136 42.3632 48.768 43.0719C50.195 42.2352 50.9061 40.3531 52.5559 39.943C52.8521 39.8695 53.1627 40.0615 53.2148 40.3602C53.2717 40.6849 53.139 41.0618 52.9304 41.3131C51.9159 42.3466 50.7567 43.2355 49.7967 44.3211C50.3396 45.2266 51.0815 45.978 51.8519 46.6843C51.8827 46.7128 51.9087 46.7412 51.9324 46.7744C52.1055 47.0233 52.3022 47.4026 52.352 47.7463C52.4184 48.1943 51.9538 48.5403 51.5461 48.3507C50.2519 47.7486 49.5147 46.4141 48.4741 45.4826C47.1751 46.0752 46.675 47.6372 45.3262 48.1706C44.9233 48.4763 44.3828 47.8814 44.4255 47.4832C45.0157 45.9993 46.6678 45.447 47.6207 44.2618C46.8267 42.996 44.8569 42.1641 44.8593 40.5641C44.8593 40.3175 45.075 40.1184 45.3238 40.1469Z"
                fill={color}
            />
        </svg>
    );
});
