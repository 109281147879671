import React, {useCallback} from 'react';
import styled from 'styled-components';
import {CoreText, CoreButton} from '@anywhere-expert/base-ui';
import {ThumbUpIcon, ThumbDownIcon} from '@anywhere-expert/icons';
import {useTweekValue} from 'react-tweek';
import {enrichAnalytics, withAnalyticOnView, useAnalytics} from 'react-shisell';
import {compose} from 'recompose';

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 90%;
`;

const QuestionTitle = styled(CoreText)`
    text-align: center;
    margin-bottom: 16px;
`;

const useTitleText = expertName => {
    const titleText = useTweekValue<string>(
        'support/session/during/similar_sessions/same_tab/feedback_module/title',
        `Was <expertName>'s session helpful?`
    );

    return titleText.replace('<expertName>', expertName);
};

type Props = {
    expertName: string;
    setFeedback: (value) => void;
};

const Question = ({expertName, setFeedback}: Props) => {
    const analytics = useAnalytics();

    const setPositiveFeedback = useCallback(() => {
        setFeedback(true);
        analytics.dispatcher.withExtra('FeedbackValue', true).dispatch('Click');
    }, [analytics, setFeedback]);

    const setNegativeFeedback = useCallback(() => {
        setFeedback(false);
        analytics.dispatcher.withExtra('FeedbackValue', false).dispatch('Click');
    }, [analytics, setFeedback]);

    const titleText = useTitleText(expertName);

    return (
        <>
            <QuestionTitle size="l" textType="primary" weight="bold">
                {titleText}
            </QuestionTitle>
            <ButtonsContainer>
                <CoreButton
                    variant="outlined"
                    icon={ThumbUpIcon}
                    text="Yes"
                    onClick={setPositiveFeedback}
                    style={{marginRight: '10px'}}
                />
                <CoreButton variant="outlined" icon={ThumbDownIcon} text="No" onClick={setNegativeFeedback} />
            </ButtonsContainer>
        </>
    );
};

const enhance = compose<Props, Props>(
    enrichAnalytics(dispatcher => dispatcher.createScoped('Value')),
    withAnalyticOnView({
        analyticName: 'View',
    })
);

export default enhance(Question);
