import React, {useMemo} from 'react';
import {CoreText} from '@anywhere-expert/base-ui';
import styled from 'styled-components';
import moment from 'moment';

const Line = styled.div`
    height: 1px;
    flex: 1;
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    padding-top: 42px;
    flex-shrink: 0;
`;

type Props = {
    timestamp: number;
};

const getTimestampText = (timestamp: number) => {
    const time = moment(timestamp).hour(0).minute(0).second(0).millisecond(0);
    const today = moment().hour(0).minute(0).second(0).millisecond(0);

    const daysAgo = today.diff(time, 'days');

    if (daysAgo == 0) {
        return 'Today';
    }
    if (daysAgo == 1) {
        return 'Yesterday';
    }

    return time.format('MMMM DD');
};

export const Timestamp = ({timestamp}: Props) => {
    const text = useMemo(() => getTimestampText(timestamp), [timestamp]);
    const textStyle = useMemo(() => ({margin: '0 .75em'}), [text]);

    return (
        <Container>
            <Line />
            <CoreText size="xs" textType="secondary" style={textStyle}>
                {text}
            </CoreText>
            <Line />
        </Container>
    );
};

export default Timestamp;
