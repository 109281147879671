import React, {Ref} from 'react';
import {compose} from 'recompose';
import {enrichAnalytics, withAnalyticOnView, withAnalytics} from 'react-shisell';
import {withTweekKeys} from '@anywhere-expert/tweek';
import {withSpreadProp, useDetectOutsideClick} from '@anywhere-expert/utils';
import styled from 'styled-components';
import {CoreText, CoreIconButton, CoreIcon, CoreList, CoreActionMenuItem} from '@anywhere-expert/base-ui';
import ResolveButton from './Buttons';
import {TruckIcon, CloseIcon} from '@anywhere-expert/icons';
import {getBoxShadowStyle, getBorderStyle} from '@anywhere-expert/base-ui';

export type ResolveMenuProps = {
    sessionId: string;
    closeResolveMenu: () => void;
};

const CloseIconBtn = styled(CoreIconButton)`
    position: absolute;
    margin: -23px 12px 0px 250px;
    z-index: 1;
    &:hover {
        color: inherit;
        background-color: inherit;
    }
`;

const Container = styled(CoreActionMenuItem)`
    right: -80px;
    margin-top: 0px;
    padding: 24px 8px 24px 8px;
    background-color: ${({theme}) => theme.colors.background.secondary};
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    height: max-content;
    z-index: 3;
    ${({theme}) => getBoxShadowStyle(theme)};
    ${({theme}) => getBorderStyle(theme)};
`;

const SubHeaderText = styled(CoreText)`
    text-align: left;
    margin-left: 40px;
`;

const ButtonsContainer = styled(CoreList)`
    margin-top: -40px;
    display: flex;
    flex-direction: column;
    min-width: 235px;
    border: none;
` as typeof CoreList;

const listFixedStyle = {overflow: 'hidden'};

const TitleDiv = styled.div`
    padding: 1.5rem 0;
    margin-top: 15px;
    margin-bottom: 10px;
    width: inherit;
    display: inline-flex;
    position: relative;
    flex: 1;
`;

const StyledCoreText = styled(CoreText)`
    margin-top: -8px;
    margin-left: -150px;
    line-height: 10px;
    padding-bottom: 4px;
    margin-bottom: -25px;
`;

const ShadowedContainer = styled.div`
    width: 220px;
    height: 90px;
    position: relative;
    display: flex;
    flex: 1;
    align-items: left;
    flex-direction: column;
    justify-content: space-evenly;
    border-radius: 8px;
    margin: 4px 16px 16px 27px;
    background-color: ${({theme}) => theme.colors.background.primary};
`;

const ResolveActionItem = styled(ResolveButton)`
    margin-top: 12px;
    border-radius: 40px;
    font-weight: 500;
`;

const StyleTruckIcon = styled(CoreIcon)`
    position: absolute;
    left: 12px;
    top: 45px;
    z-index: 1;
`;

type Props = ResolveMenuProps & {
    containerRef: Ref<HTMLDivElement>;
    headerText: string | null;
    subheaderText: string | null;
    solvedMessageText: string | null;
    notRealIssueMessageText: string | null;
    customerDidntReplyMessageText: string | null;
};

const ResolveMenu = ({
    sessionId,
    headerText,
    subheaderText,
    solvedMessageText,
    notRealIssueMessageText,
    customerDidntReplyMessageText,
    closeResolveMenu,
}: Props) => {
    const containerRef = useDetectOutsideClick(closeResolveMenu);

    return (
        <Container ref={containerRef} data-test-id="ResolveMenu">
            <CloseIconBtn icon={CloseIcon} onClick={closeResolveMenu} variant="icon" />
            <StyledCoreText textType="primary" size="m" weight="medium">
                Resolve Session
            </StyledCoreText>
            <TitleDiv>
                <StyleTruckIcon icon={TruckIcon} />
                <ShadowedContainer>
                    <SubHeaderText textType="primary" size="m" weight="bold">
                        {headerText || 'Whoa, that was fast!'}
                    </SubHeaderText>
                    <SubHeaderText textType="primary" size="m" weight="normal">
                        {subheaderText || 'Would the customer feel their issue is resolved?'}
                    </SubHeaderText>
                </ShadowedContainer>
            </TitleDiv>
            <ButtonsContainer listStyle={listFixedStyle} hasOutline={false}>
                <ResolveActionItem
                    closeResolveMenu={closeResolveMenu}
                    text={solvedMessageText || 'Yep! I resolved it'}
                    sessionId={sessionId}
                    actionId="solved"
                    data-test-id="ResolveMenu_Solved"
                    variant="contained"
                    color="tertiary"
                />
                <ResolveActionItem
                    text={notRealIssueMessageText || "It wasn't a real issue"}
                    sessionId={sessionId}
                    actionId="notRealIssue"
                    data-test-id="ResolveMenu_NotRealIssue"
                    variant="text"
                    color="secondary"
                />
                <ResolveActionItem
                    text={customerDidntReplyMessageText || "Customer didn't reply"}
                    sessionId={sessionId}
                    actionId="customerDidntReply"
                    data-test-id="ResolveMenu_CustomerDidntReply"
                    variant="text"
                    color="secondary"
                />
            </ButtonsContainer>
        </Container>
    );
};

const enhance = compose<Props, ResolveMenuProps>(
    withTweekKeys({
        headerText: 'support/session/during/resolve/quick_resolve/header_text',
        subheaderText: 'support/session/during/resolve/quick_resolve/subheader_text',
        followupQuestions: 'support/session/during/resolve/quick_resolve/followup_questions/_',
    }),
    withSpreadProp('followupQuestions'),
    enrichAnalytics(
        (
            dispatcher,
            {headerText, subheaderText, solvedMessageText, notRealIssueMessageText, returnToSessionMessageText}
        ) =>
            dispatcher.createScoped('ResolutionConfirmation').withExtras({
                Reason: 'QuickResolve',
                HeaderText: headerText,
                SubHeaderText: subheaderText,
                ButtonTextSolved: solvedMessageText,
                ButtonTextNotRealIssue: notRealIssueMessageText,
                ButtonTextReturnToSession: returnToSessionMessageText,
            })
    ),
    withAnalyticOnView({analyticName: 'View'}),
    withAnalytics
);

export default enhance(ResolveMenu);
