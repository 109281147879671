import React, {useEffect, useCallback, useState} from 'react';
import {Tag} from '@expert-feed/conversation-tags';
import {useUserProfile} from '@anywhere-expert/auth';
import {useTweekValue} from 'react-tweek';
import {useAnalytics} from 'react-shisell';
import styled from 'styled-components';
import Tags from './Tags';
import AddTagsMenu from './AddTagsMenu';
import TagsStore from './TagsStore';
import {observer} from 'mobx-react';
import {SupportItem} from '@anywhere-expert/expert-feed-store';

type Props = {
    selectedSession: SupportItem;
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    min-width: 0;
    width: 100%;
`;

const TagsContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    min-width: 0;
    flex: 1;
`;

const Fader = styled.div`
    position: absolute;
    right: 0;
    background: ${({theme}) =>
        `linear-gradient(269.27deg, ${theme.colors.neutral.white} 24.55%, rgba(255, 255, 255, 0) 100%)`};
    width: 45px;
    height: 100%;
    pointer-events: none;
`;

const HeaderTags = observer(({selectedSession}: Props) => {
    const {sessionId, isAssignedToMe} = selectedSession;
    const isCustomTagsEnabledByTweek = useTweekValue('support/expert/custom_tags/is_enabled', false);
    const {uid: expertId} = useUserProfile();
    const {dispatcher} = useAnalytics();
    const [tagStore, setTagStore] = useState(() => new TagsStore(selectedSession, expertId));
    useEffect(() => {
        if (tagStore.sessionId != sessionId) setTagStore(new TagsStore(selectedSession, expertId));
    }, [sessionId]);

    const isCustomTagsEnabled = isCustomTagsEnabledByTweek && isAssignedToMe;

    const deleteTag = useCallback(
        ({tagKey}: Tag) => {
            tagStore.remove(tagKey);

            dispatcher.withExtra('ActionId', 'RemoveTag').dispatch('Click');
        },
        [dispatcher, tagStore]
    );

    return (
        <Container>
            {isCustomTagsEnabled && <AddTagsMenu suggestedTags={tagStore.suggestedTags} addTag={tagStore.add} />}
            <TagsContainer>
                <Tags
                    tags={tagStore.tags}
                    sessionId={sessionId}
                    wrap={false}
                    onIconClick={deleteTag}
                    isEditable={isCustomTagsEnabled}
                    tooltipPosition="bottom-end"
                />
                <Fader />
            </TagsContainer>
        </Container>
    );
});

export default HeaderTags;
