import * as React from 'react';
import {TimelineItem} from '@anywhere-expert/timeline-api';
import {RendererExtraProps} from '@anywhere-expert/timeline-behaviours/timelineItemsRenderer';
import {CustomerMessage, TechnicianMessage} from '../TextHelpers';
import EmailChannelIndication from './EmailIndication';

const CONVERSATION_SUBTYPE = 'conversation';
type Props = TimelineItem<any> & RendererExtraProps;

const EmailMessage = ({content: {subType, subject, body}, isSelf, ...otherProps}: Props) => {
    if (subType != CONVERSATION_SUBTYPE) return null;
    const messageText = body && body.text && body.text.length > 0 ? body.text : subject;
    if (!messageText || messageText.length === 0) return null;

    return isSelf ? (
        <TechnicianMessage extraInfoComponent={EmailChannelIndication} content={messageText} {...otherProps} />
    ) : (
        <CustomerMessage extraInfoComponent={EmailChannelIndication} content={messageText} {...otherProps} />
    );
};

export default EmailMessage;
