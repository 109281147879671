import {CoreTabsProps} from '@anywhere-expert/base-ui';

export enum DeviceToolsTabs {
    appList = 'AppsList',
    commands = 'Commands',
}

export const tabs: CoreTabsProps = {
    [DeviceToolsTabs.commands]: {label: 'Tools'},
    [DeviceToolsTabs.appList]: {label: 'Apps'},
};
