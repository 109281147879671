const itemTypes = {
    TextMessage: 'TextMessage',
    Timestamp: 'Timestamp',
    Escalate: 'AddAnExpert',
    ContentCard: 'ContentCard',
    ImageItem: 'ImageItem',
    Attachment: 'Attachment',
    VideoItem: 'VideoItem',
    Command: 'Command',
    CommandResult: 'CommandResult',
    AppLink: 'AppLink',
    AmazonPrePurchase: 'AmazonPrePurchase',
    DynamicComponent: 'DynamicComponent',
    HomeVisitDetails: 'HomeVisitDetails',
    AutoAssignAssignmentIndicator: 'AutoAssignAssignmentIndicator',
    SystemMessageForExpert: 'SystemMessageForExpert',
    SystemMessage: 'SystemMessage',
    AddAnotherDeviceSuggestion: 'AddAnotherDeviceSuggestion',
    HelixRecommendation: 'HelixRecommendation',
    ScrollToMessageItem: 'ScrollToMessageItem',
    CallAnnouncement: 'CallAnnouncement',
    Email: 'Email',
    Activity: 'Activity',
    CobrowsingApprovalItem: 'CobrowsingApprovalItem',
    AccessoriesCarouselItem: 'AccessoriesCarouselItem',
    BreakdownsCarouselItem: 'BreakdownsCarouselItem',
    FollowUp: 'FollowUp',
    Article: 'Article',
    ScreenshareExpertTimelineItem: 'ScreenshareExpertTimelineItem',
};

export default itemTypes;
