import React, {useCallback, useEffect} from 'react';
import {CoreButton} from '@anywhere-expert/base-ui';

interface Props {
    sessionId: string;
    timelineId: string;
    acceptInProgress: boolean;
    acceptButtonText: string;
    acceptConversation: () => void;
}

const AcceptConversationButton = ({
    acceptInProgress,
    acceptButtonText,
    acceptConversation,
    sessionId,
    timelineId,
}: Props) => {
    const ref = React.useRef<HTMLButtonElement>(null);

    useEffect(() => {
        ref.current?.focus();
    }, [ref, sessionId, timelineId]);

    const onKeyUp = useCallback(
        (event: React.KeyboardEvent<any>) => {
            if (event.key !== 'Enter') {
                return;
            }

            acceptConversation();
        },
        [acceptConversation]
    );

    return (
        <CoreButton
            color="primary"
            isInProgress={acceptInProgress}
            text={acceptButtonText}
            ref={ref}
            onKeyUp={onKeyUp}
            onMouseUp={acceptConversation}
            data-test-id="AcceptConversationAcceptButton"
        />
    );
};

export default AcceptConversationButton;
