import React from 'react';
import {setAppMessage} from '@anywhere-expert/app-messages';
import {CoreIconButton, CoreText, TextType} from '@anywhere-expert/base-ui';
import {CopyIcon} from '@anywhere-expert/icons';
import MissingDataTooltip from './MissingDataTooltip';

type Props = {
    postalCode?: string;
    customerName?: string;
};

const PostalCode = ({postalCode, customerName}: Props) => {
    const onCopyClick = React.useCallback(async () => {
        if (postalCode) {
            await window.navigator?.clipboard.writeText(postalCode);
            setAppMessage({text: 'Copied to clipboard'});
        }
    }, [postalCode]);

    const textType: TextType = postalCode ? 'primary' : 'tertiary';
    return (
        <CoreText textType={textType} size={'m'} disableTextOverflow={true} data-pii={false} family="apercu">
            Postal code{' '}
            {!!postalCode ? (
                <>
                    <strong>{postalCode}</strong>
                    <CoreIconButton
                        icon={CopyIcon}
                        onClick={onCopyClick}
                        color="tertiary"
                        size="xxs"
                        variant={'icon'}
                    />
                </>
            ) : (
                <>
                    <strong>Missing</strong> <MissingDataTooltip customerName={customerName} />
                </>
            )}
        </CoreText>
    );
};

export default React.memo(PostalCode);
