import * as React from 'react';
import {StyleSheet, css} from 'aphrodite';
import withSignalIcon from './withSignalIcon';
import {SpeedTestContent} from '../types';
import {useTheme} from '@anywhere-expert/colors';

const styles = StyleSheet.create({
    testHeaderContainer: {
        textAlign: 'center',
    },
    testHeaderText: {
        fontSize: '14px',
        lineHeight: '20px',
    },
    networkDescriptionText: {
        fontSize: '14px',
        lineHeight: '20px',
    },
    signalIcon: {
        justifyContent: 'center',
        width: '20px',
        height: '15px',
        marginRight: '7px',
    },
    networkNameText: {
        fontWeight: 'bold',
    },
});

const Header = ({
    isConnectedToWifi,
    networkName,
    networkType,
    signalIcon,
    speedTestResult,
    hasTimedOut,
}: SpeedTestContent & {signalIcon: string; hasTimedOut: boolean}) => {
    const {
        colors: {neutral},
    } = useTheme();
    const headerText = speedTestResult || hasTimedOut ? 'Test results for ' : 'Running test on ';
    const networkDescription = !networkType ? '' : ` - ${networkType}${isConnectedToWifi ? 'GHz' : ''}`;
    return (
        <div className={css(styles.testHeaderContainer)}>
            <div className={css(styles.testHeaderText)} style={{color: neutral.grey.deepest}}>
                {headerText}
            </div>
            <div className={css(styles.networkDescriptionText)} style={{color: neutral.grey.deepest}}>
                <img src={signalIcon} className={css(styles.signalIcon)} alt="" />
                <span className={css(styles.networkNameText)}>{networkName}</span>
                {networkDescription}
            </div>
        </div>
    );
};

export default withSignalIcon(Header);
