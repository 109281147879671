import {useState, createContext, useContext} from 'react';
import TasksStore from './TasksStore';

let instance: TasksStore | undefined;
export function useInitTasksStore() {
    const [store] = useState(() => {
        if (instance) return instance;
        instance = new TasksStore();
        return instance;
    });

    return store;
}

export const TasksStoreContext = createContext<TasksStore | null>(null);

export function useTasksStore() {
    return useContext(TasksStoreContext);
}
