import * as React from 'react';
import {useMemo} from 'react';
import {format as timeFormat} from 'date-fns';
import styled from 'styled-components';
import {CoreText} from '@anywhere-expert/base-ui';
import {useTheme} from '@anywhere-expert/colors';
import {PhoneIcon} from '@anywhere-expert/icons';

const Container = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    line-height: 14px;
    padding-top: 42px;
`;

const Icon = styled.img`
    height: 12px;
    width: 12px;
`;

const formatCallTime = (time: number) => {
    return timeFormat(time, 'h:mm aaa');
};

const CallAnnouncement: React.FC<any> = props => {
    const {time, icon, text} = props.content;
    const theme = useTheme();
    const callTime = useMemo(() => (time ? formatCallTime(time) : ''), [time]);

    return (
        <Container>
            {icon ? <Icon src={icon} /> : <PhoneIcon size="xs" color={theme.colors.neutral.grey.deeper} />}
            <CoreText size="xs" textType="tertiary" style={{margin: '0 .4em'}}>
                {text} {callTime}
            </CoreText>
        </Container>
    );
};

export default CallAnnouncement;
