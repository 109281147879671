import React from 'react';
import {SystemMessageItem, IconType} from '@anywhere-expert/system-messages';
import {VisualComponentType} from '@slash-commands/command-visual-component';
import {FolderIcon} from '@anywhere-expert/icons';
import getFormattedMessage from '../getFormattedMessage';
const StorageIcon = require('./ic-storage.svg');

export {description} from '../shared';

export const icon: IconType = StorageIcon;
export const iconOutline = FolderIcon;

export const Component: VisualComponentType = ({commandPayload, contentId}) => {
    return (
        <SystemMessageItem
            dataTestId={`StorageCommandResult_${contentId}`}
            message={getFormattedMessage(commandPayload)}
            icon={icon}
        />
    );
};
