import React, {useEffect} from 'react';
import {useTweekValue} from 'react-tweek';
import {useAnalytics} from 'react-shisell';
import {CoreIcon} from '@anywhere-expert/base-ui';
import {ResolveIcon} from '@anywhere-expert/icons';
import ConversationErrorBar from './ConversationErrorBar';
import ConversationOverlayBarContainer from './ConversationOverlayBarContainer';
import styled from 'styled-components';

const IconWrapper = styled(CoreIcon)`
    margin-right: 5px;
`;

const icon = <IconWrapper icon={ResolveIcon} />;

export default () => {
    const analytics = useAnalytics();

    useEffect(() => {
        analytics.dispatcher.createScoped('ConversationClosedBar').dispatch('View');
    }, [analytics]);

    const text = useTweekValue<string>('support/session/messaging/closed_conversation_bar/text', '');

    return (
        <ConversationOverlayBarContainer>
            <ConversationErrorBar text={text} icon={icon} />
        </ConversationOverlayBarContainer>
    );
};
