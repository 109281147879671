import React, {useState, useEffect, useRef, useMemo, useCallback} from 'react';
import styled from 'styled-components';
import {CoreText, CoreButton, CoreIcon} from '@anywhere-expert/base-ui';
import {WheelIcon} from '@anywhere-expert/icons';
import {isAutopilotAttributeFields, QueueItemAutopilotModel, SupportItem} from '@anywhere-expert/expert-feed-store';
import {observer} from 'mobx-react';
import {setAppMessage} from '@anywhere-expert/app-messages';
import logger from '@anywhere-expert/logging';
import {useAnalytics} from 'react-shisell';
import {authorizedFetch} from '@anywhere-expert/auth';
import {useTheme} from '@anywhere-expert/colors';
import {openAutopilotModal} from './AutopilotModalState';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
`;

const SpacedCoreText = styled(CoreText)`
    margin-right: 16px;
    flex: 1;
`;

export const LearnMoreLink = styled(CoreText)`
    color: ${({theme}) => theme.colors.brand.blue};
    cursor: pointer;
`;

const StyledWheelIcon = styled(CoreIcon)`
    margin: 0px 11px 0px 4px;
`;

type Props = {
    sessionId: string;
    selectedSession: SupportItem;
};

// https://github.com/github/fetch/issues/175#issuecomment-125779262
function withTimeout<T>(promise: Promise<T>, timeOutInMs: number): Promise<T> {
    return new Promise(function(resolve, reject) {
        setTimeout(function() {
            reject(new Error(`Promise timed out after ${timeOutInMs} MS`));
        }, timeOutInMs);

        promise.then(resolve, reject);
    });
}

async function onCancelButtonClick(autopilotState: QueueItemAutopilotModel, sessionId: string) {
    const setTechnicalIssueMessage = () => {
        setAppMessage({
            text: 'Sorry, we had a technical problem canceling Autopilot. Please try again.',
            type: 'warning',
        });
    };

    autopilotState.setIsCanceling(true);

    try {
        const response = await withTimeout(
            authorizedFetch(`https://autopilot-flow-manager.mysoluto.com/api/v1/session/${sessionId}`, {
                method: 'DELETE',
            }),
            7000
        );

        if (response.status !== 200) {
            logger.error('Failed to cancel autopilot. Bad status code from flow manager.', {
                extra: {sessionId, status: response.status, message: await response.text()},
            });

            setTechnicalIssueMessage();
        } else {
            autopilotState.hadAutopilotAttributes = false;
        }

        autopilotState.setIsCanceling(false);
    } catch (e) {
        logger.error('Failed to cancel autopilot.', {
            err: e,
            extra: {sessionId},
        });

        setTechnicalIssueMessage();
        autopilotState.setIsCanceling(false);
    }
}

const useRemainingTime = (futureOccuranceTime: number | undefined, frequencyInMs: number) => {
    const [remainingTime, setRemainingTime] = useState(
        futureOccuranceTime ? Math.max(futureOccuranceTime - Date.now(), 0) : 0
    );
    let counterIntervalIdRef = useRef<null | number>(null);

    const clearIntervalIfNeeded = () => {
        if (counterIntervalIdRef.current !== null) {
            clearInterval(counterIntervalIdRef.current);
            counterIntervalIdRef.current = null;
        }
    };

    useEffect(() => {
        clearIntervalIfNeeded();

        if (futureOccuranceTime === undefined || futureOccuranceTime - Date.now() <= 0) {
            setRemainingTime(0);
            return;
        }

        counterIntervalIdRef.current = setInterval(() => {
            const currRemainingTime = futureOccuranceTime - Date.now();

            if (currRemainingTime >= 0) {
                setRemainingTime(currRemainingTime);
            } else {
                setRemainingTime(0);
                clearIntervalIfNeeded();
            }
        }, frequencyInMs);

        return clearIntervalIfNeeded;
    }, [futureOccuranceTime, frequencyInMs]);

    return remainingTime;
};

export const AutopilotFooter = observer(({sessionId, selectedSession}: Props) => {
    const analytics = useAnalytics();
    const theme = useTheme();

    const dispatcher = useMemo(() => analytics.dispatcher.createScoped('AutoPilotConversationBar'), [
        analytics.dispatcher,
    ]);

    useEffect(() => {
        dispatcher.dispatch('View');
    }, [sessionId]);

    const autopilotState = selectedSession.autopilot;

    const cancelButtonCallback = useCallback(() => {
        onCancelButtonClick(autopilotState, sessionId);
        dispatcher.withExtra('ActionID', 'CancelAutoPilot').dispatch('Click');
    }, [sessionId]);

    const startTime = autopilotState.attributes?.hasMessageSendingStarted
        ? autopilotState.attributes?.timeToResolve
        : autopilotState.attributes?.timeToStartSendingMessages;

    const remainingTime = useRemainingTime(startTime, 60000);
    const timeRemainingInMinutes = Math.round(remainingTime / 60000);

    if (!isAutopilotAttributeFields(autopilotState.attributes)) {
        return null;
    }

    const message = autopilotState?.attributes?.hasMessageSendingStarted
        ? `Autopilot is taking care of this unresponsive customer and will resolve this session in ${timeRemainingInMinutes} mins. `
        : `Unresponsive customer? Autopilot will take it from here and check in with them in ${timeRemainingInMinutes} mins. `;

    return (
        <Container data-test-id="AutopilotFooter">
            <StyledWheelIcon icon={WheelIcon} size="m" color={theme.colors.neutral.black} />
            <SpacedCoreText size="m">
                {message}
                <LearnMoreLink
                    onClick={() => {
                        openAutopilotModal();
                        dispatcher.withExtra('ActionID', 'LearnMore').dispatch('Click');
                    }}
                    size="m"
                    data-test-id="LearnMoreLink"
                    color={theme.colors.brand.blue}
                >
                    Learn more
                </LearnMoreLink>
            </SpacedCoreText>
            <CoreButton
                variant="outlined"
                color="primary"
                onClick={cancelButtonCallback}
                text="Cancel Autopilot"
                isInProgress={autopilotState?.isCanceling}
            />
        </Container>
    );
});
