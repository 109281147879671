import React, {useCallback, useMemo, useEffect, useState} from 'react';
import styled from 'styled-components';
import {CoreText, CoreButton} from '@anywhere-expert/base-ui';
import ConversationOverlayBarContainer from './conversation-unavailable-bar/ConversationOverlayBarContainer';
import logger from '@anywhere-expert/logging';
import {getFeedActionsClient} from '@anywhere-expert/expert-feed-actions';
import {setAppMessage} from '@anywhere-expert/app-messages';
import {useAnalytics} from 'react-shisell';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
`;

const SpacedCoreText = styled(CoreText)`
    margin-right: 16px;
`;

type Props = {
    sessionId: string;
};
export default ({sessionId}: Props) => {
    const [isAssigning, setIsAssigning] = useState(false);
    const analytics = useAnalytics();
    const dispatcher = useMemo(() => analytics.dispatcher.createScoped('FollowUpConversationBar'), [
        analytics.dispatcher,
    ]);

    useEffect(() => {
        dispatcher.dispatch('View');
    }, [sessionId]);

    const onClickCallback = useCallback(async () => {
        dispatcher.dispatch('Click');
        if (isAssigning) return;
        setIsAssigning(true);

        try {
            await getFeedActionsClient().assignFeedItem({feedItemId: sessionId});
        } catch (err) {
            logger.error('Failed to assign follow-up session', {err, extra: {sessionId}});
            setAppMessage({text: 'Failed to assign conversation', type: 'warning'});
        }

        setIsAssigning(false);
    }, [sessionId, isAssigning]);

    return (
        <ConversationOverlayBarContainer>
            <Container>
                <SpacedCoreText size="m">Go over the message history above and check in</SpacedCoreText>
                <CoreButton
                    isInProgress={isAssigning}
                    onClick={onClickCallback}
                    variant="contained"
                    text="Reach Out"
                    color="primary"
                />
            </Container>
        </ConversationOverlayBarContainer>
    );
};
