import React, {useEffect, useMemo} from 'react';
import {TimelineItem} from '@anywhere-expert/timeline-api';
import TechListItemAction from '@soluto-private/techlist-item-action-web-component';
import styled from 'styled-components';
import {useTheme} from '@anywhere-expert/colors';
import {useAnalytics} from 'react-shisell';

type TechListItemActionProps = TimelineItem<any>;

const TechListItemActionItem = (props: TechListItemActionProps) => {
    const analytics = useAnalytics();
    const {
        content: {title, description, author, members, tag},
        timestamp,
    } = props;
    const theme = useTheme();

    const dispatcher = useMemo(() => analytics.dispatcher.createScoped('TechListItem'), [analytics.dispatcher]);

    useEffect(() => {
        dispatcher
            .withExtras({
                Title: title,
                Description: description,
                Author: author,
                NumOfRelatedFamilyMembers: members?.length || 0,
                RelatedFamilyMembers: members,
            })
            .dispatch('View');
    }, []);

    const isDarkMode = theme.themeName === 'dark';

    return (
        <Container>
            <TechListItemAction
                title={title}
                description={description}
                author={author}
                isDarkMode={isDarkMode}
                members={members}
                tag={tag}
                addBorder={false}
                timestamp={timestamp}
            />
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    margin: 12px;
`;

export default TechListItemActionItem;
