import React, {useCallback} from 'react';
import styled from 'styled-components';
import {CoreText, CoreButton} from '@anywhere-expert/base-ui';
import {ArrowLeftIcon} from '@anywhere-expert/icons';
import {useTweekValue} from 'react-tweek';
import {compose} from 'recompose';
import {enrichAnalytics, withAnalyticOnView, useAnalytics} from 'react-shisell';

const Title = styled(CoreText)`
    text-align: center;
    margin-top: 8px;
`;

const SubTitle = styled(CoreText)`
    text-align: center;
    margin-top: 8px;
    margin-bottom: 12px;
`;

const BackButton = styled(CoreButton)`
    text-transform: none;
    align-self: start;
`;

const ReasonsContainer = styled.div`
    margin-top: 5px;
    > button {
        margin: 4px;
    }
`;

type ReasonsConfig = {
    title: string;
    subTitle: string;
    reason1: string;
    reason2: string;
    reason3: string;
    reason4: string;
};

const defaultConfig = {
    title: `Why wasn't it helpful?`,
    subTitle: 'Your answer will help us improve these suggestions',
    reason1: `It's not relevant to my current session`,
    reason2: `It doesn't have any useful information`,
    reason3: `The expert didn't follow the right steps`,
    reason4: `Other reasons`,
};

type Props = {
    setReason: (value) => void;
    clearSelectedFeedback: () => void;
};

const Reasons = ({setReason, clearSelectedFeedback}: Props) => {
    const reasonsConfig = useTweekValue<ReasonsConfig>(
        'support/session/during/similar_sessions/same_tab/feedback_module/reasons/_',
        defaultConfig
    );

    const analytics = useAnalytics();
    const setFeedbackReasonCallback = useCallback(
        reason => {
            setReason(reason);
            const reasonText = reasonsConfig[`reason${reason}`];
            analytics.dispatcher.withExtra('FeedbackReason', reasonText).dispatch('Click');
        },
        [analytics, setReason, reasonsConfig]
    );

    const onBackClick = useCallback(clearSelectedFeedback, [clearSelectedFeedback]);

    return (
        <>
            <BackButton variant="text" text="Back" icon={ArrowLeftIcon} onClick={onBackClick} />
            <Title size="xl" textType="primary" weight="bold">
                {reasonsConfig.title}
            </Title>
            <SubTitle size="l" textType="primary">
                {reasonsConfig.subTitle}
            </SubTitle>
            <ReasonsContainer>
                <CoreButton variant="text" text={reasonsConfig.reason1} onClick={() => setFeedbackReasonCallback(1)} />
                <CoreButton variant="text" text={reasonsConfig.reason2} onClick={() => setFeedbackReasonCallback(2)} />
                <CoreButton variant="text" text={reasonsConfig.reason3} onClick={() => setFeedbackReasonCallback(3)} />
                <CoreButton variant="text" text={reasonsConfig.reason4} onClick={() => setFeedbackReasonCallback(4)} />
            </ReasonsContainer>
        </>
    );
};

const enhance = compose<Props, Props>(
    enrichAnalytics(dispatcher => dispatcher.createScoped('Reasons')),
    withAnalyticOnView({
        analyticName: 'View',
    })
);

export default enhance(Reasons);
