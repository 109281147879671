import React from 'react';
import UploadMediaItem from '../MediaHelpers/UploadMediaItem';
import ImageItem from './components/ImageItem';

export default props => {
    if (props.state !== 'Uploading') {
        return <ImageItem {...props} />;
    }

    if (props.senderType === 'Technician') {
        return <UploadMediaItem {...props} />;
    }

    return null;
};
