import React from 'react';
import styled from 'styled-components';
import {CoreText} from '@anywhere-expert/base-ui';

const warningIcon = require('./unavailable_warning.svg');

const ConversationUnavailableBarContainer = styled.div`
    display: flex;
    align-items: center;
`;

export type ConversationErrorBarProps = {
    text: string;
    icon?: React.ReactElement<any>;
};

const ConversationErrorBar = ({text, icon}: ConversationErrorBarProps) => (
    <ConversationUnavailableBarContainer>
        {icon || <img style={{marginBottom: '6px'}} src={warningIcon} />}
        <CoreText size="m" textType="primary" weight="medium">
            {text}
        </CoreText>
    </ConversationUnavailableBarContainer>
);

export default ConversationErrorBar;
