import {Animation, CoreTextBubble} from '@anywhere-expert/base-ui';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {TimelineItem} from '@anywhere-expert/timeline-api';
import styled from 'styled-components';
import {compose} from 'recompose';
import {withTechTemplate} from '@anywhere-expert/timeline-item';

const axios = require('axios');

type LottieContent = {
    lottieAnimationUrl: string;
    lottieWidth: number;
    lottieHeight: number;
};

type Props = TimelineItem<LottieContent> & {
    sessionId: string;
};

const AnimationContainer = styled(CoreTextBubble)`
    width: 27em;
    height: ${props => props.aspectRatio * 27}em;
    padding: 0.6em;
`;

const getAnimationOptions = json => ({
    animationData: json,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    rendererSettings: {
        scaleMode: 'noScale',
    },
});

const LottieViewer = ({content: {lottieAnimationUrl, lottieHeight, lottieWidth}}: Props) => {
    const [lottieJson, setLottieJson] = useState<{data: object}>({data: {}});

    useEffect(() => {
        axios.get(lottieAnimationUrl).then(json => {
            setLottieJson(json);
        });
    }, []);

    if (lottieJson.data['layers']) {
        return (
            <AnimationContainer aspectRatio={lottieHeight / lottieWidth} size="m" data-test-id={`LottieTimelineItem`}>
                <Animation animationOptions={getAnimationOptions(lottieJson.data)} />
            </AnimationContainer>
        );
    }

    return null;
};

export const LottieTimelineItem = compose<Props, Props>(withTechTemplate)(LottieViewer);
