import axios from 'axios';
import React, {useState, useEffect} from 'react';
import {useTweekValue} from 'react-tweek';
import styled from 'styled-components';
import {getHomeAccessToken} from '@anywhere-expert/auth';
import {CoreText, CoreButton} from '@anywhere-expert/base-ui';
import {useSendScrollAnaltic, useSendFeedbackResult, useSendTimeOnPageAnalytic} from './analytics';
import {SessionTranscriptData} from './types';
import {enrichAnalytics} from 'react-shisell';
import {compose} from 'recompose';
import {ThumbUpIcon, ThumbDownIcon} from '@anywhere-expert/icons';
import {getScrollerStyle} from '@anywhere-expert/base-ui';
import {palette} from '@anywhere-expert/colors';
import {ResolveIcon} from '@anywhere-expert/icons';
import {getBoxShadowStyle} from '@anywhere-expert/base-ui';
import configuration from '../config/config';

const {TextMessage}: {TextMessage: any} = require('@anywhere-expert/timeline-item');

const Container = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    background-color: ${({theme}) => theme.colors.neutral.white};
`;

const Header = styled.div`
    display: flex;
    background-color: ${palette.neutral.black};
    padding: 0px 24px;
    height: 60px;
    justify-content: center;
    align-items: center;
    z-index: 5;
    color: ${palette.neutral.white};
`;

const Main = styled.div`
    flex: 1;
    margin: 0 auto;
    width: 860px;
    display: flex;
    flex-direction: column;
    background-color: ${({theme}) => theme.colors.neutral.grey.lighter};
    overflow: auto;
`;

const MessagesSection = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 24px 0 0 45px;

    ${({theme}) => getScrollerStyle(theme)};
`;

const GradientDiv = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    padding-top: 32px;
    position: sticky;
    bottom: 0;
    padding-bottom: 32px;
    background: linear-gradient(transparent, ${({theme}) => theme.colors.background.primary} 54%);
`;

const ConversationHelpfulContainer = styled.div`
    padding: 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 90%;
    background: ${({theme}) => theme.colors.neutral.white};
    border-radius: 8px;
    align-self: center;
    ${({theme}) => getBoxShadowStyle(theme)};
`;

const sessionId = location.pathname.split('/').pop();

const useLoadSessionTranscript = () => {
    const [transcript, setTranscript] = useState<SessionTranscriptData | null>(null);
    useEffect(() => {
        (async () => {
            const token = await getHomeAccessToken();
            const {data} = await axios.get(`${configuration.similarSessionApiUrl}/api/similar-sessions/${sessionId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setTranscript(data);
        })();
    }, []);

    return transcript;
};

const TextMessageContainer = styled.div`
    margin-top: 5px;
    display: inline-flex;
`;

const View = () => {
    if (!sessionId) return null;

    const transcript = useLoadSessionTranscript();

    const sendScrollAnalytics = useSendScrollAnaltic(sessionId!, transcript);
    const [didFeedbackSent, sendFeedbackResult] = useSendFeedbackResult(sessionId!, transcript);
    useSendTimeOnPageAnalytic(sessionId!, transcript);

    const feedbackText = useTweekValue<string>(
        'support/session/during/similar_sessions/feedback_text',
        'Is this conversation helpful for the current issue?'
    );

    return (
        <Container>
            <Header>
                {transcript && (
                    <>
                        <ResolveIcon color={palette.neutral.white} />
                        <CoreText style={{marginLeft: 4}} size="l" textType="white">
                            {transcript.question}
                        </CoreText>
                    </>
                )}
            </Header>
            <Main>
                <MessagesSection onScroll={sendScrollAnalytics}>
                    {transcript &&
                        transcript.messages.map((x, i) => {
                            const isTech = x.senderType !== 'Customer';
                            const style = isTech ? {justifyContent: 'flex-end'} : undefined;
                            return (
                                <TextMessageContainer key={i} style={style}>
                                    <TextMessage
                                        isSelf={x.senderType !== 'Customer'}
                                        showThumbnail={true}
                                        isGroupedItem={true}
                                        senderName={transcript.expertName}
                                        senderThumbUrl={transcript.expertImage}
                                        content={x.content}
                                    />
                                </TextMessageContainer>
                            );
                        })}
                </MessagesSection>
                <GradientDiv>
                    <ConversationHelpfulContainer>
                        {!didFeedbackSent ? (
                            <>
                                <CoreText style={{marginRight: 20}} size="m" weight="medium">
                                    {feedbackText}
                                </CoreText>
                                <CoreButton
                                    style={{marginRight: 10}}
                                    color="primary"
                                    text="Yes"
                                    onClick={sendFeedbackResult(true)}
                                    icon={ThumbUpIcon}
                                />
                                <CoreButton
                                    color="primary"
                                    text="No"
                                    onClick={sendFeedbackResult(false)}
                                    icon={ThumbDownIcon}
                                />
                            </>
                        ) : (
                            <CoreText size="m" weight="medium">
                                Thank you for your feedback!
                            </CoreText>
                        )}
                    </ConversationHelpfulContainer>
                </GradientDiv>
            </Main>
        </Container>
    );
};

const enhance = compose(enrichAnalytics(dispatcher => dispatcher.withIdentity('SessionId', sessionId)));

export const SessionTranscript = enhance(View);
