import React from 'react';
import styled from 'styled-components';
import {CoreText} from '@anywhere-expert/base-ui';
import {observer} from 'mobx-react';
import {QueueItem} from '@anywhere-expert/expert-feed-store';
import {SessionToolsState} from '../../shared/types';

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

type Props = {
    selectedSession?: QueueItem;
    sessionToolsState?: SessionToolsState;
};

const DeviceDetails = observer(({sessionToolsState, selectedSession}: Props) => {
    let availableSpace: number | undefined;
    if (sessionToolsState && sessionToolsState.storage) availableSpace = sessionToolsState.storage.availableSpace;
    let osType: string | undefined;
    let osVersion: string | undefined;
    if (selectedSession && selectedSession.supportItemDetails) {
        osType = selectedSession.supportItemDetails.osType;
        osVersion = selectedSession.supportItemDetails.osVersion;
    }
    const shouldShowComponent = !!availableSpace || (!!osVersion && !!osType);

    return shouldShowComponent ? (
        <Container>
            {availableSpace !== undefined && (
                <CoreText textType="tertiary" size="s">{`${availableSpace} GB Available space`}</CoreText>
            )}
            {osType && osVersion && (
                <CoreText textType="tertiary" size="s" style={{paddingTop: '1px'}}>{`${osType} ${osVersion}`}</CoreText>
            )}
        </Container>
    ) : null;
});

export default DeviceDetails;
