import React, {useCallback, useEffect, useMemo} from 'react';
import {FollowUpPayload} from '@soluto-private/messaging-contracts';
import styled from 'styled-components';
import FollowUpTimelineLogo from './assets/FollowUpTimelineLogo';
import {CoreButton, CoreText} from '@anywhere-expert/base-ui';
import {format} from 'date-fns';
import openFollowUpModal from './openFollowUpModal';
import {ExpertFeedStore} from '@anywhere-expert/expert-feed-store';
import {useAnalytics} from 'react-shisell';
import {observer} from 'mobx-react';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 36px;
`;

const StyledCoreText = styled(CoreText)`
    line-height: 17px;
    padding-bottom: 4px;
`;

const StyledCoreButton = styled(CoreButton)`
    margin: 8px;
`;

export default observer(
    ({
        content: {originatingSessionId, customerName, scheduleTime, text},
        timelineItemId,
    }: {
        content: FollowUpPayload['value'];
        timelineItemId: string;
    }) => {
        const selectedSession = ExpertFeedStore.selectedSupportSession;
        const message = useMemo(
            () =>
                `A Follow-Up was scheduled${customerName ? ` with ${customerName}` : ''} for ${format(
                    scheduleTime,
                    'MMMM d'
                )} at ${format(scheduleTime, 'h:mm a')}`,
            [customerName, scheduleTime]
        );
        const {dispatcher} = useAnalytics();

        const dispatcherWithExtra = useMemo(
            () => dispatcher.createScoped('Indication').withExtra('IndicationType', 'FollowUp'),
            [dispatcher]
        );

        useEffect(() => {
            dispatcherWithExtra.dispatch('View');
        }, [timelineItemId]);

        const onReschedule = useCallback(() => {
            dispatcherWithExtra.withExtra('ActionId', 'Reschedule').dispatch('Click');
            openFollowUpModal();
        }, [dispatcherWithExtra]);

        return (
            <Wrapper>
                <FollowUpTimelineLogo />
                <StyledCoreText weight={'bold'} textType={'primary'}>
                    Follow-Up
                </StyledCoreText>
                <StyledCoreText textType={'primary'}>{message}</StyledCoreText>
                <StyledCoreText textType={'tertiary'} italic={true}>
                    "{text}"
                </StyledCoreText>
                {originatingSessionId && originatingSessionId === selectedSession?.sessionId && (
                    <StyledCoreButton
                        onClick={onReschedule}
                        text={'Reschedule Follow-Up'}
                        color={'tertiary'}
                        size={'s'}
                    />
                )}
            </Wrapper>
        );
    }
);
