import {ComponentType} from 'react';
import {compose, setDisplayName, wrapDisplayName, mapProps} from 'recompose';
import {IconType} from '@system-messages/types';

export default <TProps extends {icon?: IconType; subIcon?: IconType}>(Component: ComponentType<TProps>) => {
    type Outer = TProps & {iconUri?: boolean | string; subIconUri?: string};

    return compose<TProps, Outer>(
        setDisplayName(wrapDisplayName(Component, 'withIconFromUri')),
        mapProps(({iconUri, subIconUri, ...props}) => ({
            ...props,
            icon: iconUri,
            subIcon: subIconUri,
        }))
    )(Component);
};
