import React, {useState, useCallback, useMemo, useEffect} from 'react';
import {reverse, takeLast} from 'ramda';
import {observer} from 'mobx-react';
import {enrichWithTimestamps} from '@anywhere-expert/timeline-behaviours/timestamp';
import {TimelineController} from '@anywhere-expert/timeline-state';
import {useTweekValue} from 'react-tweek';
import {Message} from '@soluto-private/messaging-api-client';

type Props = {
    messages: Message[];
    initNumberOfItemsToShow: number;
    numberOfItemsToLoadOnScroll: number;
    scrollPaginationEnabled: boolean;
};
export type OuterProps = Props & {
    onSessionChanged: () => void;
    onScrolledTop: () => void;
};

const transformMessages = (
    messages: Message[],
    scrollPaginationEnabled: boolean,
    numberOfItemsToShow: number
): Message[] => {
    if (scrollPaginationEnabled) {
        messages = takeLast(numberOfItemsToShow, messages);
    }

    return reverse(enrichWithTimestamps(messages));
};

const filterVisibleOnlyByDeviceItems = (messages: Message[]): Message[] =>
    messages.filter(
        message =>
            !message.metadata.visibleOnlyBy ||
            message.metadata.visibleOnlyBy.some(item => item.userType === 'Technician')
    );

export default (Component: React.ComponentType<OuterProps>) =>
    observer(({messages, ...props}: Props) => {
        const scrollPaginationEnabled = useTweekValue('support/session/messaging/scroll_pagination/is_enabled', false);
        const initNumberOfItemsToShow = useTweekValue(
            'support/session/messaging/scroll_pagination/initial_load_of_items',
            10
        );
        const numberOfItemsToLoadOnScroll = useTweekValue(
            'support/session/messaging/scroll_pagination/incremental_load_of_items',
            10
        );

        const [numberOfItemsToShow, setNumberOfItemsToShow] = useState<number>(initNumberOfItemsToShow);

        const forceIncludeId = TimelineController.currentForceActMessageId;
        const filteredMessages = useMemo(() => filterVisibleOnlyByDeviceItems(messages), [messages]);
        const transformedMessages = useMemo(
            () => transformMessages(filteredMessages, scrollPaginationEnabled, numberOfItemsToShow),
            [messages, numberOfItemsToShow, scrollPaginationEnabled]
        );

        // If we want to scroll to a specific item, we have to make sure it's not being cut out of rendering
        useEffect(() => {
            if (!scrollPaginationEnabled) return;
            if (forceIncludeId) {
                const index = messages.findIndex(item => item.messageId === forceIncludeId);
                if (index > -1 && messages.length - numberOfItemsToShow > index) {
                    setNumberOfItemsToShow(messages.length - index);
                }
            }
        }, [scrollPaginationEnabled, messages, forceIncludeId]);

        const onScrolledTop = useCallback(() => {
            if (!scrollPaginationEnabled) return;

            if (numberOfItemsToShow < messages.length) {
                setNumberOfItemsToShow(prevState => Math.min(prevState + numberOfItemsToLoadOnScroll, messages.length));
            }
        }, [numberOfItemsToShow, messages, scrollPaginationEnabled]);

        const onSessionChanged = useCallback(() => {
            if (numberOfItemsToShow !== initNumberOfItemsToShow) {
                setNumberOfItemsToShow(initNumberOfItemsToShow);
            }
        }, [numberOfItemsToShow]);

        return (
            <Component
                {...props}
                messages={transformedMessages}
                onScrolledTop={onScrolledTop}
                onSessionChanged={onSessionChanged}
            />
        );
    });
