import {getPermissionFailedMessage} from '../shared/getPermissionFailedMessage';
import {triggerCommand} from '../../shared';
import {FeatureSuggestionTexts} from '../../components/FeatureSuggestion/types';
import {UserDetailsType} from '@anywhere-expert/core';
import {AnalyticsDispatcher} from 'shisell';
export const description: string = "See customer's zip code";

interface Result {
    success: boolean;
    message: string;
}

export const getResult = ({commandPayload}): Result => {
    const commandResult = commandPayload;
    if (commandResult.success === false) {
        let message;
        if (commandResult.refusePermission) {
            message = getPermissionFailedMessage(commandResult.reason);
        } else {
            message = "Customer's location is currently unavailable";
        }
        return {success: false, message};
    }

    if (commandResult.zipcode) {
        return {success: true, message: `Customer's zip code is *${commandResult.zipcode}*`};
    }

    return {success: false, message: 'Something went wrong'};
};

export const featureSuggestionTexts: FeatureSuggestionTexts = {
    headerText: 'Easily check customer’s cell reception',
    subHeaderText:
        'Request to view customer’s zip code (customer must give permission).\nView zip code (only you can see) - then enter it into reception map.',
    buttonText: 'See reception',
    otherToolsButtonText: 'Explore other tools',
};

export const onFeatureSuggestionClick = (
    commandMessage: string,
    timelineId: string,
    sessionId: string,
    technicianData: UserDetailsType,
    dispatcher: AnalyticsDispatcher
) => {
    triggerCommand({
        timelineId,
        sessionId,
        expertData: technicianData,
        analyticsDispatcher: dispatcher,
        commandMessage,
        triggeringMethod: 'Suggestion',
    });
};
