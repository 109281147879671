import * as React from 'react';

interface DeletedInterface {
    stroke: string;
}

const DeletedIcon: React.SFC<DeletedInterface> = ({stroke}) => (
    <svg width="12" height="12" viewBox="0 0 12 12">
        <g fill="none" fillRule="evenodd">
            <g stroke={stroke} strokeWidth="1.8">
                <circle cx="6" cy="6" r="5" />
                <path strokeLinecap="square" d="M3 3l6 6" />
            </g>
        </g>
    </svg>
);

export default DeletedIcon;
