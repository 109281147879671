import React from 'react';
import {TimelineItem} from '@anywhere-expert/timeline-api';
import ActivityMessage from '@soluto-private/activity-message-web-component';
import {useTheme} from '@anywhere-expert/colors';
import styled from 'styled-components';

const Container = styled.div`
    align-self: center;
    margin: 32px;
    width: 400px;
`;

const ActivityMessageItem = (props: TimelineItem<any>) => {
    const {
        content: {
            title,
            text,
            image,
            imageDarkMode,
            sectionIndicationText,
            sectionIndicationIcon,
            sectionIndicationIconDarkMode,
            secondaryText,
        },
    } = props;
    const theme = useTheme();
    const isDarkMode = theme.themeName === 'dark';

    return (
        <Container>
            <ActivityMessage
                title={title}
                text={text}
                sectionIndicationText={sectionIndicationText}
                sectionIndicationIcon={sectionIndicationIcon}
                image={image}
                isDarkMode={isDarkMode}
                imageDarkMode={imageDarkMode}
                secondaryText={secondaryText}
                sectionIndicationIconDarkMode={sectionIndicationIconDarkMode}
            />
        </Container>
    );
};

export default ActivityMessageItem;
