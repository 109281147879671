import {useTweekValue} from 'react-tweek';
import {User} from '@anywhere-expert/auth';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import {useCallback} from 'react';
import {Message} from '@soluto-private/messaging-api-client';

export type QuickResolveActions = {
    isResolveMenuEnabled: boolean;
    isQuickResolve: () => boolean;
};

export default (user: User, selectedSession: SupportItem): QuickResolveActions => {
    const isResolveMenuEnabled = useTweekValue<boolean>(
        'support/session/during/resolve/quick_resolve/is_enabled',
        false
    );
    const timeTillResolveInSeconds = useTweekValue<number>(
        'support/session/during/resolve/quick_resolve/time_till_resolve_in_seconds',
        180
    );
    const expertMessagesTillResolve = useTweekValue<number>(
        'support/session/during/resolve/quick_resolve/expert_messages_till_resolve',
        3
    );

    const messages = selectedSession.timelineModel.messages;
    const isQuickResolve = useCallback(() => {
        const {uid: expertId}: User = user;
        const sessionMessages = messages.filter(
            ({payload: {type}, senderId, createdAt}: Message) =>
                type === 'text_message' && senderId === expertId && createdAt > selectedSession.creationTime.getTime()
        );
        const messageCount = sessionMessages.length;
        const firstMessage = sessionMessages[0];
        const timeFromSessionStartInSeconds = firstMessage?.createdAt
            ? (Date.now() - firstMessage.createdAt) / 1000
            : 0;

        return messageCount <= expertMessagesTillResolve || timeFromSessionStartInSeconds <= timeTillResolveInSeconds;
    }, [user, selectedSession, messages, expertMessagesTillResolve, timeTillResolveInSeconds]);

    return {
        isResolveMenuEnabled,
        isQuickResolve,
    };
};
