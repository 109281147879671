import React from 'react';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import moment from 'moment';
import {CoreText} from '@anywhere-expert/base-ui';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import {AppData} from '../../../shared/types';
import useAppIcon from './useAppIcon';

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 120px;
    padding: ${({theme}) => theme.spacing(1, 2)};

    &:hover .uninstallBtn {
        opacity: 1;
    }
`;

const AppIconContainer = styled.div`
    position: relative;
    width: 55px;
    height: 55px;
    padding-bottom: 4px;
`;
const Label = styled(CoreText)`
    padding-bottom: 2px;
`;

interface AppProps {
    app: AppData;
    selectedSession: SupportItem;
}

const App = observer(({app}: AppProps) => {
    const appIcon = useAppIcon(app.package);

    const appInstallDateString = moment(app.firstInstallTime).format('MMM DD, YYYY');

    return (
        <Container data-test-id="App" data-test-app-id={app.package}>
            <AppIconContainer>
                <img title={'v' + app.versionName} src={appIcon} />
            </AppIconContainer>
            <>
                <Label textType="tertiary" weight="bold" disableTextOverflow data-pii title={app.label}>
                    {app.label}
                </Label>
                <CoreText textType="secondary" disableTextOverflow>
                    {appInstallDateString}
                </CoreText>
            </>
        </Container>
    );
});

export default App;
