import * as React from 'react';
import {StyleSheet, css} from 'aphrodite';

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        alignSelf: 'center',
    },
    minutes: {
        fontFamily: 'Roboto',
        fontSize: 32,
        alignSelf: 'flex-end',
        lineHeight: '28px',
        cursor: 'default',
    },
    units: {
        fontFamily: 'Roboto',
        fontSize: 12,
        fontStyle: 'italic',
        alignSelf: 'flex-end',
        cursor: 'default',
    },
    space: {
        marginRight: 10,
    },
});

export default ({minutes}) => {
    const hours = Math.floor(minutes / 60);
    const minutesInHour = minutes % 60;
    return (
        <div className={css(styles.container)}>
            {hours != 0 && (
                <div className={css(styles.space)}>
                    <span className={css(styles.minutes)}>{hours}</span>
                    <span className={css(styles.units)}>hrs</span>
                </div>
            )}
            <div>
                <span className={css(styles.minutes)}>{minutesInHour}</span>
                <span className={css(styles.units)}>min</span>
            </div>
        </div>
    );
};
