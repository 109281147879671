import React from 'react';
import {observer} from 'mobx-react';
import AppList from './AppList';
import {getTabDisabledText} from '../../shared/getTabDisabledText';
import {SessionToolsState} from '../../shared/types';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import {CoreText} from '@anywhere-expert/base-ui';

type Props = {
    sessionToolsState?: SessionToolsState;
    selectedSession: SupportItem;
};

interface AppListTabType extends React.FunctionComponent<Props> {
    getDisabledText?: typeof getTabDisabledText;
}

const AppListTab: AppListTabType = observer(({sessionToolsState, selectedSession}: Props) => {
    if (
        !sessionToolsState ||
        !sessionToolsState.apps ||
        !sessionToolsState.apps.apps ||
        sessionToolsState.apps.days === undefined
    ) {
        return <CoreText>Couldn't fetch the list of apps, please try again later</CoreText>;
    }

    return (
        <AppList
            apps={sessionToolsState.apps.apps}
            days={sessionToolsState.apps.days}
            selectedSession={selectedSession}
        />
    );
});

AppListTab.getDisabledText = getTabDisabledText;

export default AppListTab;
