import React, {useEffect, useMemo, useRef, useState} from 'react';
import {observer} from 'mobx-react';
import {User} from '@anywhere-expert/auth';
import {useTweekValue} from 'react-tweek';
import {useIsShowingEscalate} from '@anywhere-expert/escalate';
import {TimelineModel} from '@anywhere-expert/timeline-state';
import {Header} from './Header';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import {getScrollerStyle} from '@anywhere-expert/base-ui';
import Footer from './Footer';
import MessagesSection, {MessagesSection as MessagesSectionType} from './MessagesSection';
import styled from 'styled-components';
import media from 'styled-media-query';
import {CobrowsingSection} from '@anywhere-expert/cobrowsing';
import ConnectionIndication from './ConnectionIndication';
import {useOfflineDetector} from '@anywhere-expert/offline-detector';
import DropToBottomButton from './MessagesSection/DropToBottomButton';
import {AnalyticsProvider, useAnalytics} from 'react-shisell';
import {ScreensharePanel} from '@anywhere-expert/screenshare';

const Container = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    background-color: ${({theme}) => theme.colors.background.primary};
    position: relative;
    width: 100%;
`;

// Do not change overflow to auto, it is intentionally scroll otherwise padding becomes wrong
const ScrollContainer = styled.div`
    flex: 1;
    padding: 72px 24px 0 24px;
    ${media.lessThan('1250px' as any)`
        padding: 72px 0 0 16px;
    `};
    overflow: hidden scroll;

    ${({theme}) => getScrollerStyle(theme)};
`;

const FloatingContainer = styled.div<{hideGradientOverlay?: boolean}>`
    padding-top: 16px;
    margin: 0 24px;
    position: absolute;
    display: flex;
    z-index: 6;
    flex-direction: column;
    top: 0;
    left: 0;
    right: 0;
    background: ${({hideGradientOverlay, theme}) =>
        hideGradientOverlay
            ? 'none'
            : `
            linear-gradient(
            360deg,
            rgba(240, 240, 245, 0) 0%,
            ${theme.colors.background.primary} 99.04%
        )
    `};
`;

const BottomContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    position: sticky;
    bottom: 0;
    z-index: 1;
`;

const Sections = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 100%;
    min-width: 480px;
    margin: 0 40px;
`;

type Props = {
    user: User;
    selectedSession: SupportItem;
};

const VerticalContainer = styled.div`
    min-height: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const ConnectionContainer = styled.div`
    position: absolute;
    top: 28px;
    left: 32px;
    z-index: 100;
`;

const useDropToBottomWhenHidingRecommendations = (timelineModel: TimelineModel, onQuickDropClick: () => void) => {
    const {isRecommendationsEnabled, recommendationsStore} = timelineModel;
    useEffect(() => {
        if (!isRecommendationsEnabled) return;
        const {isHidden, hasRecommendations} = recommendationsStore!;
        if (!hasRecommendations) return;
        if (!isHidden) {
            onQuickDropClick();
        }
    }, [recommendationsStore?.isHidden, isRecommendationsEnabled, onQuickDropClick]);
};

export const Timeline = observer(({selectedSession, user}: Props) => {
    const {id, sessionId} = selectedSession;
    const [scrollContainerRef, setScrollContainerRef] = useState<HTMLDivElement | null>(null);
    const messagesSectionRef = useRef<MessagesSectionType>(null);
    const onQuickDropClick = React.useCallback(() => {
        messagesSectionRef.current?.quickDropButtonClick();
    }, []);
    const onlineState = useOfflineDetector();
    const isShowingEscalate = useIsShowingEscalate();

    const analytics = useAnalytics();

    const enhancedAnalytics = useMemo(
        () =>
            analytics.dispatcher
                .createScoped('Session')
                .createScoped('ConversationView')
                .withIdentity('SessionId', sessionId)
                .withIdentity('DeviceId', id)
                .withExtra('CustomerDeviceId', id)
                .withExtra('CustomerFormFactor', selectedSession.supportItemDetails.formFactor)
                .withExtra('CustomerDeviceModel', selectedSession.supportItemDetails.deviceModel)
                .withExtra('CustomerPartnerBrandId', selectedSession.supportItemDetails.partner)
                .withExtra('CustomerDeviceOsType', selectedSession.supportItemDetails.osType)
                .withExtra('CustomerDeviceOsVersion', selectedSession.supportItemDetails.osVersion)
                .withExtra(
                    'ConversationStatus',
                    Object.keys(selectedSession.supportItemDetails.assignedTechnicians || []).length
                        ? 'Assigned'
                        : 'Unassigned'
                ),
        [analytics, selectedSession, sessionId, id]
    );

    useEffect(() => {
        enhancedAnalytics.dispatch('View');
    }, [sessionId, enhancedAnalytics]);

    const isReadOnlyMessagingExplanation = useTweekValue<string>(
        'support/session/messaging/is_read_only_explanation',
        ''
    );
    const isConnectionIndicationEnabled = useTweekValue<boolean>(
        'support/session/messaging/connection_indication/is_enabled',
        true
    );

    const isReadOnlyMessaging = useTweekValue<boolean>('support/session/messaging/is_read_only', false);

    const timelineModel = selectedSession.timelineModel;

    useEffect(() => {
        timelineModel.requestSnapshot();
    }, [timelineModel]);

    useDropToBottomWhenHidingRecommendations(timelineModel, onQuickDropClick);

    const shouldShowConnectionIndicator = React.useMemo(
        () => isConnectionIndicationEnabled && onlineState === 'online',
        [isConnectionIndicationEnabled, onlineState]
    );

    const isWithinCall = selectedSession.callDetails.isCall;
    const isSnoozed = selectedSession.snooze.isSnoozed;
    const isAutopilot = selectedSession.isAutopilot;
    const {isAuthorizingOrActive: isScreenshareSessionAuthorizingOrActive} =
        selectedSession.timelineModel.screenshareSessionManager ?? {};
    const customerName = selectedSession.queueNaming ?? selectedSession.customerDetails.firstName ?? 'the customer';

    if (!timelineModel) return null;
    return (
        <AnalyticsProvider dispatcher={enhancedAnalytics}>
            <Container>
                <FloatingContainer hideGradientOverlay={timelineModel.cobrowsingStore?.isCobrowsingOn}>
                    <Header
                        user={user}
                        timelineModel={timelineModel}
                        disabled={isWithinCall || isSnoozed || isAutopilot || isScreenshareSessionAuthorizingOrActive}
                        selectedSession={selectedSession}
                    />
                    {shouldShowConnectionIndicator && (
                        <ConnectionContainer>
                            <ConnectionIndication timelineModel={timelineModel} timelineId={id} />
                        </ConnectionContainer>
                    )}
                    {timelineModel.cobrowsingStore && (
                        <CobrowsingSection cobrowsingStore={timelineModel.cobrowsingStore} />
                    )}
                    {timelineModel.screenshareSessionManager?.isStartedButNotEndedOrDisposed && (
                        <ScreensharePanel
                            screenshareSessionManager={timelineModel.screenshareSessionManager}
                            customerName={customerName}
                        />
                    )}
                </FloatingContainer>
                <VerticalContainer>
                    <ScrollContainer ref={setScrollContainerRef}>
                        <Sections data-test-id="Timeline" className="scroll-content">
                            <MessagesSection
                                timelineId={id}
                                sessionId={sessionId}
                                messages={timelineModel.messages}
                                timelineItemsLoaded={timelineModel.isInitialLoaded}
                                scrollerRef={scrollContainerRef}
                                ref={messagesSectionRef}
                            />
                            <BottomContainer>
                                <DropToBottomButton onClick={onQuickDropClick} />
                                <Footer
                                    sessionId={sessionId}
                                    editable={!(isShowingEscalate || isReadOnlyMessaging)}
                                    isSnoozed={isSnoozed}
                                    disabledReasonMessage={isReadOnlyMessagingExplanation}
                                    selectedSession={selectedSession}
                                    timelineModel={timelineModel}
                                />
                            </BottomContainer>
                        </Sections>
                    </ScrollContainer>
                </VerticalContainer>
            </Container>
        </AnalyticsProvider>
    );
});

export default Timeline;
