import * as React from 'react';
import {TimelineItem} from '@anywhere-expert/timeline-api';
import {RendererExtraProps} from '@anywhere-expert/timeline-behaviours/timelineItemsRenderer';
import {CustomerMessage, TechnicianMessage} from '../TextHelpers';
import {withIgnoreCorruptedTextMessage} from '../../shared';

type Props = TimelineItem<any> & RendererExtraProps;

const TextMessage = ({isSelf, ...props}: Props) =>
    isSelf ? <TechnicianMessage {...props} /> : <CustomerMessage {...props} />;

export default withIgnoreCorruptedTextMessage(TextMessage) as any;
