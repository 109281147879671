import React from 'react';
import {StyleSheet, css} from 'aphrodite';

const styles = StyleSheet.create({
    base: {
        width: 10,
        height: 10,
        cursor: 'pointer',
        marginTop: 19,
    },
    backPageButton: {
        transform: 'rotate(-45deg)',
        borderTop: 'solid 2px #00aeef',
        borderLeft: 'solid 2px #00aeef',
    },
    nextPageButton: {
        transform: 'rotate(45deg)',
        borderTop: 'solid 2px #00aeef',
        borderRight: 'solid 2px #00aeef',
    },
    hide: {
        visibility: 'hidden',
    },
});

interface ButtonProps {
    onClick: () => void;
    hide: boolean;
}

export const BackButton = ({onClick, hide}: ButtonProps) => (
    <a className={css(styles.base, styles.backPageButton, hide && styles.hide)} onClick={onClick} />
);

export const NextButton = ({onClick, hide}: ButtonProps) => (
    <a className={css(styles.base, styles.nextPageButton, hide && styles.hide)} onClick={onClick} />
);
