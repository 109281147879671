import React, {useMemo} from 'react';
import styled from 'styled-components';
import {CoreText} from '@anywhere-expert/base-ui';
import {palette} from '@anywhere-expert/colors';
import {HandWaveIcon} from '@anywhere-expert/icons';

const CustomerGreetingWrapper = styled.div`
    display: flex;
    align-items: space-between;
    margin-bottom: 20px;
`;

const CustomerGreetingTextWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
`;

const CustomerGreetingContainer = styled.div`
    background-color: ${palette.brand.tennis};
    transform: rotate(-1deg);
    padding: 0 10px;
    margin-left: -12px;
`;

const CustomerGreetingText = styled(CoreText)`
    position: relative;
    font-size: 24px;
    padding: 0px 2px;
    z-index: 1;
    line-height: 38px;
    color: ${palette.neutral.black};
    transform: rotate(1deg);
    letter-spacing: -0.5px;
`;

const NewCustomerText = styled(CoreText)`
    font-size: 24px;
    color: ${palette.neutral.black};
    font-weight: 600;
    line-height: 100%;
`;

const ExpertGreeting = styled(CoreText)`
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 5px;
    letter-spacing: -0.5px;
`;

const WavingIconContainer = styled.div`
    margin-right: 15px;
`;

type Props = {
    expertName: string;
};

const CustomerGreeting = ({expertName}: Props) => {
    const expertGreeting = useMemo(() => `Hey ${expertName},`, [expertName]);

    return (
        <CustomerGreetingWrapper>
            <WavingIconContainer>
                <HandWaveIcon />
            </WavingIconContainer>
            <CustomerGreetingTextWrapper>
                <ExpertGreeting weight="light" family="apercu pro">
                    {expertGreeting}
                </ExpertGreeting>
                <CustomerGreetingContainer>
                    <CustomerGreetingText weight="light" family="apercu pro">
                        say hello to <NewCustomerText family="apercu pro">your new customer</NewCustomerText>
                    </CustomerGreetingText>
                </CustomerGreetingContainer>
            </CustomerGreetingTextWrapper>
        </CustomerGreetingWrapper>
    );
};
export default CustomerGreeting;
