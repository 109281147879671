import React, {FunctionComponent} from 'react';
import {SelfHelpTimelineItemProps} from './types';
import {OsCheck, ContentItem} from './components';

const initialState = {
    osAnswer: '',
    imageUrl: '',
    url: '',
    title: '',
    summary: '',
};

const SelfHelpItem: FunctionComponent<SelfHelpTimelineItemProps> = (props: SelfHelpTimelineItemProps) => {
    const state = {
        ...initialState,
        needToCheckDevice: props.content.needToCheckDevice,
        ...props.content.state,
    };

    const enhancesProps = {
        //@ts-ignore
        state,
        ...props,
    };

    const osCheckProps = {
        needToCheckDevice: state.needToCheckDevice,
        osAnswer: state.osAnswer,
    };

    return (
        <>
            <ContentItem {...enhancesProps} />
            <OsCheck {...osCheckProps} />
        </>
    );
};

export default SelfHelpItem;
