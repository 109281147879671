import {useTweekValue} from 'react-tweek';
import {useMemo} from 'react';
import {format, addDays, setHours} from 'date-fns';

interface DayOption {
    value: number;
    text: string;
    secondaryText: string;
}

interface TimeOption {
    value: number;
    text: string;
}

interface TimeOptions {
    hours: number[];
}

export default (): {daysOptions: DayOption[]; timeOptions: {times: TimeOption[]}} => {
    const daysOptions = useTweekValue<Record<string, string>>('support/feed/follow_up/days_options', {});
    const timeOptions = useTweekValue<TimeOptions>('support/feed/follow_up/time_options', {hours: []});

    const daysOptionsFormatted = useMemo<DayOption[]>(
        () =>
            Object.entries(daysOptions).map(([day, text]) => {
                const value = parseInt(day);

                return {value, text, secondaryText: format(addDays(new Date(), value), 'EEEE, LLLL d')};
            }),
        [daysOptions]
    );

    const timesFormatted = useMemo(
        () =>
            timeOptions.hours.map<TimeOption>(value => ({
                value,
                text: format(setHours(new Date(), value), 'hh:00 a'),
            })),
        [timeOptions]
    );

    return {
        daysOptions: daysOptionsFormatted,
        timeOptions: {
            times: timesFormatted,
        },
    };
};
