import React, {useCallback} from 'react';
import {copySessionId, useCopyMDN} from '@anywhere-expert/session-details';
import {useEscalate} from '@anywhere-expert/session-actions';
import {CoreDropdown, CoreList, CoreListItem} from '@anywhere-expert/base-ui';
import {useAnalytics} from 'react-shisell';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import {ShowMoreVerticalIcon} from '@anywhere-expert/icons';
import styled from 'styled-components';
import {observer} from 'mobx-react';

type Props = {
    selectedSession: SupportItem;
    escalateIsEnabled: boolean;
    actionsDisabled: boolean;
};

const StyledList = styled(CoreList)`
    width: max-content;
    min-width: 180px;
` as typeof CoreList;

const childStyles = {
    border: 'unset',
    right: 0,
    width: 'max-content',
};

const SessionAdditionalActions = observer(
    ({
        selectedSession: {customerId, supportItemDetails, deviceDetails, sessionId, customerDetails, timelineModel},
        escalateIsEnabled,
        actionsDisabled,
    }: Props) => {
        const analytics = useAnalytics();
        const [open, setOpen] = React.useState(false);
        const closeMenu = useCallback(() => setOpen(false), []);
        const {copyMdn, textToCopy} = useCopyMDN(
            customerId,
            supportItemDetails.partner,
            deviceDetails.platform,
            customerDetails.mdn,
            closeMenu
        );
        const copySessionIdFunction = useCallback(() => copySessionId(sessionId, analytics, closeMenu), [
            sessionId,
            closeMenu,
            analytics,
        ]);
        const escalateFunction = useEscalate(closeMenu);

        return (
            <CoreDropdown
                icon={ShowMoreVerticalIcon}
                childStyle={childStyles}
                variant="icon"
                size="s"
                extend={open}
                hasArrow={false}
                onClick={() => setOpen(prev => !prev)}
                onClickOut={closeMenu}
                data-test-id={'SessionAdditionalActions'}
            >
                <StyledList>
                    <CoreListItem text="Copy Phone Number" onClick={copyMdn} disabled={!textToCopy} />
                    <CoreListItem text="Copy Session ID" onClick={copySessionIdFunction} />
                    {escalateIsEnabled && (
                        <CoreListItem
                            text="Escalate"
                            onClick={escalateFunction}
                            disabled={actionsDisabled}
                            data-test-id={'EscalateButton'}
                        />
                    )}
                </StyledList>
            </CoreDropdown>
        );
    }
);

export default SessionAdditionalActions;
