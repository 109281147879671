import {observable, computed, action, runInAction, reaction, makeObservable} from 'mobx';
import {Tag} from '@expert-feed/conversation-tags';
import {getFromTweekForSupportItem} from '@anywhere-expert/tweek';
import {SuggestedTags, SuggestedTagsObj, addTagParams} from './types';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import {getFeedActionsClient} from '@anywhere-expert/expert-feed-actions';

class TagsStore {
    private mTags = new Map<string, Tag>();
    private suggestedTagsSource: SuggestedTags;
    private expertId: string;
    sessionId: string;

    constructor(selectedSession: SupportItem, expertId: string) {
        makeObservable<TagsStore, 'mTags' | 'init' | 'updateMap' | 'reactOnTagChanges'>(this, {
            mTags: observable,
            init: action,
            updateMap: action,
            reactOnTagChanges: action,
            tags: computed,
            suggestedTags: computed,
            add: action,
            remove: action,
        });

        this.sessionId = selectedSession.sessionId;
        this.expertId = expertId;

        this.init(selectedSession);
    }

    private async init(selectedSession: SupportItem) {
        const {id, sessionId, pool, sessionAttributes, tags} = selectedSession;

        const suggestedTagsFromTweek = await getFromTweekForSupportItem<SuggestedTagsObj>(
            'support/expert/custom_tags/suggested_tags',
            id,
            sessionId,
            pool,
            sessionAttributes
        );
        if (!suggestedTagsFromTweek) return;

        this.suggestedTagsSource = new Map(Object.entries(suggestedTagsFromTweek));

        this.updateMap(tags);
        this.reactOnTagChanges(selectedSession);
    }

    private updateMap = (tags: Tag[]) => {
        const initTagsMap = new Map<string, Tag>();

        if (tags?.length) {
            tags.forEach(tag => initTagsMap.set(tag.tagKey, tag));
        }

        runInAction(() => {
            this.mTags = initTagsMap;
        });
    };

    private reactOnTagChanges = (selectedSession: SupportItem) => {
        reaction(
            () => selectedSession.tags,
            tags => this.updateMap(tags)
        );
    };

    get tags() {
        return Array.from(this.mTags.values());
    }

    get suggestedTags(): SuggestedTags {
        let copy = new Map(this.suggestedTagsSource);
        this.tags.forEach(({tagKey}) => copy.delete(tagKey));
        return copy;
    }

    add = ({tagKey, backgroundColor, textColor}: addTagParams) => {
        getFeedActionsClient().addQueueItemAttributes({
            expertId: this.expertId,
            feedItemId: this.sessionId,
            attributes: [
                {
                    type: 'customTag',
                    key: tagKey,
                    fields: {
                        backgroundColor: backgroundColor,
                        textColor: textColor,
                        createdTimeInUnix: Date.now(),
                    },
                },
            ],
        });
        this.mTags.set(tagKey, {
            tagKey,
            tagType: 'customTag',
            displayText: tagKey,
            tagStyle: {backgroundColor, textColor},
        });
    };

    remove = (key: string) => {
        const tag = this.mTags.get(key);

        if (!tag) return;
        const {tagType, tagKey} = tag;

        getFeedActionsClient().removeQueueItemAttributes({
            expertId: this.expertId,
            feedItemId: this.sessionId,
            attribute: {type: tagType, key: tagKey, fields: {}},
        });
        this.mTags.delete(key);
    };
}

export default TagsStore;
