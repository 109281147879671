import {useTweekValue} from 'react-tweek';

const useCopyFromTweek = () => {
    const notePlaceholderText = useTweekValue('support/session/add_an_expert/note_placeholder_text', '');
    const preSelectMessage = useTweekValue('support/session/add_an_expert/transfer_by_reason/pre_select_message', '');
    const selectionPlaceholder = 'please choose one';
    const warningText = useTweekValue('support/session/add_an_expert/transfer_by_reason/warning_text', '');

    return {
        notePlaceholderText,
        preSelectMessage,
        selectionPlaceholder,
        warningText,
    };
};

export default useCopyFromTweek;
