import React from 'react';
import {CoreText, CoreModal} from '@anywhere-expert/base-ui';
import styled from 'styled-components';
import {Resolved, CheckIn, Payment, Cancel} from './resources';
import {useTheme} from '@anywhere-expert/colors';

const Title = styled(CoreText)`
    font-size: 34px;
    line-height: 110%;
    width: 461px;
    text-align: center;
    margin: 14px 0px 54px 0px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const CancelIllustration = styled(Cancel)`
    max-height: 58px;
    margin: 0px 11px 0px 11px;
`;

const ImageSectionContainer = styled.div`
    width: 214px;
    margin: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Summary = styled.div`
    display: flex;
    margin: 0px 18px;
`;

const CenterAlignedText = styled(CoreText)`
    text-align: center;
    line-height: 130%;
    margin-bottom: 3px;
`;

const CancelAnytimeContainer = styled.div`
    background: ${({theme}) =>
        theme.themeName === 'dark' ? theme.colors.neutral.blacks.b10 : theme.colors.neutral.whites.w10};
    display: flex;
    width: 395px;
    height: 95px;
    border-radius: 8px;
    align-items: center;
    margin: 50px 0px 15px 0px;
`;

const CancelAnytimeTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 24px;
`;

const ImageSectionTextContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const ImageSection = (props: {
    title: string;
    content: string;
    illustrationComponent: React.ReactElement<{color?: string}>;
}) => {
    const {title, content, illustrationComponent} = props;

    return (
        <ImageSectionContainer>
            {illustrationComponent}
            <ImageSectionTextContainer>
                <CenterAlignedText size="l" weight="bold">
                    {title}
                </CenterAlignedText>
                <CenterAlignedText size="m">{content}</CenterAlignedText>
            </ImageSectionTextContainer>
        </ImageSectionContainer>
    );
};

export const AutopilotModal = ({close, isOpen}: {close: () => void; isOpen: boolean}) => {
    const {
        colors: {
            neutral: {black: illustrationColor},
        },
    } = useTheme();

    return (
        <CoreModal open={isOpen} onClose={close} data-test-id="AutopilotModal">
            <CoreModal.Content>
                <Container>
                    <Title>Unresponsive customer? Autopilot will take it from here</Title>
                    <Summary>
                        <ImageSection
                            illustrationComponent={<CheckIn />}
                            title="We'll check in for you"
                            content="Your customer will receive a series of follow up messages."
                        />
                        <ImageSection
                            illustrationComponent={<Resolved color={illustrationColor} />}
                            title="Session is resolved"
                            content="If they're still unresponsive, we'll mark them as resolved (just like you would)."
                        />
                        <ImageSection
                            illustrationComponent={<Payment color={illustrationColor} />}
                            title="Receive your payment"
                            content='The session will be considered "Resolved" and will be available with all your resolved sessions.'
                        />
                    </Summary>
                    <CancelAnytimeContainer>
                        <CancelIllustration color={illustrationColor} />
                        <CancelAnytimeTextContainer>
                            <CoreText weight="bold" size="m" style={{marginBottom: '4px'}}>
                                You can cancel anytime
                            </CoreText>
                            <CoreText>
                                If you need to keep this session open for any reason, you have the option to cancel
                                Autopilot.
                            </CoreText>
                        </CancelAnytimeTextContainer>
                    </CancelAnytimeContainer>
                </Container>
            </CoreModal.Content>
        </CoreModal>
    );
};
