import React from 'react';
import {compose} from 'recompose';
import {StyleSheet, css} from 'aphrodite';
import {AnalyticsDispatcher} from 'shisell';
import {withAnalytics, WithAnalyticsProps} from 'react-shisell';
import {UserDetailsType} from '@anywhere-expert/core';
import {useTheme} from '@anywhere-expert/colors';
import {SystemMessage} from '@anywhere-expert/system-messages';
import {getCommandVisualComponent, VisualComponentProps} from '@slash-commands/command-visual-component';
import {VisualComponentType} from '@slash-commands/command-visual-component';
import {withAvailableCommandNames, getCommand, AvailableCommandNamesInnerProps} from '../../../commandFactory';
import {triggerCommand} from '../../../shared';
import withTriggerCommandProps, {TriggerCommandProps} from '../../shared/withTriggerCommandProps';

const styles = StyleSheet.create({
    mainContainer: {
        marginTop: '24px',
        marginBottom: '24px',
        display: 'flex',
        alignSelf: 'center',
        flexDirection: 'column',
        flexShrink: 0,
    },
    headerMessage: {
        textAlign: 'center',
        fontSize: '14px',
        fontFamily: 'Roboto',
        lineHeight: '1.54',
        marginBottom: '10px',
    },
    commandSuggestions: {
        alignSelf: 'center',
    },
    commandWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '5px',
    },
    runCommandButton: {
        marginLeft: '30px',
        cursor: 'pointer',
        fontSize: '13px',
        fontFamily: 'Roboto',
        lineHeight: '1.54',
        backgroundColor: '#00aeef',
        color: '#ffffff',
        borderRadius: '25px',
        width: '86px',
        height: '25px',
        alignItems: 'center',
        ':hover': {
            backgroundColor: '#0091c7',
        },
        display: 'flex',
        justifyContent: 'center',
    },
    disabledRunCommandButton: {
        marginLeft: '30px',
        fontSize: '13px',
        fontFamily: 'Roboto',
        lineHeight: '1.54',
        width: '86px',
        height: '25px',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
});

const handleClick = (
    timelineId: string,
    sessionId: string,
    technicianData: UserDetailsType,
    dispatcher: AnalyticsDispatcher,
    commandName: string
) => {
    dispatcher
        .createScoped('Tools')
        .createScoped('Help')
        .withExtra('ToolName', commandName)
        .withExtra('ActionId', `${commandName}`)
        .dispatch('Click');

    triggerCommand({
        timelineId,
        sessionId,
        expertData: technicianData,
        analyticsDispatcher: dispatcher,
        commandMessage: `/${commandName}`,
        triggeringMethod: 'HelpSuggestion',
    });
};

const HelpCommandComponent: React.SFC<EnhancedHelpComponentProps> = ({
    availableCommands,
    timelineId,
    sessionId,
    technicianData,
    analytics: {dispatcher},
}) => {
    const theme = useTheme();

    if (availableCommands.length === 0) return null;

    const commandDescriptionComponents = availableCommands.map(commandName => {
        const command = getCommand(commandName);
        const hideFromHelp = !command || command.hideFromHelp;
        if (hideFromHelp) return null;

        const commandRequiresArguments = command!.requiresArgs;
        const commandVisualComponent = getCommandVisualComponent(commandName);

        return (
            <div className={css(styles.commandWrapper)} key={commandName}>
                <SystemMessage
                    message={`${commandVisualComponent!.description}`}
                    // icon={commandVisualComponent!.iconOutline}
                />
                <a
                    className={css(
                        commandRequiresArguments ? styles.disabledRunCommandButton : styles.runCommandButton
                    )}
                    style={{color: theme.colors.neutral.grey.deepest}}
                    onClick={() =>
                        !commandRequiresArguments &&
                        handleClick(timelineId, sessionId, technicianData, dispatcher, commandName)
                    }
                >
                    {`/${commandName}`}
                </a>
            </div>
        );
    });

    const headerMessage = 'Available Commands';

    return (
        <div className={css(styles.mainContainer)}>
            <span className={css(styles.headerMessage)} style={{color: theme.colors.neutral.grey.deepest}}>
                {headerMessage}
            </span>
            <div className={css(styles.commandSuggestions)}>{commandDescriptionComponents}</div>
        </div>
    );
};

export type EnhancedHelpComponentProps = TriggerCommandProps &
    AvailableCommandNamesInnerProps &
    WithAnalyticsProps &
    VisualComponentProps;

const enhance = compose<EnhancedHelpComponentProps, VisualComponentProps>(
    withTriggerCommandProps,
    withAvailableCommandNames,
    withAnalytics
);

export const Component: VisualComponentType = enhance(HelpCommandComponent);
