import {BehaviorSubject} from 'rxjs';

const autopilotModalState = new BehaviorSubject(false);

export function openAutopilotModal() {
    autopilotModalState.next(true);
}

export function closeAutopilotModal() {
    autopilotModalState.next(false);
}

export const autopilotModalState$ = autopilotModalState.asObservable();
