import * as React from 'react';
import {css} from 'aphrodite';
import bodymovin from 'bodymovin';
import {updateLottieColor} from '@anywhere-expert/utils';
const speedTestAnimation = require('../BarAnimation.json');

type Props = {
    brandColor: string;
    style: object;
};

class SpeedTestAnimation extends React.Component<Props> {
    container;
    animation;

    componentDidMount() {
        this.animation = bodymovin.loadAnimation({
            animationData: updateLottieColor(speedTestAnimation, 'brandColor', this.props.brandColor),
            renderer: 'svg',
            rendererSettings: {
                scaleMode: 'noScale',
            },
            container: this.container,
        });
        this.animation.playSegments([120, 300], true);
    }

    componentWillUnmount() {
        this.animation && this.animation.destroy();
        this.animation = null;
    }

    render() {
        return <div className={css(this.props.style)} ref={container => (this.container = container)} />;
    }
}

export default SpeedTestAnimation;
