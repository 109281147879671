import * as airplane from './airplane/executor';
import * as appLink from './app-link/executor';
import * as apps from './apps/executor';
import * as battery from './battery/executor';
import * as bluetooth from './bluetooth/executor';
import * as cellular from './cellular/executor';
import * as help from './help/executor';
import * as location from './location/executor';
import * as speedTest from './speed-test/executor';
import * as storage from './storage/executor';
import * as unknown from './unknown/executor';
import * as wifi from './wifi/executor';

export default {
    airplane,
    'app-link': appLink,
    apps,
    battery,
    bluetooth,
    cellular,
    help,
    location,
    'speed-test': speedTest,
    storage,
    unknown,
    wifi,
};
