import React from 'react';
import pRetry from 'p-retry';

const retryConfig: pRetry.Options = {
    retries: 5,
    maxTimeout: 2000,
};

const ExpertTasksView = React.lazy(() =>
    pRetry(() => import(/* webpackChunkName: "ExpertTasksView" */ '@anywhere-expert/expert-tasks-view'), retryConfig)
);

export default ExpertTasksView;
