import {observable, action, runInAction, makeObservable} from 'mobx';
import logger from '@anywhere-expert/logging';
import {TaskItem} from '@anywhere-expert/expert-feed-store';
import TasksStore from './TasksStore';
import {ExpertTasksApi} from '@soluto-private/expert-tasks-api-client';

type LoadingState = 'loading' | 'ready' | 'error';

class TaskItemModel<State = {}, Payload = {}> {
    parent: TasksStore;
    taskQueueItem: TaskItem;
    id: string;
    taskRegistryId: string;
    taskType: string;
    taskDisplayName: string;
    taskSubtype: string;
    private expertTasksClient: ExpertTasksApi;

    loadingState: LoadingState = 'loading';
    isResolveEnabled: boolean;
    payload: Payload;
    private state: State;

    constructor(parent: TasksStore, expertTaskId: string, expertTaskQueueItem: TaskItem) {
        makeObservable<TaskItemModel, 'state' | 'init'>(this, {
            loadingState: observable,
            isResolveEnabled: observable,
            payload: observable,
            state: observable,
            init: action,
            toggleResolveEnabled: action,
            upsertTaskState: action,
        });

        try {
            this.parent = parent;
            this.taskQueueItem = expertTaskQueueItem;
            this.taskRegistryId = expertTaskQueueItem.taskRegistryId;
            this.taskType = expertTaskQueueItem.taskType;
            this.taskSubtype = expertTaskQueueItem.taskSubtype;
            this.taskDisplayName = expertTaskQueueItem.taskDisplayName;

            this.id = expertTaskId;
            this.expertTasksClient = this.parent.expertTasksClient;
            this.init()
                .then(action(() => (this.loadingState = 'ready')))
                .catch(
                    action(() => {
                        this.loadingState = 'error';
                    })
                );
        } catch (err) {
            logger.error('Could not initialize taskItemModel', {err});
        }
    }

    private init = async () => {
        try {
            const result = await this.expertTasksClient.get(this.id);
            runInAction(() => {
                this.payload = result.payload as Payload;
                this.state = result.attributes as State;
            });
        } catch (err) {
            logger.error('Could not initialize TaskItemModel', {
                err,
                extra: {expertTaskId: this.id, status: err.status},
            });
            throw err;
        }
    };

    getTaskState = () => this.state;

    toggleResolveEnabled = (enabled: boolean) => {
        this.isResolveEnabled = enabled;
    };

    upsertTaskState = async (key: string, value: any) => {
        runInAction(() => {
            this.state = {
                ...this.state,
                [key]: value,
            };
        });
        await this.expertTasksClient.upsertAttribute(this.id!, {key, value});
    };
}

export default TaskItemModel;
