import * as React from 'react';
import styled from 'styled-components';
import {CoreText} from '@anywhere-expert/base-ui';

const Container = styled.div`
    justify-content: center;
    margin-top: 32px;
    display: flex;
    align-self: center;
    align-items: center;
`;

const VisibilityIndicator = ({children}) => (
    <div>
        <Container>
            <CoreText size="s" textType="secondary">
                Visible only to you
            </CoreText>
        </Container>
        {children}
    </div>
);

export default VisibilityIndicator;
