export const description: string = 'See cellular data info';
export const CellularSignalStrength = ['No Signal', 'Poor', 'Fair', 'Good', 'Excellent'];
export const noCellularDataWhileUsingWifiMessage = 'Cellular data is not available while customer is on wifi';
export const commandNotExistsForIos = 'Extended features are available for customers with an Android device';
export const generalError = 'Something went wrong, please try again later';
import {triggerCommand} from '../../shared';
import {FeatureSuggestionTexts} from '../../components/FeatureSuggestion/types';
import {UserDetailsType} from '@anywhere-expert/core';
import {AnalyticsDispatcher} from 'shisell';

export const validateSignalStrengthResult = commandResult => {
    return (
        commandResult.signalStrengthLevel &&
        Number.isInteger(commandResult.signalStrengthLevel) &&
        commandResult.connectionType &&
        commandResult.signalStrengthLevel >= 0 &&
        commandResult.signalStrengthLevel <= 4
    );
};

export const isWifiOn = commandResult => !commandResult.hasOwnProperty('network');

export const validateIosCellularInfoResult = commandResult => {
    return (
        commandResult.carrierName &&
        commandResult.carrierName.length > 0 &&
        commandResult.network &&
        commandResult.network.length > 0
    );
};

export const featureSuggestionTexts: FeatureSuggestionTexts = {
    headerText: 'View customer’s cellular info',
    subHeaderText: 'See customer’s reception level, network provider and SIM type',
    buttonText: 'See cellular info',
    otherToolsButtonText: 'Explore other tools',
};

export const onFeatureSuggestionClick = (
    commandMessage: string,
    timelineId: string,
    sessionId: string,
    technicianData: UserDetailsType,
    dispatcher: AnalyticsDispatcher
) => {
    triggerCommand({
        timelineId,
        sessionId,
        expertData: technicianData,
        analyticsDispatcher: dispatcher,
        commandMessage,
        triggeringMethod: 'Suggestion',
    });
};
