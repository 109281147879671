import React from 'react';
import {StyleSheet, css} from 'aphrodite';
import {SystemMessageItem, IconType} from '@anywhere-expert/system-messages';
import SuggestedCommands from '@slash-commands/suggested-commands';
import FeatureSuggestionComponent from '@slash-commands/feature-suggestion';
import {VisualComponentType} from '@slash-commands/command-visual-component';
import {CellTowerIcon} from '@anywhere-expert/icons';
import {getPermissionFailedMessage} from '../../shared/getPermissionFailedMessage';
import {
    getAndroidCellularInfoFormattedMessage,
    getSignalFormattedMessage,
    getIosCellularInfoFormattedMessage,
} from '../getFormattedMessage';
import {
    featureSuggestionTexts,
    onFeatureSuggestionClick,
    noCellularDataWhileUsingWifiMessage,
    validateSignalStrengthResult,
    validateIosCellularInfoResult,
    isWifiOn,
    generalError,
} from '../shared';

const suggestionIcon = require('./assets/ic_suggestion.svg');
const CellularIcon = require('./assets/ic_celltower.svg');

const SignalIcon0 = require('./assets/ic_signal_0.svg');
const SignalIcon1 = require('./assets/ic_signal_1.svg');
const SignalIcon2 = require('./assets/ic_signal_2.svg');
const SignalIcon3 = require('./assets/ic_signal_3.svg');
const SignalIcon4 = require('./assets/ic_signal_4.svg');

export {description} from '../shared';
export const icon: IconType = CellularIcon;
export const iconOutline = CellTowerIcon;

const signalIconsArray = [SignalIcon0, SignalIcon1, SignalIcon2, SignalIcon3, SignalIcon4];

const styles = StyleSheet.create({
    mainContainer: {
        marginBottom: '24px',
        alignSelf: 'center',
        display: 'flex',
        flexShrink: 0,
        alignItems: 'center',
        flexDirection: 'column',
    },
    signalActionMainContainer: {
        marginTop: '30px',
    },
});

const signalCommandNotStandAloneCustomStyles = {
    mainContainer: {
        marginTop: 0,
        marginBottom: 0,
    },
};

const getSignalComponent = (commandResult, contentId, isStandAloneCommand = true) => (
    <SystemMessageItem
        dataTestId={`CellularSignalCommandResult_${contentId}`}
        message={getSignalFormattedMessage(commandResult)}
        icon={signalIconsArray[commandResult.signalStrengthLevel]}
        customStyles={isStandAloneCommand ? undefined : signalCommandNotStandAloneCustomStyles}
    />
);

const cellularInfoCustomStyles = {
    mainContainer: {
        marginTop: 30,
        marginBottom: 0,
        display: 'flex',
        alignSelf: 'center',
        alignItems: 'center',
    },
};

const getAndroidCellularInfoComponent = (commandResult, contentId) => {
    const cellularInfoExtraData = validateSignalStrengthResult(commandResult) ? (
        getSignalComponent(commandResult, contentId, false)
    ) : (
        <SuggestedCommands showCellularSignalSuggestion />
    );
    return (
        <div className={css(styles.mainContainer)}>
            <SystemMessageItem
                dataTestId={`CellularCommandResult_${contentId}`}
                message={getAndroidCellularInfoFormattedMessage(commandResult)}
                icon={CellularIcon}
                customStyles={cellularInfoCustomStyles}
            />
            {cellularInfoExtraData}
        </div>
    );
};

const getAndroidCellularComponent = (commandResult, contentId) => {
    const {action} = commandResult;
    if (!action) {
        return getAndroidCellularInfoComponent(commandResult, contentId);
    }
    if (action === 'signal' && validateSignalStrengthResult(commandResult)) {
        return (
            <div className={css(styles.signalActionMainContainer)}>{getSignalComponent(commandResult, contentId)}</div>
        );
    }
    return <SystemMessageItem message={generalError} />;
};

const getIosCellularComponent = (commandResult, contentId) => {
    if (isWifiOn(commandResult)) {
        return (
            <SystemMessageItem
                dataTestId={`CellularIosNoWifiCommandResult_${contentId}`}
                message={noCellularDataWhileUsingWifiMessage}
            />
        );
    } else if (validateIosCellularInfoResult(commandResult)) {
        return (
            <SystemMessageItem
                dataTestId={`CellularIosCommandResult_${contentId}`}
                message={getIosCellularInfoFormattedMessage(commandResult)}
                icon={CellularIcon}
            />
        );
    } else {
        return <SystemMessageItem message={generalError} />;
    }
};

export const Component: VisualComponentType = ({commandPayload, contentId}) => {
    const commandResult = commandPayload;
    if (commandResult.success === false) {
        const message = commandResult.refusePermission
            ? getPermissionFailedMessage(commandResult.reason)
            : generalError;
        return <SystemMessageItem message={message} />;
    } else if (commandResult.osType === 'android') {
        return getAndroidCellularComponent(commandResult, contentId);
    } else if (commandResult.osType === 'ios') {
        return getIosCellularComponent(commandResult, contentId);
    } else {
        return <SystemMessageItem message={generalError} />;
    }
};

const commandName = 'cellular';
export const Suggestion: VisualComponentType = ({timelineId}) => (
    <FeatureSuggestionComponent
        timelineId={timelineId}
        featureSuggestionTexts={featureSuggestionTexts}
        suggestionIcon={suggestionIcon}
        handleClick={onFeatureSuggestionClick}
        commandName={commandName}
        featureCommand={`/${commandName}`}
        analyticsActionId={'RunCellular'}
    />
);
