import * as React from 'react';
import {CoreButton, CompoundButtonSet, CoreText} from '@anywhere-expert/base-ui';
import styled from 'styled-components';
import {useTheme} from '@anywhere-expert/colors';

const Container = styled.div`
    padding: 1em;
    border-radius: 4px;
    flex: 1;
    background-color: ${({theme}) => theme.colors.neutral.grey.deep};
    flex-direction: column;
    display: flex;
    width: 307px;
    margin-bottom: 24px;
`;

type PrePermissionContent = {
    permission: string;
    prePermissionText: string;
    timeout: string;
};

const parsePrePermissionText = prePermissionText => {
    const startOfBold = prePermissionText.indexOf('*');
    const endOfBold = prePermissionText.lastIndexOf('*');
    return {
        prePermissionTextBefore: prePermissionText.substr(0, startOfBold),
        prePermissionTextBold: prePermissionText.substr(startOfBold + 1, endOfBold - (startOfBold + 1)),
        prePermissionTextAfter: prePermissionText.substr(endOfBold + 1, prePermissionText.length - (endOfBold + 1)),
    };
};

const CommandPrePermissionComponent = ({content: {prePermissionText}}: {content: PrePermissionContent}) => {
    const theme = useTheme();
    const {prePermissionTextBefore, prePermissionTextBold, prePermissionTextAfter} = parsePrePermissionText(
        prePermissionText
    );

    return (
        <Container theme={theme}>
            <CoreText textType="primary" size="m" style={{marginBottom: '10px'}}>
                {prePermissionTextBefore}
                <CoreText textType="primary" size="m" weight="bold">
                    {prePermissionTextBold}
                </CoreText>
                {prePermissionTextAfter}
            </CoreText>
            <CompoundButtonSet size="s">
                <CoreButton text="Skip" variant="outlined" style={{opacity: 0.5}} disabled />
                <CoreButton text="Continue" color="primary" style={{opacity: 0.5}} disabled />
            </CompoundButtonSet>
        </Container>
    );
};

export default CommandPrePermissionComponent;
