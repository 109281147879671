import {openModal} from '@anywhere-expert/base-ui/src/CoreModal';
import FollowUpModal from './FollowUpModal';

export default () => {
    openModal(FollowUpModal, {
        testId: 'FollowUpModal',
        id: 'follow-up-modal',
        name: 'FollowUp',
        analyticsScope: 'Modal_FollowUp',
    });
};
