export const getPermissionFailedMessage = reason => {
    switch (reason) {
        case 'restricted':
        case 'denied': {
            return 'The customer denied the permission request, this feature is unavailable';
        }
        case 'disabled': {
            return 'This feature is currently unavailable';
        }
        case 'timeout': {
            return 'The customer did not respond to the permission request, you can try again';
        }
        case 'skipped': {
            return 'The customer skipped the permission request, you can try again';
        }
        default: {
            return 'Something went wrong, please try again later';
        }
    }
};
