import {useEffect, useState} from 'react';
import {TimelineController} from '@anywhere-expert/timeline-state';

export default function useFlashingBehavior(contentId: string) {
    const shouldFlash = TimelineController.shouldFlashMessage(contentId);
    const [isFlashing, setIsFlashing] = useState<boolean>(false);
    useEffect(
        () => {
            if (shouldFlash) {
                setIsFlashing(true);
                setTimeout(() => setIsFlashing(false), 1000);
            }
        },
        [shouldFlash]
    );

    return isFlashing;
}
