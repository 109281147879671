import React from 'react';
import styled from 'styled-components';
import {CoreText} from '@anywhere-expert/base-ui';

const ListItemContainer = styled.div`
    position: relative;
    background-color: transparent;
    margin: 4px 0px;
    display: flex;
    flex-direction: column;
`;

const ListItem = styled.span<{isEnabled: boolean}>`
    overflow: hidden;
    border: ${({theme}) => `1px solid ${theme.colors.contrast.border}`};
    border-radius: 8px;
    display: flex;
    text-align: left;
    flex: 1;
    padding: 12px 16px;
    cursor: ${({isEnabled}) => isEnabled && 'pointer'};
    &:hover {
        background-color: ${({theme, isEnabled}) => isEnabled && theme.colors.contrast.border};
    }
    &:focus {
        outline: none;
        background-color: ${({theme, isEnabled}) => isEnabled && theme.colors.contrast.disabled};
    }
`;

export default ({onClick, message, index}) => {
    const isTechnicianMessage = message.senderType === 'Expert';
    const textType = isTechnicianMessage ? 'primary' : 'tertiary';

    return (
        <ListItemContainer key={index}>
            <CoreText size="s" weight="medium" textType={textType} data-pii={!isTechnicianMessage}>
                {message.senderName}
            </CoreText>
            <ListItem
                data-test-id={`SimilarSessions_Message_${index}`}
                isEnabled={message.senderType === 'Expert'}
                onClick={() => onClick(message.content)}
            >
                <CoreText size="m" textType={textType} data-pii={!isTechnicianMessage}>
                    {message.content}
                </CoreText>
            </ListItem>
        </ListItemContainer>
    );
};
