import React from 'react';

export const Resolved: React.FunctionComponent<{color?: string}> = React.memo(({color = 'black'}) => {
    return (
        <svg width="180" height="118" viewBox="0 0 180 118" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M82.4338 22.6669C86.6418 22.4655 91.046 22.5182 94.9715 24.2619C98.5644 25.2092 102.157 26.7154 104.886 29.2985C109.044 32.2173 112.419 36.3065 114.46 40.9665C115.751 42.5687 115.636 44.7344 116.641 46.4564C118.106 51.3923 117.687 56.6159 116.878 61.6357C116.639 62.7798 115.202 62.9932 114.338 62.4056C114.01 60.6836 114.836 59.0359 114.707 57.3115C115.43 52.0183 113.953 46.7562 112.378 41.7556C110.133 37.6664 107.165 33.9873 103.534 31.0493C99.4476 28.6005 95.1582 25.9072 90.2465 25.6889C86.5197 25.0126 82.7091 25.4131 78.9822 25.9383C76.0261 26.8689 73.1298 28.0417 70.3771 29.4879C64.8048 33.1982 60.0798 38.4698 57.9782 44.9263C55.2997 51.457 55.8048 58.9208 58.1817 65.4707C59.4168 67.3582 59.5939 69.8502 61.3556 71.3635C62.9928 73.5413 64.7521 75.6734 66.8801 77.3906C68.1104 76.556 69.1253 75.4168 70.4011 74.6589C71.4734 74.3927 71.313 75.7622 71.2293 76.4217C70.717 77.4986 69.6112 78.0574 68.8213 78.8944C72.1484 81.6837 76.354 82.8685 80.4519 84.0413C86.3785 84.8688 92.4559 84.0917 98.0114 81.8276C98.3489 82.5783 98.9019 83.3386 98.8851 84.1756C97.3101 84.9791 95.6226 85.5787 93.9686 86.1951C91.654 86.303 89.4878 87.5526 87.1325 87.2168C85.7896 86.9146 84.4301 87.447 83.0825 87.1952C79.8966 86.255 76.3372 86.55 73.4458 84.7057C67.7944 82.6335 62.7056 78.6978 59.3067 73.678C58.3612 72.7138 58.445 71.1956 57.4253 70.2867C55.3309 67.4014 54.8043 63.7727 53.8181 60.4198C52.9971 55.4791 53.7056 50.4929 54.7109 45.6674C56.7503 39.0335 60.8936 33.0807 66.4325 28.9051C67.1362 28.3487 68.0051 28.0849 68.7734 27.6436C71.1048 25.5882 74.1758 24.7439 77.0314 23.6935C78.8697 23.6095 80.641 23.0507 82.4338 22.6669Z"
                fill={color}
            />
            <path
                d="M91.5367 31.7808C92.4463 31.6896 93.4229 31.6681 94.3037 31.8887C95.7184 32.8385 95.0314 34.5941 94.366 35.7789C94.5216 36.4744 95.5436 37.074 95.0601 37.9206C93.9806 38.5226 93.0279 37.5729 92.5157 36.6998C92.4798 35.7381 92.8293 34.8291 93.1333 33.9345C91.4266 33.3709 89.6625 34.702 88.0373 33.8434C87.975 32.2988 90.3926 32.2269 91.5367 31.7808Z"
                fill={color}
            />
            <path
                d="M83.8915 32.5243C84.5258 32.5363 85.9237 32.0926 85.8399 33.1023C86.0481 33.9153 85.1864 34.3374 84.5186 34.4094C82.5965 34.6564 80.9808 35.7789 79.4418 36.8557C78.8793 36.5799 78.8888 35.7357 79.279 35.3208C80.5955 34.0904 82.0652 32.8576 83.8915 32.5243Z"
                fill={color}
            />
            <path
                d="M76.7466 38.0573C77.4 37.9494 77.3282 38.9615 77.0362 39.3189C76.0165 40.7123 74.9657 42.3168 75.2194 44.13C75.2769 44.9191 74.1184 45.3028 73.7761 44.5641C72.9551 42.065 74.329 39.0766 76.7466 38.0573Z"
                fill={color}
            />
            <path
                d="M95.5484 39.4004C96.9606 39.6954 97.3652 41.427 97.5949 42.667C97.6404 43.3649 97.875 44.6193 96.9128 44.7632C95.3426 44.0053 95.7303 41.9859 95.1822 40.6068C94.9428 40.1895 94.8734 39.3764 95.5484 39.4004Z"
                fill={color}
            />
            <path
                d="M74.0848 45.7201C75.4325 45.6266 75.5258 47.4709 75.5809 48.4495C75.746 49.6295 74.4439 49.9773 73.8215 50.704C73.3189 51.5842 73.5032 52.6466 73.3668 53.6108C72.4931 54.2799 71.708 53.1095 71.8348 52.2917C71.7295 50.7951 72.7205 49.5935 73.5008 48.4303C73.6396 47.5741 73.0484 46.1303 74.0848 45.7201Z"
                fill={color}
            />
            <path
                d="M96.5752 46.43C98.3154 45.7825 97.8223 48.1305 98.6146 48.9244C99.6893 50.102 100.348 52.5459 98.8659 53.6036C97.0564 53.3326 98.6194 50.4761 96.9558 49.9988C95.6298 49.44 95.9002 47.387 96.5752 46.43Z"
                fill={color}
            />
            <path
                d="M97.2862 54.7836C97.6548 54.2463 98.6505 54.6421 98.5356 55.2848C98.7487 56.8509 97.3532 58.1581 95.9314 58.4842C94.9715 57.2035 96.7165 55.894 97.2862 54.7836Z"
                fill={color}
            />
            <path
                d="M72.6104 55.6902C74.1064 55.0522 74.8699 57.3714 75.7939 58.2756C74.8843 60.7411 71.2628 57.1795 72.6104 55.6902Z"
                fill={color}
            />
            <path
                d="M94.3659 59.2973C94.9739 59.1966 95.3282 59.5587 95.2085 60.1703C94.8112 61.5926 93.5066 62.4919 92.3888 63.3386C92.183 64.1516 92.396 65.063 92.0106 65.8497C89.9928 66.2406 89.9856 63.1155 90.7588 61.9283C91.879 60.993 92.9082 59.6283 94.3659 59.2973Z"
                fill={color}
            />
            <path
                d="M76.2104 60.7675C77.0601 60.0384 77.8668 61.18 78.3192 61.8299C79.0277 63.0483 80.8253 62.9476 81.4428 64.2235C81.7396 65.7681 81.4979 67.6844 80.1958 68.7037C78.2761 68.754 79.717 66.2669 79.3269 65.1901C78.3024 64.7631 77.108 64.3194 76.7394 63.1466C76.4785 62.4151 75.5402 61.5038 76.2104 60.7675Z"
                fill={color}
            />
            <path
                d="M111.1 64.3195C113.086 63.6192 113.034 65.6338 112.881 67.0009C111.61 67.4134 110.219 67.2599 109.022 67.9219C106.475 68.4951 104.903 70.764 103.565 72.8218C102.222 75.5559 102.588 78.964 104.106 81.5567C105.906 83.8735 108.551 85.7658 111.552 85.9073C116.28 85.7802 120.851 81.8828 120.966 76.971C120.794 75.6351 122.314 75.3113 123.338 75.484C124.344 80.9427 120.454 86.6148 115.152 87.9795C110.157 89.6656 104.012 87.4734 101.542 82.7295C100.41 80.4198 99.5529 77.9423 99.9072 75.3257C98.9976 74.834 97.5256 74.1289 97.796 72.769C98.4926 72.1143 99.45 72.9057 100.17 73.1647C101.903 68.5527 106.214 64.9887 111.1 64.3195Z"
                fill={color}
            />
            <path
                d="M123.81 64.8136C124.6 64.852 125.234 65.9744 125.473 66.634C122.113 70.4833 118.465 74.0881 115.202 78.0167C114.357 79.0599 113.259 79.8346 112.222 80.6669C110.312 79.9018 108.79 78.3092 107.402 76.8151C106.578 75.9516 107.591 74.9779 108.299 74.4766C109.932 74.2416 110.635 76.1867 112.002 76.6951C114.726 74.6877 116.732 71.8624 119.19 69.5528C120.729 67.9866 121.936 66.0008 123.81 64.8136Z"
                fill={color}
            />
            <path
                d="M91.9676 68.4184C92.9537 67.9818 93.7484 69.1259 94.5838 69.5168C95.4287 70.09 97.1043 70.6848 96.5298 71.9967C94.8543 72.8386 93.4061 71.1813 92.2692 70.1596C91.6899 69.8358 91.5224 68.8237 91.9676 68.4184Z"
                fill={color}
            />
            <path
                d="M77.1415 70.3466C77.9745 70.0852 78.6471 71.5147 77.7327 71.884C76.4138 72.6131 75.3774 73.7595 74.0513 74.4551C73.3117 74.8796 72.9574 73.9082 72.8689 73.3494C73.9173 71.9128 75.6048 71.1429 77.1415 70.3466Z"
                fill={color}
            />
            <path
                d="M128.152 83.8351C129.267 83.5161 130.457 83.9311 131.505 84.3268C132.032 84.4659 132.266 85.1494 131.826 85.5164C130.495 86.0896 129.112 85.2981 127.869 84.852C127.817 84.5019 127.723 83.979 128.152 83.8351Z"
                fill={color}
            />
            <path
                d="M121.981 90.0565C122.357 90.0613 122.836 89.9702 123.061 90.3635C124.037 91.4667 125.148 92.51 125.806 93.8507C126.081 94.3592 126.115 95.2922 125.411 95.4241C123.913 94.3712 123.042 92.7235 121.883 91.3516C121.545 90.9631 121.849 90.4618 121.981 90.0565Z"
                fill={color}
            />
            <path
                d="M100.163 90.7568C100.778 90.0924 101.149 90.9462 100.908 91.5266C100.328 93.0016 99.383 94.3831 98.1527 95.3808C97.4633 95.1578 97.0157 94.4982 97.4777 93.8267C98.246 92.7138 99.1293 91.637 100.163 90.7568Z"
                fill={color}
            />
            <path
                d="M106.822 93.6205C107.722 93.5365 107.653 94.846 107.495 95.448C107.021 97.1005 106.942 98.9712 105.8 100.343C105.049 100.379 104.34 99.8874 104.675 99.0648C105.128 97.2276 105.396 94.8916 106.822 93.6205Z"
                fill={color}
            />
            <path
                d="M114.98 93.6228C115.298 93.6636 115.76 93.5029 115.952 93.8531C116.859 95.153 117.467 96.7503 117.376 98.3548C116.741 98.8441 115.959 98.3812 115.82 97.6617C115.511 96.3282 114.848 95.0235 114.98 93.6228Z"
                fill={color}
            />
        </svg>
    );
});
