import React, {useCallback, useState, useEffect} from 'react';
import {observer} from 'mobx-react';
import {CoreText, CoreTag, getScrollerStyle} from '@anywhere-expert/base-ui';
import styled from 'styled-components';
import Feedback from './Feedback/Feedback';
import {palette} from '@anywhere-expert/colors';
import {TimelineInputStore} from '@anywhere-expert/conversation-input-section';
import {useAnalytics, enrichAnalytics} from 'react-shisell';
import Message from './Message';
import {useScrollEvents} from './behaviors';
import {SessionTranscriptData} from '../../SessionTranscript/types';
import {FeedbackStep} from '@anywhere-expert/expert-feed-store';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-top: 8px;
    overflow: hidden;
`;

const TagsContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 8px;
`;

const Title = styled(CoreText)`
    margin-top: 12px;
`;

const Divider = styled.div`
    background-color: ${palette.neutral.grey.light};
    height: 1px;
    width: 100%;
    margin-top: 15px;
`;

const MessagesContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 8px;
    width: 100%;
    align-self: center;
    flex: 1;
    overflow: auto;
    ${({theme}) => getScrollerStyle(theme)};
`;

type Props = {
    timelineId: string;
    setFeedback: (value) => void;
    setReason: (reason) => void;
    transcriptId: string;
    sessionTranscriptData: SessionTranscriptData;
    feedbackStep: FeedbackStep;
    clearSelectedFeedback: () => void;
    similarityScore: number;
};

const expertNameForFeedback = (session: SessionTranscriptData) => {
    if (!!session.expertName) {
        const expertName = session.expertName;
        return expertName.split(' ')[0];
    }

    return 'this expert';
};

const Transcript = observer(
    ({
        timelineId,
        setFeedback,
        setReason,
        transcriptId,
        sessionTranscriptData,
        feedbackStep = FeedbackStep.QUESTION,
        clearSelectedFeedback,
    }: Props) => {
        const analytics = useAnalytics();
        const [feedbackOpen, setFeedbackOpen] = useState(true);
        const setTextCallback = useCallback(
            (text: string) => {
                analytics.dispatcher.withExtra('text', text).dispatch('Click');
                TimelineInputStore.setEditorText(timelineId, text);
            },
            [timelineId, analytics]
        );

        const onClose = useCallback(() => setFeedbackOpen(false), [setFeedbackOpen]);

        useEffect(() => {
            setFeedbackOpen(true);
        }, [transcriptId]);

        const {onScroll, scrollerRef} = useScrollEvents(analytics, transcriptId);

        return (
            <Container>
                <Title size="m" textType="primary" weight="bold" data-test-id="SimilarSessions_Tab_Title">
                    {sessionTranscriptData.question}
                </Title>
                <TagsContainer>
                    {sessionTranscriptData.devices?.map((device: string) => (
                        <CoreTag key={device} text={device} />
                    ))}
                </TagsContainer>
                <Divider />
                <MessagesContainer ref={scrollerRef} onScroll={onScroll}>
                    {sessionTranscriptData.messages.map((message, index) => (
                        <Message onClick={setTextCallback} message={message} index={index} key={index} />
                    ))}
                </MessagesContainer>
                {feedbackOpen && (
                    <Feedback
                        expertName={expertNameForFeedback(sessionTranscriptData)}
                        onClose={onClose}
                        feedbackStep={feedbackStep}
                        setFeedback={setFeedback}
                        setReason={setReason}
                        clearSelectedFeedback={clearSelectedFeedback}
                    />
                )}
            </Container>
        );
    }
);

export default enrichAnalytics<Props>((dispatcher, props) =>
    dispatcher
        .withExtra('SimilarSessionId', props.transcriptId)
        .withExtra('SimilarExpertId', props.sessionTranscriptData.expertId)
        .withExtra('SimilarityScore', props.similarityScore)
        .withExtra('SessionDate', props.sessionTranscriptData.sessionDate)
)(Transcript);
