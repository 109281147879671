import React from 'react';
import SuggestedCommands from '@slash-commands/suggested-commands';
import {useTweekValue} from 'react-tweek';
import {SuggestedComponentProps} from '../../components/SuggestedCommands/types';

const WifiSuggestions = ({
    showWifiConnectSuggestion,
    ...other
}: SuggestedComponentProps & {showWifiConnectSuggestion?: boolean}) => {
    const wifiV1IsEnabled = useTweekValue('support/session/messaging/tools/wifi_v1/is_enabled', false);
    const wifiV2IsEnabled = useTweekValue('support/session/messaging/tools/wifi_v2/is_enabled', false);

    if (!wifiV1IsEnabled) {
        return null;
    }
    const showConnect = showWifiConnectSuggestion && wifiV2IsEnabled;
    return <SuggestedCommands showWifiConnectSuggestion={showConnect} {...other} />;
};

export default WifiSuggestions;
