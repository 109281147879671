export const name = 'wifi';
import logger from '@anywhere-expert/logging';

const formatIosMessage = commandResult => {
    if (commandResult.type === 'wifi') {
        return 'Customer’s WiFi is On & internet access is available';
    } else if (commandResult.type === 'cellular') {
        return 'Customer’s internet access is available via cellular';
    } else {
        logger.warn('iOS wifi command received bad object', {extra: commandResult});
        return 'Unavailable data';
    }
};

const formatAndroidMessage = commandResult => {
    if (commandResult.error) {
        return 'Command failed - ' + commandResult.error;
    }

    if (!commandResult.action || commandResult.action === 'status') {
        let message = "Customer's WiFi is";
        if (commandResult.isEnabled) {
            if (commandResult.isConnectedToNetwork) {
                if (commandResult.hasInternetAccess) {
                    message += ' On & internet access is available';
                } else {
                    message += ' On & connected, no internet access';
                }
            } else {
                message += ' On but not connected to a network';
            }
        } else {
            message += ' Off';
        }
        return message;
    }
};

export default commandResult => {
    if (Array.isArray(commandResult) && commandResult[0] && commandResult[0].type !== undefined) {
        return formatIosMessage(commandResult[0]);
    } else if (commandResult.type !== undefined) {
        return formatIosMessage(commandResult);
    } else if (commandResult.osType === 'android' || commandResult.isEnabled !== undefined) {
        return formatAndroidMessage(commandResult);
    } else {
        logger.warn('wifi command received bad object', {extra: commandResult});
        return 'Something went wrong, please try again later';
    }
};
