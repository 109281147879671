import axios from 'axios';
import logger from '@anywhere-expert/logging';
import {getFromTweek} from '@anywhere-expert/tweek';
import {useAsync} from 'react-async-hook';

const getStringHash = (str: string) => str.split('').reduce((accumulator, char) => accumulator + char.charCodeAt(0), 0);

const getRandomAppIcon = (packageName: string): string => {
    const appRandom = (getStringHash(packageName) % 4) + 1;
    return require(`./appIcons/app-${appRandom}.svg`);
};

const getAppIconFromApi = async (packageName: string) => {
    const {data: content} = await axios.get(`https://appstoresapi-production.mysoluto.com/appicon/${packageName}`);
    return content.appIconLink;
};

const getAppIcon = async (packageName: string) => {
    const is_app_icon_enabled = await getFromTweek('support/session/messaging/tools/apps_list/is_app_icon_enabled');
    if (is_app_icon_enabled) {
        try {
            const iconName = `app_icon_${packageName}`;
            const iconFromStorage = sessionStorage.getItem(iconName);

            if (iconFromStorage !== null) {
                return iconFromStorage;
            }

            const iconFromApi = await getAppIconFromApi(packageName);
            if (iconFromApi) {
                sessionStorage.setItem(iconName, iconFromApi);
                return iconFromApi;
            }
        } catch (err) {
            if (err.response && err.response.status !== 404) {
                logger.warn('Failed getting app icon for apps installed tool', {err, extra: {packageName}});
            }
        }
    }

    return getRandomAppIcon(packageName);
};

const useAppIcon = (packageName: string) => {
    const getAppIconTask = useAsync(getAppIcon, [packageName]);

    if (getAppIconTask.result) {
        return getAppIconTask.result;
    }

    return require(`./appIcons/empty-app.svg`);
};

export default useAppIcon;
