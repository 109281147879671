import * as queryString from 'query-string';
import {useCallback, useState, useMemo, useEffect} from 'react';
import {useAnalytics} from 'react-shisell';
import {AnalyticsDispatcher} from 'shisell';
import {SessionTranscriptData} from './types';

const enrichDispatcher = (dispatcher: AnalyticsDispatcher, sessionId: string, expertId: string, carrier: string) => {
    const queryParams = queryString.parse(location.search);

    let enrichedDispatcher = dispatcher
        .createScoped('Session_SimilarSessions')
        .withExtra('SimilarSessionId', sessionId)
        .withExtra('SimilarExpertId', expertId)
        .withExtra('CarrierName', carrier);
    if (queryParams.origin) {
        enrichedDispatcher = enrichedDispatcher.withExtra('OriginSessionId', queryParams.origin);
    }
    if (queryParams.similarity) {
        enrichedDispatcher = enrichedDispatcher.withExtra('SimilarityScore', queryParams.similarity);
    }

    return enrichedDispatcher;
};

export const useSendScrollAnaltic = (sessionId: string, transcript: SessionTranscriptData | null) => {
    const analytics = useAnalytics();
    const pageLoadTimestamp = useMemo(() => Date.now(), []);
    const [didScrollAnalyticSent, setDidScrollAnalyticsSent] = useState(false);

    return useCallback(() => {
        if (didScrollAnalyticSent || !transcript) return;

        const elaspedTime = (Date.now() - pageLoadTimestamp) / 1000;
        enrichDispatcher(analytics.dispatcher, sessionId, transcript.expertId, transcript.carrierName)
            .withExtra('TimeUntilScroll', elaspedTime)
            .dispatch('Scroll');

        setDidScrollAnalyticsSent(true);
    }, [sessionId, didScrollAnalyticSent, pageLoadTimestamp, transcript]);
};

export const useSendFeedbackResult = (
    sessionId: string,
    transcript: SessionTranscriptData | null
): [boolean, (didHelp: boolean) => () => void] => {
    const analytics = useAnalytics();
    const [didFeedbackSent, setDidFeedbackSent] = useState(false);

    const sendFeedbackResult = useCallback(
        (didHelp: boolean) => () => {
            if (!transcript) return;

            enrichDispatcher(analytics.dispatcher, sessionId, transcript.expertId, transcript.carrierName)
                .withExtra('IsRelevant', didHelp)
                .dispatch('Feedback');

            setDidFeedbackSent(true);
        },
        [sessionId, transcript]
    );

    return [didFeedbackSent, sendFeedbackResult];
};

export const useSendTimeOnPageAnalytic = (sessionId: string, transcript: SessionTranscriptData | null) => {
    const analytics = useAnalytics();
    const pageLoadTimestamp = useMemo(() => Date.now(), [transcript]);

    useEffect(() => {
        if (!transcript) return;

        const interval = setInterval(() => {
            const elaspedTime = (Date.now() - pageLoadTimestamp) / 1000;
            if (elaspedTime > 60) clearInterval(interval);

            enrichDispatcher(analytics.dispatcher, sessionId, transcript.expertId, transcript.carrierName)
                .withExtra('ElaspedTime', elaspedTime)
                .dispatch('TimeOnPage');
        }, 2000);

        return () => clearInterval(interval);
    }, [sessionId, transcript]);
};
