import React from 'react';
import {AcceptConversationBar} from '@anywhere-expert/accept-conversation';
import ConversationUnavailableBar from './conversation-unavailable-bar/ConversationUnavailableBar';
import ConversationOverlayBarContainer from './conversation-unavailable-bar/ConversationOverlayBarContainer';

type ConversationBarProps = {
    isAvailable: boolean;
    unavailableMessage?: string;
};

export default ({isAvailable, unavailableMessage}: ConversationBarProps) => (
    <ConversationOverlayBarContainer>
        {isAvailable ? <AcceptConversationBar /> : <ConversationUnavailableBar message={unavailableMessage} />}
    </ConversationOverlayBarContainer>
);
