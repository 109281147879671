import * as React from 'react';
import {default as moment} from 'moment';
import logger from '@anywhere-expert/logging';
import {TimelineItem} from '@anywhere-expert/timeline-api';
import timelineItemTypeToComponent from './timelineitemTypeToComponent';
import {EnhancedMessage, MessageStatus, transformMessageToTimelineItem} from '@anywhere-expert/messaging-api';
import {Message} from '@soluto-private/messaging-api-client';
import {useMemo} from 'react';

export type RendererExtraProps = {
    timelineItemId: string;
    isSelf: boolean;
    isGroupedItem: boolean;
    showThumbnail: boolean;
    showTimestamp: boolean;
    timestampValue?: string;
    seenAtValue?: number;
    seenAtText?: string;
    sessionId: string;
    timelineId: string;
    message: Message;
};

export type TimelineItemProps<T = any> = TimelineItem<T> & RendererExtraProps;

const getSender = (message: EnhancedMessage) =>
    typeof message.metadata.senderName === 'undefined'
        ? message.metadata.senderType
        : `${message.metadata.senderType}_${message.metadata.senderName}`;

const sameMinute = (prev: EnhancedMessage, current: EnhancedMessage) => {
    const prevTimestamp = moment(prev.createdAt);
    const currentTimestamp = moment(current.createdAt);
    return prevTimestamp.isSame(currentTimestamp, 'minute');
};

const shouldGroupItems = (message1: EnhancedMessage, message2?: EnhancedMessage) => {
    if (!message2) return false;
    return getSender(message1) === getSender(message2);
};

const shouldShowTimestamp = (message1: EnhancedMessage, message2?: EnhancedMessage) =>
    message1.status !== MessageStatus.Pending &&
    !(message2 && getSender(message1) === getSender(message2) && sameMinute(message1, message2));

const getSeenTime = seenAt => Math.max(...Object.keys(seenAt).map(reader => seenAt[reader]));

const getTimestampText = (seenTimestamp: moment.MomentInput, sentTimestamp: moment.MomentInput) => {
    const readDay = moment(seenTimestamp).startOf('day');
    const sendDay = moment(sentTimestamp).startOf('day');

    const daysAgo = readDay.diff(sendDay, 'days');

    const readMoment = moment(seenTimestamp);
    if (daysAgo === 0) {
        return readMoment.format('h:mm A');
    }

    return readMoment.format('MMMM DD');
};

const Element = ({message, ...props}: RendererExtraProps) => {
    const item = useMemo(() => transformMessageToTimelineItem(message), [message]);

    if (!item) {
        return null;
    }

    const Component = timelineItemTypeToComponent(item);
    return Component ? <Component {...props} {...item} testID={item.contentId} /> : null;
};

type RenderTimelineItemOptions = {
    message: EnhancedMessage;
    prevMessage?: EnhancedMessage;
    nextMessage?: EnhancedMessage;
    sessionId: string;
    timelineId: string;
    showThumbnailDecider?: (isSelf: boolean, isGrouped: boolean) => boolean;
    shouldShowSeen?: boolean;
};

export const renderMessage = ({
    message,
    prevMessage,
    nextMessage,
    sessionId,
    timelineId,
    shouldShowSeen = false,
}: RenderTimelineItemOptions) => {
    const key = `${message.payload.type}_${message.messageId}`;
    const isGroupedItem = shouldGroupItems(message, prevMessage);
    const isSelf = message.metadata.senderType === 'Technician' || message.metadata.senderType === 'Team';
    const showThumbnail = !isGroupedItem;
    const showTimestamp = shouldShowTimestamp(message, nextMessage);
    const timestampValue = showTimestamp ? moment(message.createdAt).format('h:mm A') : undefined;
    const seenAtValue = shouldShowSeen && message.seenAt ? getSeenTime(message.seenAt) : undefined;
    const seenAtText = seenAtValue ? getTimestampText(seenAtValue, message.createdAt) : undefined;

    if (shouldShowSeen && !message.seenAt) {
        const {payload, ...otherMessageData} = message;
        logger.warn('shouldShowSeen = true && itemData.seenAt is undefined', {extra: otherMessageData});
    }

    return (
        <Element
            key={key}
            timelineItemId={key}
            message={message}
            isSelf={isSelf}
            isGroupedItem={isGroupedItem}
            showThumbnail={showThumbnail}
            showTimestamp={showTimestamp}
            timestampValue={timestampValue}
            seenAtValue={seenAtValue}
            seenAtText={seenAtText}
            sessionId={sessionId}
            timelineId={timelineId}
        />
    );
};
