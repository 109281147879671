import * as React from 'react';
import styled from 'styled-components';
const cardHeight = 120;

const Container = styled.div`
    position: relative;
    color: white;
    width: ${cardHeight * 2}px;
    height: ${cardHeight}px;
    background-color: #212121;
    border-radius: 8px;
`;
const Background = styled.img`
    border-radius: 8px;
    position: relative;
    width: 100%;
    height: 100%;
    opacity: 0.5;
`;
const Text = styled.div`
    top: 15px;
    left: 15px;
    margin-right: 15px;
    border-radius: 4px;
    position: absolute;
    background-color: rgba(52, 52, 52, 0);
    color: white;
    letter-spacing: 0.4px;
    line-height: 20px;
    font-size: 14px;
    font-weight: 200;
`;

const isUrl = str => typeof str === 'string' && str.includes('http');

const Card = props => {
    const {background, title} = props.content;
    return (
        <Container>
            {isUrl(background) && <Background src={background} />}
            <Text>{title}</Text>
        </Container>
    );
};

export default Card;
