import styled from 'styled-components';
import {palette} from '@anywhere-expert/colors';
import {getBorderStyle} from '@anywhere-expert/base-ui';

export default styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    font-size: 14px;
    font-weight: bold;
    font-family: Roboto;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.11);
    border-radius: 8px;
    max-height: 68px;
    min-height: 68px;
    background-color: ${({theme}) => theme.colors.neutral.white};
    color: ${palette.neutral.grey.deepest};
    ${({theme}) => getBorderStyle(theme)};
`;
