import React, {memo, useState, useCallback} from 'react';
import styled from 'styled-components';
import {CoreInputText, CoreButton} from '@anywhere-expert/base-ui';
import {onAddTag} from './types';
import {palette} from '@anywhere-expert/colors';

const Colors = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const Color = styled.div<{backgroundColor: string}>`
    background-color: ${({backgroundColor}) => backgroundColor};
    width: 16px;
    height: 16px;
    border-radius: 18px;
    cursor: pointer;
`;

const ColorContainer = styled.div<{isChosen: boolean}>`
    ${({isChosen, theme}) => isChosen && `border: 1px solid ${theme.colors.neutral.black};`}
    border-radius: 18px;
    width: 22px;
    height: 22px;
    align-items: center;
    justify-content: center;
    display: flex;
    box-sizing: border-box;
    margin: 0 2px 2px 0;
    &:hover {
        ${({isChosen}) => !isChosen && `opacity: 0.8;`}
    }
`;

const StyledCoreInputText = styled(CoreInputText)`
    border: 1px solid ${({theme}) => theme.colors.contrast.caption};
    box-sizing: border-box;
    border-radius: 8px;
    padding: 10px 0 10px 12px;
    width: 100%;
    margin-bottom: 8px;
    &::placeholder {
        font-weight: normal;
        color: ${({theme}) => theme.colors.contrast.caption};
    }
`;

const ButtonContainer = styled.div`
    margin-top: 8px;
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
`;

const AddTagButton = styled(CoreButton)`
    text-transform: none;
`;

type Props = {onAddTag: onAddTag};

const DefaultColors = Object.values(palette.categorical);

const CreateTagForm = memo(({onAddTag}: Props) => {
    const [chosenColor, setChosenColor] = useState(DefaultColors[0]);
    const [inputValue, setInputValue] = useState('');
    const onAddTagCallback = useCallback(() => {
        onAddTag({
            tagKey: inputValue,
            backgroundColor: chosenColor,
            source: 'Custom',
        });
        setInputValue('');
    }, [onAddTag, inputValue, chosenColor, setInputValue]);

    return (
        <>
            <StyledCoreInputText
                size="m"
                autoComplete="off"
                value={inputValue}
                onChange={e => setInputValue(e.target.value)}
                placeholder="Tag name ..."
                isTransparent
                maxLength={20}
            />
            <Colors>
                {DefaultColors.map(color => (
                    <ColorContainer key={color} isChosen={chosenColor === color}>
                        <Color key={color} backgroundColor={color} onClick={() => setChosenColor(color)} />
                    </ColorContainer>
                ))}
            </Colors>
            <ButtonContainer>
                <AddTagButton
                    color="secondary"
                    text="Add Tag"
                    variant="text"
                    disabled={!inputValue}
                    onClick={onAddTagCallback}
                />
            </ButtonContainer>
        </>
    );
});

export default CreateTagForm;
