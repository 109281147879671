import * as React from 'react';
import {StyleSheet, css} from 'aphrodite';
import {SuggestedComponentProps} from './types';
import SuggestionComponent from '@slash-commands/suggestion-component';

const wifiStatusIcon = require('./assets/web/ic_status.svg');
const wifiToggleIcon = require('./assets/web/ic_toggle.svg');
const wifiScanIcon = require('./assets/web/ic_scan.svg');
const wifiConnectIcon = require('./assets/web/ic_connect.svg');
const cellularSignalIcon = require('../../commands/cellular/web/assets/ic_signal_4.svg');

const styles = StyleSheet.create({
    suggestedCommandsContainer: {
        marginTop: '10px',
        marginBottom: '15px',
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    suggestedCommands: {
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    suggestedCommandsHeader: {
        alignSelf: 'center',
        textAlign: 'center',
        fontSize: '12px',
        fontFamily: 'Roboto',
        lineHeight: '1.54',
        color: '#bdbdbd',
        fontStyle: 'italic',
    },
    suggestedCommandContainer: {
        display: 'flex',
        marginTop: '5px',
    },
    suggestedCommand: {
        textAlign: 'center',
        fontSize: '13px',
        fontFamily: 'Roboto',
        lineHeight: 1.54,
    },
    bold: {
        fontWeight: 500,
    },
    italic: {
        fontStyle: 'italic',
    },
    note: {},
    icon: {
        marginRight: '10px',
        width: '20px',
        height: '20px',
    },
});

export default ({
    showWifiStatusSuggestion,
    showWifiToggleSuggestion,
    showWifiScanSuggestion,
    showWifiConnectSuggestion,
    showCellularSignalSuggestion,
    headerText,
}: SuggestedComponentProps) => {
    let statusSuggestion, toggleSuggestion, scanSuggestion, connectSuggestion, cellularSignal;

    if (showCellularSignalSuggestion) {
        cellularSignal = (
            <SuggestionComponent
                icon={cellularSignalIcon}
                commandText={'/cellular signal'}
                commandNoteText={' - See signal strength'}
            />
        );
    }
    if (showWifiStatusSuggestion === true) {
        statusSuggestion = (
            <SuggestionComponent
                icon={wifiStatusIcon}
                commandText={'/wifi'}
                commandNoteText={' - Check Wi-Fi status and internet connectivity'}
            />
        );
    }

    if (showWifiToggleSuggestion === true) {
        toggleSuggestion = (
            <SuggestionComponent
                icon={wifiToggleIcon}
                commandText={'/wifi [enable/disable]'}
                commandNoteText={` - Toggle customer's Wi-Fi setting`}
            />
        );
    }

    if (showWifiScanSuggestion === true) {
        scanSuggestion = (
            <SuggestionComponent
                icon={wifiScanIcon}
                commandText={'/wifi scan'}
                commandNoteText={` - Scan for available networks`}
            />
        );
    }

    if (showWifiConnectSuggestion === true) {
        connectSuggestion = (
            <SuggestionComponent
                icon={wifiConnectIcon}
                commandText={'/wifi connect [network name]'}
                commandNoteText={` - Connect to an available network`}
            />
        );
    }

    return (
        <div className={css(styles.suggestedCommandsContainer)}>
            <div className={css(styles.suggestedCommandsHeader)}>{headerText || `Available commands:`}</div>
            <div className={css(styles.suggestedCommands)}>
                {statusSuggestion}
                {toggleSuggestion}
                {scanSuggestion}
                {connectSuggestion}
                {cellularSignal}
            </div>
        </div>
    );
};
