import React from 'react';
import {observer} from 'mobx-react';
import {useTweekValue} from 'react-tweek';
import {InputSection} from '@anywhere-expert/conversation-input-section';
import {ExpertFeedStore} from '@anywhere-expert/expert-feed-store';
import {TimelineModel} from '@anywhere-expert/timeline-state';
import ConversationOverlayBar from './ConversationOverlayBar';
import SnoozedFooter from './SnoozedFooter';
import ProactiveFooter from './ProactiveFooter';
import styled from 'styled-components';
import IsTypingBar from './IsTypingBar';
import {CoreTooltip} from '@anywhere-expert/base-ui';
import {LoggingErrorBoundary} from '@anywhere-expert/logging';
import ConversationClosedBar from './conversation-unavailable-bar/ConversationClosedBar';
import {AutopilotFooter} from '@anywhere-expert/autopilot';
import ConversationOverlayBarContainer from './conversation-unavailable-bar/ConversationOverlayBarContainer';
import {RecommendationsView} from '@anywhere-expert/recommendations';
import type {SupportItem} from '@anywhere-expert/expert-feed-store';
import ExpertAlertBar from './ExpertAlertBar';
import FollowUpFooter from './FollowUpFooter';
const Container = styled.div`
    background: ${({theme}) => theme.colors.background.primary};
    padding: 16px 0px 32px;
`;

const DisabledTooltipArea = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    z-index: 100;
`;

type Props = {
    sessionId: string;
    editable: boolean;
    isSnoozed: boolean;
    disabledReasonMessage?: string;
    selectedSession: SupportItem;
    timelineModel: TimelineModel;
};

const FooterComponent = observer(
    ({isSnoozed, sessionId, disabledReasonMessage, selectedSession, timelineModel, editable}: Props) => {
        const isTyping = timelineModel.isTyping;
        const closedConversationBarIsEnabled = useTweekValue<boolean>(
            'support/session/messaging/closed_conversation_bar/is_enabled',
            false
        );
        const acceptConversationBarIsEnabled = useTweekValue<boolean>(
            'support/session/messaging/accept_conversation_bar/is_enabled',
            false
        );

        const showUnavailable = !selectedSession.inFeed;

        const showAvailable = acceptConversationBarIsEnabled && !selectedSession.isAssigned;
        const proactiveData = selectedSession.proactiveSessionData;
        const isFollowUpOngoing = selectedSession.isFollowUpOngoing;
        const showRecommendations =
            timelineModel.isRecommendationsEnabled &&
            !!timelineModel.recommendationsStore?.recommendations.length &&
            !timelineModel.isRecommendationsHidden;

        if (selectedSession.isAutopilot) {
            return (
                <ConversationOverlayBarContainer>
                    <AutopilotFooter sessionId={sessionId} selectedSession={selectedSession} />
                </ConversationOverlayBarContainer>
            );
        }

        if (isSnoozed) {
            return <SnoozedFooter sessionId={sessionId} />;
        }

        if (isFollowUpOngoing && !selectedSession.isAssignedToMe) {
            return <FollowUpFooter sessionId={sessionId} />;
        }

        if (proactiveData && !selectedSession.isAssignedToMe) {
            return <ProactiveFooter proactiveData={proactiveData} sessionId={sessionId} />;
        }

        if (selectedSession.isInStore) {
            if (selectedSession.isCancelled) {
                return <ExpertAlertBar textKey={'customer_cancelled_session'} />;
            }

            if (selectedSession.isTimedOut) {
                return <ExpertAlertBar textKey={'session_timed_out'} />;
            }

            if (selectedSession.isStationAudioOnly) {
                return <ExpertAlertBar textKey={'station_audio_only'} />;
            }

            if (selectedSession.isStationReconnecting) {
                return <ExpertAlertBar textKey={'station_reconnecting'} />;
            }
        }

        if (showUnavailable) {
            return <ConversationOverlayBar isAvailable={false} />;
        }

        if (!selectedSession.isAssigned && ExpertFeedStore.isAutoAssignMethod) {
            return <ConversationOverlayBar isAvailable={false} />;
        }

        if (showAvailable) {
            return <ConversationOverlayBar isAvailable={true} />;
        }

        if (closedConversationBarIsEnabled && selectedSession.isClosed) {
            return <ConversationClosedBar />;
        }

        return (
            <>
                {showRecommendations && (
                    <LoggingErrorBoundary boundaryName="RecommendationsView">
                        <RecommendationsView
                            store={timelineModel.recommendationsStore!}
                            sessionId={timelineModel.supportItem.sessionId}
                        />
                    </LoggingErrorBoundary>
                )}
                <LoggingErrorBoundary boundaryName="InputSection">
                    <InputSection
                        editable={editable}
                        isAssignedToMe={selectedSession.isAssignedToMe}
                        selectedSession={selectedSession}
                    />
                </LoggingErrorBoundary>
                {!editable && (
                    <CoreTooltip
                        text={disabledReasonMessage || ''}
                        position="top-start"
                        disabled={!disabledReasonMessage}
                    >
                        <DisabledTooltipArea />
                    </CoreTooltip>
                )}
                {isTyping && <IsTypingBar />}
            </>
        );
    }
);

const Footer = (props: Props) => (
    <Container>
        <FooterComponent {...props} />
    </Container>
);

export default Footer;
