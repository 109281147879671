import * as React from 'react';
import styled from 'styled-components';
import {useTheme} from '@anywhere-expert/colors';
import {CoreText} from '@anywhere-expert/base-ui';

const PaperClip = require('./resources/paper-clip.svg');

const MainContainer = styled.div`
    text-align: center;
    margin-top: 24px;
    display: flex;
    flex-shrink: 0;
    align-self: center;
    flex-direction: column;
`;

const JoinedContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ExpertImage = styled.img`
    border-radius: 50%;
    margin-bottom: 7px;
    width: 30px;
    height: 30px;
`;

const NoteContainer = styled.div`
    width: 235px;
    padding: 15px 20px;
    font-style: italic;
    box-shadow: -3px 3px 10px -5px grey;
    background-color: #fbf7bb;
    text-align: left;
    margin-top: 20px;
    line-height: 20px;
    font-family: Roboto;
    font-size: 13px;
    border-radius: 4px;
    white-space: pre-line;
`;

const NoteWrapper = styled(NoteContainer)`
    align-self: center;
    position: relative;
`;

const NoteHeader = styled.p`
    font-weight: bold;
`;

const NoteMessage = styled.div`
    font-style: italic;
`;

const PaperClipImg = styled.img`
    position: absolute;
    width: 9px;
    height: 21px;
    right: 10px;
    top: -7px;
`;

export type Expert = {
    displayName: string;
    imageUrl: string;
};

export type EscalateTimelineItemProps = {
    triggeringExpert: Expert;
    joinedExpert: Expert;
    message: string;
    isCarrierRelated: boolean;
};

const EscalateTimelineItem = ({
    triggeringExpert,
    joinedExpert,
    message,
    isCarrierRelated,
}: EscalateTimelineItemProps) => {
    const theme = useTheme();
    const notePreMessage = '<triggering_expert> escalated this session';

    let component;

    if (joinedExpert && joinedExpert.imageUrl !== '' && joinedExpert.displayName !== '') {
        // old version
        component = (
            <MainContainer data-test-id="EscalateNote">
                <JoinedContainer style={{color: theme.colors.neutral.grey.deepest}}>
                    <ExpertImage alt="expert joined" src={joinedExpert.imageUrl} />
                    {`${joinedExpert.displayName} joined this session`}
                </JoinedContainer>
                <NoteContainer>
                    {message && message !== ''
                        ? [
                              <NoteHeader key="1">{`${triggeringExpert.displayName} left you a note:`}</NoteHeader>,
                              <NoteMessage key="2">"{message}"</NoteMessage>,
                          ]
                        : null}
                    <p>{isCarrierRelated ? '#carrier_related_issue' : '#not_carrier_related_issue'}</p>;
                </NoteContainer>
            </MainContainer>
        );
    } else {
        const preMessage = notePreMessage.replace(
            '<triggering_expert>',
            (triggeringExpert && triggeringExpert.displayName) || 'An expert'
        );
        const note =
            message && message.length > 0 ? (
                <NoteWrapper>
                    <PaperClipImg src={PaperClip} alt={'paperclip'} />
                    {message}
                </NoteWrapper>
            ) : null;
        component = (
            <MainContainer data-test-id="EscalateNote">
                <CoreText size="l" weight="bold" textType="tertiary" style={{textAlign: 'center', marginTop: '10px'}}>
                    {preMessage}
                </CoreText>
                {note}
            </MainContainer>
        );
    }

    return component;
};

export default EscalateTimelineItem;
