import React from 'react';
import {useAnalytics} from 'react-shisell';
import {compose} from 'recompose';
import {useAvailableCommandNames, getCommand} from '@anywhere-expert/slash-commands';
import {withTriggerCommandProps, TriggerCommandProps} from '@anywhere-expert/slash-commands';
import {observer} from 'mobx-react';
import Command from './Command';
import {getTabDisabledText} from '../../shared/getTabDisabledText';
import {QueueItem} from '@anywhere-expert/expert-feed-store';

const CommandsTab = observer(({selectedSession, technicianData}: EnhancedCommandsTabProps) => {
    const analytics = useAnalytics();
    const scopedDispatcher = analytics.dispatcher.createScoped('Sidebar').createScoped('ExploreMore');
    const availableCommands = useAvailableCommandNames(selectedSession.id);

    if (!availableCommands) {
        return <span>loading...</span>;
    }

    const commands = availableCommands
        .filter(commandName => {
            const command = getCommand(commandName);
            return command && !command.hideFromHelp;
        })
        .map(commandName => (
            <Command
                commandName={commandName}
                timelineId={selectedSession.id}
                sessionId={selectedSession.sessionId}
                technicianData={technicianData}
                dispatcher={analytics.dispatcher}
                scopedDispatcher={scopedDispatcher}
                key={commandName}
            />
        ));

    return <div>{commands}</div>;
});

export interface TabProps {
    selectedSession: QueueItem;
}

export type EnhancedCommandsTabProps = TabProps & TriggerCommandProps;

const enhance = compose<EnhancedCommandsTabProps, TabProps>(withTriggerCommandProps);

interface EnhancedCommandsTabType extends React.ComponentClass<TabProps> {
    getDisabledText?: typeof getTabDisabledText;
}

const EnhancedCommandsTab: EnhancedCommandsTabType = enhance(CommandsTab);
EnhancedCommandsTab.getDisabledText = getTabDisabledText;

export default EnhancedCommandsTab;
