import React, {ComponentType, useEffect, useMemo} from 'react';
import {Tags, Tag, TagsPropsType} from '@expert-feed/conversation-tags';
import * as R from 'ramda';
import {useAnalytics} from 'react-shisell';
import {CoreTooltipProps} from 'packages/base-ui';

type HeaderTagProps = {
    tags: Tag[];
    sessionId: 's' | 'l';
    wrap: boolean;
    onIconClick: (tag: Tag) => void;
    isEditable: boolean;
    tooltipPosition: CoreTooltipProps['position'];
};

const HeaderTags = (props: HeaderTagProps) => {
    const analytics = useAnalytics();
    const dispatcher = useMemo(() => {
        const groupedTags = R.groupBy(({tagType}) => `TagsDisplayed_${tagType}`, props.tags);
        const groupedTagsExtras = R.mapObjIndexed(
            tags =>
                tags.map(({iconType, displayText}) => (iconType ? `[${iconType}]${displayText}` : displayText)).join(),
            groupedTags
        );

        return analytics.dispatcher.createScoped('Tags').withExtras(groupedTagsExtras);
    }, [analytics, props.tags]);

    useEffect(() => {
        dispatcher.dispatch('View');
    }, [props.tags, dispatcher, props.sessionId]);

    return <Tags {...props} />;
};

export default HeaderTags as ComponentType<TagsPropsType>;
