import React, {useState, useMemo, useCallback} from 'react';
import {StyleDeclarationValue} from 'aphrodite';
import {useAnalytics} from 'react-shisell';
import {CoreActionMenu, CoreButton} from '@anywhere-expert/base-ui';
import {ResolveIcon, ResolveErrorIcon} from '@anywhere-expert/icons';
import {useResolveAction, ResolveProgress} from '@anywhere-expert/resolve-session';
import {ResolveMenu} from '../ResolveMenu';
import styled from 'styled-components';

const getDefaultIcon = (resolveState: ResolveProgress) => (resolveState === 'ERROR' ? ResolveErrorIcon : ResolveIcon);

const Container = styled.div`
    position: relative;
`;

type ResolveButtonProps = {
    sessionId: string;
    isDisabled: boolean;
    buttonText?: string;
    style?: StyleDeclarationValue;
    isResolveMenuEnabled?: boolean;
    shouldOpenResolveMenu?: () => boolean;
    onResolveSideEffect?: () => any;
};

const ResolveTitleButton = styled(CoreButton)<{isActive: boolean}>`
    ${({theme}) =>
        `background-color:${theme.colors.brand.purple};
     border: ${theme.colors.primary.purple.deeper};`}
    cursor: pointer;
    position: relative;
`;

type ResolveMainButtonProps = {isActive: boolean; [x: string]: any};

const getResolveButtonElement = (icon: any) => ({isActive, ...others}: ResolveMainButtonProps) => (
    <ResolveTitleButton text="Resolve" icon={icon} size="m" color="primary" isActive={isActive} {...others} />
);

const ResolveButton = ({
    sessionId,
    isDisabled,
    buttonText,
    isResolveMenuEnabled,
    shouldOpenResolveMenu,
    onResolveSideEffect,
}: ResolveButtonProps) => {
    const {dispatcher} = useAnalytics();
    const [openMenu, setShouldOpenMenu] = useState(false);
    const [onResolve, resolveState] = useResolveAction(sessionId);

    const onClick = useCallback(async () => {
        if (isDisabled) return;
        if (resolveState === 'PENDING') {
            return;
        }

        dispatcher
            .withExtras({ActionId: 'ResolveButton', QuickResolvedSession: isResolveMenuEnabled})
            .dispatch('Click');

        const shouldOpen = isResolveMenuEnabled && shouldOpenResolveMenu?.();

        if (shouldOpen) {
            dispatcher.createScoped('ResolutionConfirmation').dispatch('Visibility');
            setShouldOpenMenu(true);
            return;
        }
        await onResolve();
        onResolveSideEffect?.();
    }, [
        dispatcher,
        resolveState,
        isResolveMenuEnabled,
        onResolve,
        onResolveSideEffect,
        shouldOpenResolveMenu,
        setShouldOpenMenu,
        isDisabled,
    ]);

    const defaultIcon = useMemo(() => getDefaultIcon(resolveState), [resolveState]);
    const ResolveTitleButton = useMemo(() => getResolveButtonElement(defaultIcon), [defaultIcon]);
    const closeResolveMenu = useCallback(() => setShouldOpenMenu(false), []);

    return (
        <Container>
            <CoreActionMenu
                onClickOut={closeResolveMenu}
                color="primary"
                size="s"
                isInProgress={resolveState === 'PENDING'}
                customButton={ResolveTitleButton}
                disabled={isDisabled}
                onClick={onClick}
                extend={openMenu}
                text={buttonText}
                data-test-id="ResolveButton"
            >
                <ResolveMenu closeResolveMenu={closeResolveMenu} sessionId={sessionId} />
            </CoreActionMenu>
        </Container>
    );
};

export default ResolveButton;
