import {mapPropsStreamWithConfig} from 'recompose';
import rxjsconfig from 'recompose/rxjsObservableConfig';
import {Observable} from 'rxjs/Rx';
import {getFromTweekForCustomer} from '@anywhere-expert/tweek';
import executors from './commands/executors';
import {useAsync} from 'react-async-hook';

export const getCommand = (commandType: string = '') => executors[commandType.toLowerCase()] || executors['unknown'];

const excludedCommands = ['help', 'unknown'];

const capitalize = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1);

const toCamelCase = (key: string): string => {
    const [first, ...others] = key.split('_');
    return [first, ...others.map(capitalize)].join('');
};

type TweekConfig = {
    isEnabled?: boolean;
    isSupported?: boolean;
};

type TweekConfigs = {[tool: string]: TweekConfig};

export const getAvailableCommandNames = async (timelineId: string): Promise<string[]> => {
    const configs = await getFromTweekForCustomer<TweekConfigs>(timelineId, 'support/session/messaging/tools/_');
    const isCommandAvailable = (commandType: string): boolean => {
        const executor = getCommand(commandType);
        const commandTweekKey = executor.getCommandTweekKey();

        if (excludedCommands.includes(commandTweekKey)) return false;

        const config = configs && configs[toCamelCase(commandTweekKey)];
        return Boolean(config && config.isEnabled && config.isSupported);
    };

    return Object.keys(executors).filter(isCommandAvailable);
};

type AvailableCommandNamesOuterProps = {
    timelineId: string;
};

export type AvailableCommandNamesInnerProps = {
    availableCommands: string[];
};

export const withAvailableCommandNames = mapPropsStreamWithConfig(rxjsconfig)<
    AvailableCommandNamesInnerProps,
    AvailableCommandNamesOuterProps
>((props$: Observable<AvailableCommandNamesOuterProps>) => {
    const availableCommands$ = props$
        .pluck<AvailableCommandNamesOuterProps, string>('timelineId')
        .distinctUntilChanged()
        .switchMap(async timelineId => await getAvailableCommandNames(timelineId));

    return Observable.combineLatest(props$, availableCommands$, (props, availableCommands) => ({
        ...props,
        availableCommands,
    }));
});

export const useAvailableCommandNames = (customerId: string) => {
    const getAvailableCommandNamesTask = useAsync(getAvailableCommandNames, [customerId]);

    if (getAvailableCommandNamesTask.result) {
        return getAvailableCommandNamesTask.result;
    }

    return undefined;
};
