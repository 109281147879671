import React, {useMemo} from 'react';
import {Timeline} from '@anywhere-expert/timeline-web';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import {SessionSidebar} from '@anywhere-expert/session-sidebar';
import {registerTabsConfig} from '../package-wiring/sessionSidebar';
import {CustomerTweekProvider, createSupportItemTweekContext} from '@anywhere-expert/tweek';
import {SupportItem, TaskItem} from '@anywhere-expert/expert-feed-store';
import {ExpertFeedStore} from '@anywhere-expert/expert-feed-store';
import {useIsTweekReady} from '@anywhere-expert/tweek';
import {AnalyticsProvider, useAnalytics} from 'react-shisell';
import {useInitTasksStore, TasksStoreContext} from '@anywhere-expert/expert-tasks-state';
import {useUserProfile} from '@anywhere-expert/auth';
import {User} from '@anywhere-expert/auth';
import {LoggingErrorBoundary} from '@anywhere-expert/logging';
import ExpertTasksView from './ExpertTasksView';
import {SupportItemContext} from '@anywhere-expert/timeline-state';

registerTabsConfig();

const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    position: relative;
    flex-grow: 1;
    min-height: 0;
`;

const Left = styled.div`
    display: flex;
    min-width: 320px;
    flex: 2;
`;

const SupportItemView = ({user, selectedFeedItem}: {user: User; selectedFeedItem: SupportItem}) => {
    const isTweekReady = useIsTweekReady('support/_');

    if (!isTweekReady) return null;

    return (
        <>
            <Left>
                <LoggingErrorBoundary boundaryName="Timeline">
                    <SupportItemContext.Provider value={selectedFeedItem}>
                        <Timeline user={user} selectedSession={selectedFeedItem} />
                    </SupportItemContext.Provider>
                </LoggingErrorBoundary>
            </Left>
            <LoggingErrorBoundary boundaryName="SessionSidebar">
                <React.Suspense fallback={null}>
                    <SessionSidebar selectedSession={selectedFeedItem} registerTabsConfig={registerTabsConfig} />
                </React.Suspense>
            </LoggingErrorBoundary>
        </>
    );
};

const SupportItemViewWithTweek = ({user, selectedFeedItem}: {user: User; selectedFeedItem: SupportItem}) => {
    const additionalContext = useMemo(
        () =>
            createSupportItemTweekContext(
                selectedFeedItem.sessionId,
                selectedFeedItem.pool,
                selectedFeedItem.sessionAttributes
            ),
        [selectedFeedItem.sessionId, selectedFeedItem.pool, selectedFeedItem.sessionAttributes]
    );

    return (
        <CustomerTweekProvider customerId={selectedFeedItem.customerId} additionalContext={additionalContext}>
            <SupportItemView user={user} selectedFeedItem={selectedFeedItem} />
        </CustomerTweekProvider>
    );
};

const TaskItemView = ({user, selectedFeedItem}: {user: User; selectedFeedItem: TaskItem}) => (
    <LoggingErrorBoundary boundaryName="TaskItem">
        <React.Suspense fallback={null}>
            <ExpertTasksView user={user} selectedSession={selectedFeedItem} />
        </React.Suspense>
    </LoggingErrorBoundary>
);

const MainSessionView = observer(() => {
    const tasksStore = useInitTasksStore();
    const analytics = useAnalytics();
    const user = useUserProfile();

    const selectedFeedItem = ExpertFeedStore.selectedSession;
    if (!selectedFeedItem) return null;

    const enhanceAnalytics = analytics.dispatcher.withExtras({
        QueueName: selectedFeedItem.pool,
        SessionType: selectedFeedItem.type,
        CustomerPlatform: selectedFeedItem.supportItemDetails.platform,
    });

    return (
        <TasksStoreContext.Provider value={tasksStore}>
            <AnalyticsProvider dispatcher={enhanceAnalytics}>
                <Container>
                    {selectedFeedItem.isSupport() ? (
                        <SupportItemViewWithTweek user={user} selectedFeedItem={selectedFeedItem} />
                    ) : selectedFeedItem.isTask() ? (
                        <TaskItemView user={user} selectedFeedItem={selectedFeedItem} />
                    ) : null}
                </Container>
            </AnalyticsProvider>
        </TasksStoreContext.Provider>
    );
});

export default MainSessionView;
