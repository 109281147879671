import * as React from 'react';

export type isFinishedFunc = (props: any) => boolean;

export interface hasTimedOutProps {
    hasTimedOut: boolean;
}

const withHasTimedOut = (isFinished: isFinishedFunc) => (WrappedComponent: React.ComponentType<hasTimedOutProps>) => {
    class withHasTimedOutComponent extends React.Component<{timeout: number}, {hasTimedOut: boolean}> {
        timeout;
        state = {hasTimedOut: false};

        componentDidMount() {
            const {timeout} = this.props;
            if (!isFinished(this.props)) {
                this.timeout = setTimeout(() => this.setState({hasTimedOut: true}), timeout || 40000);
            }
        }

        UNSAFE_componentWillReceiveProps(nextProps) {
            if (!isFinished(this.props) && isFinished(nextProps)) {
                clearTimeout(this.timeout);
            }
        }

        componentWillUnmount() {
            clearTimeout(this.timeout);
        }

        render() {
            const {timeout, ...other} = this.props;
            return <WrappedComponent hasTimedOut={this.state.hasTimedOut} {...other} />;
        }
    }

    return withHasTimedOutComponent;
};

export default withHasTimedOut;
