import {observable, action, makeObservable} from 'mobx';

class DropToBottomStore {
    isShowing: boolean;
    numberOfMessages: number;

    show = () => {
        this.isShowing = true;
    };

    hide = () => {
        this.isShowing = false;
        this.numberOfMessages = 0;
    };

    increase = () => {
        this.numberOfMessages += 1;
    };

    constructor() {
        makeObservable(this, {
            isShowing: observable,
            numberOfMessages: observable,
            show: action,
            hide: action,
            increase: action,
        });
    }
}

export default new DropToBottomStore();

export type DropToBottomStoreType = DropToBottomStore;
