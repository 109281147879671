import {compose} from 'recompose';
import {withTweekKeys} from '@anywhere-expert/tweek';
import {default as withHasTimedOut, hasTimedOutProps} from './withHasTimedOut';
import {SpeedTestContent} from './types';

const isSpeedTestFinished = ({commandPayload}: {commandPayload: SpeedTestContent}) =>
    commandPayload && commandPayload.speedTestResult && commandPayload.speedTestResult.success != null;

export default compose<hasTimedOutProps, SpeedTestContent>(
    withTweekKeys({timeout: 'support/session/messaging/tools/speed_test/timeout'}),
    withHasTimedOut(isSpeedTestFinished)
);
