import React, {useCallback} from 'react';
import {branch, compose} from 'recompose';
import styled from 'styled-components';
import {useAnalytics} from 'react-shisell';
import {useUserProfile} from '@anywhere-expert/auth';
import {useTheme, palette, Theme} from '@anywhere-expert/colors';
import {CoreText, CoreTimelineTextBubble} from '@anywhere-expert/base-ui';
import {PaperclipIcon, DownloadIcon, CloseIcon} from '@anywhere-expert/icons';
import {CoreIcon, Animation} from '@anywhere-expert/base-ui';
import {ExpertFeedStore} from '@anywhere-expert/expert-feed-store';
import withTechTemplate from '../behaviors/withTechTemplate';
import withCustomerTemplate from '../behaviors/withCustomerTemplate';
const spinner = require('./assets/spinner.json');

const Paperclip = styled(CoreIcon)`
    margin: auto;
`;

const PaperClipWrapper = styled.div<{theme: Theme}>`
    display: flex;
    width: 28px;
    height: 28px;
    background-color: ${({theme}) => theme.colors.brand.blue};
    border-radius: 4px;
    margin-right: 11px;
`;

const StyledCoreText = styled(CoreText)<{isSelf: boolean; theme: Theme}>`
    display: flex;
    align-items: center;
    color: ${({isSelf, theme}) => (isSelf ? palette.neutral.white : theme.colors.neutral.black)};
    margin-right: 9px;
`;

const AttachmentContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const UploadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StopUploadContainer = styled.div`
    position: absolute;
    cursor: pointer;
    width: 16px;
    height: 16px;
`;

const AnimationWrapper = styled(Animation)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const UploadSpinnerAnimationContainer = styled.div`
    width: 34px;
    height: 34px;
    margin-bottom: -6px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const animationOptions = {
    animationData: spinner,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    rendererSettings: {
        scaleMode: 'noScale',
    },
};

const Attachment = ({
    url,
    isSelf,
    isHover,
    showTimestamp,
    timestampValue,
    sessionId,
    timestamp,
    contentId,
    timelineId,
}) => {
    const theme = useTheme();
    const user = useUserProfile();
    const analytics = useAnalytics();
    const cancelUpload = useCallback(() => {
        localStorage.setItem(`${contentId}_cancel`, 'true');

        ExpertFeedStore.getSupportItem(sessionId)?.timelineModel.removeLocalMessage(contentId);

        const timePassedUntilCancel = Date.now() - timestamp;

        analytics.dispatcher
            .withIdentity('SessionId', sessionId)
            .createScoped('Attachment')
            .withExtra('secondsUntilClicked', timePassedUntilCancel)
            .dispatch('UploadCancel_Click');
    }, [sessionId, timestamp, contentId, timelineId, user, analytics]);

    const text = `${isSelf ? 'You' : 'Customer'} shared a file`;

    const isUploading = url === undefined;

    const Uploading = (
        <UploadingContainer>
            <UploadSpinnerAnimationContainer>
                <AnimationWrapper animationOptions={animationOptions} />
            </UploadSpinnerAnimationContainer>
            <StopUploadContainer onClick={cancelUpload}>
                <CoreIcon icon={CloseIcon} color={palette.neutral.white} size="s" />
            </StopUploadContainer>
        </UploadingContainer>
    );

    const Uploaded = <CoreIcon icon={DownloadIcon} color={theme.colors.brand.blue} />;

    const Wrapper = ({children, ...rest}) =>
        isUploading ? (
            <div {...rest}>{children}</div>
        ) : (
            <a href={url} target="_blank" {...rest}>
                {children}
            </a>
        );

    return (
        <Wrapper>
            <CoreTimelineTextBubble
                size="m"
                textBubbleType={isSelf ? 'expert' : 'customer'}
                isHover={isHover}
                timestamp={!isUploading && showTimestamp && timestampValue}
            >
                <AttachmentContainer>
                    <PaperClipWrapper>
                        <Paperclip icon={PaperclipIcon} color={palette.neutral.white} size="m" />
                    </PaperClipWrapper>
                    <StyledCoreText size="m" weight="medium" data-test-id="Attachment" isSelf={isSelf}>
                        {text}
                    </StyledCoreText>
                    {isUploading ? Uploading : Uploaded}
                </AttachmentContainer>
            </CoreTimelineTextBubble>
        </Wrapper>
    );
};

const enhance = compose(
    branch<{isSelf: boolean}>(props => props.isSelf, withTechTemplate as any, withCustomerTemplate as any)
);

export default enhance(Attachment as any);
