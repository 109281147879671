import React from 'react';
import {compose, branch, renderComponent} from 'recompose';
import {StyleSheet, css} from 'aphrodite';
import {withSelectedFeedItem} from '@anywhere-expert/expert-feed-store';
import {withTechTemplate} from '@anywhere-expert/timeline-item';
import {SystemMessageItem, IconType} from '@anywhere-expert/system-messages';
import {VisualComponentType, VisualComponentProps} from '@slash-commands/command-visual-component';
import {AppLinkIcon} from '@anywhere-expert/icons';

import appsRepository from '../appsRepository';
const starIcon = require('../resources/starIcon.png');
const appStoreIcon = require('../resources/appStoreIcon.png');
const googlePlayIcon = require('../resources/googlePlayIcon.png');

export {description} from '../shared';
export const icon: IconType = require('./app_link.svg');
export const iconOutline = AppLinkIcon;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        width: 307,
        height: 105,
        borderRadius: 4,
    },
    appIcon: {
        flex: 1,
        display: 'flex',
        height: 75,
        width: 75,
        marginTop: 15,
        marginBottom: 15,
        marginLeft: 15,
    },
    innerContainer: {
        flex: 2.5,
        marginTop: 12,
        marginBottom: 10,
        marginLeft: 18,
        marginRight: 10,
    },
    descriptionContainer: {
        display: 'flex',
        height: 17,
        marginTop: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    appNameText: {
        display: 'flex',
        textAlign: 'left',
        fontSize: 14,
        lineHeight: '16.8px',
        fontWeight: 'normal',
        color: 'white',
    },
    ratingText: {
        display: 'flex',
        textAlign: 'left',
        fontSize: 13,
        lineHeight: '16.8px',
        color: 'white',
    },
    appCategoryText: {
        display: 'flex',
        textAlign: 'left',
        marginLeft: 7,
        fontSize: 13,
        lineHeight: '16.8px',
        fontWeight: 200,
        color: 'rgba(255, 255, 255, 0.6)',
    },
    starIcon: {
        display: 'flex',
        width: 9,
        height: 8,
        marginLeft: '7px',
    },
    buttonContainer: {
        display: 'flex',
        flex: 1,
        height: 50,
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    button: {
        display: 'flex',
        width: 103,
        height: 26,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '17.5px',
        border: 'solid rgba(255, 255, 255, 0.28)',
        borderWidth: 2,
    },
    buttonInnerContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    buttonText: {
        display: 'flex',
        color: 'white',
        fontSize: 11,
        lineHeight: '14px',
        textAlign: 'center',
    },
    storeIcon: {
        display: 'flex',
        width: 13,
        height: 14,
        marginLeft: 10,
    },
});

const AppLinkError = () => {
    const messageStart = 'Sorry, we couldn’t find this app in our list. The apps you can send are:\n';
    const formattedAppList = Object.getOwnPropertyNames(appsRepository).join('*, *');
    return (
        <SystemMessageItem
            message={`${messageStart}*${formattedAppList}*`}
            subMessage={'Type /app-link [app name] to send the app link'}
        />
    );
};

const enhance = compose(
    withTechTemplate,
    withSelectedFeedItem(({osType}) => ({osType: osType && osType.toLowerCase()}))
);

const AppLink: React.SFC<any> = ({appName, appBackgroundColor, appImageUrl, appRating, appCategory, osType}) => {
    const storeIcon = osType === 'ios' ? appStoreIcon : googlePlayIcon;
    return (
        <div
            className={css(styles.container)}
            style={{backgroundColor: appBackgroundColor}}
            data-test-id={`AppLink_${appName.replace(' ', '_')}`}
        >
            <img className={css(styles.appIcon)} src={appImageUrl} />
            <div className={css(styles.innerContainer)}>
                <div className={css(styles.appNameText)}>{appName}</div>
                <div className={css(styles.descriptionContainer)}>
                    <div className={css(styles.ratingText)}>{appRating}</div>
                    <img className={css(styles.starIcon)} src={starIcon} />
                    <div className={css(styles.appCategoryText)}>{appCategory}</div>
                </div>
                <div className={css(styles.buttonContainer)}>
                    <div className={css(styles.button)}>
                        <div className={css(styles.buttonInnerContainer)}>
                            <div className={css(styles.buttonText)}>INSTALL</div>
                            <img className={css(styles.storeIcon)} src={storeIcon} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const EnhancedAppLink = enhance(AppLink);

export const Component: VisualComponentType = branch<VisualComponentProps>(
    ({type}) => type === 'app-not-found',
    renderComponent(AppLinkError)
)(EnhancedAppLink);
