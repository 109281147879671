import React from 'react';
import styled from 'styled-components';
import {CoreText, CoreIcon} from '@anywhere-expert/base-ui';
import ThankYouIcon from '../../../../assets/ThankYouIcon';
import {useTheme} from '@anywhere-expert/colors';

const Title = styled(CoreText)`
    text-align: center;
`;

const SubTitle = styled(CoreText)`
    text-align: center;
    margin-top: 8px;
`;

const StyledIcon = styled(CoreIcon)`
    margin-top: 12px;
    margin-bottom: 12px;
`;

const ThankYou = () => {
    const theme = useTheme();

    return (
        <>
            <StyledIcon icon={ThankYouIcon} color={theme.colors.neutral.black} />
            <Title size="l" textType="primary" weight="bold">
                {'Thank you for the feedback!'}
            </Title>
            <SubTitle size="m" textType="primary">
                {`We'll use it to improve these suggestions and deliver more helpful sessions in the future`}
            </SubTitle>
        </>
    );
};

export default ThankYou;
