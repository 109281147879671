import {useCallback, useState} from 'react';

type Response = {
    expanded: boolean;
    expand: () => void;
    collapse: () => void;
};

const useExpandedSessionDetails = (): Response => {
    const [expanded, setExpanded] = useState(false);
    const expand = useCallback(() => setExpanded(prev => !prev), []);
    const collapse = useCallback(() => setExpanded(false), []);

    return {
        expanded,
        expand,
        collapse,
    };
};

export default useExpandedSessionDetails;
