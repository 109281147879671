import * as React from 'react';
import styled from 'styled-components';
import {TimelineItemProps} from '@anywhere-expert/timeline-behaviours/timelineItemsRenderer';
import {palette} from '@anywhere-expert/colors';
import withCustomerTemplate from '../behaviors/withCustomerTemplate';
import withTechTemplate from '../behaviors/withTechTemplate';
import {branch, compose} from 'recompose';
import {CoreText, CoreTimelineTextBubble} from '@anywhere-expert/base-ui';
import DeletedIcon from './assets/Deleted';

const NoMessage = ({isSelf, text, timestampValue}: TimelineItemProps & {text: string}) => {
    const textColor = isSelf ? palette.neutral.blacks.b30 : palette.neutral.whites.w30;

    return (
        <CoreTimelineTextBubble size="m" textBubbleType={isSelf ? 'expert' : 'customer'} timestamp={timestampValue}>
            <Container>
                <DeletedIcon stroke={textColor} />
                <StyledText size="m" textType="tertiary" color={textColor}>
                    {text}
                </StyledText>
            </Container>
        </CoreTimelineTextBubble>
    );
};

const Container = styled.div`
    display: flex;
    align-items: center;
`;

const StyledText = styled(CoreText)<{color: string}>`
    color: ${({color}) => color};
    font-style: italic;
    margin-left: 7px;
`;

const enhance = compose<TimelineItemProps & {text: string}, any>(
    branch<TimelineItemProps>(props => props.isSelf, withTechTemplate as any, withCustomerTemplate as any)
);

export default enhance(NoMessage);
