import styled from 'styled-components';
import {HardcodedAlertCircleOutlinedIcon} from '@anywhere-expert/icons';
import {CoreTooltip} from '@anywhere-expert/base-ui';
import React from 'react';
const StyledIcon = styled(HardcodedAlertCircleOutlinedIcon)`
    position: relative;
    top: 3px;
`;
type Props = {
    customerName?: string;
};

const MissingDataTooltip = ({customerName}: Props) => {
    const missingTooltipText = `Make sure you verify it with ${customerName ?? 'the customer'}`;
    return (
        <CoreTooltip text={missingTooltipText} position="bottom">
            <StyledIcon fillOpacity={0} size={'s'} />
        </CoreTooltip>
    );
};

export default React.memo(MissingDataTooltip);
