import commandSender from '../shared/commandSender';
import {ExecuteCommandParameters, ExecuteFactory, GetCommandTweekKey} from '../shared/types';

export const getCommandTweekKey: GetCommandTweekKey = () => 'device_status';

export const executeFactory: ExecuteFactory = (name: string) => (options: ExecuteCommandParameters) =>
    commandSender({
        commandType: name,
        contentType: 'Command',
        tweekToolKey: getCommandTweekKey(),
        analyticsScope: 'DeviceStatus',
        ...options,
    });
