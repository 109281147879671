import * as React from 'react';
import {compose, pure, withProps} from 'recompose';
import styled from 'styled-components';
import throttle from 'lodash.throttle';
import {withAnalytics, WithAnalyticsProps} from 'react-shisell';
import {withTweekKeys} from '@anywhere-expert/tweek';
import {renderMessage} from '@anywhere-expert/timeline-behaviours/timelineItemsRenderer';
import {withForwardingRef} from '@anywhere-expert/base-ui';
import {Escalate, isShowingEscalate$} from '@anywhere-expert/escalate';
import {withPropFromObservable} from '@anywhere-expert/utils';
import {
    PerformanceType,
    TYPE_CONVERSATION_CLICK_TO_TIMELINE_LOAD,
    TYPE_TIMELINE_LOAD,
    withPerformance,
} from '@anywhere-expert/performance';
import DropToBottomStore from './DropToBottomStore';

import mapTimelineForRendering, {OuterProps as MapTimelineProps} from './mapTimelineForRendering';
import MessageErrorBoundary from './MessageErrorBoundary';
import Spinner from './Spinner';
import {Message} from '@soluto-private/messaging-api-client';

const SCROLL_BOTTOM_BUTTON_THRESHOLD = 65;

const messagingContainerStyle: React.CSSProperties = {float: 'left', clear: 'both'};

const MessagingSection = styled.div`
    position: relative;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column-reverse;
`;

// Fixes an issue that causes the top margin of the last timeline item not create any space
const TopPaddingElement = styled.div`
    min-height: 1px;
`;

type OuterProps = {
    timelineId: string;
    sessionId: string;
    timelineItemsLoaded?: boolean;
    messages: Message[];
    scrollerRef: HTMLDivElement | null;
};

type InnerProps = OuterProps &
    MapTimelineProps &
    WithAnalyticsProps & {
        isShowingEscalate: boolean;
        customScrollEnabled: boolean;
        initNumberOfItemsToShow: number;
        numberOfItemsToLoadOnScroll: number;
        onTimelineLoad: (loadingTimeMs: number) => void;
        openSidebar: () => void;
        closeSidebar: () => void;
        hideSidebar: () => void;
        performance: PerformanceType;
        isTimelineItemsFirstLoadSpinnerEnabled: boolean;
    };

const MessageComponent = ({message, index, messages, sessionId, timelineId, shouldShowSeen}) => {
    return renderMessage({
        message,
        prevMessage: messages[index + 1],
        nextMessage: messages[index - 1],
        sessionId,
        timelineId,
        shouldShowSeen,
    });
};

const getLastItem = (messages: Message[]) => messages[0];
const getLatestNewItem = (prevMessages: Message[], messages: Message[]) => {
    const lastPrevItem = getLastItem(prevMessages);
    const lastCurrentItem = getLastItem(messages);
    return lastCurrentItem !== lastPrevItem ? lastCurrentItem : undefined;
};
const getLastSeenMessage = (messages: Message[]) => {
    const lastSeenItem = messages.find(
        item =>
            !!(
                item.metadata.senderType === 'Technician' &&
                item.seenAt &&
                (item.payload.type === 'text_message' || item.payload.type === 'image_item') &&
                Object.keys(item.seenAt).length
            )
    );

    return lastSeenItem?.messageId;
};
const isExpertMessage = (message: Message) =>
    message && message.payload.type === 'text_message' && message.metadata.senderType === 'Technician';

export class MessagesSection extends React.Component<InnerProps> {
    messagesEnd: HTMLDivElement;
    lastScrollTop: number = 0;
    wasScrolledToBottom: boolean = true;

    constructor(props) {
        super(props);
        this.trackScrolling = throttle(this.trackScrolling.bind(this), 300);
        this.registerForAnalyticDispatchOnTimelineLoad();
    }

    scrollToBottom() {
        requestAnimationFrame(() => this.messagesEnd?.scrollIntoView());
    }

    componentDidUpdate(prevProps: InnerProps) {
        if (this.props.sessionId === prevProps.sessionId) {
            this.handleMidSessionScrollUpdates(prevProps);
        } else if (this.props.timelineItemsLoaded === true) {
            this.scrollToBottom();
        }

        if (prevProps.timelineItemsLoaded === false && this.props.timelineItemsLoaded === true) {
            this.scrollToBottom();
        }

        if (prevProps.scrollerRef !== this.props.scrollerRef) {
            prevProps.scrollerRef && prevProps.scrollerRef.removeEventListener('scroll', this.trackScrolling);
            this.props.scrollerRef && this.props.scrollerRef.addEventListener('scroll', this.trackScrolling);
        }
    }

    componentDidMount() {
        this.props.scrollerRef?.addEventListener('scroll', this.trackScrolling);

        if (this.props.timelineItemsLoaded === true) {
            this.scrollToBottom();
        }
    }

    componentWillUnmount() {
        this.props.scrollerRef?.removeEventListener('scroll', this.trackScrolling);
    }

    noTimelineItems(props: InnerProps = this.props) {
        return !props.messages.length;
    }

    startTimelineLoadTimer() {
        const {performance, timelineId} = this.props;
        performance?.start({identifier: timelineId, tag: TYPE_TIMELINE_LOAD});
    }

    getSnapshotBeforeUpdate(prevProps: InnerProps) {
        if (this.props.timelineId !== prevProps.timelineId) {
            this.startTimelineLoadTimer();
            this.registerForAnalyticDispatchOnTimelineLoad();
            this.props.onSessionChanged();
            this.scrollToBottom();
        } else if (this.noTimelineItems(prevProps) && !this.noTimelineItems(this.props)) {
            this.registerForAnalyticDispatchOnTimelineLoad();
        }

        return null;
    }

    private handleMidSessionScrollUpdates(prevProps: InnerProps) {
        if (this.props.messages !== prevProps.messages) {
            this.handleScrollOnMessagesChange(prevProps.messages);
        }

        if (this.wasExpertAdded(prevProps)) {
            this.scrollToBottom();
        }
    }

    private wasExpertAdded(prevProps: InnerProps) {
        return this.props.isShowingEscalate !== prevProps.isShowingEscalate && this.props.isShowingEscalate;
    }

    private handleScrollOnMessagesChange(prevMessages: Message[]) {
        const messages = this.props.messages;
        const latestNewItem = getLatestNewItem(prevMessages, messages);

        if (latestNewItem !== undefined) {
            if (!isExpertMessage(latestNewItem) && !this.wasScrolledToBottom) {
                DropToBottomStore.increase();
            } else {
                this.scrollToBottom();
            }
        }
    }

    private registerForAnalyticDispatchOnTimelineLoad() {
        const loadingTimeStamp = Date.now();
        const {messages} = this.props;
        window.requestAnimationFrame(() => {
            const loadingTimeInMs = Date.now() - loadingTimeStamp;
            if (messages.length) {
                this.onTimelineLoad(loadingTimeInMs);
            }
        });
    }

    private onTimelineLoad(loadingTimeMs: number) {
        const {analytics, messages, performance, sessionId, timelineId} = this.props;
        performance.end({
            tag: TYPE_CONVERSATION_CLICK_TO_TIMELINE_LOAD,
            identifier: sessionId,
            extraData: {numOfTimelineItems: messages.length},
        });
        performance.end({
            tag: TYPE_TIMELINE_LOAD,
            identifier: timelineId,
            extraData: {numOfTimelineItems: messages.length},
        });
        analytics.dispatcher
            .createScoped('Timeline')
            .withExtra('LoadingTimeInMs', loadingTimeMs)
            .withExtra('TotalNumberOfTimelineItems', messages.length)
            .dispatch('Loaded');
    }

    isAtBottom(threshold: number = 0) {
        const {scrollerRef} = this.props;
        return scrollerRef && scrollerRef.scrollTop >= scrollerRef.scrollHeight - scrollerRef.offsetHeight - threshold;
    }

    trackScrolling() {
        const {scrollerRef} = this.props;
        if (!scrollerRef) return;

        if (this.isAtBottom(SCROLL_BOTTOM_BUTTON_THRESHOLD)) {
            this.wasScrolledToBottom = true;
            DropToBottomStore.hide();
        } else {
            this.wasScrolledToBottom = false;
            DropToBottomStore.show();
        }

        const scrollTop = scrollerRef.scrollTop;
        // Initiating scroll top behavior before getting all the way to the top to provide a smoother experience
        if (scrollTop < scrollerRef.scrollHeight * 0.1) {
            this.props.onScrolledTop();

            // This is done to bypass issues with scroll position not updating
            // when loading more items at the top of the timeline
            const diff = scrollTop - this.lastScrollTop;
            if (diff < 0 || diff > 1) {
                this.nudgeScrollBrowserHack();
            }
        }

        this.lastScrollTop = scrollTop;
    }

    nudgeScrollBrowserHack = throttle(() => {
        const {scrollerRef} = this.props;
        scrollerRef && scrollerRef.scrollTop++;
    }, 1000);

    quickDropButtonClick = () => {
        this.props.analytics.dispatcher.withExtra('ActionId', 'QuickDropButton').dispatch('Click');
        this.scrollToBottom();
    };

    setRef = el => {
        this.messagesEnd = el as HTMLDivElement;
    };

    render() {
        const {
            messages,
            isShowingEscalate,
            timelineId,
            sessionId,
            isTimelineItemsFirstLoadSpinnerEnabled,
            timelineItemsLoaded,
        } = this.props;

        const lastSeenMessageId = getLastSeenMessage(messages);

        return (
            <MessagingSection>
                <Container>
                    <div style={messagingContainerStyle} ref={this.setRef} />
                    {isShowingEscalate && <Escalate sessionId={sessionId} timelineId={timelineId} />}

                    {!timelineItemsLoaded ? (
                        isTimelineItemsFirstLoadSpinnerEnabled ? (
                            <Spinner />
                        ) : null
                    ) : (
                        messages.map((message, index) => (
                            <MessageErrorBoundary
                                message={message}
                                key={message.messageId}
                                sessionId={sessionId}
                                timelineId={timelineId}
                            >
                                <MessageComponent
                                    message={message}
                                    index={index}
                                    key={message.messageId}
                                    messages={messages}
                                    sessionId={sessionId}
                                    timelineId={timelineId}
                                    shouldShowSeen={lastSeenMessageId && lastSeenMessageId === message.messageId}
                                />
                            </MessageErrorBoundary>
                        ))
                    )}

                    <TopPaddingElement />
                </Container>
            </MessagingSection>
        );
    }
}

const enhance = compose<InnerProps, OuterProps>(
    withForwardingRef,
    withPropFromObservable({fromObservable$: isShowingEscalate$, toProp: 'isShowingEscalate'}),
    withTweekKeys({
        isTimelineItemsFirstLoadSpinnerEnabled:
            'support/session/messaging/timeline_items_first_load_spinner/is_enabled',
    }),
    withAnalytics,
    withPerformance,
    pure,
    mapTimelineForRendering,
    withProps(({forwardedRef}) => ({ref: forwardedRef}))
);

export default enhance(MessagesSection);
