import React, {useCallback, useEffect, useMemo} from 'react';
import styled from 'styled-components';
import {CoreText} from '@anywhere-expert/base-ui';
import type {AnalyticsDispatcher} from 'shisell';
import CustomerGreeting from './CustomerGreeting';
import SectionComponent from './SectionComponent';
import MissingDataTooltip from './MissingDataTooltip';
import PrimeSectionComponent from './PrimeSectionComponent';
import PostalCode from './PostalCode';
import {useUserProfile} from '@anywhere-expert/auth';
import {LaptopIcon, MagnifyingGlassIcon} from '@anywhere-expert/icons';
import {withVisibilityIndicator} from '@anywhere-expert/system-messages';

const Container = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    flex-direction: column;
    margin: 40px 0px;
`;

const ApercuLink = styled(CoreText)`
    display: inline-block;
    cursor: pointer;
    text-decoration: underline;
`;

const Sections = styled.div`
    display: flex;
`;

const SearchText = styled(CoreText)`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
`;

type Props = {
    searchText?: string;
    searchUrl?: string;
    customerName?: string;
    postalCode?: string;
    prime?: boolean;
    productUrl?: string;

    dispatcher: AnalyticsDispatcher;
};

const AmazonPrePurchaseGreeting = ({
    searchText = 'laptop',
    searchUrl,
    customerName,
    postalCode,
    prime,
    productUrl,
    dispatcher,
}: Props) => {
    const user = useUserProfile();
    const greetingsDispatcher = useMemo(
        () => dispatcher.withExtras({Type: 'greeting', InitialFlow: `${productUrl ? 'ProductPage' : 'Search'}`}),
        [dispatcher, productUrl]
    );

    useEffect(() => {
        greetingsDispatcher.dispatch('View');
    }, [greetingsDispatcher]);

    const onLinkClick = useCallback(() => {
        greetingsDispatcher.dispatch('Click');
        window.open(searchUrl);
    }, [searchUrl, greetingsDispatcher]);

    const onProductLinkClick = useCallback(() => {
        greetingsDispatcher.dispatch('Click');
        window.open(productUrl);
    }, [productUrl, greetingsDispatcher]);

    return (
        <Container>
            <CustomerGreeting expertName={user.displayName} />
            <Sections>
                <PrimeSectionComponent prime={prime} customerName={customerName} />

                <SectionComponent image={LaptopIcon}>
                    <SearchText size="m" family="apercu" title={searchText}>
                        Looking to buy a <strong>{searchText}</strong>
                    </SearchText>
                </SectionComponent>

                <SectionComponent image={MagnifyingGlassIcon}>
                    {searchUrl ? (
                        <>
                            <CoreText size="m" family="apercu">
                                See their Amazon
                            </CoreText>
                            <ApercuLink
                                size="m"
                                weight="bold"
                                onClick={onLinkClick}
                                linkType="external"
                                family="apercu"
                            >
                                search results →
                            </ApercuLink>
                        </>
                    ) : productUrl ? (
                        <>
                            <CoreText size="m" family="apercu">
                                See the item they’re
                            </CoreText>
                            <ApercuLink
                                size="m"
                                weight="bold"
                                onClick={onProductLinkClick}
                                linkType="external"
                                family="apercu"
                            >
                                currently viewing →
                            </ApercuLink>
                        </>
                    ) : (
                        <CoreText size={'m'} family={'apercu'} textType={'tertiary'}>
                            Amazon search results <strong>Missing</strong>{' '}
                            <MissingDataTooltip customerName={customerName} />
                        </CoreText>
                    )}
                    <PostalCode postalCode={postalCode} customerName={customerName} />
                </SectionComponent>
            </Sections>
        </Container>
    );
};

export default withVisibilityIndicator(AmazonPrePurchaseGreeting);
