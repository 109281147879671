import React from 'react';
import styled from 'styled-components';
import {CoreText, CoreIcon, CoreIconProps} from '@anywhere-expert/base-ui';
import {useTheme} from '@anywhere-expert/colors';

const Section = styled.div`
    display: flex;
    width: 220px;
    align-items: center;
    justify-items: center;
    & > svg {
        margin-right: 8px;
    }
`;

const IconSection = styled(CoreIcon)`
    margin-right: 8px;
`;

const SectionText = styled(CoreText)`
    width: 140px;
    line-height: 16px;
`;
type Props = {
    children: React.ReactNode;
    image: CoreIconProps['icon'];
};

const SectionComponent = ({image, children}: Props) => {
    const theme = useTheme();
    return (
        <Section>
            <IconSection icon={image} color={theme.colors.neutral.black} />
            <SectionText size="m" family="apercu">
                {children}
            </SectionText>
        </Section>
    );
};

export default SectionComponent;
