import React, {useState, useCallback} from 'react';
import {palette} from '@anywhere-expert/colors';
import {CoreTextWithMarkdown} from '../timelineItemStyle';
import {TextType, CoreLink} from '@anywhere-expert/base-ui';
import styled from 'styled-components';
import Markdown from '@soluto-private/soluto-markdown';

const maxCollapsedHeightInPX = 200;
const collapsedMessageLength = 500;

type Props = {
    content: string;
    contentId: string;
    showTimestamp?: boolean;
    textType: TextType;
    textColor: string;
};

const Content = styled.div<{isCollapsed: boolean}>`
    overflow: hidden;
    ${({isCollapsed}) => isCollapsed && ` max-height: ${maxCollapsedHeightInPX}px;`}
`;

const ShowMore = styled.div`
    position: relative;
    text-align: right;
    &:after {
        content: '';
        position: absolute;
        top: -30px;
        left: 0;
        right: 0;
        height: 30px;
        width: 100%;
    }
`;

const StyledLink = styled(CoreLink)`
    color: ${palette.brand.blue};
`;

const CollapsibleMarkdownText = ({contentId, content, textType, textColor}: Props) => {
    const shouldCollapse = React.useMemo(() => !!content && content.length > collapsedMessageLength, [content]);
    const [text, setText] = React.useState(content);
    const [isExpanded, setIsExpanded] = useState(!shouldCollapse);
    const expandText = useCallback(() => setIsExpanded(true), []);

    React.useEffect(() => {
        if (shouldCollapse) {
            if (isExpanded) {
                setText(content);
            } else {
                const trimmedText = content.slice(0, collapsedMessageLength);
                setText(`${trimmedText}...`);
            }
        }
    }, [shouldCollapse, isExpanded, content]);

    return (
        <CoreTextWithMarkdown
            dir="auto"
            textType={textType}
            size={'m'}
            data-test-id={`TextMessageContent_${contentId}`}
            textColor={textColor}
        >
            <Content isCollapsed={!isExpanded} data-test-id={`TextMessageCollapsibleContent_${contentId}`} data-pii>
                <Markdown content={text} linkify classNames={{link: 'text_message_content_link'}} />
            </Content>

            {!isExpanded && (
                <ShowMore>
                    <StyledLink
                        linkType="internal"
                        data-test-id={`TextMessageReadMoreContent_${contentId}`}
                        onClick={expandText}
                    >
                        Read more
                    </StyledLink>
                </ShowMore>
            )}
        </CoreTextWithMarkdown>
    );
};

export default CollapsibleMarkdownText;
