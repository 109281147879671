import * as React from 'react';
import {StyleSheet, css} from 'aphrodite';
import {SystemMessageItem} from '@anywhere-expert/system-messages';
import {withAnalytics} from 'react-shisell';
import {compose} from 'recompose';
import {BackButton, NextButton} from './PagingButtons';
import AppComponent from './AppComponent';

const AppsIcon = require('../AppsIcon.svg');

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 34,
    },
    paging: {
        display: 'flex',
    },
    apps: {
        display: 'flex',
    },
});

class AppList extends React.Component<any, any> {
    constructor(props) {
        super(props);

        this.state = {
            currentPage: 0,
            apps: props.apps,
            pageSize: 6,
            maxPage: 0,
        };
    }

    componentDidMount() {
        this.updatePageSize();
        window.addEventListener('resize', () => this.updatePageSize());
    }

    updatePageSize() {
        const windowWidth = window.innerWidth;

        let pageSize;
        if (windowWidth > 1880) pageSize = 6;
        else if (windowWidth > 1700) pageSize = 5;
        else if (windowWidth > 1520) pageSize = 4;
        else if (windowWidth > 1340) pageSize = 3;
        else if (windowWidth > 1160) pageSize = 2;
        else pageSize = 1;

        const maxPage = Math.ceil(this.state.apps.length / pageSize) - 1;

        this.setState({
            currentPage: 0,
            pageSize,
            maxPage,
        });
    }

    render() {
        const {timelineId, analytics, days} = this.props;
        const {currentPage, apps, pageSize, maxPage} = this.state;

        const firstPageItem = currentPage * pageSize;
        const message = days >= 0 ? `Apps installed within the last ${days} days` : `Apps installed on the device`;
        return (
            <div className={css(styles.container)}>
                <SystemMessageItem message={message} icon={AppsIcon} />
                <div className={css(styles.paging)}>
                    <BackButton
                        hide={currentPage === 0}
                        onClick={() =>
                            this.setState({
                                currentPage: currentPage - 1,
                            })
                        }
                    />
                    <div className={css(styles.apps)}>
                        {apps.slice(firstPageItem, firstPageItem + pageSize).map(app => (
                            <AppComponent
                                key={app.package}
                                timelineId={timelineId}
                                analyticsDispatcher={analytics.dispatcher}
                                app={app}
                            />
                        ))}
                    </div>
                    <NextButton
                        hide={currentPage >= maxPage}
                        onClick={() =>
                            this.setState({
                                currentPage: currentPage + 1,
                            })
                        }
                    />
                </div>
            </div>
        );
    }
}

const enhance = compose(withAnalytics);

export default enhance(AppList) as any;
