import {triggerCommand} from '../../shared';
import {FeatureSuggestionTexts} from '../../components/FeatureSuggestion/types';
import {UserDetailsType} from '@anywhere-expert/core';
import {AnalyticsDispatcher} from 'shisell';

export const description: string = 'Run a network speed test';

export const featureSuggestionTexts: FeatureSuggestionTexts = {
    headerText: 'Investigate potential network issues with a speed test',
    subHeaderText: 'Sending customers a speed test will both help out and impress them for sure 😀',
    buttonText: 'Run test now',
    otherToolsButtonText: 'Explore other tools',
};

export const onFeatureSuggestionClick = (
    commandMessage: string,
    timelineId: string,
    sessionId: string,
    technicianData: UserDetailsType,
    dispatcher: AnalyticsDispatcher
) => {
    triggerCommand({
        timelineId,
        sessionId,
        expertData: technicianData,
        analyticsDispatcher: dispatcher,
        commandMessage,
        triggeringMethod: 'Suggestion',
    });
};
