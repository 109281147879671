import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTweekValue} from 'react-tweek';
import {useAnalytics, AnalyticsProvider} from 'react-shisell';
import styled from 'styled-components';
import {CoreText} from '@anywhere-expert/base-ui';
import AcceptConversationButton from './AcceptConversationButton';
import {usePerformance, TYPE_ACCEPT_CONVERSATION_TO_INPUT_RENDER} from '@anywhere-expert/performance';
import {getFeedActionsClient} from '@anywhere-expert/expert-feed-actions';
import {useUserProfile} from '@anywhere-expert/auth';
import Mustache from 'mustache';
import {useSendMessage} from '@anywhere-expert/timeline-behaviours';
import {ExpertFeedStore} from '@anywhere-expert/expert-feed-store';
import {observer} from 'mobx-react';

const renderMessage = (template: string, displayName?: string) =>
    Mustache.render(template, {
        displayName,
    });

const AcceptConversationBarContainer = styled.div<any>`
    padding: 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const style = {paddingRight: '20px'};

const TitleAndInfo = ({primaryText}) => (
    <CoreText weight="medium" size="m" data-test-id={`AcceptConversationPrimaryText`} style={style}>
        {primaryText}
    </CoreText>
);

interface Props {
    sessionId: string;
    timelineId: string;
    partner: string;
    sessionSource?: string;
}

const AcceptConversationBar = ({sessionId, timelineId, partner, sessionSource}: Props) => {
    const analytics = useAnalytics();
    const enhancedAnalytics = useMemo(() => analytics.dispatcher.createScoped('AcceptConversationBar'), [analytics]);
    const user = useUserProfile();
    const acceptButtonText = useTweekValue<string>(
        'support/session/messaging/accept_conversation_bar/text/accept_button_text',
        ''
    );
    const primaryText = useTweekValue<string>(
        'support/session/messaging/accept_conversation_bar/text/primary_text',
        ''
    );
    const defaultGreetingMessage = useTweekValue<string>(
        'support/session/messaging/accept_conversation_bar/text/default_greeting_message',
        ''
    );
    const greetingMessageIsEnabled = useTweekValue<boolean>(
        'support/session/messaging/accept_conversation_bar/text/is_enabled',
        false
    );

    useEffect(() => {
        if (!primaryText) {
            return;
        }

        enhancedAnalytics.withExtra('MessageText', primaryText).dispatch('View');
    }, [enhancedAnalytics, primaryText]);
    const sendMessage = useSendMessage(timelineId, sessionId, partner, sessionSource, true);
    const {start} = usePerformance();
    const [acceptInProgress, setAcceptInProgress] = useState<boolean>(false);
    const acceptConversation = useCallback(async () => {
        try {
            if (acceptInProgress) return;
            setAcceptInProgress(true);
            await getFeedActionsClient().assignFeedItem({feedItemId: sessionId});

            if (greetingMessageIsEnabled) {
                await sendMessage(renderMessage(defaultGreetingMessage, user.displayName));
            }
        } catch (err) {
            setAcceptInProgress(false);
        }
    }, [acceptInProgress, sessionId, defaultGreetingMessage, user, greetingMessageIsEnabled]);

    const acceptConversationWithPerformance = useCallback(() => {
        start({tag: TYPE_ACCEPT_CONVERSATION_TO_INPUT_RENDER, identifier: sessionId});
        acceptConversation();
    }, [start, acceptConversation, sessionId]);

    return (
        <AnalyticsProvider dispatcher={enhancedAnalytics}>
            <AcceptConversationBarContainer data-test-id={`AcceptConversationBar`}>
                <TitleAndInfo primaryText={primaryText} />
                <AcceptConversationButton
                    sessionId={sessionId}
                    timelineId={timelineId}
                    acceptButtonText={acceptButtonText}
                    acceptConversation={acceptConversationWithPerformance}
                    acceptInProgress={acceptInProgress}
                />
            </AcceptConversationBarContainer>
        </AnalyticsProvider>
    );
};

export default observer(() => {
    const selectedSession = ExpertFeedStore.selectedSession;

    if (!selectedSession) {
        return null;
    }

    return (
        <AcceptConversationBar
            timelineId={selectedSession.id}
            sessionId={selectedSession.sessionId}
            partner={selectedSession.supportItemDetails.partner}
            sessionSource={selectedSession.sessionSource}
        />
    );
});
