import React from 'react';
import {observer} from 'mobx-react';
import withCustomerTemplate from '../behaviors/withCustomerTemplate';
import useFlashingBehavior from '../behaviors/useFlashingBehavior';
import CollapsibleMarkdownText from '../TextMessage/CollapsibleMarkdownText';
import {useTheme} from '@anywhere-expert/colors';
import TimelineMessageBubble from './TimelineMessageBubble';

export type CustomerMessageProps = {
    content: any;
    contentId: string;
    timestampValue?: string;
    showTimestamp?: boolean;
    extraInfoComponent?: React.ComponentType<any>;
};

const CustomerMessage = observer(
    ({contentId, content, timestampValue, extraInfoComponent = undefined}: CustomerMessageProps) => {
        const textBubbleType = 'customer';
        const theme = useTheme();
        const flash = useFlashingBehavior(contentId);

        return (
            <TimelineMessageBubble
                size={'m'}
                textBubbleType={textBubbleType}
                data-test-id={`TextMessage_${contentId}`}
                timestamp={timestampValue}
                flash={flash}
                extraInfoComponent={extraInfoComponent}
            >
                <CollapsibleMarkdownText
                    textType={'secondary'}
                    textColor={theme.colors.neutral.black}
                    content={content}
                    contentId={contentId}
                />
            </TimelineMessageBubble>
        );
    }
);

export default withCustomerTemplate(CustomerMessage);
