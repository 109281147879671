import React, {FunctionComponent} from 'react';
import styled from 'styled-components';
import {CoreTimelineTextBubble, CoreText} from '@anywhere-expert/base-ui';
import {CoreIconButton} from '@anywhere-expert/base-ui';
import {OpenIcon} from '@anywhere-expert/icons';
import {SelfHelpTimelineItemProps} from '../../types';
import {withTechTemplate} from '@anywhere-expert/timeline-item';

const ArticleWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    padding: 6px 0px;
`;

const Image = styled.img`
    max-width: 135px;
    max-height: 70px;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 14px;
    min-width: 196px;
    max-width: 206px;
`;

const Title = styled(CoreText)`
    margin-bottom: 5px;
    opacity: 0.95;
`;

const Summary = styled(CoreText)`
    opacity: 0.8;
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    user-select: none;
`;

const CoreTimelineItem = styled(CoreTimelineTextBubble)`
    min-width: 395px;
    min-height: 113px;
    justify-content: space-between;
`;

type Props = SelfHelpTimelineItemProps & {onArticleClick: (e: React.SyntheticEvent, ...rest: any[]) => void};

const ArticleContentItem: FunctionComponent<Props> = ({
    onArticleClick,
    state: {imageUrl, title, summary, url},
    showTimestamp,
    timestampValue,
    seenAtText,
}: Props) => {
    return (
        <CoreTimelineItem
            textBubbleType="expert"
            size="m"
            timestamp={showTimestamp && timestampValue}
            seenAt={showTimestamp && seenAtText}
        >
            <Container data-test-id="SelfHelpArticle">
                <ArticleWrapper>
                    <Image src={imageUrl} />
                    <Content>
                        <Title textSize="m" weight="bold" textColor="white">
                            {title}
                        </Title>
                        <Summary textSize="m" textColor="white">
                            {summary}...
                        </Summary>
                    </Content>
                </ArticleWrapper>
                {url ? (
                    <a href={url} target="_blank">
                        <CoreIconButton
                            onClick={onArticleClick}
                            icon={OpenIcon}
                            variant="contained"
                            color="primary"
                            size="s"
                        />
                    </a>
                ) : null}
            </Container>
        </CoreTimelineItem>
    );
};

export default withTechTemplate(ArticleContentItem);
