import React, {useEffect, useMemo} from 'react';
import {AnalyticsProvider, useAnalytics} from 'react-shisell';
import SessionActions from './SessionActions';
import {User} from '@anywhere-expert/auth';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import BasicSessionDetails from './BasicSessionDetails';
import {getBoxShadowStyle, getBorderStyle} from '@anywhere-expert/base-ui';
import useExpandedSessionDetails from '../behaviors/useExpandedSessionDetails';
import {useDetectEscapeKey, useDetectOutsideClick} from '@anywhere-expert/utils';
import ExpandButton from './BasicSessionDetails/ExpandButton';
import {SessionDetails as ExtraSessionDetails} from '@anywhere-expert/session-details';
import {TimelineModel} from '@anywhere-expert/timeline-state';
import {useCopyTextOnMinClicksInterval} from '@anywhere-expert/utils';
import {setAppMessage} from '@anywhere-expert/app-messages';
import HeaderTags from './BasicSessionDetails/tags/HeaderTags';
import ArrowIcon from './resources/ArrowIcon';
import useExpandOnNewSessions from '../behaviors/useExpandOnNewSessions';

const getContainerBottomPadding = (expanded: boolean) => (expanded ? '32px' : '16px');

const Container = styled.div<{expanded: boolean}>`
    display: flex;
    border-radius: 8px;
    width: 100%;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 12px ${({expanded}) => getContainerBottomPadding(expanded)} 16px;
    flex: 1;
    ${({theme}) => getBoxShadowStyle(theme)};
    ${({theme}) => getBorderStyle(theme)};
    background-color: ${({theme}) => theme.colors.neutral.white};
`;

const TopRow = styled.div`
    display: flex;
    width: 100%;
    min-height: 32px;
    justify-content: space-between;
    flex-direction: row;
`;

const BasicSection = styled.div`
    width: 100%;
`;

const ExpandableContainer = styled.div<{expanded: boolean}>`
    position: relative;
    max-height: 0;
    width: 100%;
    opacity: 0;
    padding-left: 16px;
    transition: max-height 0.1s ease-in-out, opacity 0.1s linear;
    ${({expanded}) =>
        expanded &&
        `
        opacity: 1;
        max-height: 500px;
`};
`;

const StyledHeaderTags = styled(HeaderTags)`
    > div {
        margin: 2px;
    }
`;

type Props = {
    user: User;
    disabled?: boolean;
    timelineModel: TimelineModel;
    selectedSession: SupportItem;
};

const TagsContainer = styled.div`
    margin-top: 16px;
    width: 100%;
`;

const StyledArrowIcon = styled(ArrowIcon)`
    position: absolute;
    top: -5px;
    left: -9px;
`;

const Header = observer(({user, disabled, selectedSession, timelineModel}: Props) => {
    const {dispatcher} = useAnalytics();
    const enhancedDispatcher = useMemo(() => dispatcher.createScoped('Header'), [dispatcher]);

    useEffect(() => {
        enhancedDispatcher.withExtra('NumOfCustomTags', selectedSession.customTags.length).dispatch('View');
    }, [selectedSession.customTags.length]);
    const {expand, collapse, expanded} = useExpandedSessionDetails();
    useDetectEscapeKey(collapse, expanded);
    const sectionRef = useDetectOutsideClick(collapse);
    const extraSessionDetails = timelineModel?.timelineDetails;
    const tags = useMemo(() => <StyledHeaderTags selectedSession={selectedSession} />, [selectedSession]);
    const shouldShowExpandButton = extraSessionDetails?.hasData || selectedSession.tags.length > 5;
    useExpandOnNewSessions(timelineModel, shouldShowExpandButton, expand, collapse);

    const onClickEasterEgg = useCopyTextOnMinClicksInterval(selectedSession.customerId, () =>
        setAppMessage({text: 'Customer ID copied to clipboard', type: 'success'})
    );

    return (
        <AnalyticsProvider dispatcher={enhancedDispatcher}>
            <div onClick={onClickEasterEgg} ref={sectionRef} data-test-id="SessionDetails_Header">
                <Container expanded={expanded}>
                    <BasicSection>
                        <TopRow>
                            <BasicSessionDetails selectedSession={selectedSession} expanded={expanded} tags={tags} />
                            <SessionActions user={user} disabled={!!disabled} selectedSession={selectedSession} />
                        </TopRow>
                    </BasicSection>
                    {
                        <ExpandableContainer expanded={expanded}>
                            {expanded && (
                                <>
                                    <StyledArrowIcon />
                                    <ExtraSessionDetails
                                        sessionDetails={extraSessionDetails}
                                        selectedSession={selectedSession}
                                    />
                                    <TagsContainer>{tags}</TagsContainer>
                                </>
                            )}
                        </ExpandableContainer>
                    }
                    {shouldShowExpandButton && <ExpandButton onClick={expand} expanded={expanded} />}
                </Container>
            </div>
        </AnalyticsProvider>
    );
});

export default Header;
