import parseMessageToCommand from './parseMessageToCommand';
import {UserDetailsType} from '@anywhere-expert/core';
import {AnalyticsDispatcher} from 'shisell';

export {default as withTriggerCommandProps, TriggerCommandProps} from './commands/shared/withTriggerCommandProps';
export {CommandConfig} from './commands/shared/withCommandTweekProps';
export {
    withAvailableCommandNames,
    getCommand,
    AvailableCommandNamesInnerProps,
    useAvailableCommandNames,
} from './commandFactory';

type TriggerCommandProps = {
    commandMessage: string;
    timelineId: string;
    sessionId: string;
    expertData: UserDetailsType;
    analyticsDispatcher: AnalyticsDispatcher;
    triggeringMethod?: string;
};

export const triggerCommand = async ({commandMessage, ...options}: TriggerCommandProps) => {
    const command = parseMessageToCommand(commandMessage);

    await command.execute!({
        args: command.args!,
        message: commandMessage,
        ...options,
    });
};
