import {triggerCommand} from '../../shared';
import {FeatureSuggestionTexts} from '../../components/FeatureSuggestion/types';
import {UserDetailsType} from '@anywhere-expert/core';
import {AnalyticsDispatcher} from 'shisell';

export const description: string = 'See and control Wi-Fi status';

export const featureSuggestionTexts: FeatureSuggestionTexts = {
    headerText: 'View and control customer’s Wi-Fi status',
    subHeaderText: 'Check customer’s Wi-Fi status and turn it on or off',
    buttonText: 'See Wi-Fi status ',
    otherToolsButtonText: 'Explore other tools',
};

export const onFeatureSuggestionClick = (
    commandMessage: string,
    timelineId: string,
    sessionId: string,
    technicianData: UserDetailsType,
    dispatcher: AnalyticsDispatcher
) => {
    triggerCommand({
        timelineId,
        sessionId,
        expertData: technicianData,
        analyticsDispatcher: dispatcher,
        commandMessage,
        triggeringMethod: 'Suggestion',
    });
};
