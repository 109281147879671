import {withProps} from 'recompose';
import {SpeedTestContent} from '../types';

const Wifi0 = require('./resources/ic_wifi_0.svg');
const Wifi1 = require('./resources/ic_wifi_1.svg');
const Wifi2 = require('./resources/ic_wifi_2.svg');
const Wifi3 = require('./resources/ic_wifi_3.svg');
const Wifi4 = require('./resources/ic_wifi_4.svg');
const Cellular0 = require('./resources/ic_cellular_0.svg');
const Cellular1 = require('./resources/ic_cellular_1.svg');
const Cellular2 = require('./resources/ic_cellular_2.svg');
const Cellular3 = require('./resources/ic_cellular_3.svg');
const Cellular4 = require('./resources/ic_cellular_4.svg');

const allIcons: {[key: string]: string} = {
    Wifi0,
    Wifi1,
    Wifi2,
    Wifi3,
    Wifi4,
    Cellular0,
    Cellular1,
    Cellular2,
    Cellular3,
    Cellular4,
};

export default withProps<{signalIcon: string}, SpeedTestContent>(({isConnectedToWifi, signalStrength}) => {
    const networkType = isConnectedToWifi ? 'Wifi' : 'Cellular';
    const icon = allIcons[`${networkType}${signalStrength}`];
    return {
        signalIcon: icon ? icon : allIcons.Wifi3,
    };
});
