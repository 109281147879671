import React, {useCallback, useEffect, useMemo} from 'react';
import styled from 'styled-components';
import {CoreText} from '@anywhere-expert/base-ui';
import {RefreshLightIcon} from '@anywhere-expert/icons';
import type {AnalyticsDispatcher} from 'shisell';
import {withVisibilityIndicator} from '@anywhere-expert/system-messages';

const SearchUpdateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0px;
`;

const UpdateLogo = styled.div`
    margin-bottom: 5px;
`;

const UpdateTitle = styled(CoreText)`
    font-size: 13px;
    margin-bottom: 5px;
`;

const WatchResults = styled.div`
    display: flex;
`;

const ResultsLink = styled(CoreText)`
    text-decoration: underline;
    margin-left: 5px;
    cursor: pointer;
`;

type Props = {
    newResults: string;
    dispatcher: AnalyticsDispatcher;
};

const SearchRefine = ({newResults, dispatcher}: Props) => {
    const refinementDispatcher = useMemo(() => dispatcher.withExtras({Type: 'update', UpdateType: 'refinement'}), [
        dispatcher,
    ]);

    useEffect(() => {
        refinementDispatcher.dispatch('View');
    }, [refinementDispatcher]);

    const onLinkClick = useCallback(() => {
        window.open(newResults);
        refinementDispatcher.dispatch('Click');
    }, [newResults]);

    return (
        <SearchUpdateWrapper>
            <UpdateLogo>
                <RefreshLightIcon />
            </UpdateLogo>
            <UpdateTitle weight="medium">Customer refined their search</UpdateTitle>
            <WatchResults>
                <UpdateTitle>See their new </UpdateTitle>
                <ResultsLink onClick={onLinkClick}>Amazon results</ResultsLink>
            </WatchResults>
        </SearchUpdateWrapper>
    );
};

export default withVisibilityIndicator(SearchRefine);
