import React, {useCallback, CSSProperties} from 'react';
import styled from 'styled-components';
import {CoreText, CoreActionMenu} from '@anywhere-expert/base-ui';
import {palette} from '@anywhere-expert/colors';
import TagsSuggestions from './TagsSuggestions';
import CreateTagForm from './CreateTagForm';
import {onAddTagParams, addTagParams, SuggestedTags} from './types';
import useAddTagsMenu from './useAddTagsMenu';

const Content = styled.div`
    width: 260px;
    background: ${({theme}) => theme.colors.neutral.white};
    ${({theme}) => theme.themeName === 'dark' && `border: 1px solid ${theme.colors.contrast.caption};`}
    border-radius: 8px;
    padding: 16px 12px;
    z-index: 1;
`;

const containerStyle: CSSProperties = {position: 'unset'};

const childStyle: CSSProperties = {top: 44};

const Divider = styled.div`
    height: 1px;
    width: 100%s;
    background-color: ${palette.neutral.whites.w10};
    margin: 12px 0 16px 0;
`;

const Section = styled.div`
    padding: 0 4px;
`;

const Title = styled(CoreText)`
    margin-bottom: 16px;
`;

type Props = {
    suggestedTags: SuggestedTags;
    addTag: ({tagKey, backgroundColor, textColor}: addTagParams) => void;
};

const AddTagsMenu = ({suggestedTags, addTag}: Props) => {
    const {closeMenu, toggleMenu, ToggleButton, isMenuOpen, menuDispatcher} = useAddTagsMenu();

    const onAddTag = useCallback(
        ({tagKey, backgroundColor, textColor, source}: onAddTagParams) => {
            addTag({tagKey, backgroundColor, textColor});
            menuDispatcher.withExtras({ActionId: 'Add', TagType: source, TagName: tagKey}).dispatch('Click');
        },
        [menuDispatcher, addTag]
    );

    return (
        <CoreActionMenu
            containerStyle={containerStyle}
            extend={isMenuOpen}
            customButton={ToggleButton}
            childStyle={childStyle}
            onClickOut={closeMenu}
            onClick={toggleMenu}
        >
            <Content>
                <Section>
                    <Title textType="secondary" weight="bold">
                        Popular tags
                    </Title>
                    <TagsSuggestions suggestedTags={suggestedTags} onClick={onAddTag} />
                </Section>
                <Divider />
                <Section>
                    <Title textType="secondary" weight="bold">
                        Create your own
                    </Title>
                    <CreateTagForm onAddTag={onAddTag} />
                </Section>
            </Content>
        </CoreActionMenu>
    );
};

export default AddTagsMenu;
