import * as React from 'react';
import {AnalyticsDispatcher} from 'shisell';
import logger from '@anywhere-expert/logging';
import {withAnalytics} from 'react-shisell';

interface ErrorBoundaryProps {
    analytics: {dispatcher: AnalyticsDispatcher};
    commandType: string;
    errorComponent: React.ComponentType<any>;
    validStateComponent: React.ComponentType<any>;
}

interface ErrorBoundryState {
    renderError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundryState> {
    state = {
        renderError: false,
    };

    componentDidCatch(error, errorInfo) {
        const {
            analytics: {dispatcher},
            commandType,
        } = this.props;
        logger.error('Error caught in CommandResultItem', {
            err: error,
            extra: {
                componentStack: errorInfo.componentStack,
            },
        });
        dispatcher
            .createScoped('Tools')
            .createScoped('Result')
            .withExtra('ToolName', commandType)
            .dispatch('FailedRender');

        this.setState({renderError: true});
    }

    render() {
        const ErrorComponent = this.props.errorComponent;
        const ValidStateComponent = this.props.validStateComponent;

        if (this.state.renderError) {
            return <ErrorComponent />;
        } else {
            return <ValidStateComponent {...this.props} />;
        }
    }
}

export default withAnalytics(ErrorBoundary);
