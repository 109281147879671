import React from 'react';

export default ({color}) => (
    <svg width="38" height="36" viewBox="0 0 38 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.5379 0.435303C11.8119 -0.0405335 12.4317 0.178148 12.8735 0.188273C13.0637 1.0306 13.0351 1.89723 13.1025 2.75171C13.7121 2.74159 14.3236 2.67477 14.9311 2.74564C15.4199 2.87725 15.422 3.52925 15.2706 3.91195C14.8575 4.66721 13.8593 4.35133 13.1537 4.46675C13.1578 5.18759 13.2253 5.91046 13.1823 6.6313C13.1885 7.22255 12.4849 7.24685 12.0758 7.43313C11.1779 6.75481 11.5194 5.52776 11.4417 4.56597C10.5827 4.47282 9.68477 4.70163 8.85845 4.37968C8.89731 3.93827 8.80732 3.43611 9.10594 3.06354C9.80749 2.84891 10.5581 2.90358 11.2822 2.89548C11.5051 2.08959 11.1758 1.20879 11.5379 0.435303Z"
            fill={color}
        />
        <path
            d="M32.196 4.25779C33.4027 3.8832 34.8488 4.59594 35.2456 5.79262C35.7528 7.2505 34.8283 9.00603 33.3311 9.4272C32.061 9.83621 30.4942 9.12955 30.1281 7.8215C29.6556 6.32717 30.6947 4.65263 32.196 4.25779ZM32.4025 5.87969C31.7092 6.14494 31.4249 7.11079 31.8933 7.69596C32.695 8.35201 33.9447 7.42869 33.7218 6.48106C33.6666 5.87969 32.8975 5.6529 32.4025 5.87969Z"
            fill={color}
        />
        <path
            d="M19.7252 4.86112C20.1343 4.78418 20.5781 5.00083 20.6845 5.41998C21.2817 6.98315 21.746 8.5929 22.2164 10.1986C22.6644 11.6848 23.503 13.0232 24.5011 14.2078C25.9083 15.7224 27.9066 16.5687 29.9315 16.8988C30.3733 16.9919 30.8621 16.9899 31.2487 17.2471C31.4532 17.6257 31.7375 18.2595 31.2794 18.5612C30.5431 19.3205 29.3833 19.0249 28.4813 19.4298C26.5608 20.2013 25.0656 21.7402 23.9059 23.3985C22.3944 25.6177 21.4556 28.248 21.4024 30.935C21.4515 31.6234 20.4963 32.0324 19.9461 31.7064C19.4348 31.4331 19.445 30.7872 19.2732 30.3093C18.6923 28.3675 18.2137 26.3366 17.0151 24.6559C15.5384 22.5461 13.4767 20.7662 11.0284 19.8672C10.0343 19.4744 8.9319 19.5999 7.92559 19.278C7.39379 19.118 7.34675 18.4923 7.41629 18.0367C7.76196 17.4192 8.5351 17.405 9.14461 17.2531C11.2391 16.7388 13.221 15.8054 14.9616 14.5459C16.0047 13.821 16.8413 12.8248 17.4365 11.7152C18.3916 9.80578 18.8028 7.69792 19.2711 5.63663C19.3632 5.35113 19.3489 4.93199 19.7252 4.86112ZM18.9357 12.596C17.4896 15.4348 14.5607 17.075 11.7197 18.2878C15.7593 19.7477 18.9807 23.2163 20.167 27.3024C20.5454 26.4662 20.7581 25.5671 21.1631 24.743C22.4332 21.9771 24.5154 19.5493 27.2071 18.055C25.2456 17.3463 23.4457 16.097 22.3044 14.3455C21.2408 12.9625 20.6027 11.3244 20.1609 9.65594C19.6741 10.6036 19.4573 11.6626 18.9357 12.596Z"
            fill={color}
        />
        <path
            d="M0.715902 20.084C1.352 19.9322 2.06992 20.5093 1.85721 21.1795C1.75903 21.8072 0.994069 22.1757 0.445916 21.8274C-0.290409 21.463 -0.0551941 20.2481 0.715902 20.084Z"
            fill={color}
        />
        <path
            d="M36.1189 24.5639C36.89 24.2987 37.9351 24.977 37.6447 25.8416C37.4872 26.6637 36.4339 27.0383 35.7609 26.5543C34.9632 26.0886 35.2823 24.7887 36.1189 24.5639Z"
            fill={color}
        />
        <path
            d="M6.04183 24.7613C7.08904 24.4394 8.41034 25.2838 8.1649 26.446C8.08104 27.748 6.38954 28.4789 5.35868 27.6771C4.25829 26.9158 4.77576 25.0205 6.04183 24.7613Z"
            fill={color}
        />
        <path
            d="M27.7432 28.6193C28.5879 28.2933 28.9826 29.2774 29.4244 29.7836C30.0012 30.6239 31.1016 31.122 31.3614 32.1526C31.2243 33.1428 30.083 33.5052 29.6371 34.3334C29.1769 34.9247 28.9785 36.0019 28.054 35.9998C27.0764 35.8784 26.9352 34.6776 26.2132 34.1734C25.5996 33.5012 24.399 33.1934 24.3295 32.1628C24.4236 31.1342 25.6385 30.9884 26.2848 30.4093C26.7634 29.8099 26.9598 28.9311 27.7432 28.6193ZM26.6612 32.3025C27.1275 32.7237 27.5918 33.1489 28.054 33.5741C28.4631 33.1043 28.8701 32.6346 29.2751 32.1628C28.8742 31.7598 28.4754 31.3569 28.0765 30.956C27.6634 31.4622 27.1848 31.9076 26.6612 32.3025Z"
            fill={color}
        />
    </svg>
);
