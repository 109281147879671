import {useRef, useEffect, useState} from 'react';
import {firebaseProvider, initializeFirebaseProvider} from '@anywhere-expert/firebase-provider';
import {Observable} from 'rxjs/Rx';
import {equals} from 'ramda';
import logger from '@anywhere-expert/logging';
import {SessionToolsState} from './types';
import {Subscription} from 'rxjs';
import {useUserProfile} from '@anywhere-expert/auth';

const getSessionToolsState$ = (timelineId: string, sessionId: string): Observable<SessionToolsState> => {
    if (!firebaseProvider || !firebaseProvider.app || !firebaseProvider.app.database) {
        logger.error('error getting sessionToolsState', {
            err: new Error(`can't use firebaseProvider.app.database`),
            extra: {timelineId, sessionId},
        });
        return Observable.empty<SessionToolsState>();
    }

    return (firebaseProvider.app.database().ref('sessionToolsState').child(`${timelineId}/${sessionId}`) as any)
        .observe('value', {unpack: false})
        .map(snapshot => snapshot.val() as SessionToolsState)
        .distinctUntilChanged(equals)
        .catch(err => {
            logger.error('error getting sessionToolsState', {err, extra: {timelineId, sessionId}});
            return Observable.empty<SessionToolsState>();
        });
};

const useInitFirebaseIfNeeded = () => {
    const {accessToken} = useUserProfile();
    const [initialized, setInitialized] = useState<boolean>(false);

    useEffect(() => {
        async function initFirebase() {
            if (!accessToken) return;
            try {
                await initializeFirebaseProvider(accessToken);
                setInitialized(true);
            } catch (err) {
                logger.error('could not initialize firebase for session tools state', {err});
            }
        }
        initFirebase();
    }, [accessToken, setInitialized]);

    return initialized;
};

const useSessionToolsState = (customerId: string, sessionId: string): SessionToolsState | undefined => {
    const [state, setState] = useState<SessionToolsState | undefined>(undefined);
    const ref = useRef<Subscription | undefined>(undefined);
    const firebaseReady = useInitFirebaseIfNeeded();

    useEffect(() => {
        if (ref.current) {
            ref.current.unsubscribe();
            setState(undefined);
        }

        if (firebaseReady) {
            ref.current = getSessionToolsState$(customerId, sessionId).subscribe(setState);
        }
    }, [customerId, sessionId, firebaseReady]);

    // unsubscribe on unmount
    useEffect(() => () => ref.current && ref.current.unsubscribe(), []);

    return state;
};

export default useSessionToolsState;
