import {ExpertFeedStore} from '@anywhere-expert/expert-feed-store';
import {getFeedActionsClient} from '@anywhere-expert/expert-feed-actions';
import {ResolveProgress} from '../common/types';
import {useCallback, useState} from 'react';
import {setAppMessage} from '@anywhere-expert/app-messages';
import logger from '@anywhere-expert/logging';

export type ResolveAction = [() => Promise<void>, ResolveProgress];

export const useResolveAction = (sessionId: string): ResolveAction => {
    const [resolveState, setResolveState] = useState<ResolveProgress>('IDLE');

    const onResolve = useCallback(async () => {
        setResolveState('PENDING');
        try {
            await getFeedActionsClient().resolveFeedItem({feedItemId: sessionId});
            setAppMessage({text: 'Session resolved', type: 'success'});
            await ExpertFeedStore.selectSession(undefined);
            ExpertFeedStore.handleRemove(sessionId);
        } catch (err) {
            logger.error('failed resolving session', {err, extra: {sessionId}});
            setAppMessage({text: 'Failed resolving session, try again', type: 'warning'});
            setResolveState('ERROR');
        }
    }, [sessionId]);

    return [onResolve, resolveState];
};
