import React from 'react';
import moment from 'moment';
import {SystemMessageItem, IconType} from '@anywhere-expert/system-messages';
import {VisualComponentType} from '@slash-commands/command-visual-component';
import {AppsIcon} from '@anywhere-expert/icons';
import AppList from './AppList';
const AppsIconSVG = require('./AppsIcon.svg');

export {description} from '../shared';
export const icon: IconType = AppsIconSVG;
export const iconOutline = AppsIcon;

const filterRelevantApps = (apps, days) => {
    let filteredApps =
        days >= 0
            ? apps.filter(x => moment.duration(moment().diff(moment(x.firstInstallTime))).asDays() <= days)
            : apps;

    return filteredApps.filter(x => !x.isSystemApp).sort((a, b) => (a.firstInstallTime < b.firstInstallTime ? 1 : -1));
};

export const Component: VisualComponentType = ({commandPayload, timelineId}) => {
    const {apps, days} = commandPayload;

    if (!apps)
        return <SystemMessageItem message={"Couldn't fetch the list of apps, please try again later"} icon={icon} />;

    const filteredApps = filterRelevantApps(apps, days);

    if (filteredApps.length === 0)
        return (
            <SystemMessageItem message={`No apps were installed in the last ${commandPayload.days} days`} icon={icon} />
        );

    return <AppList timelineId={timelineId} apps={filteredApps} days={days} />;
};
