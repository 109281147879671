import React, {useEffect} from 'react';
import {useTweekValue} from 'react-tweek';
import {useAnalytics} from 'react-shisell';
import {CoreIcon} from '@anywhere-expert/base-ui';
import {ResolveIcon} from '@anywhere-expert/icons';
import {CoreText} from '@anywhere-expert/base-ui';
import ConversationOverlayBarContainer from './conversation-unavailable-bar/ConversationOverlayBarContainer';
import styled from 'styled-components';

const IconWrapper = styled(CoreIcon)`
    margin-right: 5px;
`;

const ExpertAlertBarContainer = styled.div`
    display: flex;
    align-items: center;
`;

const warningIcon = require('./conversation-unavailable-bar/unavailable_warning.svg');

export type ExpertAlertBarProps = {
    textKey: string;
};

export default ({textKey}: ExpertAlertBarProps) => {
    const analytics = useAnalytics();

    useEffect(() => {
        analytics.dispatcher.createScoped('ExpertAlertBar').dispatch('View');
    }, []);

    const text = useTweekValue<string>(`support/session/video/expert_notifications/${textKey}`, '');
    const icon = <IconWrapper icon={ResolveIcon} />;

    return (
        <ConversationOverlayBarContainer>
            <ExpertAlertBarContainer>
                {icon || <CoreIcon size="s" icon={warningIcon} style={{marginBottom: '6px'}} />}
                <CoreText size="m" textType="primary" weight="medium">
                    {text}
                </CoreText>
            </ExpertAlertBarContainer>
        </ConversationOverlayBarContainer>
    );
};
