import appsRepository from './appsRepository';
import commandSender from '../shared/commandSender';
import {ExecuteCommandParameters, ExecuteFactory, GetCommandTweekKey} from '../shared/types';
import sendLegacyItem from '../shared/sendLegacyItem';

export const getCommandTweekKey: GetCommandTweekKey = () => 'app_link';

export const executeFactory: ExecuteFactory = (name: string) => async ({
    timelineId,
    args,
    ...other
}: ExecuteCommandParameters) => {
    const appName = args[0].toLowerCase();

    await commandSender({
        timelineId,
        commandType: name,
        contentType: 'AppLink',
        tweekToolKey: getCommandTweekKey(),
        analyticsScope: 'AppLink',
        args,
        validateArgs: validateArgsFactory(appsRepository),
        extraTimelineItemProps: {...appsRepository[appName]},
        onFailedValidation: () =>
            sendLegacyItem({
                extraTimelineItemProps: {
                    commandArgs: args,
                    commandType: name,
                    type: 'app-not-found',
                },
                expertData: other.expertData,
                isExpertOnly: true,
                content: {message: other.message},
                subtype: 'AppLink',
                sessionId: other.sessionId,
            }),
        ...other,
    });
};

const validateArgsFactory = appsRepository => (args: string[]) => {
    const appName = args[0];
    const isAppInRepository = appName && appsRepository[appName.toLowerCase()];
    return isAppInRepository;
};

export const requiresArgs = true;
