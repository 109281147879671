import {showEscalate} from '@anywhere-expert/escalate';
import {useAnalytics} from 'react-shisell';
import {useCallback} from 'react';

export const useEscalate = (closeMenu: () => void) => {
    const {dispatcher} = useAnalytics();

    return useCallback(() => {
        dispatcher.withExtra('ActionId', 'AddAnExpertButton').dispatch('Click');
        closeMenu();
        showEscalate();
    }, [dispatcher]);
};
