import React, {useCallback} from 'react';
import styled from 'styled-components';
import {useAnalytics} from 'react-shisell';

type Props = {
    color: string;
    minutes: string;
    setMinutes: (value: number) => void;
    hours: number;
    setHours: (value: number) => void;
};

const isNumber = x => x === '' || /^[0-9\b]+$/.test(x);

const inputStringToNumber = x => parseInt(x) || 0;

const Container = styled.div`
    display: flex;
    align-self: center;
`;

const TimeArea = styled.div`
    flex-direction: column;
`;

const HoursTimeArea = styled(TimeArea)`
    margin-right: 10px;
`;

const TimeInput = styled.input`
    text-align: right;
    border: 0;
    background-color: unset;
    border-bottom: 1px;
    border-bottom-style: solid;
    font-family: Roboto;
    font-size: 28px;
    line-height: 28px;
    cursor: default;
    max-width: 40px;
    &:focus {
        outline: none;
    }
`;

const Hours = styled(TimeInput)<{color: string}>`
    align-self: flex-end;
    border-bottom-color: ${({theme}) => theme.colors.neutral.grey.deep};
    color: ${({color}) => color};
`;

const Units = styled.div`
    font-family: Roboto;
    font-size: 12px;
    font-style: italic;
    cursor: default;
    text-align: right;
    color: ${({theme}) => theme.colors.neutral.grey.deep};
`;

const Minutes = styled(TimeInput)`
    align-self: flex-start;
    border-bottom-color: ${({theme}) => theme.colors.neutral.grey.deep};
    color: ${({color}) => color};
`;

const WakeUpTime = ({color, hours, minutes, setHours, setMinutes}: Props) => {
    const analytics = useAnalytics();
    const handleOnHourChange = useCallback(
        ({target: {value}}) => {
            if (isNumber(value)) {
                setHours(inputStringToNumber(value));
            }
        },
        [setHours]
    );
    const handleOnMinutesChange = useCallback(
        ({target: {value}}) => {
            if (isNumber(value)) {
                setMinutes(inputStringToNumber(value));
            }
        },
        [setMinutes]
    );
    const handleMinutesClick = useCallback(() => {
        analytics.dispatcher.withExtra('ActionId', 'MinutesInputFocus').dispatch('Click');
    }, [analytics]);
    const handleHoursClick = useCallback(() => {
        analytics.dispatcher.withExtra('ActionId', 'HoursInputFocus').dispatch('Click');
    }, [analytics]);

    return (
        <Container>
            <HoursTimeArea>
                <Hours color={color} value={hours} onChange={handleOnHourChange} onClick={handleHoursClick} autoFocus />
                <Units>hrs</Units>
            </HoursTimeArea>
            <TimeArea>
                <Minutes color={color} value={minutes} onChange={handleOnMinutesChange} onClick={handleMinutesClick} />
                <Units>min</Units>
            </TimeArea>
        </Container>
    );
};

export default WakeUpTime;
