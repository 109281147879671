import React from 'react';
import {SystemMessageItem, IconType} from '@anywhere-expert/system-messages';
import {VisualComponentType} from '@slash-commands/command-visual-component';
import {PowerIcon} from '@anywhere-expert/icons';
import getFormattedMessage from '../getFormattedMessage';
const BatteryIconSVG = require('./ic-battery.svg');

export {description} from '../shared';

export const icon: IconType = BatteryIconSVG;
export const iconOutline = PowerIcon;

export const Component: VisualComponentType = ({commandPayload}) => {
    return <SystemMessageItem message={getFormattedMessage(commandPayload)} icon={icon} />;
};
