import React, {useCallback, useEffect, useMemo} from 'react';
import styled from 'styled-components';
import {CoreText} from '@anywhere-expert/base-ui';
import type {AnalyticsDispatcher} from 'shisell';
import {MagnifyingGlassIcon} from '@anywhere-expert/icons';
import {withVisibilityIndicator} from '@anywhere-expert/system-messages';

const SearchUpdateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0px;
`;

const UpdateLogo = styled.div`
    margin-bottom: 5px;
`;

const UpdateTitle = styled(CoreText)`
    margin-bottom: 5px;
    margin-left: 3px;
    font-size: 13px;
`;

const WatchResults = styled.div`
    display: flex;
    font-size: 13px;
    font-family: 'Roboto';
    max-width: 400px;
`;

const ResultsLink = styled(CoreText)`
    text-decoration: underline;
    margin-left: 5px;
    cursor: pointer;
    line-height: 130%;
    font-size: 13px;
`;

type Props = {
    newResults: string;
    newQuery: string;
    dispatcher: AnalyticsDispatcher;
};

const SearchUpdate = ({newResults, newQuery, dispatcher}: Props) => {
    const searchDispatcher = useMemo(() => dispatcher.withExtras({Type: 'update', UpdateType: 'search'}), [dispatcher]);

    useEffect(() => {
        searchDispatcher.dispatch('View');
    }, [searchDispatcher]);

    const onLinkClick = useCallback(() => {
        window.open(newResults);
        searchDispatcher.dispatch('Click');
    }, [newResults]);

    return (
        <SearchUpdateWrapper>
            <UpdateLogo>
                <MagnifyingGlassIcon />
            </UpdateLogo>
            <UpdateTitle weight="medium">Customer changed their search</UpdateTitle>
            <WatchResults>
                <ResultsLink onClick={onLinkClick}>See their results</ResultsLink>
                <UpdateTitle>for: {newQuery}</UpdateTitle>
            </WatchResults>
        </SearchUpdateWrapper>
    );
};

export default withVisibilityIndicator(SearchUpdate);
