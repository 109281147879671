import * as React from 'react';
import {StyleSheet, css} from 'aphrodite';
import moment from 'moment';
import {compose, mapPropsStream} from 'recompose';
import withTriggerCommandProps, {TriggerCommandProps} from '../../../shared/withTriggerCommandProps';
import {AnalyticsDispatcher} from 'shisell';
import {palette, useTheme} from '@anywhere-expert/colors';
import {Observable} from 'rxjs';
import {getFromTweek} from '@anywhere-expert/tweek';
import axios from 'axios';
import logger from '@anywhere-expert/logging';

const styles = StyleSheet.create({
    appBlock: {
        margin: '0 42px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: 90,
    },
    appIcon: {
        width: 45,
        height: 45,
    },
    appName: {
        marginTop: 8,
        width: 'inherit',
        textAlign: 'center',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    appInstallationDate: {
        marginTop: 2,
        fontSize: 12,
        fontStyle: 'italic',
        color: palette.neutral.grey.deeper,
    },
    appVersion: {
        marginTop: 7,
        fontSize: 11,
        fontStyle: 'italic',
        color: '#c5c5cb',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    approvalContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 10,
        width: 110,
        borderRadius: 10,
        border: 'solid 1px #00aeef',
        padding: 6,
    },
    approvalText: {
        marginTop: 2,
        fontSize: 12,
        textAlign: 'center',
        color: palette.neutral.grey.deepest,
        fontFamily: 'Roboto',
        lineHeight: '1.36',
    },
    approvalButtonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 6,
    },
    approvalButton: {
        width: 20,
        height: 20,
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 5,
        backgroundColor: '#d0f2ff',
        fontSize: 12,
        fontFamily: 'Roboto',
        color: '#00aeef',
        borderRadius: 25,
        cursor: 'pointer',
        outline: 'none',
        ':hover': {
            backgroundColor: '#00aeef',
        },
        ':active': {
            backgroundColor: '#00aeef',
        },
    },
    dataContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

const AppComponent: React.SFC<EnhancedAppComponentProps> = ({app, appDefaultIcon}) => {
    const theme = useTheme();

    const icon =
        app.appIconBase64 && app.appIconBase64.length > 0 && isBase64(app.appIconBase64)
            ? 'data:image/png;base64,' + app.appIconBase64
            : appDefaultIcon;
    return (
        <div className={css(styles.appBlock)}>
            <img className={css(styles.appIcon)} src={icon} />
            <span className={css(styles.appName)} style={{color: theme.colors.neutral.grey.deepest}} title={app.label}>
                {app.label}
            </span>
            <div className={css(styles.dataContainer)}>
                <span className={css(styles.appInstallationDate)}>
                    {moment(app.firstInstallTime).format('MMM DD, YYYY')}
                </span>
                <span className={css(styles.appVersion)}>v{app.versionName}</span>
            </div>
        </div>
    );
};

const getStringHash = (str: string) => str.split('').reduce((accumulator, char) => accumulator + char.charCodeAt(0), 0);

const isBase64 = (str: string) => {
    try {
        atob(str);
        return true;
    } catch (err) {
        return false;
    }
};

const getRandomAppIcon = (packageName: string): string => {
    const appRandom = (getStringHash(packageName) % 4) + 1;
    return require(`./AppIcons/app-${appRandom}.svg`);
};

const getAppIconFromApi = async (packageName: string) => {
    const {data: content} = await axios.get(`https://appstoresapi-production.mysoluto.com/appicon/${packageName}`);
    return content.appIconLink;
};

const getAppIcon = async (packageName: string) => {
    const is_app_icon_enabled = await getFromTweek('support/session/messaging/tools/apps_list/is_app_icon_enabled');
    if (is_app_icon_enabled) {
        try {
            const iconName = `app_icon_${packageName}`;
            const iconFromStorage = sessionStorage.getItem(iconName);

            if (iconFromStorage !== null) {
                return iconFromStorage;
            }

            const iconFromApi = await getAppIconFromApi(packageName);
            if (iconFromApi) {
                sessionStorage.setItem(iconName, iconFromApi);
                return iconFromApi;
            }
        } catch (err) {
            if (err.response && err.response.status !== 404) {
                logger.warn('Failed getting app icon for apps installed tool', {err, extra: {packageName}});
            }
        }
    }

    return getRandomAppIcon(packageName);
};

type AppData = {
    label: string;
    package: string;
    versionName: string;
    appIconBase64?: string;
    firstInstallTime: Date;
};

interface AppComponentState {
    isVHover: boolean;
    isXHover: boolean;
}

type AppComponentStateHandlers = {
    setIsVHover(boolean): Partial<AppComponentState>;
    setIsXHover(boolean): Partial<AppComponentState>;
};

type AppComponentProps = {
    timelineId: string;
    analyticsDispatcher: AnalyticsDispatcher;
    app: AppData;
};

export type EnhancedAppComponentProps = AppComponentProps &
    TriggerCommandProps & {appDefaultIcon: any} & AppComponentState &
    AppComponentStateHandlers;

const withAppIcon = mapPropsStream((props$: any) => {
    const icon$ = Observable.from(props$)
        .pluck('app')
        .pluck('package')
        .distinctUntilChanged()
        .flatMap(packageName => {
            const promise = getAppIcon(String(packageName));
            return Observable.fromPromise(promise);
        })
        .startWith(require(`./AppIcons/empty-app.svg`));

    return Observable.combineLatest(props$, icon$, (props: any, icon) => ({
        ...props,
        appDefaultIcon: icon,
    }));
});

const enhance = compose<EnhancedAppComponentProps, AppComponentProps>(withAppIcon, withTriggerCommandProps);

export default enhance(AppComponent);
