import {Location} from 'history';

// This is a hack to get around the fact react-router won't give us the global match object
// https://github.com/ReactTraining/react-router/issues/5870
// so we manually find whether we are in session url and extract params ourselves
function extractSessionIdFromPath(path) {
    const match = path.match(/session\/([a-z0-9-]*)/i);
    if (match) return match[1];
    return undefined;
}

function extractSessionIdFromQuery(path) {
    const match = path.match(/[?&]sessionId=([a-z0-9-]*)/i);
    if (match) return match[1];
    return undefined;
}

function extractTimelineIdFromQuery(path) {
    const match = path.match(/[?&]timelineId=([a-z0-9-]*)/i);
    if (match) return match[1];
    return undefined;
}
function extractTimelineIdFromPath(path) {
    const match = path.match(/timeline\/([a-z0-9-]*)/i);
    if (match) return match[1];
    return undefined;
}

export default function extractSessionDetailsFromLocation(location: Location<any>) {
    const path = `${location.pathname}${location.search}`;
    const routeStateItem = (location.state && location.state.feedItem) || {};

    return {
        sessionId: routeStateItem.sessionId || extractSessionIdFromPath(path) || extractSessionIdFromQuery(path),
        customerId: routeStateItem.customerId || extractTimelineIdFromQuery(path) || extractTimelineIdFromPath(path),
        pool: routeStateItem.pool,
    };
}
