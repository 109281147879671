import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

const style = {color: '#00aeef'};

const Wrapper = styled.div`
    margin: auto;
`;

const Spinner = () => (
    <Wrapper>
        <CircularProgress size={24} thickness={2} variant="indeterminate" style={style} />
    </Wrapper>
);

export default Spinner;
