import {reaction, observable, IReactionDisposer, makeObservable} from 'mobx';
import LRUCache from 'lru-cache';
import {TaskItem} from '@anywhere-expert/expert-feed-store';
import {ExpertFeedStore} from '@anywhere-expert/expert-feed-store';
import TaskItemModel from './TaskItemModel';
import {authorizedFetch} from '@anywhere-expert/auth';
import {configuration as config} from '@anywhere-expert/core';
import {ExpertTasksApi} from '@soluto-private/expert-tasks-api-client';

class TasksStore {
    expertTasksClient: ExpertTasksApi;
    private selectedSessionReaction: IReactionDisposer | undefined;
    private tasksCache = new LRUCache<string, TaskItemModel>({
        max: 10,
        maxAge: 1000 * 60 * 60,
    });
    selectedTask: TaskItemModel | undefined;

    constructor() {
        makeObservable(this, {
            selectedTask: observable,
        });

        this.expertTasksClient = new ExpertTasksApi({}, config.expertTasksApiUrl, authorizedFetch);
        this.selectedSessionReaction = reaction(
            () => ExpertFeedStore.selectedTaskSession,
            selectedTaskSession => {
                if (!selectedTaskSession) return;
                if (this.selectedTask?.id === selectedTaskSession.id) return;

                this.selectedTask = this.getOrCreateModel(selectedTaskSession);
            },
            {fireImmediately: true}
        );
    }

    private getOrCreateModel(expertTask: TaskItem) {
        let task = this.tasksCache.get(expertTask.sessionId);
        if (task) return task;

        task = new TaskItemModel(this, expertTask.sessionId, expertTask);
        this.tasksCache.set(expertTask.sessionId, task);

        return task;
    }

    dtor() {
        if (this.selectedSessionReaction) {
            this.selectedSessionReaction = undefined;
        }
        this.tasksCache.reset();
    }
}

export default TasksStore;
