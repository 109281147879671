import {mapProps} from 'recompose';
import {TimelineItemProps} from '@anywhere-expert/timeline-behaviours/timelineItemsRenderer';
import {EscalateTimelineItem, EscalateTimelineItemProps} from '@anywhere-expert/escalate';

const enhance = mapProps<EscalateTimelineItemProps, TimelineItemProps>(
    ({content: {triggeringExpert, joinedExpert, message, isCarrierRelated}}) => ({
        triggeringExpert,
        joinedExpert,
        message,
        isCarrierRelated,
    })
);

export default enhance(EscalateTimelineItem);
