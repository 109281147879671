import React, {ComponentType} from 'react';
import {StyleSheet, css} from 'aphrodite';
import {useTheme} from '@anywhere-expert/colors';
import {SystemMessageItem, IconType} from '@anywhere-expert/system-messages';
import FeatureSuggestionComponent from '@slash-commands/feature-suggestion';
import {VisualComponentType, VisualComponentProps} from '@slash-commands/command-visual-component';
import {WifiIcon} from '@anywhere-expert/icons';
import getFormattedMessage from '../getFormattedMessage';
import {featureSuggestionTexts, onFeatureSuggestionClick} from '../shared';
import Scan from './components/Scan';
import Status from './components/Status';
import Connect from './components/Connect/Connect';
const WifiIconSVG = require('./assets/ic_wifi_4.svg');
const suggestionIcon = require('./assets/ic_suggestion.svg');

const styles = StyleSheet.create({
    message: {
        marginTop: '24px',
        marginBottom: '24px',
        textAlign: 'center',
        fontSize: '14px',
        fontFamily: 'Roboto',
        lineHeight: '1.54',
    },
});

export {description} from '../shared';

export const icon: IconType = WifiIconSVG;
export const iconOutline = WifiIcon;

export const Component: ComponentType<VisualComponentProps> = ({commandPayload, contentId}: VisualComponentProps) => {
    const {
        colors: {neutral},
    } = useTheme();
    const commandResult = typeof commandPayload === 'string' ? JSON.parse(commandPayload) : commandPayload;
    if (commandResult.osType === 'android') {
        const action = commandResult.action;

        if (commandResult.error) {
            return (
                <div className={css(styles.message)} style={{color: neutral.grey.deepest}}>
                    {'Something went wrong, please try again later'}
                </div>
            );
        }
        if (!action || action === 'status' || action === 'enable' || action === 'disable') {
            return <Status commandResult={commandResult} dataTestId={`WifiCommandResult_${contentId}`} />;
        } else if (action === 'scan') {
            return <Scan commandResult={commandResult} dataTestId={`WifiCommandResult_${contentId}`} />;
        } else if (action === 'connect') {
            return <Connect commandResult={commandResult} dataTestId={`WifiCommandResult_${contentId}`} />;
        } else {
            return null;
        }
    } else {
        return (
            <SystemMessageItem
                dataTestId={`WifiCommandResult_${contentId}`}
                message={getFormattedMessage(commandPayload)}
                icon={icon}
                subMessage={'Extended features are available for customers with Android devices'}
            />
        );
    }
};

const commandName = 'wifi';
export const Suggestion: VisualComponentType = ({timelineId}) => (
    <FeatureSuggestionComponent
        timelineId={timelineId}
        featureSuggestionTexts={featureSuggestionTexts}
        suggestionIcon={suggestionIcon}
        handleClick={onFeatureSuggestionClick}
        commandName={commandName}
        featureCommand={`/${commandName}`}
        analyticsActionId={'RunWifi'}
    />
);
