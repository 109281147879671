import React from 'react';
import {StyleSheet, css} from 'aphrodite';
import WifiSuggestedCommands from '../../../WifiSuggestedCommands.web';
import ConnectToSecureWifi from './ConnectToSecureWifi';
import FailedToConnect from './FailedToConnect';
import {useTheme} from '@anywhere-expert/colors';

const styles = StyleSheet.create({
    mainContainer: {
        marginTop: '24px',
        marginBottom: '24px',
        alignSelf: 'center',
        display: 'flex',
        flexShrink: 0,
        flexDirection: 'column',
    },
    message: {
        textAlign: 'center',
        fontSize: '14px',
        fontFamily: 'Roboto',
        lineHeight: 1.54,
    },
    bold: {
        fontWeight: 500,
    },
});

type Props = {
    commandResult: any;
    dataTestId: string;
};

const Connect = ({commandResult, dataTestId}: Props) => {
    const theme = useTheme();

    let message;
    let suggestions;
    if (commandResult.isSecureNetwork) {
        return <ConnectToSecureWifi commandResult={commandResult} dataTestId={dataTestId} />;
    } else if (commandResult.success === false) {
        return <FailedToConnect commandResult={commandResult} dataTestId={dataTestId} />;
    } else if (commandResult.success) {
        message = (
            <div className={css(styles.message)} style={{color: theme.colors.neutral.grey.deepest}}>
                {'Now connected to '}
                <span className={css(styles.bold)}>{commandResult.ssid}</span>
            </div>
        );
        suggestions = <WifiSuggestedCommands showWifiStatusSuggestion={true} />;
    }

    return (
        <div className={css(styles.mainContainer)} data-test-id={dataTestId}>
            {message}
            {suggestions}
        </div>
    );
};

export default Connect;
