import React, {useState} from 'react';
import {TabProps, SidebarTabContainer} from '@anywhere-expert/session-sidebar';
import {getTabDisabledText} from '../../shared/getTabDisabledText';
import AppListTab from '../AppListTab';
import CommandsTab from '../CommandsTab';
import useSessionToolsState from '../../shared/useSessionToolsState';
import {CoreTabs} from '@anywhere-expert/base-ui';
import DeviceDetails from './DeviceDetails';
import {DeviceToolsTabs, tabs} from './consts';
import styled from 'styled-components';

interface DeviceToolsType extends React.FunctionComponent<TabProps> {
    getDisabledText?: typeof getTabDisabledText;
}

const StyledCoreTabs = styled(CoreTabs)`
    padding-top: 13px;
`;

const Content = styled.div`
    padding-top: 16px;
`;

const DeviceDetailsContainer = styled.div`
    margin-top: 16px;
`;

const StyledCoreTabsContainer = styled.div`
    margin-top: 10px;
`;

const DeviceTools: DeviceToolsType = ({selectedSession}: TabProps) => {
    const [selectedTab, setSelectedTab] = useState<string>(DeviceToolsTabs.commands);
    const sessionToolsState = useSessionToolsState(selectedSession.id, selectedSession.sessionId);
    const title = selectedSession.deviceDetails.deviceModel || 'Device info';

    return (
        <SidebarTabContainer title={title} addChildrenSpace={false}>
            <DeviceDetailsContainer>
                <DeviceDetails sessionToolsState={sessionToolsState} selectedSession={selectedSession} />
            </DeviceDetailsContainer>
            <StyledCoreTabsContainer>
                <StyledCoreTabs tabs={tabs} selectedTab={selectedTab} onTabSelected={setSelectedTab} />
            </StyledCoreTabsContainer>

            <Content>
                {selectedTab === DeviceToolsTabs.appList && (
                    <AppListTab sessionToolsState={sessionToolsState} selectedSession={selectedSession} />
                )}
                {selectedTab === DeviceToolsTabs.commands && <CommandsTab selectedSession={selectedSession} />}
            </Content>
        </SidebarTabContainer>
    );
};

DeviceTools.getDisabledText = getTabDisabledText;

export default DeviceTools;
