import React from 'react';
import styled from 'styled-components';
import {palette} from '@anywhere-expert/colors';
import {CloseIcon} from '@anywhere-expert/icons';
import {CoreIconButton} from '@anywhere-expert/base-ui';
import Question from './Question';
import ThankYou from './ThankYou';
import Reasons from './Reasons';
import {FeedbackStep} from '@anywhere-expert/expert-feed-store';
import {observer} from 'mobx-react';
import {AnalyticsProvider, useAnalytics} from 'react-shisell';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-bottom: 8px;
    background-color: ${({theme}) => theme.colors.background.secondary};
`;

const Divider = styled.div`
    background-color: ${palette.neutral.grey.light};
    height: 1px;
    width: 100%;
    margin-bottom: 16px;
`;

const CloseButton = styled(CoreIconButton)`
    position: absolute;
    right: 0;
    top: 10px;
`;

type Props = {
    expertName: string;
    onClose: () => void;
    feedbackStep: FeedbackStep;
    setFeedback: (value) => void;
    setReason: (reason) => void;
    clearSelectedFeedback: () => void;
};

const useFeedbackAnalytics = () => {
    const {dispatcher} = useAnalytics();
    const enhanced = React.useMemo(() => dispatcher.createScoped('Feedback'), [dispatcher]);

    React.useEffect(() => {
        enhanced.dispatch('View');
    }, [enhanced]);

    return enhanced;
};

const getComponentByStep = ({expertName, feedbackStep, setFeedback, setReason, clearSelectedFeedback}: Props) => {
    switch (feedbackStep) {
        case FeedbackStep.QUESTION:
            return <Question expertName={expertName} setFeedback={setFeedback} />;
        case FeedbackStep.REASON:
            return <Reasons setReason={setReason} clearSelectedFeedback={clearSelectedFeedback} />;
        case FeedbackStep.THANK_YOU:
            return <ThankYou />;
        default:
            return <Question expertName={expertName} setFeedback={setFeedback} />;
    }
};

const Feedback = observer((props: Props) => {
    const {onClose} = props;
    const dispatcher = useFeedbackAnalytics();
    const close = React.useCallback(() => {
        dispatcher.withExtra('ActionId', 'Close').dispatch('Click');
        onClose();
    }, [onClose, dispatcher]);
    return (
        <AnalyticsProvider dispatcher={dispatcher}>
            <Container>
                <CloseButton size="s" onClick={close} variant="icon" color="default" icon={CloseIcon} />
                <Divider />
                {getComponentByStep(props)}
            </Container>
        </AnalyticsProvider>
    );
});

export default Feedback;
