import React, {useMemo} from 'react';
import {AmazonBubbleIcon, AmazonPrimeBubbleIcon} from '@anywhere-expert/icons';
import {CoreText} from '@anywhere-expert/base-ui';
import SectionComponent from './SectionComponent';
import MissingDataTooltip from './MissingDataTooltip';

type Props = {prime?: boolean; customerName?: string};

const PrimeSectionComponent = ({prime, customerName}: Props) => {
    const AmazonImage = useMemo(() => (prime ? AmazonPrimeBubbleIcon : AmazonBubbleIcon), [prime]);
    const mainText = useMemo(() => {
        if (prime)
            return (
                <>
                    They are an <strong>Amazon Prime</strong> customer
                </>
            );
        if (prime === false)
            return (
                <>
                    They are an Amazon customer <strong>(Non-Prime)</strong>
                </>
            );
        return <>They are an Amazon customer</>;
    }, [prime]);

    return (
        <SectionComponent image={AmazonImage}>
            <CoreText size="m" family="apercu">
                {mainText}
            </CoreText>

            {prime === undefined && (
                <CoreText size="m" family="apercu" textType={'tertiary'}>
                    Prime - <strong>Unknown</strong> <MissingDataTooltip customerName={customerName} />
                </CoreText>
            )}
        </SectionComponent>
    );
};

export default PrimeSectionComponent;
