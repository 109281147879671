import React from 'react';
import {SystemMessageItem, IconType} from '@anywhere-expert/system-messages';
import {VisualComponentType} from '@slash-commands/command-visual-component';
import {BluetoothIcon} from '@anywhere-expert/icons';
import getFormattedMessage from '../getFormattedMessage';
const BluetoothIconSVG = require('./ic-bluetooth.svg');

export {description} from '../shared';

export const icon: IconType = BluetoothIconSVG;
export const iconOutline = BluetoothIcon;

export const Component: VisualComponentType = ({commandPayload}) => {
    return <SystemMessageItem message={getFormattedMessage(commandPayload)} icon={icon} />;
};
