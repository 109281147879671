import {default as moment} from 'moment';
import {Message} from '@soluto-private/messaging-api-client';

const shouldRenderTimestamp = (currentTimestamp, prevTimestamp) => {
    if (prevTimestamp === undefined) {
        return currentTimestamp !== undefined;
    }

    if (currentTimestamp === undefined) {
        return false;
    }

    if (prevTimestamp > currentTimestamp) {
        return false;
    }

    const currentDate = moment(currentTimestamp).hour(0).minute(0).second(0);
    const prevDate = moment(prevTimestamp).hour(0).minute(0).second(0);

    return !currentDate.isSame(prevDate, 'day');
};

const createTimestampMessage = (timestamp: number): Message => ({
    createdAt: timestamp,
    messageId: 'time' + timestamp,
    payload: {
        type: 'timestamp',
        value: {},
    },
    senderId: '',
    recipientId: '',
    sentAt: timestamp,
    metadata: {
        senderType: 'Team',
    },
});

export const enrichWithTimestamps = (messages: Message[]): Message[] => {
    const result: Message[] = [];

    for (let i = 0; i < messages.length; i++) {
        const currentTimestamp = messages[i].createdAt;
        const prevTimestamp = i !== 0 ? messages[i - 1].createdAt : undefined;

        if (shouldRenderTimestamp(currentTimestamp, prevTimestamp)) {
            result.push(createTimestampMessage(currentTimestamp));
        }

        result.push(messages[i]);
    }

    return result;
};
