import * as React from 'react';
import {StyleSheet, css} from 'aphrodite';
import WifiSuggestedCommands from '../../WifiSuggestedCommands.web';
import {useTheme} from '@anywhere-expert/colors';

const styles = StyleSheet.create({
    mainContainer: {
        marginTop: '24px',
        marginBottom: '24px',
        alignSelf: 'center',
        display: 'flex',
        flexShrink: 0,
        alignItems: 'center',
        flexDirection: 'column',
    },
    message: {
        marginRight: '10px',
        textAlign: 'center',
        fontSize: '14px',
        fontFamily: 'Roboto',
        lineHeight: '1.54',
    },
    subMessage: {
        textAlign: 'center',
        fontSize: '13px',
        fontFamily: 'Roboto',
        lineHeight: '1.54',
    },
});

type Props = {
    commandResult: any;
    dataTestId: string;
};

const Status = ({commandResult, dataTestId}: Props) => {
    const theme = useTheme();

    let wifiStatusMessage;
    let networkSubMessage;
    wifiStatusMessage = `Customer's Wi-Fi is ${
        commandResult.action === 'enable' || commandResult.action === 'disable' ? 'now ' : ''
    }${commandResult.isEnabled ? 'On' : 'Off'}`;

    if (commandResult.isEnabled && (commandResult.action === 'status' || !commandResult.action)) {
        networkSubMessage = commandResult.currentSsid
            ? `Connected to ${commandResult.currentSsid}, ${
                  commandResult.hasInternetAccess ? 'has' : 'no'
              } Internet Access`
            : 'Not connected to a network';
    }

    return (
        <div className={css(styles.mainContainer)} data-test-id={dataTestId}>
            <div className={css(styles.message)} style={{color: theme.colors.neutral.grey.deepest}}>
                {wifiStatusMessage}
            </div>
            <div className={css(styles.subMessage)} style={{color: theme.colors.neutral.grey.deepest}}>
                {networkSubMessage}
            </div>
            <WifiSuggestedCommands
                headerText={
                    commandResult.action === 'disable' && !commandResult.isEnabled ? 'To turn it back on:' : undefined
                }
                showWifiStatusSuggestion={commandResult.action === 'enable' && commandResult.isEnabled}
                showWifiScanSuggestion={commandResult.action !== 'disable' && commandResult.isEnabled}
                showWifiConnectSuggestion={commandResult.action !== 'disable' && commandResult.isEnabled}
                showWifiToggleSuggestion={true}
            />
        </div>
    );
};
export default Status;
