import React from 'react';
import styled from 'styled-components';
import {CoreActionMenuItem, CoreActionMenuItemProps, CoreText} from '@anywhere-expert/base-ui';

interface ExtendableProps {
    isCustomIntervalEnabled: boolean;
}

export const Row2 = styled.div`
    display: flex;
    align-self: center;
    justify-content: center;
    margin-bottom: 15px;
`;

export const Row3 = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-end;
`;

export const AutopilotMessage = styled(CoreText)`
    margin-bottom: 16px;
    width: 202px;
`;

export const Container = styled(({isCustomIntervalEnabled, ...rest}: ExtendableProps & CoreActionMenuItemProps) => (
    <CoreActionMenuItem {...rest} />
))`
    transition: height 200ms linear;
    padding: 16px;
    justify-content: center;
`;
