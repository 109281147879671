import commandSender from '../shared/commandSender';
import {ExecuteFactory, GetCommandTweekKey} from '../shared/types';

export const getCommandTweekKey: GetCommandTweekKey = () => 'speed_test';

export const executeFactory: ExecuteFactory = () => options =>
    commandSender({
        commandType: 'speed_test',
        contentType: 'Command',
        tweekToolKey: getCommandTweekKey(),
        analyticsScope: 'SpeedTest',
        ...options,
    });
