import React, {useCallback, useMemo, useState} from 'react';
import {useAnalytics} from 'react-shisell';
import {observer} from 'mobx-react';
import {useNeglectionWarning} from '@anywhere-expert/expert-feed';
import {ExpertFeedStore} from '@anywhere-expert/expert-feed-store';
import logger from '@anywhere-expert/logging';
import {setAppMessage} from '@anywhere-expert/app-messages';
import {CoreButton} from '@anywhere-expert/base-ui';
import {getFeedActionsClient} from '@anywhere-expert/expert-feed-actions';
import {useUserProfile} from '@anywhere-expert/auth';

type Props = {
    disabled: boolean;
    sessionId: string;
    minutes: number;
    hours: number;
    closeMenu: () => void;
};

const SnoozeApprovalButton = observer(({disabled, sessionId, closeMenu, hours, minutes}: Props) => {
    const [snoozeInProgress, setSnoozeInProgress] = useState(false);
    const neglectionWarning = useNeglectionWarning(ExpertFeedStore.selectedSession);
    const analytics = useAnalytics();
    const user = useUserProfile();
    const snoozeTimeInMinutes = useMemo(() => hours * 60 + minutes, [hours, minutes]);

    const onSnoozeClick = useCallback(async () => {
        analytics.dispatcher
            .withExtras({
                SnoozeIntervalInMinutes: snoozeTimeInMinutes,
                ActionId: 'Snooze',
            })
            .dispatch('Click');
        if (Boolean(neglectionWarning)) {
            logger.info('attempt to snooze neglected item', {extra: {feedItemId: sessionId, neglectionWarning}});
            setAppMessage({text: 'This customer is waiting for reply, answer before you snooze', type: 'warning'});
            closeMenu();
            return;
        }
        setSnoozeInProgress(true);
        try {
            await getFeedActionsClient().addQueueItemAttributes({
                expertId: user.uid,
                feedItemId: sessionId,
                attributes: [
                    {
                        type: 'snooze',
                        key: 'snooze',
                        fields: {
                            snoozeWakeUpTime: (Date.now() + snoozeTimeInMinutes * 60000).toString(),
                            snoozeTimeInMinutes: snoozeTimeInMinutes.toString(),
                        },
                    },
                ],
            });

            setAppMessage({text: 'The session is now snoozed', type: 'success'});

            setSnoozeInProgress(false);
            ExpertFeedStore.selectSession(undefined);
        } catch (err) {
            setAppMessage({text: 'Failed to snooze session', type: 'warning'});
            logger.warn('failed to snooze feed item', {err, extra: {feedItemId: sessionId}});
            setSnoozeInProgress(false);
        }
    }, [closeMenu, snoozeTimeInMinutes, sessionId, user, neglectionWarning]);

    return (
        <CoreButton
            variant="contained"
            size="s"
            color="secondary"
            disabled={disabled}
            onClick={onSnoozeClick}
            text="Snooze"
            isInProgress={snoozeInProgress}
        />
    );
});

export default SnoozeApprovalButton;
