import commandSender from '../shared/commandSender';
import {ExecuteCommandParameters, ExecuteFactory, GetCommandTweekKey} from '../shared/types';

export const getCommandTweekKey: GetCommandTweekKey = args =>
    !args || args.length === 0 ? 'device_status' : args[0] === 'connect' ? 'wifi_v2' : 'wifi_v1';

export const executeFactory: ExecuteFactory = (name: string) => (options: ExecuteCommandParameters) =>
    commandSender({
        commandType: name,
        contentType: 'Command',
        tweekToolKey: getCommandTweekKey(options.args),
        analyticsScope: 'DeviceStatus',
        validateArgs,
        ...options,
    });

const validateArgs = args =>
    args.length === 0 ||
    args[0] === 'enable' ||
    args[0] === 'disable' ||
    args[0] === 'scan' ||
    (args[0] === 'connect' && args[1]);
