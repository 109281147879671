import React, {useCallback, useEffect, useMemo} from 'react';
import styled from 'styled-components';
import {CoreText, CoreIcon} from '@anywhere-expert/base-ui';
import {OpenIcon} from '@anywhere-expert/icons';
import VisibilityIndicator from '@anywhere-expert/system-messages/components/VisibilityIndicator';
import type {AnalyticsDispatcher} from 'shisell';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 4px 0px;
`;
const UserBrowsingTitle = styled(CoreText)`
    font-weight: bold;
    font-size: 13px;
    margin-bottom: 4px;
    margin-top: 8px;
`;

const Message = styled.a`
    width: 270px;
    height: 28px;
    border-radius: 8px;
    background-color: ${props => props.theme.palette.common.white};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 6px 8px;
`;

const Text = styled(CoreText)`
    font-size: 12px;
    max-width: 177px;
    text-overflow: ellipsis;
    overflow-wrap: anywhere;
    overflow: hidden;
    white-space: nowrap;
    margin: 0 auto 0 8px;
`;

const AmazonImg = styled.div`
    background: url(${require('./assets/amazonImg.png')});
    width: 25px;
    height: 20px;
`;

type Props = {
    title: string;
    url: string;
    isFirst?: boolean;
    dispatcher: AnalyticsDispatcher;
};

const ProductPageOpened = ({title, url, dispatcher, isFirst = false}: Props) => {
    const pageProductDispatcher = useMemo(() => dispatcher.withExtras({Type: 'update', UpdateType: 'ProductPage'}), [
        dispatcher,
    ]);

    useEffect(() => {
        pageProductDispatcher.dispatch('View');
    }, [pageProductDispatcher]);

    const onLinkClick = useCallback(() => {
        pageProductDispatcher.dispatch('Click');
    }, []);

    return (
        <Container>
            {isFirst && (
                <VisibilityIndicator>
                    {' '}
                    <UserBrowsingTitle>Customer is browsing:</UserBrowsingTitle>
                </VisibilityIndicator>
            )}
            <Message href={url} target="_blank" onClick={onLinkClick}>
                <AmazonImg />
                <Text>{title} </Text> <CoreIcon icon={OpenIcon} color="black" size="s" />
            </Message>
        </Container>
    );
};

export default ProductPageOpened;
