import React, {useEffect} from 'react';
import {observer} from 'mobx-react';
import {getTweekValue} from '@anywhere-expert/tweek';
import {TabProps, SidebarTabContainer} from '@anywhere-expert/session-sidebar';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import TranscriptTabs from './components/TranscriptTabs';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
import {CoreToast} from '@anywhere-expert/base-ui';
import {LoadState} from '@anywhere-expert/expert-feed-store';

interface SimilarSessionsTabContainerType extends React.FunctionComponent<TabProps> {
    getDisabledText?: any;
}

const SpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Spinner = () => (
    <SpinnerContainer>
        <CircularProgress size={24} thickness={2} variant="indeterminate" style={{color: '#00aeef'}} />
    </SpinnerContainer>
);

const sidebarContents = (loadState: LoadState, selectedSession: SupportItem) => {
    if (loadState === LoadState.LOADING) {
        return <Spinner />;
    }
    if (loadState === LoadState.LOADED) {
        return <TranscriptTabs selectedSession={selectedSession} timelineId={selectedSession.customerId} />;
    }
    return null;
};

const SimilarSessionsTabContainer: SimilarSessionsTabContainerType = observer(({selectedSession}: TabProps) => {
    useEffect(() => {
        selectedSession.similarSessions.fetchData();
    }, [selectedSession.sessionId]);

    const loadState = selectedSession.similarSessions.loadState;

    return (
        <SidebarTabContainer title="Similar resolved issues">
            <CoreToast text="Failed to load Similar Sessions" show={loadState === LoadState.FAILED} showClose={false} />
            {sidebarContents(loadState, selectedSession)}
        </SidebarTabContainer>
    );
});

export const getDisabledText = (selectedSession: SupportItem) => {
    const isEnabled = getTweekValue('support/session/during/similar_sessions/is_enabled', true);
    if (!selectedSession.isAssignedToMe) {
        return 'Available only for your assigned sessions';
    }
    if (!isEnabled) {
        return 'This tool is not available';
    }
    if (!selectedSession.similarSessions || !selectedSession.similarSessions.hasSimilarSessions) {
        return 'No relevant Similar Sessions';
    }

    return undefined;
};

export default SimilarSessionsTabContainer;
