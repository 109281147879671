import React, {useEffect, useMemo} from 'react';
import TextMessage from '../TextMessage';
import {useAnalytics} from 'react-shisell';

const AccessoriesCarouselItem = () => {
    const analytics = useAnalytics();
    const dispatcher = useMemo(() => analytics.dispatcher.createScoped('AccessoriesCarouselItem'), [
        analytics.dispatcher,
    ]);

    useEffect(() => {
        dispatcher.dispatch('View');
    }, []);

    return (
        <TextMessage
            contentId="AccessoriesCarouselItem"
            isSelf={true}
            showThumbnail={false}
            senderThumbUrl="teamIcon"
            isGroupedItem={true}
            content={`TVs and gaming consoles, including remote controls.
                Laptops, desktops, and tablets, including speakers, mouse, keyboard, external monitors, and modems.
                (FYI - the customer is seeing an image gallery of all these covered accessories)`}
        />
    );
};

export default AccessoriesCarouselItem;
