import React from 'react';
import {StyleSheet, css} from 'aphrodite';
import WifiNetworkItem from './WifiNetworkItem';
import WifiSuggestedCommands from '../../WifiSuggestedCommands.web';
import {getPermissionFailedMessage} from '../../../shared/getPermissionFailedMessage';
import {useTheme} from '@anywhere-expert/colors';

const styles = StyleSheet.create({
    mainContainer: {
        marginTop: '24px',
        marginBottom: '24px',
        alignSelf: 'center',
        display: 'flex',
        flexShrink: 0,
        flexDirection: 'column',
    },
    message: {
        textAlign: 'center',
        fontSize: '14px',
        fontFamily: 'Roboto',
        lineHeight: 1.54,
    },
    wifiNetworks: {
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
});

type Props = {
    commandResult: any;
    dataTestId: string;
};

const Scan = ({commandResult, dataTestId}: Props) => {
    const {
        colors: {neutral},
    } = useTheme();

    if (!commandResult.success) {
        let message;
        let suggestions;
        if (commandResult.isEnabled === false) {
            message = 'It seems the Wi-Fi is turned OFF';
            suggestions = <WifiSuggestedCommands headerText={'To turn it on:'} showWifiToggleSuggestion={true} />;
        } else if (commandResult.refusePermission) {
            message = getPermissionFailedMessage(commandResult.reason);
        }
        return (
            <div className={css(styles.mainContainer)}>
                <div className={css(styles.message)} style={{color: neutral.grey.deepest}}>
                    {message}
                </div>
                {suggestions}
            </div>
        );
    }

    const wifiNetworks = commandResult.networks
        .sort((a, b) => b.frequency - a.frequency)
        .slice(0, 10)
        .map(n => <WifiNetworkItem wifiNetwork={n} key={n.BSSID} />);
    const wifiNetworksHeaderMessage =
        wifiNetworks.length > 0 ? 'Here are the available networks:' : 'No available networks found';

    return (
        <div className={css(styles.mainContainer)} data-test-id={dataTestId}>
            <div className={css(styles.message)}>{wifiNetworksHeaderMessage}</div>
            <div className={css(styles.wifiNetworks)}>{wifiNetworks}</div>
        </div>
    );
};
export default Scan;
