import {VisualComponent} from './types';

type getCommandVisualComponentsType = (commandType?: string) => VisualComponent | undefined;

export const initVisualComponentsFactory = (components): getCommandVisualComponentsType => {
    return (commandType?: string): VisualComponent | undefined => {
        if (!commandType) {
            return undefined;
        }

        return components[commandType.toLowerCase()];
    };
};
