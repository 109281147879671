import React from 'react';
import {useTheme} from '@anywhere-expert/colors';

const ArrowIcon = ({...props}: React.SVGProps<SVGSVGElement>) => {
    const theme = useTheme();
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="28" fill="none" viewBox="0 0 26 28" {...props}>
            <path
                stroke={theme.colors.neutral.black}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.114"
                d="M14.557 25.513c.154.028 3.54.877 3.57.501.094-1.178-.422-2.72-.92-3.767"
            ></path>
            <path
                stroke={theme.colors.neutral.black}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.114"
                d="M8.294 1C2.18 16.924 16.732 24.42 17.25 24.923c.13.127.91.488.38.261"
            ></path>
        </svg>
    );
};

export default React.memo(ArrowIcon);
