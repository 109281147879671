import React, {useCallback, useState} from 'react';
import {withAnalyticOnEvent} from 'react-shisell';
import {CoreButton, CoreButtonProps} from '@anywhere-expert/base-ui';
import {getFeedActionsClient} from '@anywhere-expert/expert-feed-actions';
import logger from '@anywhere-expert/logging';
import {setAppMessage} from '@anywhere-expert/app-messages';

export type UnsnoozeButtonProps = {
    sessionId: string;
    text: string;
};

const CoreButtonWithAnalytics = withAnalyticOnEvent<CoreButtonProps, {}>({
    eventName: 'onClick',
    analyticName: 'Click',
    extras: {
        ActionId: 'Dismiss',
    },
})(CoreButton);

export default ({sessionId, text, ...others}) => {
    const [isActivating, setIsActivating] = useState(false);
    const onClick = useCallback(async () => {
        try {
            setIsActivating(true);
            await getFeedActionsClient().clearSnoozeIndication({feedItemId: sessionId});
            setAppMessage({text: 'Session activated', type: 'success'});
        } catch (err) {
            logger.warn('failed to snooze feed item', {err, extra: {feedItemId: sessionId}});
            setAppMessage({text: 'Failed to activate session', type: 'warning'});
        } finally {
            setIsActivating(false);
        }
    }, [sessionId]);

    return (
        <CoreButtonWithAnalytics
            variant="contained"
            color="primary"
            text={text}
            size="s"
            onClick={onClick}
            isInProgress={isActivating}
            {...others}
        />
    );
};
