import React from 'react';
import {StyleSheet, css} from 'aphrodite';
import {IconType} from '@anywhere-expert/system-messages';
import FeatureSuggestionComponent from '@slash-commands/feature-suggestion';
import {VisualComponentType} from '@slash-commands/command-visual-component';
import {SpeedTestIcon} from '@anywhere-expert/icons';
import {useTheme} from '@anywhere-expert/colors';
import withSpeedTestTimeout from '../withSpeedTestTimeout';
import {SpeedTestContent} from '../types';
import {featureSuggestionTexts, onFeatureSuggestionClick} from '../shared';
import Header from './Header';
import SpeedTestAnimation from './SpeedTestAnimation';
import Results from './Results';

const speedTestIcon = require('./resources/speedTestIcon.svg');

const suggestionIcon = require('./resources/ic_suggestion.svg');

export {description} from '../shared';

export const icon: IconType = speedTestIcon;
export const iconOutline = SpeedTestIcon;

const styles = StyleSheet.create({
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        marginTop: '48px',
        marginBottom: '24px',
        fontFamily: 'Roboto',
    },
    testAnimation: {
        width: '150px',
        height: '150px',
        margin: 'auto',
    },
});

// TODO add explicit type when fixing command timeline item
export const Component = withSpeedTestTimeout(
    ({commandPayload, hasTimedOut}: {commandPayload: SpeedTestContent; hasTimedOut: boolean}) => {
        const theme = useTheme();

        const {speedTestResult} = commandPayload;
        const isTestFinished = speedTestResult && speedTestResult.success != null;
        return (
            <div className={css(styles.mainContainer)}>
                <Header {...commandPayload} hasTimedOut={hasTimedOut} />
                {isTestFinished || hasTimedOut ? (
                    <Results {...speedTestResult} />
                ) : (
                    <SpeedTestAnimation
                        {...speedTestResult}
                        brandColor={theme.colors.brand.blue}
                        style={styles.testAnimation}
                    />
                )}
            </div>
        );
    }
);

const commandName = 'speed-test';
export const Suggestion: VisualComponentType = ({timelineId}) => (
    <FeatureSuggestionComponent
        timelineId={timelineId}
        featureSuggestionTexts={featureSuggestionTexts}
        suggestionIcon={suggestionIcon}
        handleClick={onFeatureSuggestionClick}
        commandName={commandName}
        featureCommand={`/${commandName}`}
        analyticsActionId={'RunSpeedTest'}
    />
);
