import {QueueItem} from '@anywhere-expert/expert-feed-store';

export function getTabDisabledText(selectedSession: QueueItem) {
    if (!selectedSession.isAssignedToMe) {
        return 'Available only for your assigned sessions';
    }

    if (!selectedSession.supportItemDetails || selectedSession.supportItemDetails.platform !== 'device') {
        return 'Available only for mobile sessions';
    }

    return undefined;
}
