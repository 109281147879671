import * as React from 'react';
import {StyleSheet, css} from 'aphrodite/no-important';
import {compose, withProps, lifecycle} from 'recompose';
import {withAnalytics, WithAnalyticsProps} from 'react-shisell';
import {AnalyticsDispatcher} from 'shisell';
import withTriggerCommandProps, {TriggerCommandProps} from '../../commands/shared/withTriggerCommandProps';
import {FeatureSuggestionProps} from './types';
import {CoreText, CoreButton, CoreLink} from '@anywhere-expert/base-ui';
import {useTheme} from '@anywhere-expert/colors';

const styles = StyleSheet.create({
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        marginTop: '34px',
        marginBottom: '24px',
        alignItems: 'center',
        marginRight: '3.1em',
        marginLeft: '3.1em',
        borderRadius: '8px',
        padding: '16px',
    },
    icon: {
        height: '48px',
        width: '116px',
    },
    header: {
        marginTop: '12px',
        textAlign: 'center',
    },
    subHeader: {
        textAlign: 'center',
        alignItems: 'center',
        marginTop: '4px',
        marginBottom: '4px',
    },
    lineWrap: {
        whiteSpace: 'pre',
    },
    actionsContainer: {
        marginTop: '8px',
        textAlign: 'center',
    },
    otherToolsLink: {
        marginLeft: '10px',
        marginTop: '16px',
    },
});

const FeatureSuggestionComponent: React.SFC<EnhancedFeatureSuggestionProps> = ({
    timelineId,
    sessionId,
    technicianData,
    featureSuggestionTexts,
    handleClick,
    suggestionIcon,
    featureCommand,
    analyticsActionId,
    featureSuggestionDispatcher,
    analytics: {dispatcher},
}) => {
    const theme = useTheme();

    const subHeaderText = featureSuggestionTexts.subHeaderText.split('\n').map((item, i) => (
        <div className={css(styles.lineWrap)} key={i}>
            {item}
        </div>
    ));

    return (
        <div className={css(styles.mainContainer)} style={{backgroundColor: theme.colors.neutral.grey.light}}>
            <img src={suggestionIcon} className={css(styles.icon)} />
            <CoreText size="l" textType="primary" className={css(styles.header)}>
                {featureSuggestionTexts.headerText}
            </CoreText>
            <CoreText size="m" textType="secondary" className={css(styles.subHeader)}>
                {subHeaderText}
            </CoreText>
            <div className={css(styles.actionsContainer)}>
                <CoreButton
                    color="primary"
                    text={featureSuggestionTexts.buttonText}
                    onClick={() => {
                        featureSuggestionDispatcher.withExtra('ActionId', analyticsActionId).dispatch('Click');
                        handleClick(featureCommand, timelineId, sessionId, technicianData, dispatcher);
                    }}
                />
                <CoreLink
                    linkType="internal"
                    size="m"
                    onClick={() => {
                        featureSuggestionDispatcher.withExtra('ActionId', 'SeeOtherTools').dispatch('Click');
                        handleClick('/help', timelineId, sessionId, technicianData, dispatcher);
                    }}
                    className={css(styles.otherToolsLink)}
                >
                    {featureSuggestionTexts.otherToolsButtonText}
                </CoreLink>
            </div>
        </div>
    );
};

type EnhancedFeatureSuggestionProps = TriggerCommandProps &
    WithAnalyticsProps & {featureSuggestionDispatcher: AnalyticsDispatcher} & FeatureSuggestionProps;

const enhance = compose<EnhancedFeatureSuggestionProps, FeatureSuggestionProps>(
    withTriggerCommandProps,
    withAnalytics,
    withProps(({analytics, sessionId, commandName}: EnhancedFeatureSuggestionProps) => ({
        featureSuggestionDispatcher: analytics.dispatcher
            .createScoped('Tools')
            .createScoped('FeatureSuggestion')
            .withIdentity('SessionId', sessionId)
            .withExtra('SuggestionType', commandName),
    })),
    lifecycle<EnhancedFeatureSuggestionProps, {}>({
        componentDidMount() {
            this.props.featureSuggestionDispatcher.dispatch('View');
        },
    })
);

export default enhance(FeatureSuggestionComponent);
