import {AnalyticsProvider, useAnalytics} from 'react-shisell';
import ConversationErrorBar from './ConversationErrorBar';
import React, {useEffect, useMemo} from 'react';

interface Props {
    message?: string;
}

const ConversationUnavailableBar = ({message}: Props) => {
    const text = message ?? 'This conversation is currently unavailable';

    const analytics = useAnalytics();
    const enhancedAnalytics = useMemo(() => analytics.dispatcher.createScoped('ConversationUnavailableMessage'), [
        analytics,
    ]);

    useEffect(() => {
        enhancedAnalytics.withExtra('MessageText', text).dispatch('View');
    }, [enhancedAnalytics, text]);

    return (
        <AnalyticsProvider dispatcher={enhancedAnalytics}>
            <ConversationErrorBar text={text} />
        </AnalyticsProvider>
    );
};

export default ConversationUnavailableBar;
