import {SystemMessageItemProps} from '@anywhere-expert/system-messages';

interface ErrorProps extends SystemMessageItemProps {
    isError: true;
}

interface SuccessProps {
    isError: false;
}

type ResultProps = SuccessProps | ErrorProps;

export const getResultProps = ({content, commandType, commandError}): ResultProps => {
    if (commandType === 'command-error') {
        return {
            isError: true,
            dataTestId: 'error-generic',
            message: commandError || 'This data is unavailable at the moment. Try again soon',
        };
    } else if (commandType === 'command-disabled') {
        return {isError: true, dataTestId: 'error-disabled', message: 'This feature is currently unavailable'};
    } else if (commandType === 'command-unsupported') {
        return {
            isError: true,
            message: "The customer's app is out of date for this feature",
            dataTestId: 'error-unsupported',
        };
    } else if (commandType === 'command-bad-args') {
        return {
            isError: true,
            message: `Please specify all relevant parameters in the command`,
            dataTestId: 'error-bad-args',
            subMessage: 'Type /help to see available commands',
        };
    } else if (commandType === 'command-unknown') {
        const commandContent = content && content.message ? content.message : content;
        const truncatedCommand =
            typeof commandContent === 'string' &&
            `${commandContent.substring(0, 10)}${commandContent.length > 10 ? '...' : ''}`;
        return {
            isError: true,
            message: `*"${truncatedCommand}"* is an unrecognized command`,
            subMessage: 'Type /help to see available commands',
        };
    } else {
        return {isError: false};
    }
};

export const tryJsonParse = data => {
    try {
        return JSON.parse(data);
    } catch (e) {
        return data;
    }
};
