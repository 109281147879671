import React, {useState, useEffect, useMemo} from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {TimelineModel, TimelineConnectionStatus} from '@anywhere-expert/timeline-state';
import {CoreText} from '@anywhere-expert/base-ui';
import {useTheme, ThemeContext, LightThemeProvider, InvertedThemeProvider} from '@anywhere-expert/colors';
import {WifiIcon, WiFiNoConnectionIcon} from '@anywhere-expert/icons';
import {useAnalytics} from 'react-shisell';
import logger from '@anywhere-expert/logging';

const Container = styled.div<{isDisconnected: boolean}>`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: ${({theme}) => theme.colors.neutral.grey.light};
    min-width: 154px;
    height: 48px;
    padding: 16px;
    border-radius: 4px;

    ${({theme, isDisconnected}) =>
        isDisconnected &&
        `
        background-color: ${theme.colors.semantic.yellow.normal};
        padding-right: 0;
    `}

    transition: opacity 0.2s ease;
`;

const IconContainer = styled.div`
    margin-right: 4px;
`;

const statusText = {
    [TimelineConnectionStatus.Connecting]: 'Trying to connect...',
};

type Props = {
    timelineModel: TimelineModel;
    timelineId: string;
};

const useIndicationDelay = (timelineId: string) => {
    const [showConnecting, setShowConnecting] = useState<boolean>(false);
    useEffect(() => {
        setShowConnecting(false);
        const timeout = setTimeout(() => {
            setShowConnecting(true);
        }, 2000);

        return () => clearTimeout(timeout);
    }, [timelineId]);

    return showConnecting;
};

const ConnectionIndication = observer(({timelineModel, timelineId}: Props) => {
    const theme = useTheme();
    const {dispatcher} = useAnalytics();
    const show = useIndicationDelay(timelineId);

    const enhancedDispatcher = useMemo(() => dispatcher.createScoped('ConnectionIndication'), [dispatcher]);

    useEffect(() => {
        if (timelineModel.connectionStatus !== TimelineConnectionStatus.Connected) {
            enhancedDispatcher
                .withExtras({
                    ConnectionStatus: timelineModel.connectionStatus,
                    Timeline: timelineId,
                })
                .dispatch('View');
        }
    }, [timelineModel.connectionStatus, enhancedDispatcher]);
    if (!show || timelineModel.connectionStatus === TimelineConnectionStatus.Connected) {
        return null;
    }

    const isDisconnected = timelineModel.connectionStatus === TimelineConnectionStatus.Disconnected;
    if (isDisconnected) {
        logger.info(statusText[TimelineConnectionStatus.Disconnected]);
    }
    const Icon = isDisconnected ? WiFiNoConnectionIcon : WifiIcon;
    const ThemeProvider = theme.themeName === 'light' && isDisconnected ? LightThemeProvider : InvertedThemeProvider;

    return (
        <ThemeProvider>
            <Container isDisconnected={isDisconnected}>
                <ThemeContext.Consumer>
                    {({theme}) => (
                        <>
                            <IconContainer>
                                <Icon size="s" color={theme.colors.contrast.primary} />
                            </IconContainer>
                            <CoreText size="s" textType="primary">
                                {statusText[timelineModel.connectionStatus]}
                            </CoreText>
                        </>
                    )}
                </ThemeContext.Consumer>
            </Container>
        </ThemeProvider>
    );
});

export default ConnectionIndication;
