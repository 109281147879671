import React, {FunctionComponent, useEffect, useCallback} from 'react';
import {SelfHelpTimelineItemProps} from '../../types';
import {TextMessage} from '@anywhere-expert/timeline-item';
import {useTweekValue} from 'react-tweek';
import ArticleContentItem from './ArticleContentItem';
import {enrichAnalytics, useAnalytics} from 'react-shisell';

const ContentItem: FunctionComponent<SelfHelpTimelineItemProps> = (props: SelfHelpTimelineItemProps) => {
    const needHelpMessage = useTweekValue(
        'support/session/initiate/bots/self_help_bot/need_help_message',
        `If you still need to talk with an expert, just type your name and someone from our team will jump in to help you.`
    );

    const {
        state: {title},
    } = props;

    const analytics = useAnalytics();

    useEffect(() => {
        if (title) {
            analytics.dispatcher.dispatch('View');
        }
    }, [title, analytics]);

    const onArticleClick = useCallback(() => {
        analytics.dispatcher.dispatch('Click');
    }, [analytics]);

    return title ? (
        <>
            <TextMessage
                isSelf={true}
                showThumbnail={false}
                senderThumbUrl="teamIcon"
                isGroupedItem={true}
                content={needHelpMessage}
            />
            <ArticleContentItem onArticleClick={onArticleClick} {...props} />
        </>
    ) : null;
};

const enhance = enrichAnalytics<SelfHelpTimelineItemProps>((dispatcher, {state: {title}}) =>
    dispatcher.createScoped('SelfHelp').withExtra('ContentItem', title)
);

export default enhance(ContentItem);
