import * as React from 'react';
import {default as moment} from 'moment';
import logger from '@anywhere-expert/logging';
import {NoMessage} from '@anywhere-expert/timeline-item';
import {Message} from '@soluto-private/messaging-api-client';

type MessageErrorBoundaryProps = {
    message: Message;
    sessionId: string;
    timelineId: string;
};

export default class MessageErrorBoundary extends React.Component<MessageErrorBoundaryProps, {hasError: boolean}> {
    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
        };
    }

    static getDerivedStateFromError() {
        return {hasError: true};
    }

    componentDidCatch(err) {
        const {
            message: {messageId},
        } = this.props;

        logger.error('Failed to resolve a component for timeline item', {err, extra: {messageId}});

        if (
            process.env.IS_BEHAVIOUR_TEST === 'true' ||
            process.env.IS_LIVE_PR === 'true' ||
            process.env.NODE_ENV !== 'production'
        ) {
            throw new Error(err);
        }
    }

    render() {
        const {children, message, sessionId, timelineId} = this.props;
        const {hasError} = this.state;
        const timestampValue = moment(message.createdAt).format('h:mm A');

        if (hasError) {
            return (
                <NoMessage
                    timestampValue={timestampValue}
                    showTimestamp={true}
                    text="Error loading message"
                    isSelf={message.metadata.senderType === 'Technician'}
                    timelineItemId={message.messageId}
                    showThumbnail={true}
                    isGroupedItem={false}
                    sessionId={sessionId}
                    timelineId={timelineId}
                />
            );
        }

        return children;
    }
}
