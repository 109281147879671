import React, {useEffect, useMemo, useState} from 'react';
import type {TimelineItem} from '@anywhere-expert/timeline-api';
import {ExpertFeedStore} from '@anywhere-expert/expert-feed-store';
import {useAnalytics} from 'react-shisell';
import AmazonPrePurchaseGreeting from './AmazonPrePurchaseGreeting';
import ZipcodeUpdate from './ZipcodeUpdate';
import SearchUpdate from './SearchUpdate';
import SearchRefine from './SearchRefine';
import ProductPageOpened from './ProductPageOpened';

enum AmazonPrePurchaseMessageType {
    GREETING = 'greeting',
    QUERY_CHANGE = 'queryChange',
    REFINE_SEARCH = 'filterChange',
    ZIPCODE_CAHNGE = 'zipCodeChange',
    FIRST_PODUCT_PAGE_CHANGE = 'firstProductPageOpen',
    PRODUCT_PAGE_CHANGE = 'productPageOpen',
    NONE_CHANGED = 'non_changed',
}

type GetMessaageToShowProps = {
    messageId: string;
    postalCode?: any;
    searchText?: string;
    asin?: string;
    title?: string;
    productUrl?: string;
    searchUrl?: string;
};

const isFirstProductPageMessage = (amazonMessages, index) => {
    const current = amazonMessages[index].payload.value;

    return !(
        (index > 0 && amazonMessages[index - 1]?.payload?.value?.productUrl) ||
        amazonMessages[index - 1]?.payload?.value?.productUrl === current?.productUrl
    );
};

const getMessageToShow = ({
    messageId,
    postalCode,
    searchText,
    searchUrl,
    productUrl,
}: GetMessaageToShowProps): AmazonPrePurchaseMessageType => {
    const timeLineMessages = ExpertFeedStore?.selectedSupportSession?.timelineModel?.messages;
    const currentMessageSessionId = timeLineMessages?.find(m => m.messageId === messageId)?.sessionId;
    const preMessgesPerSession = timeLineMessages?.reduce((perSession, currentMessege) => {
        if (currentMessege.sessionId) {
            if (!perSession[currentMessege.sessionId]) perSession[currentMessege.sessionId] = [];
            perSession[currentMessege.sessionId].push(currentMessege);
        }
        return perSession;
    }, {});

    const currentSessionMessages =
        preMessgesPerSession && currentMessageSessionId && preMessgesPerSession[currentMessageSessionId];
    const currentSessionPreMessages =
        preMessgesPerSession &&
        currentMessageSessionId &&
        preMessgesPerSession[currentMessageSessionId]?.filter(msg => msg.payload.type === 'amazon_pre_purchase');

    const currentMsgIndexInSession = currentSessionMessages.findIndex(m => m.messageId === messageId);
    const currentPreMsgIndexInSession = currentSessionPreMessages.findIndex(m => m.messageId === messageId);

    if (currentPreMsgIndexInSession === 0) return AmazonPrePurchaseMessageType.GREETING;
    const prevSearch = currentSessionPreMessages[currentPreMsgIndexInSession - 1].payload.value;

    if (postalCode && prevSearch.postalCode !== postalCode) return AmazonPrePurchaseMessageType.ZIPCODE_CAHNGE;
    if (searchText && prevSearch.searchText !== searchText) return AmazonPrePurchaseMessageType.QUERY_CHANGE;
    if (searchUrl && prevSearch.searchUrl != searchUrl) return AmazonPrePurchaseMessageType.REFINE_SEARCH;
    if (productUrl && prevSearch.productUrl != productUrl) {
        if (isFirstProductPageMessage(currentSessionMessages, currentMsgIndexInSession))
            return AmazonPrePurchaseMessageType.FIRST_PODUCT_PAGE_CHANGE;
        return AmazonPrePurchaseMessageType.PRODUCT_PAGE_CHANGE;
    }
    return AmazonPrePurchaseMessageType.NONE_CHANGED;
};

const AmazonPrePurchaseTimelineItem = (props: TimelineItem<any>) => {
    const {
        content: {searchText, searchUrl, customerName, postalCode, prime, title, productUrl, asin},
        contentId,
    } = props;
    const [messageToShow, setMessageToShow] = useState('');
    const analytics = useAnalytics();
    const dispatcher = useMemo(
        () =>
            analytics.dispatcher.createScoped('AmazonPrePurchase').withExtras({
                ActionId: 'SearchResults',
                Url: searchUrl,
                SearchText: searchText,
                PostalCode: postalCode,
                IsPrime: prime,
                ProductPageUrl: productUrl,
                Asin: asin,
            }),
        [analytics]
    );

    useEffect(() => {
        const messageType: AmazonPrePurchaseMessageType = getMessageToShow({
            messageId: contentId,
            postalCode,
            searchText,
            searchUrl,
            productUrl,
        });

        setMessageToShow(messageType);
    }, [props]);

    const renderMessage = () => {
        switch (messageToShow) {
            case AmazonPrePurchaseMessageType.GREETING:
                return (
                    <AmazonPrePurchaseGreeting
                        dispatcher={dispatcher}
                        searchText={searchText}
                        customerName={customerName}
                        searchUrl={searchUrl}
                        postalCode={postalCode}
                        productUrl={productUrl}
                        prime={prime}
                    />
                );

            case AmazonPrePurchaseMessageType.ZIPCODE_CAHNGE:
                return <ZipcodeUpdate newZipcode={postalCode} dispatcher={dispatcher} />;
            case AmazonPrePurchaseMessageType.QUERY_CHANGE:
                return <SearchUpdate newResults={searchUrl} newQuery={searchText} dispatcher={dispatcher} />;
            case AmazonPrePurchaseMessageType.REFINE_SEARCH:
                return <SearchRefine newResults={searchUrl} dispatcher={dispatcher} />;
            case AmazonPrePurchaseMessageType.FIRST_PODUCT_PAGE_CHANGE:
                return <ProductPageOpened title={title} url={productUrl} isFirst={true} dispatcher={dispatcher} />;
            case AmazonPrePurchaseMessageType.PRODUCT_PAGE_CHANGE:
                return <ProductPageOpened title={title} url={productUrl} dispatcher={dispatcher} />;
            default:
                return null;
        }
    };

    return messageToShow !== AmazonPrePurchaseMessageType.NONE_CHANGED && <div>{renderMessage()}</div>;
};

export default AmazonPrePurchaseTimelineItem;
