import React, {useMemo} from 'react';
import {observer} from 'mobx-react';
import {compose, withProps} from 'recompose';
import {withTweekKeys} from '@anywhere-expert/tweek';
import withTechTemplate from '../behaviors/withTechTemplate';
import {palette} from '@anywhere-expert/colors';
import TimelineMessageBubble from './TimelineMessageBubble';
import styled from 'styled-components';
import useFlashingBehavior from '../behaviors/useFlashingBehavior';
import CollapsibleMarkdownText from '../TextMessage/CollapsibleMarkdownText';

export type TechnicianMessageProps = {
    content: any;
    contentId: string;
    extraInfoComponent?: React.ComponentType<any>;
};

type TweekConfig = {
    areCannedMessageActionsEnabled: boolean;
};

type Props = TechnicianMessageProps &
    TweekConfig & {
        deleteable?: boolean;
        canAddCannedMessageFromTimeline?: boolean;
        isHover: boolean;
        timestampValue: string;
        seenAtText: string;
        showTimestamp: boolean;
    };

const ExtendedTextBubble = styled(TimelineMessageBubble)`
    align-items: flex-start;
`;

export const TechnicianMessage = observer(
    ({
        content,
        contentId,
        isHover,
        canAddCannedMessageFromTimeline,
        deleteable,
        showTimestamp,
        timestampValue,
        seenAtText,
        extraInfoComponent,
    }: Props) => {
        const flash = useFlashingBehavior(contentId);
        const showHoverEffect = useMemo(() => !!(canAddCannedMessageFromTimeline || deleteable) && isHover, [
            canAddCannedMessageFromTimeline,
            deleteable,
            isHover,
        ]);
        const textBubbleType = 'expert';

        return (
            <ExtendedTextBubble
                size={'m'}
                textBubbleType={textBubbleType}
                deleteable={deleteable}
                timestamp={showTimestamp && timestampValue}
                seenAt={showTimestamp && seenAtText}
                flash={flash}
                isHover={showHoverEffect}
                extraInfoComponent={extraInfoComponent}
            >
                <CollapsibleMarkdownText
                    content={content}
                    textType={'primary'}
                    textColor={palette.neutral.white}
                    contentId={contentId}
                />
            </ExtendedTextBubble>
        );
    }
);

const enhance = compose<Props, TechnicianMessageProps>(
    withTweekKeys<TweekConfig>({
        areCannedMessageActionsEnabled: 'support/session/canned_message/actions/is_enabled',
    }),
    withProps(({senderType, areCannedMessageActionsEnabled}) => ({
        canAddCannedMessageFromTimeline: areCannedMessageActionsEnabled && senderType === 'Technician',
    })),
    withTechTemplate
);

export default enhance(TechnicianMessage);
