import {v4 as uuid} from 'uuid';
import {UserDetailsType} from '../../../core';
import {ExpertFeedStore} from '@anywhere-expert/expert-feed-store';

export default async ({
    expertData,
    sessionId,
    isExpertOnly,
    subtype,
    partner,
    content,
    extraTimelineItemProps,
}: {
    expertData: UserDetailsType;
    sessionId: string;
    isExpertOnly: boolean;
    subtype: string;
    partner?: string;
    content: any;
    extraTimelineItemProps?: {};
}) => {
    await ExpertFeedStore.getSupportItem(sessionId)?.timelineModel.createMessage({
        messageId: uuid(),
        senderId: expertData.id,
        sessionId,
        payload: {
            type: 'legacy_item',
            value: {
                subtype,
                content,
                extraTimelineItemProps,
            },
        },
        metadata: {
            senderName: expertData.name,
            senderThumbUrl: expertData.thumbUrl,
            senderType: 'Technician',
            source: 'web',
            partner: partner && partner.toLowerCase(),
            ...(isExpertOnly && {visibleOnlyBy: [{userType: 'Technician'}]}),
        },
    });
};
