import React from 'react';
import styled from 'styled-components';

const Comp: React.SFC<any> = () => (
    <MainContainer>
        <Title>Thanks for reaching out!</Title>
        <div>Can you please share your name?</div>
    </MainContainer>
);

const MainContainer = styled.div`
    display: flex;
    align-self: center;
    align-items: center;
    flex-direction: column;
    margin-top: 16px;
`;

const Title = styled.div`
    font-size: 18px;
    font-weight: 700;
`;
export default Comp;
