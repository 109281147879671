import {ItemTypes} from '@anywhere-expert/timeline-api';
import {
    TextMessage,
    TimestampGroupHeader,
    ImageItem,
    EscalateTimelineItem,
    withTechTemplate,
    DeletedMessage,
    SystemMessageForExpert,
    Attachment,
    CallAnnouncement,
    EmailMessage,
    AmazonPrePurchase,
    ActivityMessage,
    AccessoriesCarouselItem,
    BreakdownsCarouselItem,
    Article,
} from '@anywhere-expert/timeline-item';
import {ScrollToMessageMessageItem} from '@anywhere-expert/scroll-to-message-item';

import {Card} from '@anywhere-expert/content-card';
import {CommandResultItem} from '@anywhere-expert/slash-commands';
import {withVisibilityIndicator} from '@anywhere-expert/system-messages';
import dynamicTypeToComponent from './dynamicTypeToComponent';
import {AssignmentIndicatorTimelineItem} from '@anywhere-expert/get-next-item';
import {CobrowsingApprovalItem} from '@anywhere-expert/cobrowsing';
import {ScreenshareExpertTimelineItem} from '@anywhere-expert/screenshare';
import {FollowUpItem} from '@anywhere-expert/follow-up';

const TimelineItemsComponents = {
    [ItemTypes.TextMessage]: TextMessage,
    [ItemTypes.Timestamp]: TimestampGroupHeader,
    [ItemTypes.Escalate]: withVisibilityIndicator(EscalateTimelineItem),
    [ItemTypes.ContentCard]: withTechTemplate(Card),
    [ItemTypes.ImageItem]: ImageItem,
    [ItemTypes.Attachment]: Attachment,
    [ItemTypes.CommandResult]: CommandResultItem,
    [ItemTypes.AppLink]: CommandResultItem,
    [ItemTypes.AutoAssignAssignmentIndicator]: withVisibilityIndicator(AssignmentIndicatorTimelineItem),
    [ItemTypes.SystemMessageForExpert]: SystemMessageForExpert,
    [ItemTypes.SystemMessage]: SystemMessageForExpert,
    [ItemTypes.ScrollToMessageItem]: ScrollToMessageMessageItem,
    [ItemTypes.CallAnnouncement]: CallAnnouncement,
    [ItemTypes.Email]: EmailMessage,
    [ItemTypes.Activity]: ActivityMessage,
    [ItemTypes.AmazonPrePurchase]: AmazonPrePurchase,
    [ItemTypes.CobrowsingApprovalItem]: CobrowsingApprovalItem,
    [ItemTypes.AccessoriesCarouselItem]: AccessoriesCarouselItem,
    [ItemTypes.BreakdownsCarouselItem]: BreakdownsCarouselItem,
    [ItemTypes.FollowUp]: withVisibilityIndicator(FollowUpItem),
    [ItemTypes.Article]: Article,
    [ItemTypes.ScreenshareExpertTimelineItem]: ScreenshareExpertTimelineItem,
};

export default item => {
    if (item.lastState === 'deleted') return DeletedMessage;
    if (item.lastState === 'hidden') return null;
    if (item.contentType != ItemTypes.DynamicComponent) return TimelineItemsComponents[item.contentType];

    return dynamicTypeToComponent(item);
};
