import {useTheme} from '@anywhere-expert/colors';
import {FeedView} from '@anywhere-expert/expert-feed';
import {useFullstory} from '@anywhere-expert/fullstory';
import {TYPE_FIRST_HOME_PAGE_RENDER, usePerformance} from '@anywhere-expert/performance';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {observer} from 'mobx-react';
import React, {useCallback, useEffect} from 'react';
import {Switch} from 'react-router-dom';
import SplitPane from 'react-split-pane';
import {useTweekValue} from 'react-tweek';
import styled from 'styled-components';
import MainSessionView from '../components/MainSessionView';
import {NoSessionSelected} from '@anywhere-expert/no-session-selected';
import {usePrefetchTweekContexts} from '../package-wiring/expert-feed';
import '../style/SplitPane.css';
import {GlobalShortcuts} from '@anywhere-expert/app-shortcuts';
import ErrorBoundaryRoute from '../components/ErrorBoundaryRoute';
import useSyncSelection from '../package-wiring/useSyncSelection';
import {useUserProfile} from '@anywhere-expert/auth';
import {OfflineStatusBar} from '@anywhere-expert/offline-detector';
import {ApolloProvider} from 'react-apollo';
import {getApolloClient} from '@anywhere-expert/graph-client';
import {AutopilotModal, autopilotModalState$, closeAutopilotModal} from '@anywhere-expert/autopilot';
import {useObservable} from 'rxjs-hooks';
import {AppOverlay} from '@anywhere-expert/app-overlay';
import {CannedMessagesStoreV2, CannedMessagesNavigationStore} from '@anywhere-expert/canned-messages-v2';
import {TimelineInputStore} from '@anywhere-expert/conversation-input-section';
import {default as useClosingTab} from './useClosingTab';
import {ExpertAvailabilityStore} from '@expert-feed/expert-availability';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const HomeContainer = styled.div`
    position: relative;
    flex: 1;

    .Pane {
        display: flex;
    }

    .Resizer.vertical {
        border-left: 3px solid rgb(0, 0, 0, 0);
        border-right: 4px solid rgba(0, 0, 0, 0);
    }
`;

const ContentContainer = styled.div`
    flex: 3;
    display: flex;
    flex-direction: column;
    z-index: 1;
    background-color: ${({theme}) => theme.colors.background.primary};
`;

const useInitCannedMessagesStore = user => {
    useEffect(() => {
        CannedMessagesStoreV2.init(user);
        CannedMessagesNavigationStore.init();
    }, [user]);
};

const resizerStyle = {zIndex: 5};

const Home = observer(() => {
    const user = useUserProfile();
    const performance = usePerformance();
    useEffect(() => {
        window.requestAnimationFrame(() => {
            performance.end({tag: TYPE_FIRST_HOME_PAGE_RENDER});
        });
    }, []);
    usePrefetchTweekContexts();
    useSyncSelection();
    const theme = useTheme();
    useFullstory();
    useInitCannedMessagesStore(user);

    const offlineIndicatorEnabled = useTweekValue<boolean>('support/app/offline_indication/is_enabled', false);
    const isSmallScreen = useMediaQuery('(max-width:1280px)');
    const isBigScreen = useMediaQuery('(min-width:1480px)');
    const isAutopilotModalOpen = useObservable(() => autopilotModalState$);

    useEffect(() => {
        TimelineInputStore.init();
        return TimelineInputStore.dtor;
    }, []);

    const clearExpertAvailabilityAndWaitingForSession = useCallback(() => {
        ExpertAvailabilityStore.cancelExpertWaitingForSession();
    }, []);
    useClosingTab(clearExpertAvailabilityAndWaitingForSession);

    return (
        <GlobalShortcuts>
            <ApolloProvider client={getApolloClient()}>
                <Container data-test-id="Home" className={`${theme.themeName}-theme`}>
                    {offlineIndicatorEnabled && <OfflineStatusBar />}
                    <HomeContainer>
                        <SplitPane
                            primary="first"
                            defaultSize={350}
                            minSize={295}
                            maxSize={isBigScreen ? 440 : 380}
                            size={isSmallScreen ? 295 : undefined}
                            allowResize={!isSmallScreen}
                            resizerStyle={resizerStyle}
                        >
                            <FeedView />
                            <ContentContainer>
                                <Switch>
                                    <ErrorBoundaryRoute
                                        path={`/session/:sessionId`}
                                        component={MainSessionView}
                                        boundaryName="MainSessionView"
                                    />
                                    <ErrorBoundaryRoute
                                        path="/"
                                        component={NoSessionSelected}
                                        boundaryName="NoSessionSelected"
                                    />
                                </Switch>
                            </ContentContainer>
                        </SplitPane>
                        <AutopilotModal close={closeAutopilotModal} isOpen={Boolean(isAutopilotModalOpen)} />
                    </HomeContainer>
                </Container>
                <AppOverlay />
            </ApolloProvider>
        </GlobalShortcuts>
    );
});

export default Home;
