import styled from 'styled-components';
import {DeviceTools} from '@anywhere-expert/sidebar-device-tools';
import {TabType, SidebarStore} from '@anywhere-expert/session-sidebar';
import {SessionToolbox} from '@anywhere-expert/sidebar-toolbox';
import {CannedMessages, IndicatorBadge as CannedMessagesIndicatorBadge} from '@anywhere-expert/canned-messages-v2';
import {HelixSearch} from '@anywhere-expert/helix-search';
import {ViewAsTab} from '@anywhere-expert/sidebar-view-as';
import {NotesTab} from '@anywhere-expert/notes';
import {SimilarSessionsTabContainer, similarSessionsTabDisabledText} from '@anywhere-expert/similar-sessions';
import {
    BookmarkIcon,
    EyeIcon,
    NotesIcon,
    PhoneDetailsIcon,
    ToolboxIcon,
    HelixIcon,
    SimilarIcon,
    GifIcon,
    SearchIcon,
} from '@anywhere-expert/icons';
import {VisualGuideTab} from '@anywhere-expert/visual-guides';
import {TimelineSearchTabContainer as TimelineSearch} from '@anywhere-expert/timeline-search';
import {shortcutsMap} from '@anywhere-expert/app-shortcuts-consts';

const StyledCannedMessagesIndicatorBadge = styled(CannedMessagesIndicatorBadge)`
    right: 6px;
    top: 12px;
`;

export const registerTabsConfig = () => {
    const tabsConfig = {
        ['toolbox']: {
            Content: SessionToolbox,
            tooltipText: 'Toolbox',
            Icon: ToolboxIcon,
            type: TabType.Constant,
        },
        ['canned-messages']: {
            Content: CannedMessages,
            tooltipText: 'Canned Messages',
            tooltipProps: {keyboardSequence: shortcutsMap['open-canned-messages'].key},
            Icon: BookmarkIcon,
            IndicatorIcon: StyledCannedMessagesIndicatorBadge,
            type: TabType.Pinned,
            minWidth: '372px',
            maxWidth: '372px',
            getDisabledText: CannedMessages.getDisabledText,
        },
        ['device-tools']: {
            Content: DeviceTools,
            Icon: PhoneDetailsIcon,
            tooltipText: 'Device Info',
            getDisabledText: DeviceTools.getDisabledText,
            type: TabType.Constant,
        },
        ['helix-search']: {
            Content: HelixSearch,
            Icon: HelixIcon,
            tooltipText: 'Search Helix',
            type: TabType.Pinned,
        },
        ['view-as']: {
            Content: ViewAsTab,
            Icon: EyeIcon,
            tooltipText: "Customer's Main view",
            type: TabType.Pinned,
            getDisabledText: ViewAsTab.getDisabledText,
            minWidth: '372px',
            maxWidth: '402px',
        },
        ['notes']: {
            Content: NotesTab,
            Icon: NotesIcon,
            tooltipText: 'Session Notes',
            tooltipProps: {keyboardSequence: shortcutsMap['session-notes'].key},
            type: TabType.Pinned,
            getDisabledText: NotesTab.getDisabledText,
        },
        ['similar-sessions']: {
            Content: SimilarSessionsTabContainer,
            Icon: SimilarIcon,
            tooltipText: 'Similar Sessions',
            getDisabledText: similarSessionsTabDisabledText,
            type: TabType.Pinned,
            minWidth: '372px',
        },
        ['visual-guide']: {
            Content: VisualGuideTab,
            tooltipText: 'GIF Guides',
            Icon: GifIcon,
            type: TabType.Pinned,
            getDisabledText: VisualGuideTab.getDisabledText,
        },
        ['timeline-search']: {
            Content: TimelineSearch,
            tooltipText: 'Search Messages',
            Icon: SearchIcon,
            tooltipProps: {keyboardSequence: shortcutsMap['find-in-page'].key},
            type: TabType.Pinned,
            getDisabledText: TimelineSearch.getDisabledText,
        },
    };

    SidebarStore.setTabsConfig({
        ...SidebarStore.tabsConfig,
        ...tabsConfig,
    });
};
