import * as React from 'react';
import {StyleSheet, css} from 'aphrodite';
import {useTheme} from '@anywhere-expert/colors';

const styles = StyleSheet.create({
    suggestedCommandContainer: {
        display: 'flex',
        marginTop: '5px',
    },
    suggestedCommand: {
        textAlign: 'center',
        fontSize: '13px',
        fontFamily: 'Roboto',
        lineHeight: '1.54',
    },
    bold: {
        fontWeight: 500,
    },
    italic: {
        fontStyle: 'italic',
    },
    note: {},
    icon: {
        marginRight: '10px',
        width: '20px',
        height: '20px',
    },
});

type Props = {
    commandText: string;
    icon: any;
    commandNoteText: string;
    dataTestId?: string;
};

const SuggestionComponent = ({icon, commandText, commandNoteText}: Props) => {
    const theme = useTheme();

    return (
        <div className={css(styles.suggestedCommandContainer)}>
            <img src={icon} className={css(styles.icon)} />
            <div className={css(styles.suggestedCommand)} style={{color: theme.colors.neutral.grey.deepest}}>
                <span className={css(styles.bold)}>{commandText}</span>
                <span className={css(styles.note)}>{commandNoteText}</span>
            </div>
        </div>
    );
};

export default SuggestionComponent;
