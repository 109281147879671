import React from 'react';
import Actions from './Actions';
import {User} from '@anywhere-expert/auth';
import {SupportItem} from '@anywhere-expert/expert-feed-store';

type Props = {
    user: User;
    disabled: boolean;
    selectedSession: SupportItem;
};

const SessionActionsContainer = ({user, disabled, selectedSession}: Props) => (
    <div>
        <Actions user={user} disabled={disabled} selectedSession={selectedSession} />
    </div>
);

export default SessionActionsContainer;
