import React, {ComponentType, SFC} from 'react';
import VisibilityIndicator from '@system-messages/visibility-indicator';

export default function<T>(Component: ComponentType<T>): SFC<T> {
    return props => {
        const {source} = props as any;
        return source !== 'customerTimeline' ? (
            <VisibilityIndicator>
                <Component {...props} />
            </VisibilityIndicator>
        ) : (
            <Component {...props} />
        );
    };
}
