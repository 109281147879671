import React, {useMemo, useCallback, useEffect, useState} from 'react';
import {branch, compose} from 'recompose';
import {useUserProfile} from '@anywhere-expert/auth';
import withTechTemplate from '../../behaviors/withTechTemplate';
import withCustomerTemplate from '../../behaviors/withCustomerTemplate';
import {ImageItemProps} from '../types';
import {CoreTimelineTextBubble, openModal} from '@anywhere-expert/base-ui';
import styled from 'styled-components';
import ImageItemModal from './ImageItemModal';
import {useAnalytics} from 'react-shisell';
import {ExpertFeedStore} from '@anywhere-expert/expert-feed-store';
import {observer} from 'mobx-react';

const StyledCoreTimelineTextBubble = styled(CoreTimelineTextBubble)`
    height: 260px;
    width: 368px;
    padding: 8px;
    position: relative;
`;

const Wrapper = styled.div`
    position: relative;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: ${({theme}) =>
        theme.themeName === 'light' ? theme.colors.neutral.black : theme.colors.background.secondary};
    align-items: center;
    height: 224px;
    margin-bottom: 8px;
`;

const Img = styled.img<{loaded: boolean}>`
    opacity: 0;
    position: absolute;
    max-width: 100%;
    transition: opacity 400ms linear;
    ${({loaded}) => loaded && 'opacity: 1'};
`;

const useImageAnalytics = (isSelf: boolean, imageUrl: string) => {
    const analytics = useAnalytics();
    const dispatcher = useMemo(() => analytics.dispatcher.createScoped('ImageItem'), []);
    useEffect(() => {
        dispatcher.withExtras({Sender: isSelf ? 'Expert' : 'Customer', imageUrl}).dispatch('View');
    }, [dispatcher, isSelf, imageUrl]);

    return dispatcher;
};

const Image = observer(
    ({
        imageUrl,
        isSelf,
        thumbnailUrl,
        contentId,
        isHover,
        timestampValue,
        seenAtText,
        senderId,
        timelineId,
        sessionId,
        imageDimensions,
    }: ImageItemProps) => {
        const dispatcher = useImageAnalytics(isSelf, imageUrl);
        const user = useUserProfile();
        const [loaded, setLoaded] = useState(false);
        const onImageLoaded = useCallback(() => {
            setLoaded(true);
            localStorage.removeItem(contentId);
        }, [contentId]);
        const isAssignedToMe = ExpertFeedStore.selectedSession?.isAssignedToMe;
        const alt = isSelf ? 'Image sent by Expert' : 'Image sent by Customer';
        const data = localStorage.getItem(contentId);
        const openImageModal = useCallback(() => {
            const editableImageEnabled = isSelf ? user.uid === senderId : isAssignedToMe;

            openModal(ImageItemModal, {
                analyticsScope: 'Modal_ImageItem',
                analyticsExtras: {Sender: isSelf ? 'Expert' : 'Customer', EditMode: editableImageEnabled},
                contentProps: {
                    imageUrl,
                    editableImageEnabled,
                    timelineId,
                    sessionId,
                    partner: ExpertFeedStore.selectedSession?.supportItemDetails.partner,
                    imageDimensions,
                },
            });
            dispatcher.withExtras({ActionId: 'Preview', Sender: isSelf ? 'Expert' : 'Customer'}).dispatch('Click');
        }, [dispatcher, imageUrl, isSelf, timelineId, sessionId, imageDimensions, isAssignedToMe, senderId, user]);

        return (
            <StyledCoreTimelineTextBubble
                textBubbleType={isSelf ? 'expert' : 'customer'}
                size="m"
                isHover={isHover}
                timestamp={timestampValue}
                seenAt={seenAtText}
            >
                <Wrapper data-test-id={'ImageItem'} onClick={openImageModal}>
                    <Img alt={alt} src={data || imageUrl} loaded={loaded} onLoad={onImageLoaded} />
                    <Img alt={alt} src={data || thumbnailUrl} loaded={!loaded} />
                </Wrapper>
            </StyledCoreTimelineTextBubble>
        );
    }
);

const enhance = compose(
    branch<{isSelf: boolean}>(props => props.isSelf, withTechTemplate as any, withCustomerTemplate as any)
);

export default enhance(Image as any);
