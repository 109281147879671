import React from 'react';
import {StyleSheet, css} from 'aphrodite';
import {palette} from '@anywhere-expert/colors';

const SuccessIcon = require('../../assets/ic_wifi_connect_success.svg');
const FailureIcon = require('../../assets/ic_wifi_connect_failure.svg');

const styles = StyleSheet.create({
    mainContainer: {
        marginTop: '24px',
        marginBottom: '24px',
        alignSelf: 'center',
        display: 'flex',
        flexShrink: 0,
        alignItems: 'center',
        flexDirection: 'column',
    },
    message: {
        textAlign: 'center',
        fontSize: '14px',
        color: palette.neutral.grey.deepest,
        fontFamily: 'Roboto',
        fontStyle: 'italic',
        // fontWeight: 'medium',
        lineHeight: 1.54,
    },
    icon: {
        width: '40px',
        height: '32px',
        marginBottom: '4px',
        justifyContent: 'center',
    },
    bold: {
        fontWeight: 'bold',
    },
});

export default ({isSuccess, ssid, dataTestId}) => {
    const message = isSuccess ? 'connected to ' : "couldn't connect to ";
    return (
        <div className={css(styles.mainContainer)} data-test-id={dataTestId}>
            <img src={isSuccess ? SuccessIcon : FailureIcon} className={css(styles.icon)} />
            <div className={css(styles.message)}>
                {message}
                <span className={css(styles.bold)}>{ssid}</span>
            </div>
        </div>
    );
};
