import {ExecuteCommandParameters, ExecuteFactory, GetCommandTweekKey} from '../shared/types';
import sendLegacyItem from '../shared/sendLegacyItem';

export const getCommandTweekKey: GetCommandTweekKey = () => 'help';

export const executeFactory: ExecuteFactory = () => async ({
    analyticsDispatcher,
    triggeringMethod,
    sessionId,
    message,
    expertData,
}: ExecuteCommandParameters) => {
    analyticsDispatcher
        .createScoped('Tools')
        .createScoped('Help')
        .withExtra('TriggeringMethod', triggeringMethod || 'SlashCommand')
        .withExtra('ToolName', 'help')
        .dispatch('Request');

    await sendLegacyItem({
        sessionId,
        subtype: 'CommandResult',
        content: {message},
        isExpertOnly: true,
        expertData,
        extraTimelineItemProps: {commandType: 'help', type: 'help'},
    });
};
