import React, {useCallback, useMemo, useState} from 'react';
import {
    CoreButton,
    CoreDropdown,
    CoreModal,
    CoreInputArea,
    CoreListItem,
    CoreList,
    CoreText,
    CoreListProps,
    closeModal,
} from '@anywhere-expert/base-ui';
import styled from 'styled-components';
import {scheduleFollowUp, deleteFollowUp} from './scheduleFollowUp';
import useFollowUpConfig from './useFollowUpConfig';
import {FollowUpData} from '@anywhere-expert/expert-feed-store';
import {ExpertFeedStore} from '@anywhere-expert/expert-feed-store';
import {addDays, getHours, isSameDay, setHours, setMinutes} from 'date-fns';
import {useAnalytics} from 'react-shisell';
import {observer} from 'mobx-react';

const InputAreaContainer = styled.div`
    min-width: 480px;
`;

const DropdownContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const DropDown = styled(CoreDropdown).attrs({contentStyle: {width: '100%'}, childStyle: {width: '100%'}})`
    width: 100%;
`;

const DayDropDown = styled(DropDown)`
    min-width: 280px;
    margin-right: 8px;
`;

const TimeDropDown = styled(DropDown)`
    margin-left: 8px;
`;

const WideCoreList = styled(CoreList)`
    width: 100%;
` as React.ComponentType<CoreListProps & React.HTMLProps<HTMLUListElement>>;

const InputArea = styled(CoreInputArea)`
    min-height: 250px;
`;

const justifyContent = {justifyContent: 'space-between'};

const closeFollowUpModal = () => closeModal('follow-up-modal');

const useDefaultOptions = (daysOptions, timeOptions, currentFollowUpData?: FollowUpData) =>
    useMemo(() => {
        if (!currentFollowUpData) {
            return {};
        }

        const {scheduledTime, description} = currentFollowUpData;

        const scheduledDate = new Date(scheduledTime);
        const scheduledDateHours = getHours(scheduledDate);

        return {
            defaultDayOption: daysOptions.find(({value}) => isSameDay(addDays(new Date(), value), scheduledDate)),
            defaultTimeOption: timeOptions.times.find(({value}) => scheduledDateHours === value),
            defaultDescription: description,
        };
    }, [daysOptions, timeOptions, currentFollowUpData]);

export default observer(() => {
    const selectedSession = ExpertFeedStore.selectedSupportSession;
    const currentFollowUpData = selectedSession?.followUpData;
    const {daysOptions, timeOptions} = useFollowUpConfig();
    const {defaultDayOption, defaultTimeOption, defaultDescription} = useDefaultOptions(
        daysOptions,
        timeOptions,
        currentFollowUpData
    );
    const [dayDropdownOpen, setDayDropdownOpen] = useState(false);
    const [selectedDay, setSelectedDay] = useState(defaultDayOption);

    const toggleDayDropdown = useCallback(() => setDayDropdownOpen(open => !open), [setDayDropdownOpen]);
    const closeDayDropdown = useCallback(() => setDayDropdownOpen(false), [setDayDropdownOpen]);
    const onDaySelected = useCallback(
        day => {
            setSelectedDay(day);
            closeDayDropdown();
        },
        [setSelectedDay, closeDayDropdown]
    );

    const [timeDropdownOpen, setTimeDropdownOpen] = useState(false);
    const [selectedTime, setSelectedTime] = useState(defaultTimeOption);

    const toggleTimeDropdown = useCallback(() => setTimeDropdownOpen(open => !open), [setTimeDropdownOpen]);
    const closeTimeDropdown = useCallback(() => setTimeDropdownOpen(false), [setTimeDropdownOpen]);
    const onTimeSelected = useCallback(
        time => {
            setSelectedTime(time);
            closeTimeDropdown();
        },
        [setSelectedTime, closeTimeDropdown]
    );
    const [description, setDescription] = useState(defaultDescription || '');
    const [descriptionValid, setDescriptionValid] = useState(description || false);
    const onInputUpdated = useCallback(
        (val, isValid) => {
            setDescriptionValid(isValid);
            setDescription(val);
        },
        [setDescription, setDescriptionValid]
    );

    const {dispatcher} = useAnalytics();

    const dispatcherWithExtra = useMemo(
        () => dispatcher.withExtra('FollowupDay', selectedDay?.text).withExtra('FollowUpTime', selectedTime?.value),
        [dispatcher, selectedDay, selectedTime]
    );

    const onDone = useCallback(async () => {
        if (!selectedSession || !selectedDay?.value || !selectedTime?.value) return;

        closeFollowUpModal();

        await scheduleFollowUp({
            description,
            scheduledTime: setMinutes(
                setHours(addDays(new Date(), selectedDay.value), selectedTime.value),
                0
            ).getTime(),
            selectedSession,
        });

        dispatcherWithExtra.withExtra('ActionId', currentFollowUpData ? 'Reschedule' : 'Done').dispatch('Click');
    }, [description, selectedDay, selectedTime, selectedSession, dispatcher]);

    const onDelete = useCallback(async () => {
        if (!selectedSession) return;

        closeFollowUpModal();

        await deleteFollowUp({selectedSession});

        dispatcherWithExtra.withExtra('ActionId', 'Delete').dispatch('Click');
    }, [selectedSession]);

    const disabled = !descriptionValid || !selectedDay || !selectedTime;

    return (
        <>
            <CoreModal.Title>Schedule a Follow-Up</CoreModal.Title>
            <CoreModal.Content>
                <DropdownContainer>
                    <DayDropDown
                        text={selectedDay?.text || 'When?'}
                        extend={dayDropdownOpen}
                        onClick={toggleDayDropdown}
                        onClickOut={closeDayDropdown}
                        data-test-id={'FollowUp_Day_Dropdown'}
                    >
                        <WideCoreList>
                            {daysOptions.map((day, index) => (
                                <CoreListItem
                                    text={day.text}
                                    key={day.value}
                                    style={justifyContent}
                                    onClick={() => onDaySelected(day)}
                                    data-test-id={`FollowUp_Day_Dropdown_Option_${index}`}
                                >
                                    <CoreText textType={'tertiary'}>{day.secondaryText}</CoreText>
                                </CoreListItem>
                            ))}
                        </WideCoreList>
                    </DayDropDown>
                    <TimeDropDown
                        text={selectedTime?.text || 'At what time?'}
                        extend={timeDropdownOpen}
                        onClick={toggleTimeDropdown}
                        onClickOut={closeTimeDropdown}
                        data-test-id={'FollowUp_Time_Dropdown'}
                    >
                        <WideCoreList>
                            {timeOptions.times.map((time, index) => (
                                <CoreListItem
                                    text={time.text}
                                    key={time.value}
                                    onClick={() => onTimeSelected(time)}
                                    data-test-id={`FollowUp_Time_Dropdown_Option_${index}`}
                                />
                            ))}
                        </WideCoreList>
                    </TimeDropDown>
                </DropdownContainer>
                <InputAreaContainer>
                    <InputArea
                        placeholder="Add a note about the session, the solution you gave, and why you’d like to follow up with this customer – another expert may follow up for you, so leave them all the details."
                        onChange={onInputUpdated}
                        value={description}
                        min={30}
                        data-test-id={'FollowUp_Description'}
                    />
                </InputAreaContainer>
            </CoreModal.Content>
            <CoreModal.Actions>
                {currentFollowUpData ? (
                    <CoreButton variant="outlined" text="Delete Follow-Up" onClick={onDelete} />
                ) : (
                    <CoreButton
                        variant="outlined"
                        text="Cancel"
                        data-test-id={'CancelFollowUp'}
                        onClick={closeFollowUpModal}
                    />
                )}
                <CoreButton
                    color="primary"
                    text="Done"
                    data-test-id={'DoneFollowUp'}
                    disabled={disabled}
                    onClick={onDone}
                />
            </CoreModal.Actions>
        </>
    );
});
