import * as React from 'react';
import NoMessage from '../NoMessage';
import {useTweekValue} from 'react-tweek';
import {TimelineItemProps} from '@anywhere-expert/timeline-behaviours/timelineItemsRenderer';

const DeletedMessage = (props: TimelineItemProps) => {
    const deletedText = useTweekValue('support/session/messaging/rich_messaging/deletion/deleted_text', '');
    return <NoMessage {...props} text={deletedText} />;
};

export default DeletedMessage;
