import React, {FunctionComponent} from 'react';
import {useTweekValue} from 'react-tweek';
import {TextMessage} from '@anywhere-expert/timeline-item';
export interface OsCheckProps {
    needToCheckDevice?: boolean;
    osAnswer: string;
}

const OsCheck: FunctionComponent<OsCheckProps> = ({needToCheckDevice, osAnswer}: OsCheckProps) => {
    const osCheckMessage = useTweekValue(
        'support/session/initiate/bots/self_help_bot/ostype_question_message',
        `OK, we think we got it, but first - do you have an Android or an iPhone?`
    );

    const secondMessage = useTweekValue(
        'support/session/initiate/bots/self_help_bot/second_message',
        `OK, we think we got it.`
    );

    return needToCheckDevice ? (
        <>
            {osAnswer ? (
                <TextMessage contentId="SelfHelpOsAnswer" isSelf={false} isGroupedItem={false} content={osAnswer} />
            ) : null}
            <TextMessage
                contentId="SelfHelpOsCheck"
                isSelf={true}
                showThumbnail={true}
                senderThumbUrl="teamIcon"
                isGroupedItem={false}
                content={osCheckMessage}
            />
        </>
    ) : (
        <TextMessage
            contentId="SelfHelpMessage"
            isSelf={true}
            showThumbnail={true}
            senderThumbUrl="teamIcon"
            isGroupedItem={false}
            content={secondMessage}
        />
    );
};

export default OsCheck;
