import {authorizedFetch} from '@anywhere-expert/auth';
import {ScheduleApi, Configuration} from '@soluto-private/proactive-session-api-client';
import {FollowUpPayload} from '@soluto-private/messaging-contracts';
import uuid from 'uuid/v4';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import {setAppMessage} from '@anywhere-expert/app-messages';
import {format} from 'date-fns';
import logger from '@anywhere-expert/logging';
import {configuration} from '@anywhere-expert/core';

const scheduleApi = new ScheduleApi(
    new Configuration({fetchApi: authorizedFetch, basePath: configuration.proactiveSessionApiUrl})
);

export const scheduleFollowUp = async ({
    description,
    scheduledTime,
    selectedSession,
}: {
    description: string;
    scheduledTime: number;
    selectedSession: SupportItem;
}) => {
    const messagePayload: FollowUpPayload = {
        type: 'follow_up',
        value: {
            scheduleTime: scheduledTime,
            originatingSessionId: selectedSession.sessionId,
            text: description,
        },
    };

    try {
        await scheduleApi.schedule({
            payload: {
                scheduledTime,
                customerId: selectedSession.id,
                pool: selectedSession.pool,
                expertise: selectedSession.mandatoryExpertise,
                partner: selectedSession.supportItemDetails.partner,
                originatingSessionId: selectedSession.sessionId,
                originatingSessionUpsertAttributes: [
                    {
                        attributeType: 'proactive',
                        key: 'follow-up',
                        fields: {
                            scheduledTime: scheduledTime as any,
                            description: description as any,
                        },
                    },
                ],
                attributes: [
                    {attributeType: 'proactive', key: 'follow-up'},
                    {
                        attributeType: 'proactive-session',
                        key: 'proactive-session',
                    },
                ],
                messagePayload: messagePayload as any,
            },
            meta: {correlationId: uuid()},
        });

        setAppMessage({
            text: `Follow-Up set for ${format(scheduledTime, 'MMMM d')} at ${format(scheduledTime, 'h:mm a')}`,
            type: 'followup',
        });
    } catch (err) {
        logger.error('Follow-Up Failed', {err});
        setAppMessage({text: 'Follow-Up failed', type: 'warning'});
    }
};

export const deleteFollowUp = async ({selectedSession}: {selectedSession: SupportItem}) => {
    try {
        await scheduleApi.cancel({
            payload: {
                customerId: selectedSession.id,
                originatingSessionId: selectedSession.sessionId,
                originatingSessionRemoveAttribute: {
                    attributeType: 'proactive',
                    key: 'follow-up',
                },
            },
            meta: {correlationId: uuid()},
        });

        setAppMessage({text: 'Follow-Up deleted', type: 'followup'});
    } catch (err) {
        logger.error('Follow-Up Failed', {err});
        setAppMessage({text: 'Follow-Up failed', type: 'warning'});
    }
};
