import React, {useEffect, useMemo} from 'react';
import {CoreText, CoreIconButton} from '@anywhere-expert/base-ui';
import styled from 'styled-components';
import type {AnalyticsDispatcher} from 'shisell';
import {CopyIcon, LocationOnMapIcon} from '@anywhere-expert/icons';
import {setAppMessage} from '@anywhere-expert/app-messages';

const ZipcodeUpdateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -25px;
    margin: 40px 0px;
`;

const LocationLogo = styled.div`
    margin-bottom: 5px;
`;

const UpdateTitle = styled(CoreText)`
    font-size: 13px;
    margin-bottom: 5px;
`;

const WatchResults = styled.div`
    display: flex;
`;

const ZipCode = styled(CoreText)`
    text-decoration: underline;
    margin: 0 2px 0 5px;
`;

type Props = {
    newZipcode: string;
    dispatcher: AnalyticsDispatcher;
};

const ZipcodeUpdate = ({newZipcode, dispatcher}: Props) => {
    const zipCodeDispatcher = useMemo(() => dispatcher.withExtras({Type: 'update', UpdateType: 'zipcode'}), [
        dispatcher,
    ]);

    useEffect(() => {
        zipCodeDispatcher.dispatch('View');
    }, [zipCodeDispatcher]);

    const onCopyClick = React.useCallback(async () => {
        if (newZipcode) {
            await window.navigator?.clipboard.writeText(newZipcode);
            setAppMessage({text: 'Copied to clipboard'});
        }
        zipCodeDispatcher.dispatch('Click');
    }, [newZipcode]);

    return (
        <ZipcodeUpdateWrapper>
            <LocationLogo>
                <LocationOnMapIcon />
            </LocationLogo>
            <UpdateTitle weight="medium">Zip code changed</UpdateTitle>
            <WatchResults>
                <UpdateTitle>Customer is now searching in</UpdateTitle>
                <ZipCode>{newZipcode}</ZipCode>
                <CoreIconButton icon={CopyIcon} onClick={onCopyClick} color="tertiary" size="xxs" variant={'icon'} />
            </WatchResults>
        </ZipcodeUpdateWrapper>
    );
};

export default ZipcodeUpdate;
