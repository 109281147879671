import {withOnPropChangedAnalytic, enrichAnalytics, withAnalyticOnView} from 'react-shisell';
import {withState, compose, withProps, withHandlers} from 'recompose';
import {DropdownPosition} from '@anywhere-expert/base-ui';
import {withTweekKeys} from '@anywhere-expert/tweek';
import SnoozeMenu from './SnoozeMenu';
import {CSSProperties} from 'react';

const tweekConfig = withTweekKeys({
    isCustomIntervalEnabled: 'support/feed/auto_pilot/snooze/custom_interval/is_enabled',
});

const snoozeMenuOpenState = withState('snoozeMenuOpen', 'setSnoozeMenuOpen', false);
const snoozeTimeState = compose(
    withProps(() => {
        const defaultMinutes = 30;
        const hours = Math.floor(defaultMinutes / 60);
        const minutes = defaultMinutes % 60;

        return {hours, minutes};
    }),
    withState('hours', 'setHours', ({hours}) => hours),
    withState('minutes', 'setMinutes', ({minutes}) => minutes)
);

const behaviors = compose(
    withHandlers({
        onMenuHeaderClick: ({snoozeMenuOpen, setSnoozeMenuOpen}) => () => {
            setSnoozeMenuOpen(!snoozeMenuOpen);
        },
    })
);

const analytics = compose(
    withOnPropChangedAnalytic({
        propName: 'snoozeMenuOpen',
        analyticName: 'Click',
        mapPropsToExtras: ({snoozeMenuOpen}) => ({ActionId: snoozeMenuOpen ? 'SnoozeOpen' : 'SnoozeClose'}),
    }),
    enrichAnalytics(dispatcher => dispatcher.createScoped('SnoozeMenu')),
    withAnalyticOnView({analyticName: 'View', predicate: ({snoozeMenuOpen}: any) => snoozeMenuOpen})
);

type Input = {
    disabled?: boolean;
    style?: CSSProperties;
    sessionId: string;
    position?: DropdownPosition;
    childStyle?: CSSProperties;
    withText?: boolean;
    openAutopilotModal?: () => void;
    showAutopilotMessage?: boolean;
};
type Output = Input & {onMenuHeaderClick: () => Promise<void>};

export default compose<Output, Input>(
    tweekConfig,
    snoozeMenuOpenState,
    snoozeTimeState,
    behaviors,
    analytics
)(SnoozeMenu);
