import * as React from 'react';
import styled from 'styled-components';
import {compose} from 'recompose';
import {useAnalytics, withAnalyticOnEvent, WithAnalyticOnEventProps} from 'react-shisell';
import {ExpertFeedStore} from '@anywhere-expert/expert-feed-store';
import {getFeedActionsClient} from '@anywhere-expert/expert-feed-actions';
import {disableEscalate} from '../api';
import DropDown from './dropdown';
import useTransferOptions, {Option} from '../behaviours/useTransferOptions';
import {CoreText, CoreActionMenuItem, CoreButton, CoreButtonProps, CompoundButtonSet} from '@anywhere-expert/base-ui';
import {useDetectEscapeKey} from '@anywhere-expert/utils';
import {setAppMessage} from '@anywhere-expert/app-messages';
import useCopyFromTweek from '../behaviours/useCopyFromTweek';
import VisibilityIndicator from '@system-messages/visibility-indicator';
import {Attribute} from '@soluto-private/session-api-types';
import {observer} from 'mobx-react';

const EscalateContainer = styled(CoreActionMenuItem)`
    width: 500px;
    max-height: 280px;
    flex-direction: column;
    align-items: center;
    margin-top: 34px;
    align-self: center;
    display: flex;
    z-index: 2;
    flex-shrink: 0;
`;

const NoteContainer = styled.div`
    width: 380px;
    min-height: 81px;
    border-radius: 4px;
    background-color: #fbf7bb;
    box-sizing: border-box;
    display: flex;
    padding: 12px;
`;
const Input = styled.textarea`
    flex: 1;
    font-size: 13px;
    border-width: 0;
    resize: none;
    min-height: 30px;
    background-color: transparent;
    color: ${({theme}) => theme.colors.neutral.grey.deepest};
    &::placeholder {
        font-style: italic;
        color: #5f5c31;
    }
    &:focus {
        outline-width: 0;
    }
`;

const StepContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const TransferContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 24px;
    flex-direction: row;
    z-index: 1;
`;

const htmlElementWithAnalytics = (actionId: string) => Element =>
    withAnalyticOnEvent<React.HTMLAttributes<any>>({
        eventName: 'onClick',
        analyticName: 'Click',
        extras: {
            ActionId: actionId,
        },
    })(Element);

const ContinueButtonDiv = compose<CoreButtonProps, CoreButtonProps & WithAnalyticOnEventProps<Event>>(
    htmlElementWithAnalytics('ContinueButton')
)(CoreButton);

const useEscalateLifecycle = (timelineId: string) => {
    React.useEffect(() => {
        return disableEscalate;
    }, [timelineId]);
};

const useEscalateAnalytics = (options: Option[]) => {
    const analytics = useAnalytics();
    const dispatcher = React.useMemo(
        () =>
            analytics.dispatcher.createScoped('AddAnExpert').withExtras({Options: options.map(x => x.label).join(',')}),
        [options]
    );
    React.useEffect(() => {
        dispatcher.dispatch('View');
    }, []);

    return dispatcher;
};

// @ts-ignore
const ExitButtonDiv = htmlElementWithAnalytics('ExitButton')(CoreButton) as React.ReactType<CoreButtonProps>;

const Component = observer(({sessionId, timelineId}: EscalateProps) => {
    const {notePlaceholderText, preSelectMessage, selectionPlaceholder, warningText} = useCopyFromTweek();
    useEscalateLifecycle(timelineId);
    const {selectedOption, setSelectedOption, filteredOptions} = useTransferOptions();
    useEscalateAnalytics(filteredOptions);
    const [isSubmitInProgress, setIsSubmitInProgress] = React.useState(false);
    useDetectEscapeKey(disableEscalate);
    const [message, setMessage] = React.useState('');
    const inputRef = React.useRef<HTMLTextAreaElement>(null);
    const currentExpertise = ExpertFeedStore.selectedSession?.sessionAttributes?.['expertise'];

    const sendEscalateRequest = React.useCallback(async () => {
        const messageWithOption = `${selectedOption!.label}\n${message}`;
        const expertiseAttribute: Attribute[] =
            selectedOption!.value?.map(expertiseKey => ({
                isMandatory: true,
                attributeType: 'expertise',
                key: expertiseKey || '',
            })) ?? [];

        setIsSubmitInProgress(true);
        const attributesToRemove = expertiseAttribute.length ? Object.values(currentExpertise ?? {}) : [];
        await getFeedActionsClient().escalateFeedItem({
            feedItemId: sessionId,
            attributes: expertiseAttribute,
            attributesToRemove,
            message: messageWithOption,
        });

        setAppMessage({text: 'The session was escalated to another Expert', type: 'success'});
        ExpertFeedStore.selectSession(undefined);
        ExpertFeedStore.handleRemove(sessionId);
    }, [selectedOption, message]);

    React.useEffect(() => {
        if (selectedOption && inputRef && inputRef.current) {
            inputRef.current.focus();
        }
    }, [selectedOption, currentExpertise]);

    const onInputChange = React.useCallback(e => setMessage(e.target.value), []);
    return (
        <StepContainer data-test-id="Escalate">
            {warningText && (
                <CoreText size="s" textType="tertiary" weight="bold" style={{fontStyle: 'italic', marginTop: '10px'}}>
                    {warningText}
                </CoreText>
            )}
            <TransferContainer>
                <CoreText size="m" textType="primary" style={{marginRight: '10px'}}>
                    {preSelectMessage}
                </CoreText>
                <DropDown
                    placeholder={selectionPlaceholder}
                    options={filteredOptions}
                    onChange={setSelectedOption}
                    value={selectedOption}
                />
            </TransferContainer>
            <NoteContainer>
                <Input
                    placeholder={notePlaceholderText}
                    data-test-id="InputEscalate"
                    value={message}
                    ref={inputRef}
                    onChange={onInputChange}
                />
            </NoteContainer>
            <CompoundButtonSet size={'m'} style={{marginTop: '20px', marginBottom: '20px'}}>
                <ExitButtonDiv
                    variant="outlined"
                    text="Cancel"
                    onClick={disableEscalate}
                    data-test-id="CancelEscalate"
                />
                <ContinueButtonDiv
                    variant="contained"
                    color="primary"
                    text="Continue"
                    isInProgress={isSubmitInProgress}
                    disabled={!selectedOption}
                    data-test-id="ConfirmEscalate"
                    onClick={sendEscalateRequest}
                    analyticsExtras={{
                        NoteAdded: message.length ? 'Yes' : 'No',
                        NoteText: message,
                        SelectedOption: selectedOption,
                        TransferReason: selectedOption?.label,
                        TransferExpertise: selectedOption?.value?.join(', '),
                    }}
                />
            </CompoundButtonSet>
        </StepContainer>
    );
});

interface EscalateProps {
    timelineId: string;
    sessionId: string;
}

const Centered = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
`;

const Escalate = ({sessionId, timelineId}: EscalateProps) => (
    <VisibilityIndicator>
        <Centered>
            <EscalateContainer>
                <Component sessionId={sessionId} timelineId={timelineId} />
            </EscalateContainer>
        </Centered>
    </VisibilityIndicator>
);

export default Escalate;
