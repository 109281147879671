import {getFromTweekForCustomer} from '@anywhere-expert/tweek';
import logger from '@anywhere-expert/logging';
import {ExecuteCommandParameters} from './types';
import {AnalyticsDispatcher} from 'shisell';
import sendLegacyItem from './sendLegacyItem';
import {UserDetailsType} from '../../../core/types';

type CommandSenderParameters = ExecuteCommandParameters & {
    extraTimelineItemProps?: {};
    contentType: string;
    commandType: string;
    tweekToolKey: string;
    analyticsScope: string | string[];
    validateArgs?: (args: string[]) => boolean;
    onFailedValidation?: () => void;
};

type TweekResponseForCommand = {
    isEnabled: boolean;
    isSupported: boolean;
    resultDestination: any;
};

const getConfigFromTweek = async (deviceId: string, tweekToolKey: string): Promise<TweekResponseForCommand> => {
    const config = await getFromTweekForCustomer<TweekResponseForCommand>(
        deviceId,
        `support/session/messaging/tools/${tweekToolKey}/_`
    );
    if (!config) {
        logger.error('There was a problem fetching tweek values for command', {
            extra: {command: tweekToolKey, deviceId},
        });
        return {
            isEnabled: false,
            isSupported: false,
            resultDestination: null,
        };
    }
    return config;
};

const sendMessageToCustomer = async (
    contentType: string,
    commandType: string,
    sessionId: string,
    args: string[],
    resultDestination: any,
    triggeringMethod: string,
    expertData: UserDetailsType,
    extraTimelineItemProps?: {}
) =>
    sendLegacyItem({
        expertData,
        isExpertOnly: false,
        sessionId,
        content: {args, resultDestination, triggeringMethod},
        subtype: contentType,
        extraTimelineItemProps: {
            ...extraTimelineItemProps,
            type: commandType,
            lastSender: 'expert',
            commandType,
            commandArgs: args,
            triggeringMethod,
        },
    });

const sendMessageToExpert = async (
    expertData: UserDetailsType,
    sessionId: string,
    commandType: string,
    message: string,
    extraTimelineItemProps?: {}
) =>
    sendLegacyItem({
        expertData,
        isExpertOnly: true,
        sessionId,
        content: {message},
        subtype: 'CommandResult',
        extraTimelineItemProps: {...extraTimelineItemProps, commandType, type: commandType},
    });

const dispatchAnalytics = (
    analyticsDispatcher: AnalyticsDispatcher,
    analyticsScope: string | string[],
    commandType: string,
    args: string[],
    resultDestination: any,
    triggeringMethod: string
) => {
    let analytic = analyticsDispatcher
        .createScoped('Tools')
        .withExtra('TriggeringMethod', triggeringMethod)
        .withExtra('ResultDestination', Object.keys(resultDestination).join(','))
        .withExtra('ToolName', commandType);

    if (typeof analyticsScope === 'string') {
        analytic = analytic.createScoped(analyticsScope);
    } else if (Array.isArray(analyticsScope)) {
        analyticsScope.forEach(x => (analytic = analytic.createScoped(x)));
    }

    if (args && Array.isArray(args)) {
        const argsCopy = args.slice(0);
        if (argsCopy.length === 0) {
            argsCopy.push('default');
        }
        argsCopy.forEach((a, i) => {
            analytic = analytic.withExtra(`Args_${i + 1}`, a);
        });
    }

    analytic.dispatch('Request');
};

export default async ({
    timelineId,
    commandType,
    contentType,
    tweekToolKey,
    analyticsScope,
    args,
    analyticsDispatcher,
    validateArgs,
    onFailedValidation,
    triggeringMethod = 'SlashCommand',
    expertData,
    message,
    sessionId,
    extraTimelineItemProps,
}: CommandSenderParameters) => {
    if (validateArgs && !validateArgs(args)) {
        return (
            (onFailedValidation && onFailedValidation()) ||
            (await sendMessageToExpert(expertData, sessionId, 'command-bad-args', message, extraTimelineItemProps))
        );
    }

    const commandConfig = await getConfigFromTweek(timelineId, tweekToolKey);
    if (!commandConfig.isEnabled) {
        return sendMessageToExpert(expertData, sessionId, 'command-disabled', message, extraTimelineItemProps);
    }
    if (!commandConfig.isSupported) {
        return sendMessageToExpert(expertData, sessionId, 'command-unsupported', message, extraTimelineItemProps);
    }
    if (!commandConfig.resultDestination) {
        return sendMessageToExpert(expertData, sessionId, 'command-error', message, extraTimelineItemProps);
    }

    await sendMessageToCustomer(
        contentType,
        commandType,
        sessionId,
        args,
        commandConfig.resultDestination,
        triggeringMethod,
        expertData,
        extraTimelineItemProps
    );

    dispatchAnalytics(
        analyticsDispatcher,
        analyticsScope,
        commandType,
        args,
        commandConfig.resultDestination,
        triggeringMethod
    );
};
