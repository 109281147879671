import React, {useCallback} from 'react';
import WakeUpTime from './WakeUpTime';
import Time from './Time';
import SnoozeApprovalButton from './SnoozeApprovalButton';
import {Row2, Row3, Container, AutopilotMessage} from './styles';
import {CoreDropdown} from '@anywhere-expert/base-ui';
import {css} from 'aphrodite';
import {SnoozeIcon} from '@anywhere-expert/icons';
import {LearnMoreLink} from '@anywhere-expert/autopilot';
import {useTheme} from '@anywhere-expert/colors';

const tooltipProps = {
    title: 'Snooze',
    position: 'bottom',
};

export default ({
    sessionId,
    snoozeMenuOpen,
    onMenuHeaderClick,
    minutes,
    setMinutes,
    hours,
    position,
    disabled,
    setHours,
    style,
    childStyle,
    setSnoozeMenuOpen,
    isCustomIntervalEnabled,
    withText,
    openAutopilotModal,
    showAutopilotMessage,
}) => {
    const theme = useTheme();
    const closeSnoozeMenu = useCallback(() => {
        setSnoozeMenuOpen(false);
    }, [setSnoozeMenuOpen]);
    const isValidHours = hours >= 0 && hours < 24;
    const isValidMinutes = (minutes > 0 || hours > 0) && minutes < 60;
    const isTotalTimeZero = minutes === 0 && hours === 0;

    const autoPilotLearnMoreClick = useCallback(() => {
        openAutopilotModal();
        setSnoozeMenuOpen(false);
    }, [openAutopilotModal, setSnoozeMenuOpen]);

    return (
        <CoreDropdown
            onClick={onMenuHeaderClick}
            onClickOut={closeSnoozeMenu}
            icon={SnoozeIcon}
            variant={withText ? 'outlined' : 'icon'}
            color={withText ? 'secondary' : 'default'}
            text={withText && 'Snooze again'}
            data-test-id="SnoozeMenu"
            position={position}
            extend={snoozeMenuOpen}
            disabled={disabled}
            childStyle={childStyle}
            size="s"
            className={css(style)}
            hasArrow={false}
            tooltipProps={tooltipProps}
        >
            <Container isCustomIntervalEnabled={isCustomIntervalEnabled}>
                <Row2>
                    {isCustomIntervalEnabled ? (
                        <WakeUpTime
                            color={theme.colors.neutral.black}
                            minutes={minutes}
                            setMinutes={setMinutes}
                            hours={hours}
                            setHours={setHours}
                        />
                    ) : (
                        <Time minutes={minutes} />
                    )}
                </Row2>
                {showAutopilotMessage && (
                    <AutopilotMessage>
                        {'FYI: If a customer is unresponsive for 30 mins, Autopilot will start checking in with them. '}
                        <LearnMoreLink onClick={autoPilotLearnMoreClick} data-test-id="LearnMoreLink">
                            Learn more
                        </LearnMoreLink>
                    </AutopilotMessage>
                )}
                <Row3>
                    <SnoozeApprovalButton
                        disabled={!isValidMinutes || !isValidHours || isTotalTimeZero}
                        sessionId={sessionId}
                        closeMenu={closeSnoozeMenu}
                        hours={hours}
                        minutes={minutes}
                    />
                </Row3>
            </Container>
        </CoreDropdown>
    );
};
