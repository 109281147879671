import React, {Suspense} from 'react';
import pRetry from 'p-retry';

const retryConfig: pRetry.Options = {
    retries: 5,
    maxTimeout: 2000,
};

const Modal = React.lazy(() =>
    pRetry(() => import(/* webpackChunkName: "ImageItemModal" */ '@anywhere-expert/image-item'), retryConfig)
);

const ImageItemModal = props => (
    <Suspense fallback={<span>Loading...</span>}>
        <Modal {...props} />
    </Suspense>
);

export default ImageItemModal;
