import React from 'react';
import {StyleSheet, css} from 'aphrodite';
import {palette} from '@anywhere-expert/colors';

const wifiSignal1Icon = require('../assets/ic_wifi_0.svg');
const wifiSignal2Icon = require('../assets/ic_wifi_1.svg');
const wifiSignal3Icon = require('../assets/ic_wifi_2.svg');
const wifiSignal4Icon = require('../assets/ic_wifi_3.svg');
const wifiSignal5Icon = require('../assets/ic_wifi_4.svg');
const wifiSignal1SecuredIcon = require('../assets/ic_wifi_0_l.svg');
const wifiSignal2SecuredIcon = require('../assets/ic_wifi_1_l.svg');
const wifiSignal3SecuredIcon = require('../assets/ic_wifi_2_l.svg');
const wifiSignal4SecuredIcon = require('../assets/ic_wifi_3_l.svg');
const wifiSignal5SecuredIcon = require('../assets/ic_wifi_4_l.svg');

const styles = StyleSheet.create({
    mainContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '5px',
    },
    icon: {
        marginRight: '10px',
        width: '20px',
        height: '20px',
    },
    ssid: {
        marginRight: '10px',
        textAlign: 'center',
        fontSize: '13px',
        color: palette.neutral.grey.deepest,
        fontFamily: 'Roboto',
        lineHeight: '1.54',
    },
});

const getIcon = wifiNetwork => {
    const isSecure =
        wifiNetwork.capabilities &&
        (wifiNetwork.capabilities.includes('WPA') || wifiNetwork.capabilities.includes('WEP'));
    const dbmLevel = wifiNetwork.level;
    if (dbmLevel > -67) {
        return isSecure ? wifiSignal5SecuredIcon : wifiSignal5Icon;
    } else if (-67 >= dbmLevel && dbmLevel > -70) {
        return isSecure ? wifiSignal4SecuredIcon : wifiSignal4Icon;
    } else if (-70 >= dbmLevel && dbmLevel > -80) {
        return isSecure ? wifiSignal3SecuredIcon : wifiSignal3Icon;
    } else if (-80 >= dbmLevel && dbmLevel > -90) {
        return isSecure ? wifiSignal2SecuredIcon : wifiSignal2Icon;
    } else {
        return isSecure ? wifiSignal1SecuredIcon : wifiSignal1Icon;
    }
};

export default props => {
    const {wifiNetwork} = props;

    return (
        <div className={css(styles.mainContainer)}>
            <img src={getIcon(wifiNetwork)} className={css(styles.icon)} />
            <div className={css(styles.ssid)}>{wifiNetwork.SSID}</div>
        </div>
    );
};
