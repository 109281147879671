import React from 'react';
import {reaction} from 'mobx';
import {useHistory} from 'react-router-dom';
import {ExpertFeedStore} from '@anywhere-expert/expert-feed-store';
import extractSessionDetailsFromLocation from '../components/behaviors/extractSessionDetailsFromLocation';

export default () => {
    const history = useHistory();
    React.useEffect(() => {
        const feedItem = extractSessionDetailsFromLocation(history.location);

        if (feedItem && feedItem.sessionId) {
            ExpertFeedStore.selectSession(feedItem.sessionId);
        }

        const selectedSessionReactionDisposer = reaction(
            () => ExpertFeedStore.selectedSessionId,
            selectedSessionId => {
                if (selectedSessionId) {
                    history.push(`/session/${selectedSessionId}`);
                }
            }
        );
        const noSelectionReactionDisposer = reaction(
            () => !ExpertFeedStore.selectedSessionId,
            nothingSelected => {
                if (nothingSelected) {
                    history.push(`/`);
                }
            }
        );

        return () => {
            selectedSessionReactionDisposer();
            noSelectionReactionDisposer();
        };
    }, []);
};
