import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {CoreText} from '@anywhere-expert/base-ui';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import App from './App';
import {AppData} from '../../shared/types';

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 110px);
    grid-gap: 16px 24px;
    justify-content: center;
`;

interface AppListProps {
    apps: AppData[];
    days: number;
    selectedSession: SupportItem;
}

const AppList = ({apps, days, selectedSession}: AppListProps) => {
    const indexedApps = apps.map((app, index) => {
        return {originAppIndex: index, ...app};
    });
    const filteredApps = filterRelevantApps(indexedApps, days);

    if (filteredApps.length === 0) {
        return <CoreText>No apps were installed in the last {days} days</CoreText>;
    }

    return <Container>{filteredApps.map(app => renderApp(app, selectedSession))}</Container>;
};

export default AppList;

function renderApp(app: AppData, selectedSession: SupportItem) {
    return <App key={app.package} app={app} selectedSession={selectedSession} />;
}

function filterRelevantApps(apps: AppData[], days: number): AppData[] {
    let filteredApps =
        days >= 0
            ? apps.filter(x => moment.duration(moment().diff(moment(x.firstInstallTime))).asDays() <= days)
            : apps;

    return filteredApps.filter(x => !x.isSystemApp).sort((a, b) => (a.firstInstallTime < b.firstInstallTime ? 1 : -1));
}
