import React, {useCallback} from 'react';
import {CoreIconButton} from '@anywhere-expert/base-ui';
import {FollowUpFilledIcon, FollowUpIcon} from '@anywhere-expert/icons';
import {openFollowUpModal} from '@anywhere-expert/follow-up';
import {useAnalytics} from 'react-shisell';

interface FollowUpProps {
    disabled?: boolean;
    isFollowUpOngoing?: boolean;
}

export default ({disabled, isFollowUpOngoing}: FollowUpProps) => {
    const {dispatcher} = useAnalytics();

    const onClick = useCallback(() => {
        dispatcher.withExtra('ActionId', 'Followup').dispatch('Click');
        openFollowUpModal();
    }, [dispatcher]);

    return (
        <CoreIconButton
            icon={isFollowUpOngoing ? FollowUpFilledIcon : FollowUpIcon}
            variant={'icon'}
            disabled={disabled}
            size={'s'}
            data-test-id={'FollowUpButton'}
            onClick={onClick}
            tooltipProps={{
                text: isFollowUpOngoing ? 'Reschedule Follow-Up' : 'Schedule a Follow-Up',
                position: 'bottom',
            }}
            {...(isFollowUpOngoing && {color: 'tertiary'})}
        />
    );
};
