import {useRef, useCallback, useEffect} from 'react';
import throttle from 'lodash.throttle';

export default (analytics, selectedTranscriptId) => {
    const loadTime = useRef<number>(Date.now());
    const scrollerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        scrollerRef.current?.scrollTo(0, 0);
        analytics.dispatcher.dispatch('View');
        loadTime.current = Date.now();
    }, [selectedTranscriptId]);

    const onScroll = useCallback(
        throttle(() => {
            const now = Date.now();
            analytics.dispatcher.withExtra('timeUntilScroll', (now - loadTime.current) / 1000).dispatch('Scroll');
        }, 300),
        [loadTime]
    );

    return {onScroll, scrollerRef};
};
