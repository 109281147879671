import React, {useCallback, useState, useEffect} from 'react';
import {useAnalytics} from 'react-shisell';
import {AnalyticsDispatcher} from 'shisell';
import {Theme} from '@anywhere-expert/colors';
import {CoreTag} from '@anywhere-expert/base-ui';
import styled from 'styled-components';

const getButtonActiveStyle = (theme: Theme) =>
    `background-color:${theme.colors.contrast.border};
    color: ${theme.colors.neutral.black};
    border: 1px dashed ${theme.colors.neutral.black};`;

const AddTagsButton = styled(CoreTag)<{isActive: boolean}>`
    ${({theme, isActive}) =>
        isActive
            ? getButtonActiveStyle(theme)
            : `background-color:${theme.colors.neutral.white};
                border: 1px dashed ${theme.colors.contrast.caption};
                color: ${theme.colors.contrast.caption};`}
    cursor: pointer;
    position: relative;
    &:hover {
        background-color: ${({theme}) => theme.colors.contrast.disabled};
        color: ${({theme}) => theme.colors.neutral.black};
        border: 1px dashed ${({theme}) => theme.colors.neutral.black};
    }
    &:active {
        ${({theme}) => getButtonActiveStyle(theme)}
    }
`;

type ToggleButtonProps = {isActive: boolean; [x: string]: any};

const getToggleButtonElement = (dispatcher: AnalyticsDispatcher) => ({isActive, ...others}: ToggleButtonProps) => {
    useEffect(() => {
        dispatcher.dispatch('View');
    }, []);

    return <AddTagsButton text="+ Tag" size="m" isActive={isActive} data-test-id="ToggleAddTagMenu" {...others} />;
};

const useAddTagsMenu = () => {
    const {dispatcher} = useAnalytics();

    const customTagDispatcher = dispatcher.createScoped('CustomTag');
    const [ToggleButton] = useState(() => getToggleButtonElement(customTagDispatcher));

    const menuDispatcher = customTagDispatcher.createScoped('Menu');
    const [isMenuViewAnalyticSent, setIsMenuViewAnalyticSent] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const closeMenu = useCallback(() => {
        setIsMenuOpen(false);
    }, [setIsMenuOpen]);

    const toggleMenu = useCallback(() => {
        setIsMenuOpen(!isMenuOpen);
        if (isMenuOpen) return;
        dispatcher.withExtra('ActionId', 'AddTag').dispatch('Click');
        if (!isMenuViewAnalyticSent) {
            menuDispatcher.dispatch('View');
            setIsMenuViewAnalyticSent(true);
        }
    }, [setIsMenuOpen, isMenuOpen, dispatcher, isMenuViewAnalyticSent, setIsMenuViewAnalyticSent]);

    return {closeMenu, toggleMenu, isMenuOpen, ToggleButton, menuDispatcher};
};

export default useAddTagsMenu;
