import React, {FunctionComponent} from 'react';
import styled from 'styled-components';
import {TimelineItemProps} from '@anywhere-expert/timeline-behaviours/timelineItemsRenderer';
import {CoreIconButton, CoreText, CoreTimelineTextBubble} from '@anywhere-expert/base-ui';
import {OpenIcon} from '@anywhere-expert/icons';
import withTechTemplate from '../behaviors/withTechTemplate';

const ArticleWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    padding: 6px 0px;
`;

const Image = styled.img`
    max-width: 135px;
    max-height: 70px;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 14px;
    min-width: 196px;
    max-width: 206px;
`;

const Title = styled(CoreText)`
    margin-bottom: 5px;
    opacity: 0.95;
`;

const Summary = styled(CoreText)`
    opacity: 0.8;
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    user-select: none;
`;

const CoreTimelineItem = styled(CoreTimelineTextBubble)`
    min-width: 410px;
    min-height: 113px;
    justify-content: space-between;
`;

export type ArticleContentType = {
    className?: string;
    url?: string;
    title?: string;
    summary?: string;
    imageUrl?: string;
};

const Article: FunctionComponent<TimelineItemProps<ArticleContentType>> = ({
    content,
    showTimestamp,
    timestampValue,
    seenAtText,
}) => {
    const {url, imageUrl, title, summary} = content;

    return (
        <CoreTimelineItem
            textBubbleType="expert"
            size="m"
            timestamp={showTimestamp && timestampValue}
            seenAt={showTimestamp && seenAtText}
        >
            <Container data-test-id="SelfHelpArticle">
                <ArticleWrapper>
                    <Image src={imageUrl} />
                    <Content>
                        <Title textSize="m" weight="bold" textColor="white">
                            {title}
                        </Title>
                        <Summary textSize="m" textColor="white">
                            {summary}...
                        </Summary>
                    </Content>
                </ArticleWrapper>
                {url ? (
                    <a href={url} target="_blank">
                        <CoreIconButton icon={OpenIcon} variant="contained" color="primary" size="s" />
                    </a>
                ) : null}
            </Container>
        </CoreTimelineItem>
    );
};

export default withTechTemplate(Article);
