import commandSender from '../shared/commandSender';
import {ExecuteCommandParameters, ExecuteFactory, GetCommandTweekKey, ImplementedPlatforms} from '../shared/types';

export const getCommandTweekKey: GetCommandTweekKey = () => 'apps_list';
export const implementedPlatforms: ImplementedPlatforms = {web: true, mobile: false}; //Optional

export const executeFactory: ExecuteFactory = (name: string) => (options: ExecuteCommandParameters) => {
    if (options.args.length === 0) options.args = ['-1'];

    return commandSender({
        commandType: name,
        contentType: 'Command',
        tweekToolKey: getCommandTweekKey(),
        validateArgs: args => args.length === 0 || !isNaN(args[0] as any),
        analyticsScope: ['Apps', 'RecentApps'],
        ...options,
    });
};
