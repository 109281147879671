import * as React from 'react';
import styled from 'styled-components';
import {CoreDropdown, CoreList, CoreListItem} from '@anywhere-expert/base-ui';
import {Option} from '../../behaviours/useTransferOptions';

const StyledDropdown = styled(CoreDropdown)`
    .buttonText {
        max-width: 150px;
    }
`;

const dropdownStyle = {display: 'flex', justifyContent: 'center'};
const listStyle = {width: 'max-content', minWidth: '270px'};

type Props = {
    onChange: (x: Option) => void;
    options: Option[];
    value?: Option;
    placeholder: string;
};

const Dropdown = ({options, placeholder, onChange, value}: Props) => {
    const [dropDownOpen, setDropDownOpen] = React.useState(false);

    const onMenuHeaderClick = React.useCallback(() => setDropDownOpen(x => !x), []);

    return (
        <div style={dropdownStyle}>
            <StyledDropdown
                onClickOut={() => setDropDownOpen(false)}
                extend={dropDownOpen}
                onClick={onMenuHeaderClick}
                data-test-id="Escalate_Dropdown"
                text={value?.label ?? placeholder}
            >
                <CoreList style={listStyle}>
                    {options.map((o, i) => (
                        <CoreListItem
                            onClick={() => {
                                onMenuHeaderClick();
                                onChange(o);
                            }}
                            data-test-id={`Escalate_Dropdown_Option_${i}`}
                            text={o.label}
                            key={o.label}
                        />
                    ))}
                </CoreList>
            </StyledDropdown>
        </div>
    );
};

export default Dropdown;
