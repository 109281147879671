export const name = 'cellular';
import {CellularSignalStrength} from './shared';

interface Options {
    shouldBreakLines?: boolean;
}

export const getAndroidCellularInfoFormattedMessage = (commandResult, options?: Options) => {
    let message = 'Customer is ';
    if (commandResult.carrierName && commandResult.carrierName.length > 0) {
        message += `using *${commandResult.carrierName}* sim, `;
    }
    if (options && options.shouldBreakLines) {
        message += '\n';
    }
    if (commandResult.network && commandResult.network.length > 0) {
        message += `*${commandResult.network}* network`;
    }
    if (commandResult.hasOwnProperty('roaming')) {
        message += commandResult.roaming ? ', Roaming is *ON*' : ', Roaming is *OFF*';
    }
    return message;
};

export const getIosCellularInfoFormattedMessage = commandResult =>
    `Customer is using *${commandResult.carrierName}* sim on a *${commandResult.network.toUpperCase()}* network`;

export const getSignalFormattedMessage = commandResult =>
    `${CellularSignalStrength[commandResult.signalStrengthLevel]} *${commandResult.connectionType}* connection`;
