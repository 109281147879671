import {withSelectedFeedItem} from '@anywhere-expert/expert-feed-store';
import {compose, withProps} from 'recompose';
import {withPropFromObservable, extractFirstName} from '@anywhere-expert/utils';
import {UserDetailsType} from '@anywhere-expert/core';
import {userProfile$, User} from '@anywhere-expert/auth';

const withTechnicianData = compose<{technicianData: UserDetailsType}, {}>(
    withPropFromObservable({fromObservable$: userProfile$, toProp: 'userProfile'}),
    withProps(({userProfile}: {userProfile: User}) => ({
        technicianData: {
            id: userProfile.uid,
            name: extractFirstName(userProfile.displayName),
            thumbUrl: userProfile.imageUrl,
        },
    }))
);

export type TriggerCommandProps = {
    technicianData: UserDetailsType;
    sessionId: string;
};

const withTriggerCommandProps = compose<TriggerCommandProps, {}>(
    withSelectedFeedItem(({sessionId}) => ({sessionId})),
    withTechnicianData
);

export default withTriggerCommandProps;
