import React, {useCallback, useMemo} from 'react';
import {observer} from 'mobx-react';
import {CoreTabs, CoreTabsProps, TechAvatar, CoreText} from '@anywhere-expert/base-ui';
import styled from 'styled-components';
import {useAnalytics} from 'react-shisell';
import Transcript from './Transcript';
import {SupportItem, QueueItemSimilarSessionsModel} from '@anywhere-expert/expert-feed-store';
import {SessionTranscriptData} from '../../SessionTranscript/types';
import {setSessionFeedback} from '../../shared/similarSessionsApi';

const TabHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StyledAvatar = styled(TechAvatar)`
    margin-bottom: 4px;
    line-height: normal;
    letter-spacing: normal;
`;

const createTabHeader = (session: SessionTranscriptData, isSelected: boolean) => {
    const hasExpertName = !!session.expertName;
    const expertName = session.expertName || 'Expert';
    const expertFirstName = expertName.split(' ')[0];
    return (
        <TabHeaderContainer>
            <StyledAvatar
                size={24}
                name={expertName}
                imageUrl={session.expertImage}
                style={{fontSize: '16px'}}
                tryNameInitialsImageFallback={hasExpertName}
            />
            <CoreText size="s" textType={isSelected ? 'primary' : 'tertiary'} weight={isSelected ? 'bold' : 'normal'}>
                {expertFirstName}
            </CoreText>
        </TabHeaderContainer>
    );
};

const createTabHeaders = (similarSessionModel: QueueItemSimilarSessionsModel): CoreTabsProps => {
    return similarSessionModel.similarSessions.reduce(
        (headers, session) => ({
            ...headers,
            [session.sessionId]: {
                label: createTabHeader(
                    similarSessionModel.getTranscriptData(session.sessionId)!,
                    similarSessionModel.selectedTranscriptId === session.sessionId
                ),
            },
        }),
        {}
    );
};

type Props = {
    timelineId: string;
    selectedSession: SupportItem;
};

const TabContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 18px;
    padding-right: 24px;
`;

const TranscriptTabs = observer(({timelineId, selectedSession}: Props) => {
    const analytics = useAnalytics();
    const similarSessions = selectedSession.similarSessions;

    const onTabSelected = useCallback(
        sessionId => {
            similarSessions.setSelectedTranscript(sessionId);
            analytics.dispatcher
                .withExtras({
                    SimilarSessionId: similarSessions.selectedTranscriptId,
                    SimilarExpertId: similarSessions.selectedSessionData!.expertId,
                    SimilarityScore: similarSessions.selectedSimilarityScore,
                    SessionDate: similarSessions.selectedSessionData!.sessionDate,
                })
                .dispatch('Navigate');
        },
        [similarSessions, analytics]
    );

    const tabHeaders = useMemo(() => createTabHeaders(similarSessions), [
        similarSessions,
        similarSessions.selectedTranscriptId,
    ]);

    const setFeedbackRating = useCallback(
        (value: boolean, reason: number | undefined) => {
            setSessionFeedback(
                selectedSession.sessionId,
                selectedSession.store.user.uid,
                similarSessions.selectedTranscriptId,
                value,
                reason
            );
        },
        [
            setSessionFeedback,
            selectedSession.sessionId,
            selectedSession.store.user.uid,
            similarSessions.selectedTranscriptId,
        ]
    );

    const setFeedback = useCallback(
        (value: boolean) => {
            similarSessions.setSelectedFeedback(value);
            setFeedbackRating(value, undefined);
        },
        [similarSessions.setSelectedFeedback, setFeedbackRating]
    );

    const setReason = useCallback(
        (reason: number) => {
            similarSessions.setSelectedNegativeFeedbackReason(reason);
            setFeedbackRating(false, reason);
        },
        [similarSessions.setSelectedNegativeFeedbackReason, setFeedbackRating]
    );

    return (
        <TabContainer data-test-id="SimilarSessions_Tab">
            <div>
                <CoreTabs
                    tabs={tabHeaders}
                    selectedTab={similarSessions.selectedTranscriptId}
                    onTabSelected={onTabSelected}
                />
            </div>
            <Transcript
                setFeedback={setFeedback}
                setReason={setReason}
                transcriptId={similarSessions.selectedTranscriptId}
                sessionTranscriptData={similarSessions.selectedSessionData}
                feedbackStep={similarSessions.selectedFeedbackStep}
                clearSelectedFeedback={similarSessions.clearSelectedFeedback}
                similarityScore={similarSessions.selectedSimilarityScore}
                timelineId={timelineId}
            />
        </TabContainer>
    );
});

export default TranscriptTabs;
