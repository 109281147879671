export * from './types';
import {initVisualComponentsFactory} from './shared';
import * as airplane from '../../commands/airplane/web';
import * as appLink from '../../commands/app-link/web';
import * as apps from '../../commands/apps/web';
import * as battery from '../../commands/battery/web';
import * as bluetooth from '../../commands/bluetooth/web';
import * as cellular from '../../commands/cellular/web';
import * as help from '../../commands/help/web';
import * as location from '../../commands/location/web';
import * as speedTest from '../../commands/speed-test/web';
import * as storage from '../../commands/storage/web';
import * as wifi from '../../commands/wifi/web';

const components = {
    airplane,
    'app-link': appLink,
    apps,
    battery,
    bluetooth,
    cellular,
    help,
    location,
    'speed-test': speedTest,
    storage,
    wifi,
};

export const getCommandVisualComponent = initVisualComponentsFactory(components);
