import commandSender from '../shared/commandSender';
import {ExecuteCommandParameters, ExecuteFactory, GetCommandTweekKey} from '../shared/types';

export const getCommandTweekKey: GetCommandTweekKey = (args: string[]) =>
    !args || args.length === 0 ? 'cellular' : 'cellular_signal';

export const executeFactory: ExecuteFactory = (name: string) => (options: ExecuteCommandParameters) =>
    commandSender({
        commandType: name,
        contentType: 'Command',
        tweekToolKey: getCommandTweekKey(options.args),
        analyticsScope: 'Cellular',
        validateArgs,
        ...options,
    });

const validateArgs = args => args.length === 0 || args[0] === 'signal';
