export default {
    facebook: {
        appName: 'Facebook',
        appBackgroundColor: '#243C6E',
        appCategory: 'Social',
        appRating: 4.1,
        appImageUrl: 'https://home1rawext.blob.core.windows.net/apps-icons/facebook.png',
        androidAppLink: 'https://play.google.com/store/apps/details?id=com.facebook.katana',
        iosAppLink: 'https://itunes.apple.com/il/app/facebook/id284882215',
    },
    instagram: {
        appName: 'Instagram',
        appBackgroundColor: '#6B213A',
        appCategory: 'Social',
        appRating: 4.5,
        appImageUrl: 'https://home1rawext.blob.core.windows.net/apps-icons/instagram.png',
        androidAppLink: 'https://play.google.com/store/apps/details?id=com.instagram.android',
        iosAppLink: 'https://itunes.apple.com/il/app/instagram/id389801252',
    },
    snapchat: {
        appName: 'Snapchat',
        appBackgroundColor: '#AEAC00',
        appCategory: 'Social',
        appRating: 4.1,
        appImageUrl: 'https://home1rawext.blob.core.windows.net/apps-icons/snapchat.png',
        androidAppLink: 'https://play.google.com/store/apps/details?id=com.snapchat.android',
        iosAppLink: 'https://itunes.apple.com/il/app/snapchat/id447188370',
    },
    spotify: {
        appName: 'Spotify Music',
        appBackgroundColor: '#003600',
        appCategory: 'Music & Audio',
        appRating: 4.6,
        appImageUrl: 'https://home1rawext.blob.core.windows.net/apps-icons/spotify.png',
        androidAppLink: 'https://play.google.com/store/apps/details?id=com.spotify.music',
        iosAppLink: 'https://itunes.apple.com/us/app/spotify-music/id324684580',
    },
    whatsapp: {
        appName: 'WhatsApp Messenger',
        appBackgroundColor: '#2A6031',
        appCategory: 'Communication',
        appRating: 4.4,
        appImageUrl: 'https://home1rawext.blob.core.windows.net/apps-icons/whatsapp.png',
        androidAppLink: 'https://play.google.com/store/apps/details?id=com.whatsapp',
        iosAppLink: 'https://itunes.apple.com/il/app/whatsapp-messenger/id310633997',
    },
    gmail: {
        appName: 'Gmail',
        appBackgroundColor: '#673C37',
        appCategory: 'Communication',
        appRating: 4.3,
        appImageUrl: 'https://home1rawext.blob.core.windows.net/apps-icons/gmail.png',
        androidAppLink: 'https://play.google.com/store/apps/details?id=com.google.android.gm',
        iosAppLink: 'https://itunes.apple.com/us/app/gmail-email-by-google/id422689480',
    },
    aol: {
        appName: 'AOL - News, Mail & Video',
        appBackgroundColor: '#006FBF',
        appCategory: 'News & Magazines',
        appRating: 4.2,
        appImageUrl: 'https://home1rawext.blob.core.windows.net/apps-icons/aol.png',
        androidAppLink: 'https://play.google.com/store/apps/details?id=com.aol.mobile.aolapp',
        iosAppLink: 'https://itunes.apple.com/us/app/aol-news-email-weather-video/id646100661',
    },
    yahoo: {
        appName: 'Yahoo Mail',
        appBackgroundColor: '#400194',
        appCategory: 'Communication',
        appRating: 4.3,
        appImageUrl: 'https://home1rawext.blob.core.windows.net/apps-icons/yahoo.png',
        androidAppLink: 'https://play.google.com/store/apps/details?id=com.yahoo.mobile.client.android.mail',
        iosAppLink: 'https://itunes.apple.com/us/app/yahoo-mail/id577586159',
    },
};
