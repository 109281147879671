import React, {useCallback} from 'react';
import styled from 'styled-components';
import VisibilityIndicator from '@system-messages/visibility-indicator';
import {CoreText, CoreButton} from '@anywhere-expert/base-ui';
import {ArrowUpIcon} from '@anywhere-expert/icons';
import {TimelineController} from '@anywhere-expert/timeline-state';
import {withAnalyticOnView, enrichAnalytics, withAnalyticOnEvent} from 'react-shisell';
import {compose} from 'recompose';
import {ExpertFeedStore} from '@anywhere-expert/expert-feed-store';
import {useTweekValue} from 'react-tweek';

const Container = styled.div`
    margin-top: 28px;
    margin-bottom: 24px;
    display: flex;
    align-self: center;
    align-items: center;
    flex-direction: column;
    flex-shrink: 0;
`;

const handlePress = (firstMessageIdInSession: string) => {
    if (firstMessageIdInSession) {
        const contentId = firstMessageIdInSession.substring(firstMessageIdInSession.indexOf('_') + 1);
        if (contentId && contentId.length > 0) {
            TimelineController.scrollToMessage(contentId);
        }
    }
};

type ScrollToMessageContent = {
    firstMessageIdInSession: string;
    message: string;
    sessionId: string;
};

export const ScrollToMessageMessageComp = ({content}: {content: ScrollToMessageContent}) => {
    const {firstMessageIdInSession, message, sessionId} = content;

    const isAutoScrollEnabled = useTweekValue<boolean>(
        'support/session/expert_neglection/post_neglection/auto_scroll/is_enabled',
        false
    );

    const onClick = useCallback(() => handlePress(firstMessageIdInSession), [firstMessageIdInSession, handlePress]);
    if (sessionId !== ExpertFeedStore.selectedSessionId) {
        return null;
    }

    return !isAutoScrollEnabled ? (
        <VisibilityIndicator>
            <ContainerWithAnalytic>
                <CoreText size="m" weight={'medium'} style={{marginBottom: 16}}>
                    {message}
                </CoreText>

                <TouchableOpacityWithAnalytics
                    size="m"
                    color="primary"
                    icon={ArrowUpIcon}
                    onClick={onClick}
                    text="Go there"
                />
            </ContainerWithAnalytic>
        </VisibilityIndicator>
    ) : null;
};

const ContainerWithAnalytic = compose<any, any>(
    withAnalyticOnView({
        analyticName: 'View',
    })
)(Container);

const TouchableOpacityWithAnalytics = withAnalyticOnEvent<any, {}>({
    eventName: 'onClick',
    analyticName: 'Click',
})(CoreButton);

const enhance = compose<any, any>(
    enrichAnalytics((dispatcher, {content}) =>
        dispatcher.createScoped('ViewHistory').withExtra('HandoverType', content.handoverType)
    )
);

export const ScrollToMessageMessageItem = enhance(ScrollToMessageMessageComp);
