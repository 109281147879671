import * as React from 'react';
import {useEffect} from 'react';
import {compose} from 'recompose';
import {SystemMessageItem, withVisibilityIndicator} from '@anywhere-expert/system-messages';
import {getCommandVisualComponent} from '@slash-commands/command-visual-component';
import ErrorBoundary from '../ErrorBoundary';
import {getResultProps, tryJsonParse} from './shared';
import withHideAutoActionForUnimplementedCommands from '../../behaviours/withHideAutoActionForUnimplementedCommands';
import {useAnalytics} from 'react-shisell';

const CommandResultItem = props => {
    const {content, commandType, isSuggestion, ...other} = props;
    const resultProps = getResultProps(props);
    const analytics = useAnalytics();

    useEffect(() => {
        analytics.dispatcher
            .withExtras({commandType, triggeringMethod: other.triggeringMethod, isSuggestion})
            .createScoped('Tools')
            .dispatch('View');
    }, []);

    if (resultProps.isError) {
        return <SystemMessageItem {...resultProps} />;
    } else {
        const commandVisualComponent = getCommandVisualComponent(commandType);
        if (commandVisualComponent) {
            const CommandComponent = isSuggestion
                ? commandVisualComponent.Suggestion!
                : commandVisualComponent.Component;
            return <CommandComponent commandPayload={tryJsonParse(content)} {...other} />;
        } else {
            return <SystemMessageItem message={'Something went wrong'} />;
        }
    }
};

const CommandResultComponent = props => {
    return (
        <ErrorBoundary
            commandType={props.commandType}
            errorComponent={() => <SystemMessageItem message={'Something went wrong'} />}
            validStateComponent={CommandResultItem}
            {...props}
        />
    );
};

const enhance = compose(withHideAutoActionForUnimplementedCommands, withVisibilityIndicator);

export default enhance(CommandResultComponent);
