import React from 'react';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import {FeedItemName} from '@anywhere-expert/feed-item-name';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import {CoreText} from '@anywhere-expert/base-ui';

type Props = {
    selectedSession: SupportItem;
    expanded: boolean;
    tags: JSX.Element;
};

const FeedItemContainer = styled.div<{expanded: boolean}>`
    display: flex;
    width: fit-content;
    ${({expanded}) => !expanded && 'margin-right: 6px'};
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    overflow: hidden;
    padding-right: 4px;
    flex: 1;
    min-width: 0;
`;

const BasicSessionDetails = observer(({selectedSession, expanded, tags}: Props) => {
    const {isAssignedToMe, inFeed} = selectedSession;
    const readonly = !isAssignedToMe || !inFeed;

    return (
        <Container>
            <FeedItemContainer expanded={expanded}>
                <FeedItemName
                    session={selectedSession}
                    fontSize={16}
                    editIconPosition="before"
                    editableByIconClick={!readonly}
                    editableByInput={!readonly}
                    hideEditIconWhenEditing={false}
                    data-test-id="FeedItemNameInput"
                    weight="bold"
                />
            </FeedItemContainer>
            {!expanded && tags}
        </Container>
    );
});

export default BasicSessionDetails;
