import React from 'react';
import {useAnalytics} from 'react-shisell';
import {User} from '@anywhere-expert/auth/api';
import {CoreIconButton, CoreTooltipProps} from '@anywhere-expert/base-ui';
import {NotesIcon} from '@anywhere-expert/icons';
import {ResolveButton, SnoozeMenu, FollowUpButton} from '@anywhere-expert/session-actions';
import {useTweekValue} from 'react-tweek';
import {useIsShowingEscalate} from '@anywhere-expert/escalate';
import {SupportItem} from '@anywhere-expert/expert-feed-store';
import useQuickResolveActions from './useQuickResolveActions';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import {openAutopilotModal} from '@anywhere-expert/autopilot';
import {useIsFollowUpEnabled} from '@anywhere-expert/follow-up';
import SessionAdditionalActions from './SessionAdditionalActions';
import {SearchIcon} from '@anywhere-expert/icons';
import {SidebarStore} from '@anywhere-expert/session-sidebar';
import {shortcutsMap} from '@anywhere-expert/app-shortcuts-consts';

const iconProps = {size: 's'};

const notesTooltipProps: Omit<CoreTooltipProps, 'children'> = {
    text: 'Session Notes',
    keyboardSequence: shortcutsMap['session-notes'].key,
};

const searchTooltipProps: Omit<CoreTooltipProps, 'children'> = {
    text: 'Search messages',
    keyboardSequence: shortcutsMap['find-in-page'].key,
};

export const Container = styled.div`
    z-index: 2;
    display: flex;
    align-items: center;
    > button {
        margin-left: 2px;
        margin-right: 2px;
    }
    > div {
        margin-left: 2px;
        margin-right: 2px;
    }
`;

export const ResolveContainer = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    align-content: flex-start;
`;

export const Splitter = styled.div`
    background-color: rgba(0, 0, 0, 0.1);
    display: block;
    width: 1px;
    margin-right: 12px;
    height: 24px;
`;

const Badge = styled.div<{show: boolean}>`
    ${({show}) => !show && `opacity: 0`};
    width: 10px;
    height: 10px;
    background-color: ${({theme}) => theme.colors.brand.blue};
    border-radius: 50%;
    position: absolute;
    top: 1px;
    border: 2px solid ${({theme}) => theme.colors.background.secondary};
    right: 2px;
    z-index: 1;
`;

const NotesContainer = styled.div`
    position: relative;
`;

type enabledSessionActions = {
    conclude: boolean;
    snooze: boolean;
    escalate: boolean;
};

type SessionActionsProps = {
    disabled: boolean;
    user: User;
    selectedSession: SupportItem;
};

const SessionActions = observer(({disabled, user, selectedSession}: SessionActionsProps) => {
    const cobrowsingStore = selectedSession.timelineModel.cobrowsingStore;
    const {dispatcher} = useAnalytics();
    const isShowingEscalate = useIsShowingEscalate();
    const visible =
        selectedSession.inFeed &&
        selectedSession.isAssigned &&
        (selectedSession.isAssignedToMe || (user && user.roles && user.roles.includes('moderator')));
    disabled = disabled || isShowingEscalate;

    const enabledSessionActions = useTweekValue<enabledSessionActions>(
        'support/session/during/enabled_session_actions/_',
        {
            conclude: true,
            snooze: true,
            escalate: true,
        }
    );

    const isFollowUpEnabled = useIsFollowUpEnabled();
    const isAutopilotEnabled = useTweekValue<boolean>('support/feed/autopilot/is_enabled', false);

    const openNotesTab = React.useCallback(() => {
        SidebarStore.setSelectedTabId('notes');
        dispatcher
            .withExtras({
                ActionId: 'NotesShortCut',
                OpensToolbox: true,
            })
            .dispatch('Click');
    }, [dispatcher]);

    const onSearchClick = React.useCallback(() => {
        SidebarStore.setSelectedTabId('timeline-search');
        dispatcher
            .withExtras({
                ActionId: 'TimelineSearch',
                OpensToolbox: true,
            })
            .dispatch('Click');
    }, [dispatcher]);

    const escalateIsEnabled =
        enabledSessionActions.escalate && !selectedSession.isClosed && !cobrowsingStore?.isCobrowsingOn;
    const snoozeIsEnabled =
        enabledSessionActions.snooze && !selectedSession.isClosed && !cobrowsingStore?.isCobrowsingOn;

    const {isResolveMenuEnabled, isQuickResolve} = useQuickResolveActions(user, selectedSession);

    const isTimelineSearchEnabled = useTweekValue<boolean>('support/session/messaging/search/is_enabled', false);

    if (!visible) {
        return null;
    }

    return (
        <Container>
            {isTimelineSearchEnabled && (
                <CoreIconButton
                    variant="icon"
                    size={'s'}
                    tooltipProps={searchTooltipProps}
                    icon={SearchIcon}
                    onClick={onSearchClick}
                />
            )}
            {isFollowUpEnabled && (
                <FollowUpButton
                    disabled={disabled}
                    isFollowUpOngoing={!!selectedSession?.followUpData?.scheduledTime}
                />
            )}
            <NotesContainer>
                <Badge show={selectedSession.notesStore.hasUnseenPreviousNotes} />
                <CoreIconButton
                    variant="icon"
                    data-test-id="NotesShortcutPicker"
                    size="s"
                    iconProps={iconProps}
                    tooltipProps={notesTooltipProps}
                    icon={NotesIcon}
                    onClick={openNotesTab}
                />
            </NotesContainer>
            {snoozeIsEnabled && (
                <SnoozeMenu
                    disabled={disabled}
                    sessionId={selectedSession.sessionId}
                    childStyle={{right: 0}}
                    openAutopilotModal={openAutopilotModal}
                    showAutopilotMessage={isAutopilotEnabled}
                />
            )}
            <SessionAdditionalActions
                selectedSession={selectedSession}
                escalateIsEnabled={escalateIsEnabled}
                actionsDisabled={disabled}
            />
            {enabledSessionActions.conclude && (
                <ResolveContainer>
                    <Splitter></Splitter>
                    <ResolveButton
                        buttonText="Resolve"
                        sessionId={selectedSession.sessionId}
                        isDisabled={disabled}
                        isResolveMenuEnabled={isResolveMenuEnabled}
                        shouldOpenResolveMenu={isQuickResolve}
                    />
                </ResolveContainer>
            )}
        </Container>
    );
});

export default SessionActions;
