import React from 'react';
import {reaction} from 'mobx';
import logger from '@anywhere-expert/logging';
import {addedSupportItems$, deletedSupportItems$} from '@anywhere-expert/expert-feed-store';
import {
    customIdToContextConfig,
    getTweekRepoForContext,
    removeRepoFromCache,
    createSupportItemTweekContext,
} from '@anywhere-expert/tweek';

const testKey = 'support/_';
export default () => {
    React.useEffect(() => {
        const addedSubscription = addedSupportItems$.subscribe(addedItems => {
            addedItems.forEach(addedItem => {
                if (!addedItem.isAssignedToMe) return;

                reaction(
                    () => addedItem.supportItemDetails,
                    async (supportItemDetails, _prev, reaction) => {
                        if (!supportItemDetails) return;

                        try {
                            const context = customIdToContextConfig(
                                addedItem.id,
                                createSupportItemTweekContext(
                                    addedItem.sessionId,
                                    addedItem.pool,
                                    addedItem.sessionAttributes
                                )
                            );
                            const repo = getTweekRepoForContext(context);

                            const cached = repo.getCached(testKey);
                            if (!cached || cached.state !== 'cached') {
                                repo.prepare(testKey);
                            }
                        } catch (err) {
                            logger.error('Failed creating/prefetching customer tweek context', {
                                err,
                                extra: {sessionId: addedItem.sessionId},
                            });
                        } finally {
                            reaction.dispose();
                        }
                    },
                    {
                        name: `supportItemDetails_tweek_reaction_${addedItem.sessionId}`,
                        fireImmediately: true,
                    }
                );
            });
        });

        const deletedSubscription = deletedSupportItems$.subscribe(deletedItems => {
            deletedItems.forEach(deletedItem => {
                const context = customIdToContextConfig(deletedItem.id);
                removeRepoFromCache(deletedItem.id, context.type);
            });
        });

        return () => {
            addedSubscription.unsubscribe();
            deletedSubscription.unsubscribe();
        };
    }, []);
};
