import React from 'react';
import {useTheme} from '@anywhere-expert/colors';

export default () => {
    const {
        colors: {
            neutral: {black},
        },
    } = useTheme();

    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.85156 25.4368C9.42246 29.346 13.0643 29.9958 15.2305 30.4536C16.6646 30.7565 18.9591 30.7216 19.3244 30.991C20.2134 31.648 20.738 34.2467 21.6694 35.0919C22.3848 34.0967 22.8584 31.8028 23.3382 31.1105C23.5409 30.8181 24.594 30.8802 25.1181 30.885C28.5391 30.9184 32.4385 29.948 34.6652 27.1687C36.2357 25.2079 36.4861 22.4023 35.8547 20.0588C35.1431 17.4194 32.9693 15.5728 30.3867 14.7673C26.9352 13.6908 23.2438 13.6645 19.6764 14.0339C17.7144 14.2369 15.5129 14.3258 13.8508 15.4854"
                stroke={black}
                strokeWidth="1.421"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.50127 18.5122C8.92127 17.0622 8.05127 13.5822 10.6613 11.8422C12.7146 10.4733 15.3013 10.6332 17.3313 10.9722C19.042 11.2579 20.4818 11.8113 21.1013 12.1322C21.6307 12.4065 19.8079 9.78697 16.1713 8.65222C12.5347 7.51747 8.63127 8.36221 6.02129 10.9722C4.23837 12.7551 3.90545 15.4674 4.23837 17.1348C4.57129 18.8021 5.23992 20.2714 6.34877 22.0321C6.34877 22.0321 3.91693 22.5553 2.9707 22.9805C3.74428 23.681 6.54002 24.5133 7.66029 24.7197C9.12067 24.9882 11.4457 25.5835 12.7276 25.4363C13.0262 25.4024 13.6262 25.4468 13.3238 24.389C12.9065 22.928 12.7276 22.271 12.37 20.7779C12.0266 19.3455 11.7132 17.0836 11.595 15.6417"
                stroke={black}
                strokeWidth="1.421"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.6221 19.7806C20.4561 19.4318 25.7709 19.5188 29.5177 20.0429"
                stroke={black}
                strokeWidth="1.421"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.1445 24.0629C21.3271 23.801 25.2503 23.7394 29.256 24.0629"
                stroke={black}
                strokeWidth="1.421"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.03125 25.7366C10.2336 25.8637 12.1065 25.8011 13.264 25.2588"
                stroke={black}
                strokeWidth="1.421"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
