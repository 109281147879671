import React from 'react';
import styled, {css} from 'styled-components';
import {CoreIconButton, getBorderStyle, getBoxShadowStyle} from '@anywhere-expert/base-ui';
import {ChevronDownIcon} from '@anywhere-expert/icons';

const MoreDetailsContainer = styled.div`
    right: 0;
    left: 0;
    justify-content: center;
    display: flex;
    position: absolute;
    bottom: -12px;
`;

const Rotatable = styled.div<{open: boolean}>`
    display: flex;
    align-items: center;
    justify-content: center;

    ${({open}) =>
        open
            ? css`
                  transform: rotateX(180deg);
              `
            : css`
                  transform: rotateX(0deg);
              `};
`;

const StyledCoreIconButton = styled(CoreIconButton)`
    background-color: ${({theme}) => theme.themeName === 'dark' && theme.colors.neutral.white};
    ${({theme}) => getBoxShadowStyle(theme)};
    ${({theme}) => getBorderStyle(theme)};
`;
const RotatableIcon = ({open, ...others}: {open: boolean}) => (
    <Rotatable open={open}>
        <ChevronDownIcon {...others} />
    </Rotatable>
);

type Props = {
    onClick: () => void;
    expanded: boolean;
};

const ExpandButton = ({onClick, expanded}: Props) => (
    <MoreDetailsContainer data-test-id="SessionDetails_Header_ExpandButton">
        <StyledCoreIconButton
            icon={RotatableIcon}
            onClick={onClick}
            variant="floating"
            iconProps={{open: expanded}}
            size="s"
        />
    </MoreDetailsContainer>
);

export default ExpandButton;
