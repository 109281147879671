import {useEffect} from 'react';
import {TimelineModel} from '@anywhere-expert/timeline-state';

const useExpandOnNewSessions = (
    timelineModel: TimelineModel,
    shouldShowExpandButton: boolean,
    expand: () => void,
    collapse: () => void
) => {
    useEffect(() => {
        if (shouldShowExpandButton && timelineModel.isNewlyAssignedSession) {
            expand();
        } else {
            collapse();
        }
    }, [shouldShowExpandButton, timelineModel.isNewlyAssignedSession, timelineModel.timelineId]);
};

export default useExpandOnNewSessions;
