import React, {useEffect, useMemo} from 'react';
import TextMessage from '../TextMessage';
import {useAnalytics} from 'react-shisell';

const BreakdownsCarouselItem = () => {
    const analytics = useAnalytics();
    const dispatcher = useMemo(() => analytics.dispatcher.createScoped('BreakdownsCarouselItem'), [
        analytics.dispatcher,
    ]);

    useEffect(() => {
        dispatcher.dispatch('View');
    }, []);

    return (
        <TextMessage
            contentId="BreakdownsCarouselItem"
            isSelf={true}
            showThumbnail={false}
            senderThumbUrl="teamIcon"
            isGroupedItem={true}
            content={`Defects in materials and/or workmanship, power surges, normal wear and tear, dust, heat, or humidity.
                (FYI - the customer is seeing an image gallery of all covered breakdown scenarios)`}
        />
    );
};

export default BreakdownsCarouselItem;
