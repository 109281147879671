import React from 'react';
import {SystemMessageItem, IconType} from '@anywhere-expert/system-messages';
import FeatureSuggestionComponent from '@slash-commands/feature-suggestion';
import {VisualComponentType} from '@slash-commands/command-visual-component';
import {LocationIcon} from '@anywhere-expert/icons';
import {featureSuggestionTexts, onFeatureSuggestionClick, getResult} from '../shared';
const LocationIconSVG = require('./ic-location.svg');

const suggestionIcon = require('./ic_suggestion.svg');

export {description} from '../shared';

export const icon: IconType = LocationIconSVG;

export const iconOutline = LocationIcon;

export const Component: VisualComponentType = ({commandPayload}) => {
    const result = getResult({commandPayload});
    if (result.success) {
        return <SystemMessageItem message={result.message} icon={icon} />;
    } else {
        return <SystemMessageItem message={result.message} />;
    }
};

const commandName = 'location';
export const Suggestion: VisualComponentType = ({timelineId}) => (
    <FeatureSuggestionComponent
        timelineId={timelineId}
        featureSuggestionTexts={featureSuggestionTexts}
        suggestionIcon={suggestionIcon}
        handleClick={onFeatureSuggestionClick}
        commandName={commandName}
        featureCommand={`/${commandName}`}
        analyticsActionId={'RunLocation'}
    />
);
