import {branch, renderNothing, compose} from 'recompose';
import {getCommand} from '../commandFactory';
import {getCommandVisualComponent} from '@slash-commands/command-visual-component';
import logger from '@anywhere-expert/logging';

const isCommandImplementedOnPlatform = (commandType, platform) => {
    try {
        const command = getCommand(commandType);
        const implementedPlatforms = command && command.implementedPlatforms;
        const expertPlatform = platform.toLowerCase();

        return !implementedPlatforms || implementedPlatforms[expertPlatform] !== false;
    } catch (err) {
        logger.error('There was a problem getting isCommandImplementedOnPlatform for command', {
            err,
            extra: {commandType, platform},
        });
        return false;
    }
};

const isSuggestionImplemented = commandType => {
    try {
        const commandVisualComponent = getCommandVisualComponent(commandType);
        return commandVisualComponent && commandVisualComponent.Suggestion;
    } catch (err) {
        logger.error('There was a problem getting isSuggestionImplemented for command', {
            err,
            extra: {commandType},
        });
        return false;
    }
};

export default compose(
    branch(({triggeringMethod, commandType, isSuggestion}) => {
        if (triggeringMethod !== 'AutoAction') return false;
        return (
            !isCommandImplementedOnPlatform(commandType, 'web') ||
            (isSuggestion && !isSuggestionImplemented(commandType))
        );
    }, renderNothing)
);
