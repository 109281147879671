import {getCommand} from './commandFactory';
import {ExecuteCommandParameters} from './commands/shared/types';

export type ParsedCommandObject = {
    execute?: (options: ExecuteCommandParameters) => void;
    args?: string[];
    commandName?: string;
};

const parseMessageToCommand = (message: string): ParsedCommandObject => {
    const startsWithSlash = message && message.startsWith('/');
    if (!startsWithSlash) return {};
    const messageWithoutSlash = message.substr(1).trim();
    const commandArguments = messageWithoutSlash.split(' ');
    const commandName = commandArguments[0].toLowerCase();
    const command = getCommand(commandName);

    return {execute: command && command.executeFactory(commandName), args: commandArguments.slice(1), commandName};
};

export default parseMessageToCommand;
