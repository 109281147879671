import {useAnalytics} from 'react-shisell';
import {CoreButton, CoreButtonProps} from '@anywhere-expert/base-ui';
import {useResolveAction} from '@anywhere-expert/resolve-session';
import React, {useCallback} from 'react';

type ActionButtonProps = Pick<CoreButtonProps, Exclude<keyof CoreButtonProps, 'onClick' | 'isInProgress' | 'Icon'>> & {
    actionId: string;
    sessionId: string;
    closeResolveMenu?: () => void;
};

const ResolveButton = ({actionId, sessionId, ...rest}: ActionButtonProps) => {
    const analytics = useAnalytics();
    const [onResolve, resolveState] = useResolveAction(sessionId);
    const onResolveEvent = useCallback(() => {
        analytics.dispatcher.withExtra('ActionId', actionId).dispatch('Click');
        onResolve();
    }, [onResolve]);

    return <CoreButton isInProgress={resolveState === 'PENDING'} onClick={onResolveEvent} {...rest} />;
};

export default ResolveButton;
