import * as React from 'react';
import {getCommandVisualComponent} from '@slash-commands/command-visual-component';
import {getCommand} from '@anywhere-expert/slash-commands';
import {triggerCommand} from '@anywhere-expert/slash-commands';
import {UserDetailsType} from '@anywhere-expert/core';
import {AnalyticsDispatcher} from 'shisell';
import {ContentItem} from '@anywhere-expert/session-sidebar';

const onCommandClick = async (
    timelineId: string,
    sessionId: string,
    technicianData: UserDetailsType,
    analyticsDispatcher: AnalyticsDispatcher,
    commandName: string,
    scopedDispatcher: AnalyticsDispatcher
) => {
    scopedDispatcher
        .withExtra('ToolName', commandName)
        .withExtra('ActionId', commandName)
        .dispatch('Click');

    await triggerCommand({
        timelineId,
        sessionId,
        expertData: technicianData,
        analyticsDispatcher,
        commandMessage: `/${commandName}`,
        triggeringMethod: 'ExploreMore',
    });
};

const Command = ({commandName, timelineId, sessionId, technicianData, dispatcher, scopedDispatcher}) => {
    const command = getCommand(commandName);
    const commandRequiresArguments = command.requiresArgs;

    const [didClick, setDidClick] = React.useState(false);
    const handleClick = React.useCallback(async () => {
        if (commandRequiresArguments) return;
        await onCommandClick(timelineId, sessionId, technicianData, dispatcher, commandName, scopedDispatcher);
        setDidClick(true);
        setTimeout(() => {
            setDidClick(false);
        }, 5000);
    }, [setDidClick]);

    const commandVisualComponent = getCommandVisualComponent(commandName);

    if (!commandVisualComponent) {
        return null;
    }

    return (
        <ContentItem
            Icon={commandVisualComponent.iconOutline}
            title={commandVisualComponent.description!}
            tooltipText={`/${commandName}`}
            dataTestId={`CommandTab_Command_${commandName}`}
            onClick={handleClick}
            disabled={didClick}
        />
    );
};

export default Command;
