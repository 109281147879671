import React, {useCallback, CSSProperties} from 'react';
import {CoreTag} from '@anywhere-expert/base-ui';
import styled from 'styled-components';
import {hexToRgba} from '@anywhere-expert/colors';
import {onAddTag, SuggestedTags, SuggestedTag} from './types';
import {CSSTransition, TransitionGroup} from 'react-transition-group';

const StyledTag = styled(CoreTag)<{backgroundColor?: string}>`
    ${({backgroundColor}) => backgroundColor && `border: 1px solid ${backgroundColor};`};
    background-color: unset;
    margin-bottom: 4px;
    cursor: pointer;
    color: ${({theme}) =>
        theme.themeName === 'light' ? theme.colors.neutral.whites.w60 : theme.colors.neutral.blacks.b80};

    &:hover {
        ${({backgroundColor}) => backgroundColor && `background: ${hexToRgba(backgroundColor, 0.2)}`};
        color: ${({theme}) => theme.colors.neutral.black};
    }

    &:active {
        ${({backgroundColor}) => backgroundColor && `background: ${hexToRgba(backgroundColor, 0.4)}`};
        color: ${({theme}) => theme.colors.neutral.black};
    }
`;

type Props = {
    suggestedTags: SuggestedTags;
    onClick: onAddTag;
};

const Tag = ({tag, onClick, ...other}: {tag: SuggestedTag; onClick: onAddTag}) => {
    const [tagKey, {backgroundColor, textColor}] = tag;

    const onTagClick = useCallback(() => {
        onClick({tagKey, backgroundColor, textColor, source: 'Pick_Suggestion'});
    }, [tagKey, backgroundColor, textColor, onclick]);

    return (
        <StyledTag
            onClick={onTagClick}
            text={tagKey}
            data-test-id={`tag-suggestion-${tagKey}`}
            size="m"
            backgroundColor={backgroundColor}
            isWrapped={true}
            {...other}
        />
    );
};

const TagWithAnimation = styled(Tag)`
    &.tag-exit {
        opacity: 1;
    }
    &.tag-exit-active {
        opacity: 0;
        transition: opacity 200ms ease-in;
    }
`;

const containerStyle: CSSProperties = {
    display: 'flex',
    overflow: 'hidden',
    flexWrap: 'wrap',
};

const TagsSuggestions = ({suggestedTags, onClick}: Props) => (
    <TransitionGroup style={containerStyle}>
        {Array.from(suggestedTags).map(tag => {
            const [key] = tag;
            return (
                <CSSTransition key={key} timeout={200} classNames="tag">
                    <TagWithAnimation key={key} tag={tag} onClick={onClick} />
                </CSSTransition>
            );
        })}
    </TransitionGroup>
);

export default TagsSuggestions;
