import React from 'react';
import {StyleSheet, css} from 'aphrodite';
import {getPermissionFailedMessage} from '../../../../shared/getPermissionFailedMessage';
import WifiSuggestedCommands from '../../../WifiSuggestedCommands.web';
import {palette} from '@anywhere-expert/colors';

const styles = StyleSheet.create({
    mainContainer: {
        marginTop: '24px',
        marginBottom: '24px',
        alignSelf: 'center',
        display: 'flex',
        flexShrink: 0,
        flexDirection: 'column',
    },
    message: {
        textAlign: 'center',
        fontSize: '14px',
        color: palette.neutral.grey.deepest,
        fontFamily: 'Roboto',
        lineHeight: 1.54,
    },

    bold: {
        fontWeight: 500,
    },
});

export default ({commandResult, dataTestId}) => {
    let message;
    let suggestions;
    if (commandResult.isEnabled === false) {
        message = <div className={css(styles.message)}>{'It seems the Wi-Fi is turned OFF'}</div>;
        suggestions = <WifiSuggestedCommands headerText={'To turn it on:'} showWifiToggleSuggestion={true} />;
    } else if (commandResult.refusePermission) {
        message = <div className={css(styles.message)}>{getPermissionFailedMessage(commandResult.reason)}</div>;
    } else if (commandResult.ssidNotFound) {
        message = (
            <div className={css(styles.message)}>
                <span className={css(styles.bold)}>{commandResult.ssid}</span>
                {' was not found, make sure it was typed correctly'}
            </div>
        );
    } else if (commandResult.isTimeout || commandResult.passwordRequestFailed) {
        message = (
            <div className={css(styles.message)}>
                {'Could not connect to '}
                <span className={css(styles.bold)}>{commandResult.ssid}</span>
            </div>
        );
    } else {
        message = `Something went wrong, please try again later`;
    }
    return (
        <div className={css(styles.mainContainer)} data-test-id={dataTestId}>
            {message}
            {suggestions}
        </div>
    );
};
